import axios, { AxiosRequestConfig } from 'axios';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { API } from '../properties/apiProperties';

function stringifyFileObject(arrWithFiles: any) {
    const arrData = [];
    for (let i = 0; i < arrWithFiles.length; i++) {
        const file = arrWithFiles[i];
        const obj = {
            id: file.id,
            name: file.name,
            type: file.type,
            location: file.location,
        }
        arrData.push(obj);
    }
    return JSON.stringify(arrData)
}

export const uploadPresignedFile = async (file: any, generated_token: any) => {
    let result = { 'id': file['id'], 'response': {} };
    try {
        if (generated_token) {
            const formData = new FormData();
            Object.entries(generated_token['fields']).forEach((pair: any) => {
                formData.append(pair[0], pair[1]);
            });
            formData.append('file', file); // The file has be the last element
            const options: AxiosRequestConfig = {
                url: generated_token['url'],
                method: "POST",
                headers: {
                    "Content-Type": 'multipart/form-data',
                    "Content-Disposition": "attachment",
                },
                data: formData,
            };
            result['response'] = await (axios(options));
            return Promise.resolve(result);
        }
        else {
            return Promise.resolve(result);
        }
    }
    catch{
        return Promise.resolve(result);
    }
}


export const getPresignedUrl = async (files_obj: any, flag: string, bbDomain: string) => {
    let result = null;
    // let filesList = stringifyFileObject(files_obj)
    const requestOptions: AxiosRequestConfig = {
        url: API.filetransferApi,
        method: 'POST',
        headers: {
            bbdomain: bbDomain,
            bucketId: localStorage.getItem('bucket_name'),
            // msgId: msgId,
            domainId: localStorage.getItem('domainID'),
            schoolId: localStorage.getItem('reachOrgSchoolId'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
        data: {
            file: files_obj,
            flag: flag
        }
    };
    result = await axios(requestOptions);
    return result
};

axios.interceptors.request.use(function(config) {
    config.headers.ACCEPT = 'application/json';
    return config;
});