import React, { useState, useContext } from 'react';
import {
  Card,
  CardContent,
  ListItem,
  Box,
  Divider,
  Link,
} from '@material-ui/core';
import BadgeAvatar from '../BadgeAvatar';
import ReactHtmlParser from 'react-html-parser';
import { UserAuthCtx } from '../AuthComponent';
import MessagePanelStyles from '../MessagesPanel/MessagePanelStyle';
import MessageReplyIcon from '../MessageReplyIcon';
import { GroupType } from '../../Types/GroupType';
import { GroupEssential } from '../../Types/GroupEssential';
import { useMediaQuery } from 'react-responsive';
import { StateContext } from '../StateContextComponent';
import { highlightMatchedChars } from '../SearchResultPanel/SearchResultPanel';
import { MessageCardStyles } from '../MessageCard/MessageCard';
import { channelList } from '../../dataLoaders/DataHandler';
import MessageDisplayType from './MessageDisplayType';
import AttachmentDisplayComponent from "../AttachmentDisplayComponent/AttachmentDisplayComponent"
import CustomCircularProgress from '../ReusableComponents/CircularProgress';
const DOMPurify = require('dompurify');

function DateFormat(dateString: string) {
  const dateObj = new Date(dateString);
  return dateObj.toLocaleDateString() === new Date().toLocaleDateString()
  ? 'Today'
  : dateObj.toDateString();
}

function epochToJsDate(ts: number) {
    return new Date(ts * 1000);
}

const MessageDisplayComponent = ({
    setShowImagePreview,
    message,
    index,
    setSelectedMsg,
    authContextUserName,
    authContextUserRole,
    replyHandler,
    directMessageHandler,
    showFilesErrorMsg,
    selectedMsg,
    dateItem,
    selectedGroup,
    jumpToMessage,
    myRefs,
    setAlertOpen,
    translateMessage,
    getOriginalMsg,
    translateInProgress,
    translateMessageID,
    currentPreferredLanguage
}: MessageDisplayType) => {
    const classes = MessagePanelStyles()();
    let cardStyles = MessageCardStyles();
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isDirect = selectedGroup.groupType === GroupType.DirectMessage;
    const userContext = useContext(UserAuthCtx).userCtx;
    const recipientPreferredLanguage = currentPreferredLanguage || userContext.preferredLanguage.code;

    const jump = async () => {
        var groupDetails: any = {};
        const { id, channelId, createdAt } = message.parentMessage;
        const domain = userContext.userLoggedInDomain;
        var split_channel_id = channelId.split('|');
        groupDetails['groupType'] = split_channel_id[0];
        if (groupDetails['groupType'] == 'CLASSES') {
            groupDetails['groupid'] = split_channel_id[1] + '|' + split_channel_id[2];
        } else {
            groupDetails['groupid'] = split_channel_id[1];
        }
        groupDetails['offset'] = id;
        groupDetails['lastdate'] = createdAt;
        //jumpToMessage(groupDetails);
        //jumpToMessage(message.parentMessage.channelId.split('|')[1], 'c24f50d6f9b14e79bb121748fd758dc2');

        const existingChannel = channelList.find(
            (channel) => channel.id === groupDetails['groupid']
        );
        if (existingChannel) {
            jumpToMessage(groupDetails);
        } else {
            setAlertOpen(true);
            return false;
        }
    };

    const { impersonating, impersonatingUserDetails } = useContext(StateContext);

    const loggedInUserId = (impersonating) ?
        `${impersonatingUserDetails.userId}|${userContext.domainId}` :
        `${userContext.userId}|${userContext.domainId}`;

    var msgToDisp = message.showTranslatedMessage
        ? message.translatedMessage
        : message.message;

    if (
        impersonating &&
        impersonatingUserDetails.highlightTokens !== undefined &&
        message.id === impersonatingUserDetails.groupDetails.offset
    ) {
        msgToDisp = highlightMatchedChars(
            msgToDisp,
            impersonatingUserDetails.highlightTokens
        );
    }

    const [needTouchEvent, setNeedTouchEvent] = useState(true);
    const getFileAttachments = () => {
        return (
            <AttachmentDisplayComponent files={message.uploadedFiles} setShowImagePreview={setShowImagePreview} showFilesErrorMsg={showFilesErrorMsg} />
        )
    }
  function createMarkup(msgHtmlElm: string) {
    var htmlElemnt = DOMPurify.sanitize(msgHtmlElm)
    DOMPurify.addHook('afterSanitizeAttributes', function (msgHtmlElm : any) {
        if ('target' in msgHtmlElm) {
            msgHtmlElm.setAttribute('target', '_blank');
            msgHtmlElm.setAttribute('rel', 'noopener noreferrer');
        }
      });
    return {__html: htmlElemnt};
  }
  
  function getMessage_div(msgHtmlElm: string) {
    return <div dangerouslySetInnerHTML={createMarkup(msgHtmlElm)} />;
  }  

    const isAttachment = message.attachment;
    const showMessageReplyIcon = (message.profile.role.toLowerCase() === 'parent' || message.profile.role.toLowerCase() === 'student') &&
        (authContextUserRole === 'PARENT' || authContextUserRole === 'STUDENT')

    if (message.parentMessage && message.parentMessage.id) {
        return (
            <ListItem
                id={message.id}
                data-testid={`item-${dateItem}-${index}`}
                key={`item-${dateItem}-${index}`}
                className={classes.eachMessage}
                onMouseEnter={() => setSelectedMsg(`item-${dateItem}-${index}`)}
                onMouseLeave={() => setSelectedMsg('')}
                ref={myRefs[message.id]}
            >
                <div className={classes.replyToMessage}>
                    <Card className={classes.replyToCard} variant='outlined'>
                        <CardContent>
                            <div className={classes.liMessage}>
                                <div className={classes.cardTitle}>
                                    <img
                                        className={classes.courseIconClass}
                                        src='/images/courses.png'
                                    />
                                    <span>
                                        {message.parentMessage.details.channelName +
                                            ' - ' +
                                            DateFormat(
                                                epochToJsDate(
                                                    message.parentMessage.createdAt
                                                ).toString()
                                            ) +
                                            ' at ' +
                                            epochToJsDate(
                                                message.parentMessage.createdAt
                                            ).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                    </span>
                                    <img
                                        className={classes.jumpIconClass}
                                        src='/images/jump-to.png'
                                        onClick={jump}
                                        alt='jump'
                                    />
                                </div>
                                <Box
                                    display='flex'
                                    flexDirection='row'
                                    width='100%'
                                    paddingTop='12px'
                                >
                                    <Box component='div' display='flex'>
                                        <BadgeAvatar
                                            size={classes.medium}
                                            name={message.parentMessage.senderName}
                                        />
                                    </Box>
                                    <Box display='flex' flexDirection='column' width='100%'>
                                        <Box display='flex' flexDirection='row'>
                                            <div className={classes.group}>
                                                <div className={classes.profileName}>
                                                    {message.parentMessage.senderName}
                                                </div>
                                                <div className={classes.profileRole}>
                                                    {message.parentMessage.details.senderRole?.toLowerCase()}
                                                </div>
                                            </div>
                                        </Box>
                                        <Box component='div' className={classes.copiedMessage}>
                                        {ReactHtmlParser(DOMPurify.sanitize(message.parentMessage.details.messageContent))}
                                        </Box>
                                        {message.parentMessage.attachment && <span style={{ color: "#8C8C8C", fontSize: "14px" }}>{message.parentMessage.uploadedFiles.length} {message.parentMessage.uploadedFiles.length > 1 ? 'attachments' : 'attachment'}</span>}
                                    </Box>
                                </Box>
                            </div>
                        </CardContent>
                    </Card>
                    <Box display='flex' flexDirection='row' width='100%'>
                        <Box component='div' display='flex'>
                            <BadgeAvatar size={classes.medium} name={message.profile.name} />
                        </Box>
                        <Box display='flex' flexDirection='column' width='100%'>
                            <Box display='flex' flexDirection='row'>
                                <div className={classes.group}>
                                    <div className={classes.profileName}>
                                        {message.profile.name}
                                    </div>
                                    <div className={classes.profileRole}>
                                        {message.profile.role.toLowerCase()}
                                    </div>
                                    <Divider className={classes.divider} orientation='vertical' />
                                    <div className={classes.messageTime}>{message.Time}</div>
                                </div>
                                {!isDirect &&
                                    !isMobile &&
                                    message.profile.name !== authContextUserName &&
                                    !showMessageReplyIcon &&
                                    !impersonating && (
                                        <MessageReplyIcon
                                            id={`item-${dateItem}-${index}`}
                                            selectedMsg={selectedMsg}
                                            replyHandler={replyHandler}
                                            directMessageHandler={directMessageHandler}
                                            isMobile={isMobile}
                                        />
                                    )}
                            </Box>
                            <Box
                                component='div'
                                className={`${classes.message} ${cardStyles.highlighter}`}
                            >
                                {getMessage_div(msgToDisp)}
                                {isAttachment && getFileAttachments()}
                                {loggedInUserId !== message.sender &&
                                    message.sourceLanguage && (
                                        <>
                                            {message.isTranslated ? <Link
                                                data-testid='see-translation-link'
                                                id='see-translation-link-id2'
                                                component='button'
                                                variant='body2'
                                                onClick={() => {
                                                    getOriginalMsg(message.messageId);
                                                    /*translateMessage(
                                                      message.message,
                                                      message.messageId,
                                                      message.translatedMessage
                                                    );*/
                                                }}
                                            >
                                                {message.showTranslatedMessage
                                                    ? 'See translation'
                                                    : 'See original'}
                                            </Link> : null}
                                            {translateMessageID === message.messageId && translateInProgress ?
                                                <span className={classes.progressBarParent}>
                                                    <CustomCircularProgress
                                                        css={classes.translateProgressBar}
                                                        aria-label='Loading'
                                                        size='0.875rem'
                                                    />
                                                </span>
                                                : null}
                                            {message.messageTranslationError && (
                                                <div className={classes.errorMessage}>
                                                    {message.messageTranslationError}
                                                </div>
                                            )}
                                        </>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                </div>
            </ListItem>
        );
    } else {
        return (
            <ListItem
                id={message.id}
                data-testid={`item-${dateItem}-${index}`}
                className={classes.eachMessage}
                key={`item-${dateItem}-${index}`}
                onTouchStart={() => {
                    needTouchEvent && setSelectedMsg(`item-${dateItem}-${index}`);
                    setNeedTouchEvent(false);
                }}
                onMouseEnter={() => setSelectedMsg(`item-${dateItem}-${index}`)}
                onMouseLeave={() => setSelectedMsg('')}
                ref={myRefs[message.id]}
            >
                <div className={classes.liMessage}>
                    <Box display='flex' flexDirection='row' width='100%'>
                        <Box component='div' display='flex'>
                            <BadgeAvatar size={classes.medium} name={message.profile.name} />
                        </Box>
                        <Box display='flex' flexDirection='column' width='100%'>
                            <Box display='flex' flexDirection='row'>
                                <div className={classes.group}>
                                    <div className={classes.profileName}>
                                        {message.profile.name}
                                    </div>
                                    <div className={classes.profileRole}>
                                        {message.profile.role}
                                    </div>
                                    <Divider className={classes.divider} orientation='vertical' />
                                    <div className={classes.messageTime}>{message.Time}</div>
                                </div>
                                {!isDirect &&
                                    !isMobile &&
                                    message.profile.name !== authContextUserName &&
                                    !showMessageReplyIcon &&
                                    !impersonating && (
                                        <MessageReplyIcon
                                            id={`item-${dateItem}-${index}`}
                                            selectedMsg={selectedMsg}
                                            replyHandler={replyHandler}
                                            directMessageHandler={directMessageHandler}
                                            isMobile={isMobile}
                                        />
                                    )}
                            </Box>
                            <Box
                                component='div'
                                className={`${classes.message} ${cardStyles.highlighter}`}
                            >
                                {getMessage_div(msgToDisp)}
                                {isAttachment && getFileAttachments()}
                                {loggedInUserId !== message.sender &&
                                    message.sourceLanguage && (
                                        <>
                                            {message.isTranslated ? <Link
                                                data-testid='see-translation-link'
                                                id='see-translation-link-id2'
                                                component='button'
                                                variant='body2'
                                                onClick={() => {
                                                    getOriginalMsg(message.messageId);
                                                    /*translateMessage(
                                                      message.message,
                                                      message.messageId,
                                                      message.translatedMessage
                                                    );*/
                                                }}
                                            >
                                                {message.showTranslatedMessage
                                                    ? 'See translation'
                                                    : 'See original'}
                                            </Link> : null}
                                            {translateMessageID === message.messageId && translateInProgress ?
                                                <span className={classes.progressBarParent}>
                                                    <CustomCircularProgress
                                                        css={classes.translateProgressBar}
                                                        aria-label='Loading'
                                                        size='0.875rem'
                                                    />
                                                </span>
                                                : null}
                                            {message.messageTranslationError && (
                                                <div className={classes.errorMessage}>
                                                    {message.messageTranslationError}
                                                </div>
                                            )}
                                        </>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                    {!isDirect &&
                        isMobile &&
                        message.profile.name !== authContextUserName &&
                        !showMessageReplyIcon &&
                        !impersonating && (
                            <MessageReplyIcon
                                id={`item-${dateItem}-${index}`}
                                selectedMsg={selectedMsg}
                                replyHandler={replyHandler}
                                directMessageHandler={directMessageHandler}
                                isMobile={isMobile}
                            />
                        )}
                </div>
            </ListItem>
        );
    }
};

export default MessageDisplayComponent;
