import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MemberIcon, ChatIcon } from '../Icons/TabIcon';
import MessagesPanel from './MessagesPanel/MessagesPanel';
import MembersPanel from './MembersPanel/MembersPanel';
import { GroupType } from '../Types/GroupType';
import { ChannelNameType } from '../Types/ChannelNameType';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import { useMediaQuery } from 'react-responsive';
import { UserAuthCtx } from './AuthComponent';
import ActionableAlertBox from './ActionableAlertBox/ActionableAlertBox';
import EditIcon from '../Icons/EditIcon';
import CustomSwitch from './CustomTable/GreenSwitch';
import groupComponentSytle from '../styles/groupComponentstyle';
import { channelEditApi } from '../services/ChannelService';
import { setChannelDelete } from '../services/ChannelService';
import { remove_channel_id } from '../dataLoaders/DataHandler';
import NotificationBox from './NotificationBox/NotificationBox';


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component='div'
      role='tabpanel'
      flexDirection='column'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    root: {
      flexGrow: 1,
    },
    tabButton: {
      display: 'flex',
      textTransform: 'none',
      flexDirection: 'row',
      minHeight: '0px',
    },
    tabIcon: {
      verticalAlign: 'middle',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '7px',
      },
    },
    wrapper: {
      '& [class^="MuiTabsscroller"]': {
        display: 'flex',
        '& [class^="PrivateTabIndicatorroot"]': {
          backgroundColor: '#FF5991',
        },
      },
      marginRight: '10px',
      marginLeft: '15px',
    },
    tabBar: {
      display: 'flex',
      height: '60px',
      flexDirection: 'row',
      borderBottom: '1px solid #CDCDCD',
    },
    groupNameDiv: {
      display: 'flex',
      flex: '1',
      alignContent: 'space-between',
      alignItems: 'center',
      gap: '18px',
      [theme.breakpoints.down('sm')]: {
        gap: '10px',
        width: '50%',
      },
    },
    groupName: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: '18px',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
    toggleButton: {
      width: '35px',
      height: '60px',
      backgroundColor: '#FFF',
      alignContent: 'center',
      padding: '2px 2px 2px 2px',
      borderWidth: '1px',
      borderColor: '#CDCDCD',
      borderStyle: 'solid',
      outline: 'none',
    },
    editButton: {
      width: '70px',
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
      padding: '5px',
      border: '2px solid #262626',
      borderRadius: '2px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        width: '40px',
        height: '32px',
      }
    },
    hideLabelText: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      }
    }
  });
});

const ConversationPanel = ({
  selectedGroup,
  setIsChannelEdited,
  username,
  selectedOrganizationId,
  bbdomain,
  personid,
  userRole,
  isParenDrawerOpen,
  setParentDrawerOpen,
  setGroupUnread,
  setDirectUnread,
  setGroup,
  setSelectMyGroup,
  setDirectChannelIDFromMessageQueue,
  impersonating,
  isMessagePanelLoading = false,
  conversationTitle = false,
  setMessagePanelLoading = () => {},
  setOnDeleted,
}: any) => {
  const classes = useStyles();
  const userDetails = useContext(UserAuthCtx).userCtx;
  let { userId, userLoggedInDomain, domainId } = userDetails;
  const [value, setValue] = useState(0);
  const [panelHeader, setPanelHeader] = useState('');
  
  const [channelName, setChannelName] = useState<ChannelNameType>({name: ''});
  const [charLeft, setCharLeft] = useState(0);
  const [toggleStatus, setToggleStatus] = useState(false)
  const CHANNEL_NAME_MAX_LENGTH = 50;
  const [unloadConfirmation, setUnloadConfirmation] = useState({
    open: false,
    title: 'Discard changes?',
    message: 'Your are not able to navigate members tab. Please remove attachments and try again!',
    value: 0,
    event: '',
  });
  const [editChannelConfirmation, setEditChannelConfirmation] = useState({
    open: false,
    title: 'Edit channel'
  });
  const [isLoading, setLoader] = useState(false);
  const [showChannelError, setShowChannelError] = useState({
    isChannelExist: false,
    isError: false,
    isMembersTabError: false,
    isMsgPanelError: false,
    message: '',
    severity: '',
    isShowClose: 1,
  });
  const EditChannelStyle = groupComponentSytle()({charLeft}) 
  const unloadConfirmationDialogStyle = {
    paper: {
      width: '20rem',
      height: '15rem',
    },
    root: {
      padding: '0 16px',
    },
  };
  const editChannelConfirmationDialogStyle = {
    paper: {
      width: '27rem',
      height: '18rem',
      maxWidth:'95%',
      // minHeight: '18rem', 
      margin:"0",
      padding:"0"
    },
    root: {
      padding: '0 16px',
      marginTop: '16px',
      opacity:selectedGroup.type==="CHANNEL" ? "" : 0.5
    },
    dialogActionsBtns:{
      flexWrap:"nowrap"
    },
  };

  const handleDelete = (groupId: string | any) => {
    setdeleteConfirmation({...deleteConfirmation,open: true,groupId: groupId});
    setEditChannelConfirmation({
      ...editChannelConfirmation,
      open: false,
    });
    return true
    }

  const [deleteConfirmation, setdeleteConfirmation] = useState({
      open: false,
      title: 'Delete Channel',
      message: 'Do you want to delete this channel?',
      groupId: ''
    });

    const DeleteConfirmationDialogStyle = {
      paper: {
        width: '450px',
        height: '291px',
      },
      root: {
        paddingTop: '20px',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontWeight:600,
        fontSize:"16px",
      },
      colorChange: {
        backgroundColor: "#C23E37!important",
        borderColor: "white!important",
        '&:hover': {
          backgroundColor: "#C23E37",
        },
      },
    };

  
  const handleChange = (event: any, newValue: any) => {
    if (localStorage.getItem("filelength") !== "0")
    {
      setUnloadConfirmation({...unloadConfirmation,open: true, value: newValue, event: event});
      return true
    }
    setValue(newValue);
  };

  const toggleSidebar = () => {
    setParentDrawerOpen(!isParenDrawerOpen);
  };

  useEffect(() => {
    setPanelHeader(conversationTitle);
  }, [conversationTitle]);

  useEffect(() => {
    setPanelHeader(selectedGroup.name);
    resetChannelProperties();
    setShowChannelError({isChannelExist: false, isError: false, message: '', isMembersTabError: false, isMsgPanelError: false, severity: '', isShowClose: 1})
    if (selectedGroup.groupType === GroupType.DirectMessage) {
      setValue(0);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (channelName.input && channelName.start && channelName.end){
        channelName.input.setSelectionRange(channelName.start, channelName.end)
    }
}, [channelName]);

  const resetChannelProperties = () => {
    setToggleStatus(selectedGroup.twoWayEnabled)
    setCharLeft(CHANNEL_NAME_MAX_LENGTH - selectedGroup.name.length)
    setChannelName({'name': selectedGroup.name})
  }
  const changeCharLeft = (event:any) => {
    const input = event.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    setChannelName({...channelName, 'name': input.value, input: input, start: start, end: end})
    if (event.target.value.trim('').length == 0){
      event.target.value = ''
      setChannelName({name: ''})
    } 
    setCharLeft(CHANNEL_NAME_MAX_LENGTH - event.target.value.length)
  };
  const handleEditChannel = () => {
    setEditChannelConfirmation({...editChannelConfirmation, open: true}); 
    return true
  };
  
  const EditChannelDetails = () => {
    setLoader(true);
    channelEditApi(selectedGroup.id, channelName.name.trim(), toggleStatus, userLoggedInDomain).then((response: any) => {
      setEditChannelConfirmation({
        ...editChannelConfirmation,
        open: false,
      });
      setIsChannelEdited(true)
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
      setIsChannelEdited(false)
      if (err.response.status == 409){
        setShowChannelError({...showChannelError, isChannelExist: true})
      }
      else{
        setEditChannelConfirmation({...editChannelConfirmation, open: false,});
        resetChannelProperties()
        setShowChannelError({
          ...showChannelError, 
          isMembersTabError: false, 
          isMsgPanelError: false,
          isError: true,
          severity: 'ownerPrivilegesErr',
          message: err.response.status == 401 ?
          'Channel updation failed. Please contact administrator' : 
          'Channel updation failed. Please try again later.'})
      }
    })
  }

  return (
    <div className={classes.root}>

      <ActionableAlertBox
        data-testid='unloadConfirmationBox'
        title={unloadConfirmation.title}
        shouldOpen={unloadConfirmation.open}
        onAction={() => {
          handleChange(unloadConfirmation.event, unloadConfirmation.value);
          setUnloadConfirmation({...unloadConfirmation,open: false});
        }}
        secondaryButtonLabel='Close'
        primaryButtonLabel='Close'
        styles={unloadConfirmationDialogStyle}
        secondaryButtonProps={{style: {display: 'none'}}}
        primaryButtonProps={{}}
        onClose={() => {
          setUnloadConfirmation({
            ...unloadConfirmation,
            open: false,
          });
        }}
      >
      <Box paddingBottom='74px'>{unloadConfirmation.message}</Box>
      </ActionableAlertBox>
      
      <ActionableAlertBox
        data-testid='editChannelConfirmation'
        title={editChannelConfirmation.title}
        shouldOpen={editChannelConfirmation.open}
        onAction={() => {
          EditChannelDetails()
        }} 
        onEdited={true}
        channelType={selectedGroup.type}
        isLoading={isLoading}
        disableBackdropClick = {true}
        secondaryButtonLabel='Cancel'
        primaryButtonLabel='Save'
        styles={editChannelConfirmationDialogStyle}
        primaryButtonProps={
           {disabled: (selectedGroup.type === "CHANNEL" && 
          (selectedGroup.name !== channelName.name.trim() || selectedGroup.twoWayEnabled !== toggleStatus) &&
          channelName.name.length !== 0) ? false : true}
        }
        
        onClose={() => {
          resetChannelProperties()
          setEditChannelConfirmation({
            ...editChannelConfirmation,
            open: false,
          });
          setShowChannelError({...showChannelError, isChannelExist: false})
        }}
	      onDelete={() => {
          var groupId = selectedGroup.id;
          handleDelete(groupId) 
        }}
      
      >
        <span className={EditChannelStyle.channelNameSpanEl}>Channel name</span>
        <br/>
        <input 
        data-testid="inputEl"
        disabled={selectedGroup.type==="CHANNEL"?false:true} 
        className={EditChannelStyle.channelNameInputEl} 
        placeholder='Type the name here' maxLength={CHANNEL_NAME_MAX_LENGTH} 
        onChange={(event)=>{changeCharLeft(event)}}
        value={channelName.name}
        />
        <br/>
        <span data-testid="charsLeft" className={EditChannelStyle.charsLeftSpanEl}>{charLeft} characters left</span>
        <div className={EditChannelStyle.toggleSwitchCon}>
          <div className={EditChannelStyle.toggleSwitchEl} data-testid="toggleStatus">
          <CustomSwitch status={toggleStatus ? 1 : 0} handler={()=>{return selectedGroup.type==="CHANNEL"?setToggleStatus(!toggleStatus):""}}/>
          </div>
          <span className={EditChannelStyle.toggleSwitchSpanEl}>Everyone can post</span>
        </div>
        {showChannelError.isChannelExist && (
          <span data-testid='errormsg' className={EditChannelStyle.channelNameDuplicateSpanEl}>This name is already taken. Try a different name.</span>
        )}
      </ActionableAlertBox>
 {/* Alert box for Delete Channel */}

       <ActionableAlertBox
        data-testid='deleteConfirmationBox'
        title={deleteConfirmation.title}
        shouldOpen={deleteConfirmation.open}
        onAction={() => { 
            if(deleteConfirmation.open === true )
            {             
              var channelid = deleteConfirmation.groupId;
              var cluster = localStorage.getItem('cluster');
              var bbdomain = localStorage.getItem('bbDomain');
              setLoader(true);
              setChannelDelete(channelid,cluster,bbdomain).then((response: any) => {
                setOnDeleted(true)
                remove_channel_id(channelid);
                setIsChannelEdited(true); 
                setLoader(false);
                setdeleteConfirmation({...deleteConfirmation,open: false});  
              }).catch((err) => {
                 setLoader(false);
                 setShowChannelError({
                  ...showChannelError, 
                  isMembersTabError: false,
                  isMsgPanelError: false,
                  isError: true,
                  severity: 'ownerPrivilegesErr',
                  message: err.response.status === 401 ? 
                  'Failed to delete channel. Please contact administrator' :
                  'Failed to delete channel'
                })
                 setIsChannelEdited(false); 
                 setdeleteConfirmation({
                  ...deleteConfirmation,
                  open: false
             });
            });
          }
          }}
        secondaryButtonLabel='Cancel'
        primaryButtonLabel='Delete'
        styles={DeleteConfirmationDialogStyle}
        isLoading={isLoading}
        disableBackdropClick = {true}
        primaryButtonProps={{}}
        onClose={() => {
          setdeleteConfirmation({
            ...deleteConfirmation,
            open: false
          });
        }}
        >
        <Box paddingBottom='74px'>{deleteConfirmation.message}</Box>
      </ActionableAlertBox>

      <div className={classes.tabBar}>
        <div className={classes.groupNameDiv}>
          <button onClick={toggleSidebar} className={classes.toggleButton}>
            {isParenDrawerOpen ? (
              <ArrowBackIosOutlinedIcon
                fontSize={'default'}
                data-testid="HideListIcon"
              ></ArrowBackIosOutlinedIcon>
            ) : (
              <ArrowForwardIosOutlinedIcon
                fontSize={'default'}
                data-testid="HideListIcon"
              ></ArrowForwardIosOutlinedIcon>
            )}
          </button>

          <span className={classes.groupName} data-testid="ChannelOrDmName">{panelHeader}</span>
          {((selectedGroup.owner == personid && selectedGroup.type !== 'CHANNEL') 
                || selectedGroup.userType === "owner")
            && !impersonating
            && <div className={classes.editButton} data-testid="EditBtn"
                  onClick={handleEditChannel} >
                  <EditIcon/>
                  <span className={classes.hideLabelText} style={{marginLeft: '10px'}}>Edit</span>
               </div>
          }
        </div>
        {!impersonating &&
          selectedGroup.groupType !== GroupType.DirectMessage &&
          (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.wrapper}
              >
                <Tab
                  data-testid='ChatTab'
                  className={classes.tabButton}
                  label={
                    <div>
                      <ChatIcon className={classes.tabIcon} /> 
                      <span className={classes.hideLabelText}>Chat</span> 
                    </div>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  data-testid='MembersTab'
                  className={classes.tabButton}
                  label={
                    <div>
                      <MemberIcon className={classes.tabIcon} /> 
                      <span className={classes.hideLabelText}>Members</span> 
                    </div>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </>
          )}
      </div>
 
      <TabPanel value={value} index={0}>
        <MessagesPanel
          username={username}
          selectedGroup={selectedGroup}
          groupid={selectedGroup.id}
          organizationid={selectedOrganizationId}
          personid={personid}
          bbdomain={bbdomain}
          userRole={userRole}
          setGroupUnread={setGroupUnread}
          setDirectUnread={setDirectUnread}
          setGroup={setGroup}
          setSelectMyGroup={setSelectMyGroup}
          setDirectChannelIDFromMessageQueue={
            setDirectChannelIDFromMessageQueue
          }
          impersonating={impersonating}
          isLoading={isMessagePanelLoading}
          setPanelLoading={setMessagePanelLoading}
          isParenDrawerOpen={isParenDrawerOpen}
          setExpireNotification={setShowChannelError}
          expireNotification={showChannelError}
        />
      </TabPanel>
      {!impersonating && (
        <TabPanel value={value} index={1}>
          <MembersPanel
            selectedGroup={selectedGroup}
            bbdomain={bbdomain}
            isParenDrawerOpen={isParenDrawerOpen}
            setGroup={setGroup}
            setShowError={setShowChannelError}
            showError={showChannelError}
          />
        </TabPanel>
      )}
    </div>
  );
};

export default ConversationPanel;