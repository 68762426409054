export enum USER_ROLES {
    ADMIN = 'ADMIN',
    SYSTEM = 'SYSTEM',
    PARENT = 'PARENT',
    TEACHER = 'TEACHER',
    STAFF = 'STAFF',
};

export enum SECTION {
    ROOT = "/",
    HOME = "/Home",
    SETTINGS = "/Settings",
    PROFILE = "/Profile",
    REPORT = "/Report"
};