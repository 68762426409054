import React,{ useState, useContext } from 'react';
import Uppy from '@uppy/core';
import { Dashboard, useUppy } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/url/dist/style.css';
import GoogleDrive from '@uppy/google-drive';
import GoogleDriveOptions from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
import AwsS3 from '@uppy/aws-s3';
import ModalStyle from './CloudStorageComponentStyles';
import { IoClose } from 'react-icons/io5';
import './CloudStorageComponent.css';
import { v4 as uuidv4 } from 'uuid';
import { UserAuthCtx } from '../AuthComponent';
import { getPresignedUrl } from '../../services/FilesServices';
import axios from 'axios';

const CloudStorageComponent = (props: any) => {
  const { closeModal, setCloudfiles, files, msgId, availItems} = props;
  const [disabled, setDisabled] = useState(false);
  const [iconclose, setIconclose] = useState('flex');
  const authContext = useContext(UserAuthCtx);
  const userDetails = authContext.userCtx;
  var domainId = userDetails.domainId;

  const UPLOAD_FILES_LIMIT = 5;
  const UPLOAD_FILES_SIZE_LIMIT = 52428800;
  const UPLOAD_FILES_SIZE_LIMIT_IN_MB = 50;

  const HideModal = () => {
    handledonebutton()
  }
  let filesSize = 0;
  let editorFiles = [...files]
    editorFiles.map((eachFile:any)=>{
      filesSize += eachFile.size
    });
  
  let domainName = process.env.REACT_APP_REACH_BASE_URL;
  var companion_url: any = domainName?.replace('/api', '/companion')
  var schoolId = domainId+'-'+localStorage.getItem('reachOrgSchoolId');
  var personId = localStorage.getItem('userId');
  const option = { companionUrl: companion_url,metaFields: ['messageId', msgId, 'userId', personId,'schoolId',schoolId,'itemNo', '']};


  const equipment_uppy = useUppy(() => {
    const up = new Uppy({
      id: 'equipment_uppy',
      autoProceed: false,
      locale:{
        strings:{
          youCanOnlyUploadX: {
            0: `You can attach only ${UPLOAD_FILES_LIMIT} files per message.`,
            1: `You can attach only ${UPLOAD_FILES_LIMIT} files per message.`,
          },
          exceedsSize:`You can attach files upto ${UPLOAD_FILES_SIZE_LIMIT_IN_MB} MB per message.`
        }
      },
      restrictions: {
        maxNumberOfFiles:UPLOAD_FILES_LIMIT - files.length,
        maxTotalFileSize:UPLOAD_FILES_SIZE_LIMIT - filesSize,
      }
    })
      .use(GoogleDrive, {
        ...option,
      })
      .use(OneDrive, {
        ...option,
      })
      .use(AwsS3, {
        ...option,
      })

    up.setMeta({
      'messageId': msgId,
      'userId': personId,
      'schoolId': schoolId,
    });

    up.setState({
      'file_obj': null
    })
    // up.addFiles({files})
    return up;
  });
  const getFileBlobAndUpload = (fileIds: any[]) => {
    const promises = fileIds.map((fileId) => {
      const file = equipment_uppy.getFile(fileId);
      equipment_uppy.emit('postprocess-progress', file, {
        mode: 'indeterminate',
        message: 'Uploading...',
      });
      var res = file.meta; 
      var fileName = res.name;
      var payload = [{
        "name": fileName,
        "location" : 'https://' + res?.bucket + ".s3.amazonaws.com/" + res?.key
      }]
      const bbDomain = userDetails.userLoggedInDomain

      return getPresignedUrl(payload, "download", bbDomain).then(async (response: any) => {
        if ("generated_url" in response.data) {
          var url = response.data.generated_url[fileName];
          var blob_obj = await axios({url:url,method: 'GET',responseType: 'blob'});
          var file_obj = new File([blob_obj.data], fileName,{type:blob_obj.data.type})
          equipment_uppy.log(`Getting Blob for ${file.id} completed sucessfully`);
          equipment_uppy.setFileState(fileId, { file_obj: file_obj, meta: file.meta});
        }
      }).catch((error) => {
        return true
        // equipment_uppy.log(`Getting Blob for ${file.id} Failed...`, 'error');
        // equipment_uppy.log(error, 'error');
      });
    });


    const emitPostprocessCompleteForAll = () => {
      fileIds.forEach((fileId) => {
        const file = equipment_uppy.getFile(fileId);
        equipment_uppy.emit('postprocess-complete', file);
      })
    };


    return Promise.all(promises)
      .then(emitPostprocessCompleteForAll);
  }

  equipment_uppy.addPostProcessor(getFileBlobAndUpload)

  equipment_uppy.on('complete', (result) => {
    setDisabled(false)
    setIconclose('flex')
  });
    
  function handledonebutton() {
    var cloudFileList: any[] = [];
    equipment_uppy.getFiles().forEach(file =>{
      let f_obj = Object(file)
      if(f_obj.file_obj != undefined){
        f_obj.file_obj['itemNo'] = f_obj.meta.itemNo
        cloudFileList.push(f_obj.file_obj) 
      }
    })
    setCloudfiles([...cloudFileList])
    closeModal();
  }

  // To  update items count when files added
  equipment_uppy.on('file-added', (file) => {
    var items = equipment_uppy.getFiles()
    items.map((data: any, index: any) => {
      data.meta.itemNo = availItems[index]
    })
  });

  // To  update items count when files removed
  equipment_uppy.on('file-removed', (file) => {
    var items = equipment_uppy.getFiles()
    items.map((data: any, index: any) => {
      data.meta.itemNo = index
    })
  });

  equipment_uppy.on('upload', (data) => {
    setDisabled(true)
    setIconclose('none')
  })

  equipment_uppy.on('upload-error', (file, error, response) => {
    setDisabled(false)
    setIconclose('flex')
  })


return (
  <div className={ModalStyle().bgContainer}>
  <div className={ModalStyle().modalContainer}>
    <div className={ModalStyle().headerContainer}>
      <div className={ModalStyle().subHeaderContainer}>
        <p className={ModalStyle().title}>Import from cloud</p>
        <div className={ModalStyle().subHeaderContainer}>
          <IoClose onClick={HideModal} className={ModalStyle().closeIcon} display={iconclose}/>
        </div>
      </div>
      <p className={ModalStyle().instructionCloud}>Log in to the cloud service and select the file you want to import</p>
      <span data-testid="instruction" className={ModalStyle().warningMsg}>**You can attach only {UPLOAD_FILES_LIMIT} files per message & You can attach files upto {UPLOAD_FILES_SIZE_LIMIT_IN_MB} MB per message.</span>
    </div>
    <div className={ModalStyle().imageContainer}>
      <Dashboard 
      uppy={equipment_uppy}
      id='equipment_location_image'
      width='98%'
      plugins={["GoogleDrive", "OneDrive"]}
      disableLocalFiles={true}
      proudlyDisplayPoweredByUppy ={false}
      doneButtonHandler = {()=>{handledonebutton()}}
      hideCancelButton={true}
      />
    </div>
    <div className={ModalStyle().footerContainer}>
      <button onClick={HideModal} className={ModalStyle().CancelBtn} disabled={disabled} >
          <span >Close</span>
      </button>
    </div>
    
  </div>
</div>
  
);
};

export default CloudStorageComponent;