import React, { useState, ChangeEvent, useContext } from 'react';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FilterSearchComponent, {
  SearchComponentStateTypes,
} from './FilterSearchComponent';
import { getSearchMembers } from '../services/userAccountServices';
import { UserAuthCtx } from '../components/AuthComponent';
import SearchUsersPanel from './SearchUsersPanel/SearchUsersPanel';
import PageNavigation from './PageNavigation/PageNavigation';
import Pagination from './Pagination/Pagination';

export const PAGE_SIZE = 50;

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    root: {
      display: 'flex',
      height: 'inherit',
    },
    searchPanel: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      marginBottom: '12px',
    },
    groupNameDiv: {
      display: 'flex',
      flex: '1',
      alignContent: 'space-between',
      alignItems: 'center',
    },
    groupName: {
      display: 'flex',
      alignItems: 'left',
      fontWeight: 600,
      fontSize: '18px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      display: 'flex',
      fontSize: '14px',
    },
    textField: {
      borderRadius: '2px',
      border: '1px solid #CDCDCD',
    },
    focused: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
        borderColor: '#262626',
      },
    },
    messageTitle: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
    },
    messagePanel: {
      margin: '20px 20px 0 20px',
    },
    overflowContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        overflow: 'auto',
        paddingRight: '15px',
        height: 'inherit',
        maxHeight: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 450px)',
      },
      ['@media (max-height: 500px)']: {
        overflow: 'unset',
      },
    },
    customScroll: {
      scrollbarColor: '#666666 transparent',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },

      /* Track */
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#666666',
        borderRadius: '10px',
        border: '1px solid #666',
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    },
    gridRoot: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: 'inherit',
      maxWidth: '100%',
    },
    container: {
      height: 'inherit',
    },
    navigationContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
      },
      paddingTop: theme.spacing(3),
    },
    paginationWrapper: {
      padding: '0 0 30px 0;',
    },
  });
});

const ChatArchiveUsers = (props: any) => {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState('');
  const [searchResult, setSearchResult] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalUsersList, setTotalUsersList] = useState<any>({});

  const inputTextFieldProps = {
    disableUnderline: true,
    classes: { focused: classes.focused },
  };

  const requiredFilterElements = {
    tabIndex: 1,
    schoolSelector: true,
    dateRange: false,
    channelField: false,
    userField: true,
  };
  const handleSearchOnchange = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchStr(e.target.value);

  const [resultPanelLoading, setResultPanelLoading] = useState(false);

  const authContext = useContext(UserAuthCtx);
  const userId = authContext.userCtx.userId ? authContext.userCtx.userId : '';
  const domain = authContext.userCtx.userLoggedInDomain
    ? authContext.userCtx.userLoggedInDomain
    : '';

  const clearKeyword = () => setSearchStr('');
  const launchSearch = async (
    filterSearchState: SearchComponentStateTypes,
    searchKeyword: string
  ) => {
    clearAllData();
    setResultPanelLoading(true);
    const { school, sender } = filterSearchState;
    try {
      const users: any = await getSearchMembers(
        filterSearchState.sender.trim(),
        domain,
        filterSearchState.school.split('|')[0],
        userId
      );
      if (users.data?.errorType) {
        throw new Error(`Error getting search result ${users.data.errorType}`);
      }
      setTotalUsersList(users.data.recipients);
      setTotalUsers(users.data.recipients.length);
      setSearchResult(users.data.recipients.slice(0, PAGE_SIZE));
      setResultPanelLoading(false);
    } catch (error) {
      console.error(error);
      setResultPanelLoading(false);
      setSearchResult(null);
    }
  };

  const showPagination = () => {
    return totalUsers > PAGE_SIZE && !resultPanelLoading;
  };

  const getPageForNavigation = () => {
    return currentPage <= 1 ? currentPage : PAGE_SIZE * (currentPage - 1) + 1;
  };

  const getOffsetForNavigation = () => {
    const offset = getPageForNavigation() + PAGE_SIZE - 1;
    return offset > totalUsers ? totalUsers : offset;
  };

  const getPaginatedResults = async (page: number, direction: string) => {
    setResultPanelLoading(true);
    let startIndex = PAGE_SIZE * (page - 1);
    let endIndex = PAGE_SIZE * (page - 1) + PAGE_SIZE;
    setSearchResult(totalUsersList.slice(startIndex, endIndex));
    setResultPanelLoading(false);
  };

  const clearAllData = () => {
    setSearchResult(null);
    setCurrentPage(1);
    setTotalUsers(0);
    setTotalUsersList({});
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={4}>
          <FilterSearchComponent
            showElements={requiredFilterElements}
            handleSearchMessages={launchSearch}
            clearSearch={clearKeyword}
          />
        </Grid>
        <Box className={`${classes.overflowContainer} ${classes.customScroll}`}>
          <Grid item xs={12} sm={8} className={classes.gridRoot}>
            <Box className={classes.navigationContainer}>
              {showPagination() && (
                <PageNavigation
                  total={totalUsers}
                  offset={getOffsetForNavigation()}
                  currentPage={getPageForNavigation()}
                />
              )}
            </Box>
            <SearchUsersPanel
              emptyMessage={searchResult === null ? 'Find Users' : 'No Results'}
              loggedInUser={userId}
              users={searchResult}
              isLoading={resultPanelLoading}
            />
            {showPagination() && (
              <div className={classes.paginationWrapper}>
                <Pagination
                  totalPages={Math.ceil(totalUsers / PAGE_SIZE)}
                  currentPage={currentPage}
                  resource={getPaginatedResults}
                  updateDom={(users: any, currentPage: number) => {
                    setCurrentPage(currentPage);
                  }}
                />
              </div>
            )}
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};
export default ChatArchiveUsers;
