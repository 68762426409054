import { makeStyles } from '@material-ui/core/styles';

const LoginStyle = makeStyles((theme) => ({
    wrapper: {
        flexGrow: 1
    },
    root: {
        margin: '35px auto 10px',
        width: '220px',
        display: 'flex',
        flexDirection: 'column',
    },
    headerName:{
        textAlign: 'center',  
        marginBottom: '18px',
        fontSize: '24px',
    },
    formElements: {
        margin: '3px 0'
    },
    rightAllign: {
        textAlign: "right",
    },
    versionTag: {
        position: 'fixed',
        width: '100%',
        textAlign: 'center',
        bottom: '10px',
        ['@media (max-height: 500px)']: {
            position: 'relative',
            marginTop: 0
        },
    },
    topBottomSpacing: {
        marginTop: '10px',
        marginBottom: 0
    },
    topSpacing:{
        marginTop: '15px'
    },
    bottomSpacing:{
        marginBottom: '0px'
    },
    greyText: {
        color: '#767676',
        marginBottom: '16px',
        fontSize: '13px'
    },
    disabledButton: {
        color: '#ffffff!important',
        backgroundColor: '#262626!important',
        opacity: '0.6'
    },
    cancelButton: {
        width: '40px',
        margin: '0 auto',
        padding: '0',
        textAlign: 'center',
        fontSize: '14px',
        paddingTop: '10px',
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#2073A1',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    loginProgressBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progressDivCenter: {
        textAlign: 'center'
    },
    root2: {
        margin: '35px auto 10px',
        width: '360px',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    passcodeTo: {
        fontWeight: 600,
        marginBottom: '40px',
    },
    PasscodeButton: {
        marginTop: '5px',
        width: '60%',
        margin: '0 auto',
    },
    resendEnabledStyle: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#2073A1',
        padding: '0',
        paddingBottom: '4px',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    resendDisabledStyle: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#767676!important',
        padding: '0',
        paddingBottom: '4px',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    passcodeTimer: {
        color: '#C23E37',
    },
    resendBlock: {
        padding: '15px 0'
    }
}));

export default LoginStyle;