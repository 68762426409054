import React from 'react'
import { useState, useEffect } from 'react';

const Timer = (props:any) => {
    const {initialMinute = 0,initialSeconds = 0,hidePasscodeTimer,otpTimerExpired,disableResendFunc} = props;
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    const [estimate, setEstimate ] =  useState(0);
    let d:any;
    if (estimate === 0){
        d = new Date();
        d.setMilliseconds(0);
        d.setMinutes(d.getMinutes() + 10);
        setEstimate(d);
    }

    useEffect(()=>{
        let myInterval = setInterval(() => {
            let now:any = new Date();
            now.setMilliseconds(0);

            if (now < estimate){
                let dateDiff:number = Math.abs(estimate - now)/1000;
                let mins:number = Math.floor(dateDiff/60);
                let secs:number = Math.floor(dateDiff%60);
                setSeconds(secs);
                setMinutes(mins);
                if (minutes===0 && seconds<12){
                    disableResendFunc();
                }   
            } else {
                otpTimerExpired();
                hidePasscodeTimer();
                clearInterval(myInterval)
            }
        }, 1000);
        return ()=> {
            clearInterval(myInterval);
        };
    });

    return (
        <>
        { minutes === 0 && seconds === 0
            ? null
            : <span> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span> 
        }
        </>
    )
}

export default Timer;