import React, { useContext, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { logoutService } from '../services/logOutService';
import { UserAuthCtx } from './AuthComponent';

const LogoutComponent = (props: any) => {

    var history = useHistory();
    var user = useContext(UserAuthCtx);
    const logout = async () => {
        try {
            var result = await logoutService();
            Object.keys(localStorage).forEach((key) => {
                if (!key.includes('district')) delete localStorage[key];
            });
            if (props.redirectUrl) {
                window.location.assign(props.redirectUrl);
            }
            else {
                user.emptyUserCtx()
                history.push('/Login')
            }
        }
        catch (error) {
            history.push('/Login')
        }
    }
    useEffect(() => {
        if(localStorage.getItem('userId')) {
            logout()
        }
    },[])
    return <></>
}
export default LogoutComponent