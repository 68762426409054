import InfoCardType from '../InfoCard/InforCardType';

export const infoCards: Array<InfoCardType> = [
  {
    id: 'users-card',
    title: 'Total users',
    count: 0,
  },
  {
    id: 'teachers-card',
    title: 'Teachers',
    count: '0',
    caption: '0',
  },
  {
    id: 'parents-card',
    title: 'Parents',
    count: '0',
    caption: '0',
  },
  {
    id: 'students-card',
    title: 'Students',
    count: '0',
    caption: '0',
  },
  {
    id: 'channels-card',
    title: 'Channels enabled',
    count: '1,223',
  },
];

export const getTotal = (dataset: any) => {
  return dataset.reduce(function (prev: any, cur: any) {
    return prev + cur.y;
  }, 0);
};

export const numberWithCommas = (val: any) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dataModification = (obj: any) => {
  const colors = ['#004270', '#5FD1FF', '#2C9EDE', '#2073A1'];
  let modifiedData: any = [];
  Object.keys(obj).map((key, index) => {
    modifiedData.push({ name: key, y: obj[key], color: colors[index] });
  });
  return modifiedData;
};

export const getChartOptions = (title: string, dataset: any) => {
  const mainTitle = numberWithCommas(getTotal(dataset));
  const chartOptions: any = {
    title,
    options: {
      chart: {
        plotBackgroundColor: '',
        plotBorderWidth: 0,
        plotShadow: false,
      },
      credits: {
        enabled: false,
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        symbolHeight: 11,
        symbolWidth: 11,
        symbolRadius: 0,
        floating: true,
        x: -20,
        y: -10,
        itemStyle: {
          fontWeight: 'normal',
        },
      },
      title: {
        text: mainTitle,
        align: 'center',
        verticalAlign: 'middle',
        //y: 20
      },
      tooltip: {
        pointFormat: '{point.y:,.0f} ({point.percentage:.1f}%)',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: 'normal',
              color: 'black',
            },
            format:
              '{point.name} <br>{point.y:,.0f} ({point.percentage:.1f}%)</b>',
          },
          center: ['50%', '50%'],
          size: '80%',
          showInLegend: true,
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '70%',
          data: dataset,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 600,
            },
            chartOptions: {
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: false,
                  },
                },
              },
            },
          },
        ],
      },
    },
  };

  return chartOptions;
};
