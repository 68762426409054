import React, {useState, useCallback, useContext} from 'react'
import ActionableAlertBox from '../../../ActionableAlertBox/ActionableAlertBox'
import { Box } from '@material-ui/core'
import ProfileEmailListContext from '../../ProfileEmailListContext'
import EmailField from '../../../EmailField/EmailField'
import { ValidFormType } from '../../../EmailField/EmailFieldTypes'
import { isPresentInDelete, removeFromDelete } from '../../profileEmail.utils'
import { AddProfileEmailType } from '../ProfileEmailFormTypes'

const AddProfileEmail = ({ show, onClose }: AddProfileEmailType) => {
  const styles = {
    paper: {
      width: '450px'
    }
  };

  const [isValid, setIsValid] = useState<ValidFormType>({
    valid: false,
    type: 1
  })
  
  const [disableForm, setDisableForm] = useState(false)
  const [emailToAdd, setEmailToAdd] = useState<null|string>(null)

  const { emails, setEmails, userId }: any = useContext(ProfileEmailListContext)

  return (
    <ActionableAlertBox
      title='Add email address'
      shouldOpen={show}
      onAction={() => {
        setDisableForm(true);
        if(emailToAdd) {
          const emailObjectToAdd = { userId, email: emailToAdd, userPreference: false, source: 'Reach' }
          // check if this email is already in delete, if yes remove from delete
          const isInDelete = isPresentInDelete(emails.uncommitted.deleted, emailToAdd)
          let deletedEmails = [...emails.uncommitted.deleted]
          if(isInDelete) {
            deletedEmails = removeFromDelete(emails.uncommitted.deleted, emailToAdd)
          }

          setEmails({
            ...emails,
            display: [
              ...emails.display,
              { ...emailObjectToAdd }
            ],
            uncommitted: {
              ...emails.uncommitted,
              added: [
                ...emails.uncommitted.added,
                { ...emailObjectToAdd }
              ],
              deleted: [
                ...deletedEmails
              ]
            }
          })
          onClose()
          setDisableForm(false)
        }
      }}
      secondaryButtonLabel='Cancel'
      primaryButtonLabel='Add'
      styles={styles}
      primaryButtonProps={{disabled: !isValid.valid || disableForm}}
      onClose={onClose}
      onExited={() => {
        setIsValid({
          valid: false,
          type: 1
        });
      }}
    >
      <Box display='flex' flexDirection='column'>
        Add your email address here:
        <EmailField 
          emails={emails.display}
          setFormOutput={setEmailToAdd}
          isValid={isValid}
          setIsValid={setIsValid}
        />
      </Box>
    </ActionableAlertBox>
  )
}

export default AddProfileEmail