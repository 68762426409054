import React, { useRef, useState, useEffect, useContext } from 'react';
import CustomTableStyles from './CustomTableStyles';
import CustomTableTypes from './CustomTableTypes';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

import {
    Grid,
    Checkbox,
    Divider,
    withStyles,
    Box,
    Collapse,
    FormControl,
    MenuItem,
    Select,
    Input,
    IconButton,
    CheckboxProps,
} from '@material-ui/core';
import CustomSwitch from './GreenSwitch';
import { orgItemSettingPayload } from '../../Types/orgSettingInfo';
import { SortUpArrow } from '../../Icons/SortUpArrrow';
import { SortDownArrow } from '../../Icons/SortDownArrow';
import { useMediaQuery } from 'react-responsive';
import SettingConfirmationWindow from '../SettingConfirmationWindow/SettingConfirmationWindow';
import { UserAuthCtx } from '../AuthComponent';
import {
    setReachAccess,
    updateChannelAccess,
} from '../../services/settingPageService';
import { SchoolItem } from '../../Types/SchoolItem';
import { ChannelItem, selectedChannel } from '../../Types/channelItem';
import { ChannelType } from '../SchoolSettingsComponent/SchoolSettingsTypes';
import { StateContext } from '../StateContextComponent';
import { loadOrgData } from '../../dataLoaders/DataHandler';


const CustomTable = ({
    isCheckBox,
    header = {
        mobile: [],
        tablet: [],
        desktop: [],
    },
    statusDropdown = [],
    data,
    sortOrgDict,
    setDisableSearchText,
    renderFor = 'district',
    getUserCount,
    isGettingTotalCount,
    SelectedSchool
}: CustomTableTypes) => {
    const authContext = useContext(UserAuthCtx);
    const { setSchools } = useContext(StateContext);

    const {
        userName: personName,
        organizationName,
        mobile,
        userId: personId,
        userLoggedInDomain: domain,
        userRole,
        domainId,
    } = authContext.userCtx;
    const [isEnablePopup, toggleEnable] = useState(false);
    const [school, setSchool] = useState<SchoolItem>(new SchoolItem());
    const [channel, setChannel] = useState<ChannelItem>(new ChannelItem());
    const [expandClick, setIsExpandClick] = useState(true);
    let [selectedItemList, setSelectedItemList] = useState<any>([]);
    var [isBatchUpdate, setBatchUpdate] = useState(false);
    const [statusDropdownList, setStatusDropdown] = React.useState(
        statusDropdown
    );
    const [clickedChannelId, setClickedChannelId] = React.useState(null);

    const dialogTitle = {
        Enable: 'Additional Settings',
        Disable: renderFor == 'district' ? 'Disable Reach?' : 'Disable Channel?',
    };
    const dialogContent = {
        BatchEnable: "You've enabled Reach for the selection.",
        Enable: `You've enabled Reach for ${school.schoolName}.`,
        Disable:
            renderFor == 'district'
                ? 'Are you sure you want to disable Reach for this school? All settings will be lost.'
                : 'Are you sure you want to disable class and group channels? These channels will no longer be accessible.',
    };

    //For Dialog to Open and Close
    const [dialogeOpen, toggleDialog] = useState(false);
    function handleClickOpen(
        type: string,
        name: string,
        id: number,
        isEnablePopUp: boolean,
        category: string = ''
    ) {
        setBatchUpdate(false);
        toggleEnable(isEnablePopUp);
        if (renderFor == 'district') {
            var school = new SchoolItem();
            school.schoolId = id;
            school.schoolName = name;
            school.schoolType = type;
            setSchool(school);
            toggleDialog(true);
        } else {
            var channel = new ChannelItem();
            channel.channelId = id;
            channel.channelName = name;
            channel.channelType = type;
            channel.category = category;
            setChannel(channel);
            if (!isEnablePopUp) {
                toggleDialog(true);
            } else {
                var item = new selectedChannel();
                item.channelId = id;
                item.channelType = type;
                var SelectedChannels = new Array<selectedChannel>();
                SelectedChannels.push(item);
                isBatchUpdate = false;
                setChannelAccess(SelectedChannels, isEnablePopUp);
                toggleDialog(false);
            }
        }
    }

    async function handleSaveCloseDialog(
        enableReach: boolean,
        enableChannel: boolean
    ) {
        var orgIds = new Array<number>();
        var selectedChannels = new Array<selectedChannel>();
        var SelectedChannel = new selectedChannel();
        if (!isBatchUpdate) {
            if (renderFor == 'district') {
                orgIds.push(school.schoolId);
            } else {
                SelectedChannel.channelId = channel.channelId;
                SelectedChannel.channelType = channel.channelType;
                selectedChannels.push(SelectedChannel);
            }
        } else if (renderFor == 'district') {
            Object.keys(data).map((key, index) => {
                data[key].map((school: orgItemSettingPayload) => {
                    if (school.isChecked === true) {
                        orgIds.push(school.organizationID);
                    }
                });
            });
        } else {
            Object.keys(data).map((key, index) => {
                data[key].map((channel: ChannelType) => {
                    if (channel.isChecked === true) {
                        SelectedChannel = new selectedChannel();
                        SelectedChannel.channelId = channel.channelId;
                        SelectedChannel.channelType = channel.channelType;
                        selectedChannels.push(SelectedChannel);
                    }
                });
            });
        }

        renderFor == 'district'
            ? setSchoolReachAccess(orgIds, enableReach, enableChannel)
            : setChannelAccess(selectedChannels, enableChannel);
    }
    async function setSchoolReachAccess(
        orgIds: number[],
        enableReach: boolean,
        enableChannel: boolean
    ) {
        try {
            var result = await setReachAccess(
                domain,
                personId,
                orgIds,
                enableReach,
                enableChannel
            );
            if (result?.data?.statusCode) {
                if (isBatchUpdate) {
                    Object.keys(data).map((key, index) => {
                        data[key].map((school: orgItemSettingPayload) => {
                            if (
                                school.isChecked === true &&
                                orgIds.includes(school.organizationID)
                            ) {
                                school.ReachStatus = isEnablePopup ? 1 : 0;
                            }
                        });
                    });
                    //After succesfull update uncheck all the checkbox
                    setAllGroupCheckUncheck(false);
                } else {
                    data[school.schoolType].map((shl: orgItemSettingPayload) => {
                        if (shl.organizationID === school.schoolId) {
                            shl.ReachStatus = isEnablePopup ? 1 : 0;
                        }
                    });
                }
            }
            setChecked(false);
            //refresh school list.
            loadOrgData(authContext.userCtx)
                .then((orgList) => {
                    orgList && setSchools(orgList);
                    handleClickClose();
                })
                .catch((error) => {
                    console.error(error);
                    handleClickClose();
                });
        } catch (e) {
            console.log(e);
            handleClickClose();
            setChecked(false);
        }
    }
    async function setChannelAccess(
        selectedChannels: Array<selectedChannel>,
        enableChannel: boolean
    ) {
        try {
            var result = await updateChannelAccess(
                domain,
                personId,
                selectedChannels,
                enableChannel,
                domainId,
                SelectedSchool.id
            );
            if (result?.data?.statusCode === 200) {
                if (isBatchUpdate) {
                    Object.keys(data).map((key, index) => {
                        data[key].map((channel: ChannelType) => {
                            if (channel.isChecked === true) {
                                channel.channelStatus = enableChannel;
                            }
                        });
                    });
                    //After succesfull update uncheck all the checkbox
                    setAllGroupCheckUncheck(false);
                    setChecked(false);
                } else {
                    Object.keys(data).map((key, index) => {
                        data[key].map((chl: ChannelType) => {
                            if (chl.channelId === selectedChannels[0].channelId) {
                                chl.channelStatus = enableChannel;
                            }
                        });
                    });
                }
            }
            handleClickClose();
            setChecked(false);
        } catch (e) {
            console.log(e);
            handleClickClose();
            setChecked(false);
        }
    }

    function handleClickClose() {
        toggleDialog(false);
        SetData(data);
        getSelectedItemCount();
        setItemStatus('');
        setDisableSearchText(false);
    }
    const [checkGroupClick, setIsCheckGroupClick] = useState(false);
    let [isGroupChecked, setIsGroupChecked] = useState<{
        [id: string]: boolean;
    }>({});
    const [customHeader, setHeader] = React.useState(header.desktop);
    const [sort, setSortCompleted] = useState(false);
    var [datas, SetData] = useState<{
        [id: string]: Array<orgItemSettingPayload>;
    }>({});
    var [isExpand, setIsExpand] = useState<{
        [id: string]: boolean;
    }>({});
    const handleExpandClick = (key: string) => {
        isExpand[key] = !isExpand[key];
        setIsExpand(isExpand);
        setIsExpandClick(!expandClick);
    };
    useEffect(() => {
        setIsExpand(isExpand);
    }, [expandClick]);

    useEffect(() => {
        setIsGroupChecked(isGroupChecked);
    }, [checkGroupClick]);

    useEffect(() => {
        SetData(data);
    }, [data]);

    useEffect(() => {
        // Sorting the channels in school tab in alphabetical order
        arrowDownHandler('all', 'channelName');
    }, [])

    useEffect(() => {
        const expandStatus: Record<string, boolean> = {};
        const groupCheckedStatus: Record<string, boolean> = {};

        Object.keys(data).map((key) => {
            expandStatus[key] = true;
            groupCheckedStatus[key] = false;
        });

        setIsExpand(expandStatus);
        setIsGroupChecked(groupCheckedStatus);
    }, []);

    useEffect(() => {
        responsiveData();
    });

    const [headerChecked, setChecked] = React.useState(false);

    const handleHeaderCheckChange = (e: any) => {
        setChecked(e.target.checked);
        setAllGroupCheckUncheck(e.target.checked);
        getSelectedItemCount();
        setDisableSearchText(e.target.checked);
    };

    const setAllGroupCheckUncheck = (checkStatus: any) => {
        Object.keys(datas).map((key) => {
            isGroupChecked[key] = checkStatus;
            setCheckUncheckStateOfItem(checkStatus, key);
        });
    };

    const handleGroupCheckChange = (key: any) => {
        isGroupChecked[key] = !isGroupChecked[key];
        setIsCheckGroupClick(!checkGroupClick);
        checkAllGroupCheckStatus(key);
        setCheckUncheckStateOfItem(isGroupChecked[key], key);
        getSelectedItemCount();
        setDisableSearchText(isGroupChecked[key]);
    };

    const checkAllGroupCheckStatus = (key: any) => {
        const groupUncheckList = Object.keys(isGroupChecked).filter((key) => {
            return !isGroupChecked[key];
        });
        groupUncheckList.length > 0 ? setChecked(false) : setChecked(true);
    };

    const handleItemChange = (key: any, list: any, listIndex: any, e: any) => {
        list.isChecked = e.target.checked;
        isAllItemChecked(key);
        getSelectedItemCount();
        setDisableSearchText(e.target.checked);
    };

    const isAllItemChecked = (key: any) => {
        const itemUncheckList = datas[key].filter((listItem) => {
            return !listItem.isChecked;
        });
        itemUncheckList.length > 0
            ? (isGroupChecked[key] = false)
            : (isGroupChecked[key] = true);
        setIsCheckGroupClick(!checkGroupClick);
        checkAllGroupCheckStatus(key);
    };

    const setCheckUncheckStateOfItem = (checkStatus: any, categoryKey: any) => {
        const categoryItemArr = datas[categoryKey];
        categoryItemArr.map(function(key: any, index: any) {
            key.isChecked = checkStatus;
        });
    };

    const arrowUpHandler = (
        sortByCategoryValue: string,
        sortByColumnValue: string = 'organizationName'
    ) => {
        sortOrgDict(sortByCategoryValue, 1, sortByColumnValue);
        setSortCompleted(!sort);
    };

    const arrowDownHandler = (
        sortByCategoryValue: string,
        sortByColumnValue: string = 'organizationName'
    ) => {
        sortOrgDict(sortByCategoryValue, 0, sortByColumnValue);
        setSortCompleted(!sort);
    };

    const getSelectedItemCount = () => {
        selectedItemList = [];
        Object.keys(datas).map((key) => {
            const itemCheckList = datas[key].map((listItem) => {
                if (listItem.isChecked) {
                    selectedItemList.push(listItem);
                }
            });
        });
        setSelectedItemList(selectedItemList);
    };

    const switchRef = useRef('');

    const [itemStatus, setItemStatus] = React.useState('');

    const handleChange = (event: any) => {
        if (event.target.value === 'enable') {
            if (renderFor == 'district') {
                toggleEnable(true);
                setItemStatus(statusDropdownList[0].text);
                toggleDialog(true);
            } else {
                isBatchUpdate = true;
                toggleEnable(true);
                handleSaveCloseDialog(true, true);
                toggleDialog(false);
            }
        } else {
            toggleEnable(false);
            setItemStatus(statusDropdownList[1].text);
            toggleDialog(true);
        }
        setBatchUpdate(true);
        var school = new SchoolItem();
        setSchool(school);
    };

    const handleTotalCountClick = (key: any, list: any) => {
        setClickedChannelId(list.channelId);
        getUserCount(key, list);
    };

    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const isTablet = useMediaQuery({
        query: '(min-width: 481px) and (max-width: 829px)',
    });
    const isDesktop = useMediaQuery({ query: '(min-width: 830px)' });
    const isLandscape = useMediaQuery({
        query: '(max-height: 500px)',
    });
    const responsiveData = () => {
        if (isMobile) {
            setHeader(header.mobile);
        }
        if (isTablet || isLandscape) {
            setHeader(header.tablet);
        }
        if (!isMobile && !isTablet && !isLandscape) {
            setHeader(header.desktop);
        }
    };

    let classes = CustomTableStyles()({
        selected: selectedItemList,
        isMobile,
        isTablet,
        renderFor,
    });

    const inputSelectFieldProps = {
        'aria-label': 'Without label',
        disableUnderline: true,
        autoFocus: false,
        classes: { focused: classes.focused },
    };

    return (
        <>
            <SettingConfirmationWindow
                openDialog={dialogeOpen}
                handleSaveCloseDialog={handleSaveCloseDialog}
                handleCloseDialog={handleClickClose}
                isEnablePopUp={isEnablePopup}
                dialogTitle={isEnablePopup ? dialogTitle.Enable : dialogTitle.Disable}
                dialogContent={
                    isEnablePopup
                        ? isBatchUpdate
                            ? dialogContent.BatchEnable
                            : dialogContent.Enable
                        : dialogContent.Disable
                }
            />
            <div
                className={
                    isMobile ? classes.rootContainerMobile : classes.rootContainer
                }
            >
                <div className={classes.headerWrapper}>
                    <Grid container className={classes.headerContainer}>
                        {customHeader.map(function(row, index) {
                            return (
                                <Grid
                                    item
                                    xs={row.breakpoints}
                                    key={index}
                                    className={`${classes.listItem} ${
                                        row.dataKey === 'selectDropdown'
                                            ? classes.selectDropdownGridContainer
                                            : ''
                                        }`}
                                >
                                    {isCheckBox && index === 0 ? (
                                        <CustomCheckbox
                                            data-testid='headerchb'
                                            checked={headerChecked}
                                            onChange={handleHeaderCheckChange}
                                        />
                                    ) : null}

                                    {row.dataKey !== 'selectDropdown' ? (
                                        <>
                                            <span
                                                className={`${
                                                    (isTablet || isLandscape) &&
                                                        customHeader[customHeader.length - 1].dataKey ===
                                                        row.dataKey
                                                        ? classes.tableColumnItem
                                                        : ''
                                                    }`}
                                            >
                                                {row.column}
                                            </span>
                                            <div className={classes.sortArrow}>
                                                <SortUpArrow
                                                    handler={() => {
                                                        arrowUpHandler('All', row.dataKey);
                                                    }}
                                                />
                                                <SortDownArrow
                                                    handler={() => {
                                                        arrowDownHandler('All', row.dataKey);
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                    {selectedItemList.length > 1 &&
                                        row.dataKey === 'selectDropdown' ? (
                                            <div className={classes.selectDropdownGrid}>
                                                <div className={classes.selectDropdownGridItem}>
                                                    <span>{selectedItemList.length} items selected</span>
                                                </div>
                                                <div className={classes.selectDropdownGridItem}>
                                                    <FormControl>
                                                        <Select
                                                            displayEmpty
                                                            disableUnderline
                                                            classes={{ root: classes.focused }}
                                                            value={itemStatus}
                                                            onChange={handleChange}
                                                            inputProps={inputSelectFieldProps}
                                                            input={<Input />}
                                                            renderValue={(selected: any) => {
                                                                if (selected.length === 0) {
                                                                    return <>Edit selection</>;
                                                                }
                                                                return selected;
                                                            }}
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                },
                                                                transformOrigin: {
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                        >
                                                            {statusDropdownList.map((menuItem) => {
                                                                return (
                                                                    <MenuItem
                                                                        className={classes.MenuItemClass}
                                                                        value={menuItem.value}
                                                                    >
                                                                        {menuItem.text}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        ) : // </div>
                                        null}
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
                <div className={classes.contentWrapper}>
                    <Grid container className={classes.content}>
                        {Object.keys(datas).map(function(key, index) {
                            const categorySortDescHandler = () => {
                                sortOrgDict(key, 1);
                                setSortCompleted(!sort);
                            };
                            const categorySortAscHandler = () => {
                                sortOrgDict(key, 0);
                                setSortCompleted(!sort);
                            };
                            return (
                                <div key={index}>
                                    {renderFor === "district" &&
                                        <>
                                            <div className={classes.contentHeader}>
                                                <div className={classes.listItem}>
                                                    {isCheckBox ? (
                                                        <CustomCheckbox
                                                            data-testid='groupchb'
                                                            checked={isGroupChecked[key]}
                                                            onChange={() => {
                                                                handleGroupCheckChange(key);
                                                            }} />
                                                    ) : null}{' '}
                                                    <span data-testid="category" className={classes.listItemLabel}>{key}</span>
                                                    <div className={classes.sortArrow}>
                                                        <SortUpArrow
                                                            handler={() => {
                                                                arrowUpHandler(key);
                                                            }} />
                                                        <SortDownArrow
                                                            handler={() => {
                                                                arrowDownHandler(key);
                                                            }} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <IconButton
                                                        data-testid='collapse-icon'
                                                        onClick={() => {
                                                            handleExpandClick(key);
                                                        }}
                                                    >
                                                        {isExpand[key] ? (
                                                            <ExpandLessIcon />
                                                        ) : (
                                                                <ExpandMoreIcon />
                                                            )}
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <Divider />
                                        </>}
                                    <Collapse in={isExpand[key]} timeout='auto' unmountOnExit>
                                        <Grid container className={classes.content}>
                                            {datas[key].map((list: any, listIndex) => {
                                                return (
                                                    <>
                                                        <div key={index} className={classes.listItem}>
                                                            {customHeader.map((row: any, index: any) => {
                                                                return (
                                                                    <Grid item xs={row.breakpoints} key={index}>
                                                                        <div className={classes.listColumn}>
                                                                            {isCheckBox && index === 0 ? (
                                                                                <div>
                                                                                    <CustomCheckbox
                                                                                        data-testid='list_chb'
                                                                                        onChange={(e) => {
                                                                                            handleItemChange(
                                                                                                key,
                                                                                                list,
                                                                                                listIndex,
                                                                                                e
                                                                                            );
                                                                                        }}
                                                                                        inputProps={{
                                                                                            // @ts-ignore
                                                                                            'data-testid': `clickable_list_chb`,
                                                                                        }}
                                                                                        checked={list.isChecked}
                                                                                    />
                                                                                </div>
                                                                            ) : null}
                                                                            {row.dataKey === 'ReachStatus' ||
                                                                                row.dataKey === 'channelStatus' ? (
                                                                                    <CustomSwitch
                                                                                        status={
                                                                                            row.dataKey === 'ReachStatus'
                                                                                                ? list.ReachStatus
                                                                                                : list.channelStatus
                                                                                        }
                                                                                        handler={() => {
                                                                                            if (renderFor == 'district') {
                                                                                                handleClickOpen(
                                                                                                    list.organizationType,
                                                                                                    list.organizationName,
                                                                                                    list.organizationID,
                                                                                                    list.ReachStatus === 0
                                                                                                        ? true
                                                                                                        : false
                                                                                                );
                                                                                            } else {
                                                                                                handleClickOpen(
                                                                                                    list.channelType,
                                                                                                    list.channelName,
                                                                                                    list.channelId,
                                                                                                    list.channelStatus === false
                                                                                                        ? true
                                                                                                        : false,
                                                                                                    list.category
                                                                                                );
                                                                                            }

                                                                                            setSortCompleted(!sort);
                                                                                        }}
                                                                                    />
                                                                                ) : row.dataKey === 'totalMembers' &&
                                                                                    !list.fetchTotalCount ? (
                                                                                        <div>
                                                                                            <a
                                                                                                className={
                                                                                                    isGettingTotalCount &&
                                                                                                        list.channelId ===
                                                                                                        clickedChannelId
                                                                                                        ? classes.loadingLink
                                                                                                        : classes.customLink
                                                                                                }
                                                                                                aria-describedby={list.channelId}
                                                                                                onClick={() =>
                                                                                                    handleTotalCountClick(key, list)
                                                                                                }
                                                                                            >
                                                                                                {isGettingTotalCount &&
                                                                                                    list.channelId ===
                                                                                                    clickedChannelId ? (
                                                                                                        <>Loading..</>
                                                                                                    ) : (
                                                                                                        <>Total</>
                                                                                                    )}
                                                                                            </a>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <span
                                                                                                className={`${
                                                                                                    isTablet ||
                                                                                                        (isLandscape &&
                                                                                                            customHeader[
                                                                                                                customHeader.length - 1
                                                                                                            ].dataKey === row.dataKey)
                                                                                                        ? classes.tableColumnItem
                                                                                                        : ''
                                                                                                    }`}
                                                                                            >
                                                                                                {row.dataKey === "channelId" && list.channelType === "CHANNEL" ? "NA" : list[row.dataKey]}
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                        </div>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </Grid>
                                    </Collapse>
                                </div>
                            );
                        })}
                    </Grid>
                </div>
                {(isMobile || isTablet || isLandscape) &&
                    selectedItemList.length > 1 ? (
                        <Box
                            id='footer-bottom'
                            position='absolute'
                            className={classes.fixedFooter}
                        >
                            <Box>{selectedItemList.length} items selected</Box>
                            <Box
                                width={`${isMobile ? '100%' : 'auto'}`}
                                display='flex'
                                flexDirection='column'
                            >
                                <Select
                                    displayEmpty
                                    disableUnderline
                                    classes={{ root: classes.focused }}
                                    value={itemStatus}
                                    onChange={handleChange}
                                    inputProps={inputSelectFieldProps}
                                    input={<Input />}
                                    renderValue={(selected: any) => {
                                        if (selected.length === 0) {
                                            return <>Edit selection</>;
                                        }
                                        return selected;
                                    }}
                                    MenuProps={{
                                        style: {
                                            transform: 'translateY(-43px)',
                                        },
                                    }}
                                >
                                    {statusDropdownList.map((menuItem) => {
                                        return (
                                            <MenuItem
                                                className={classes.MenuItemClass}
                                                value={menuItem.value}
                                            >
                                                {menuItem.text}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        </Box>
                    ) : null}
            </div>
        </>
    );
};

export default CustomTable;
