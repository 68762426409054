import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    }
  }
}));

var getSize = function (size: string) {
    switch (size) {
        case 'small':
            return 24;
        case 'medium':
            return 38;
        case 'large':
            return 50;
        default:
            return size;
    }
};
export default function CustomCircularProgress(props: any) {
  const classes = useStyles();
  const size= getSize(props.size);
  return (
    <Box style={{display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',}}>
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={4}
        style={{ color: "#E7D1EF",position: "absolute" }}
      />
      <CircularProgress
        variant="indeterminate"
        color="secondary"
        size={size}
        thickness={4}
        style={{ position: "relative",color:"#a234b5"}}
      />
    </Box>
  );
}
