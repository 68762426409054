import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Loader = () => {
    return (
      <Box
        data-testid='loader'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        flex='1'
      >
        <CircularProgress />
      </Box>
    );
};

export const SaveLoader = () => {
  return (
    <Box
      data-testid='loader'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex='1'
      height='100%'
      width='100%'
      position='fixed'
      zIndex='11'
      bgcolor='rgba(0,0,0,0.5)'
    >
      <CircularProgress />
    </Box>
  );
};

export const AlertBoxLoader = (props: any) => {
  const classes: any = makeStyles(_ => (props.stylesprop))()
  return (
    <Box
      data-testid='dialogloader'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex='1'
      height= {props.stylesprop.paper.height}
      width={props.stylesprop.paper.width}
      position='fixed'
      zIndex='11'
      bgcolor='rgba(0,0,0,0.5)'
    >
      <CircularProgress />
    </Box>
  );
};