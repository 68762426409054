import { makeStyles, fade } from '@material-ui/core/styles';

const ReadRateComponentStyle = makeStyles((theme) => ({
  green: {
    height: '8px',
    width: '8px',
    backgroundColor: '#39E379',
    borderRadius: '50%',
    display: 'inline-block',
  },
  lightgreen: {
    height: '8px',
    width: '8px',
    backgroundColor: '#CDEE4B',
    borderRadius: '50%',
    display: 'inline-block',
  },
  yellow: {
    height: '8px',
    width: '8px',
    backgroundColor: '#FFE300',
    borderRadius: '50%',
    display: 'inline-block',
  },
  orange: {
    height: '8px',
    width: '8px',
    backgroundColor: '#FF9600',
    borderRadius: '50%',
    display: 'inline-block',
  },
  red: {
    height: '8px',
    width: '8px',
    backgroundColor: '#FF4A36',
    borderRadius: '50%',
    display: 'inline-block',
  },
}));

export default ReadRateComponentStyle;