import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { TextField, Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FilterSearchComponent, {
  SearchComponentStateTypes,
} from './FilterSearchComponent';
import SearchResultPanel from './SearchResultPanel/SearchResultPanel';
import { MessageSearchPayload } from '../Types/MessageSearchPayload';
import { UserAuthCtx } from '../components/AuthComponent';
import {
  getChatArchiveMessages,
  getChannelStatusUsingName,
} from '../services/ChatArchiveService';
import Pagination from './Pagination/Pagination';
import PageNavigation from './PageNavigation/PageNavigation';
import AlertBox from './AlertBox/AlertBox';
import AutoComplete from './AutoComplete/AutoComplete';
import { stringType } from 'aws-sdk/clients/iam';
import { orgList } from '../dataLoaders/DataHandler';
import { useMediaQuery } from 'react-responsive';

export const MESSAGE_CARD_LENGTH = 100;
export const PAGE_SIZE = 50;

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    root: {
      display: 'flex',
      height: 'inherit',
    },
    searchPanel: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      marginBottom: '12px',
      overflowX: 'hidden',
    },
    groupNameDiv: {
      display: 'flex',
      flex: '1',
      alignContent: 'space-between',
      alignItems: 'center',
    },
    groupName: {
      display: 'flex',
      alignItems: 'left',
      fontWeight: 600,
      fontSize: '18px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      display: 'flex',
      fontSize: '14px',
      marginTop: '12px',
    },
    textField: {
      borderRadius: '2px',
    },
    focused: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
        borderColor: '#262626',
      },
    },
    gridContainer: {
      height: 'inherit',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
    },
    gridRoot: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: 'inherit',
      maxWidth: '100%',
    },
    asterisk: {
      float: 'none',
    },
    searchFieldSection: {
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        paddingRight: '15px',
        height: 'inherit',
        maxHeight: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 500px)',
      },
      ['@media (max-height: 500px)']: {
        overflow: 'unset',
      },
      ['@media screen and (min-device-width: 641px) and (orientation:landscape)']: {
        overflow: 'auto',
      },
    },
    customScroll: {
      overflow: 'auto',
      scrollbarColor: '#666666 transparent',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },

      /* Track */
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#666666',
        borderRadius: '10px',
        border: '1px solid #666',
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    },
    overflowContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        overflow: 'auto',
        paddingRight: '15px',
        height: 'inherit',
        maxHeight: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        height: 'calc(100vh - 500px)',
      },
      ['@media (max-height: 500px)']: {
        overflow: 'unset',
      },
      ['@media screen and (min-device-width: 641px) and (orientation:landscape)']: {
        overflow: 'auto',
      },
    },
    navigationContainer: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
      },
      paddingTop: theme.spacing(3),
    },
    paginationWrapper: {
      padding: '0 0 30px 0;',
    },
  });
});

const ChatArchiveMessages = () => {
  const classes = useStyles();

  const inputTextFieldProps = {
    disableUnderline: true,
    classes: { focused: classes.focused },
  };
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const [searchStr, setSearchStr] = useState('');
  const [searchResult, setSearchResult] = useState<null | []>(null);
  const [resultPanelLoading, setResultPanelLoading] = useState(false);
  const [isOverlayLoading, setIsOverlayLoading] = useState(false);
  const [highlightTokens, sethighlightTokens] = useState<string[]>(['']);
  const [
    searchPayload,
    setSearchPayload,
  ] = useState<MessageSearchPayload | null>(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalMessages, setTotalMessages] = useState(0);
  const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState<any>({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [showFilterPanel, toggleFilterPanel] = useState(false);
  const [schoolName, setSchoolName] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [schoolId, setSchoolId] = useState('');

  const isEmptyResultSet = () => {
    return Array.isArray(searchResult) && searchResult.length <= 0;
  };

  const handleSearchOnchange = (e: ChangeEvent<HTMLInputElement>) =>
    setSearchStr(e.target.value);

  const [requiredFilterElements, setrequiredFilterElements] = useState({
    tabIndex: 0,
    schoolSelector: false,
    dateRange: true,
    channelField: true,
    userField: true,
  });

  const authContext = useContext(UserAuthCtx);
  const userId = authContext.userCtx.userId ? authContext.userCtx.userId : '';
  const domain = authContext.userCtx.userLoggedInDomain
    ? authContext.userCtx.userLoggedInDomain
    : '';
  var domainId = authContext.userCtx.domainId
    ? authContext.userCtx.domainId
    : '';

  const clearKeyword = () => {
    setSearchStr('');
    setSchoolName(null);
    setSchoolId('');
    setSearchResult(null);
  };

  const showPagination = () => {
    return (
      searchResult &&
      totalMessages > PAGE_SIZE &&
      !resultPanelLoading &&
      !isError
    );
  };

  const showNavigation = () => {
    return (
      searchResult &&
      Array.isArray(searchResult) &&
      ((searchResult as unknown) as []).length > 0 &&
      !isError &&
      !resultPanelLoading
    );
  };

  const getPageForNavigation = () => {
    return currentPage <= 1 ? currentPage : PAGE_SIZE * (currentPage - 1) + 1;
  };

  const getOffsetForNavigation = () => {
    const offset = getPageForNavigation() + PAGE_SIZE - 1;
    return offset > totalMessages ? totalMessages : offset;
  };

  const setPayloadForPagination = (
    LastEvaluatedKey: boolean | string | null = '',
    lastdate: boolean | string | null = '',
    page: number | stringType
  ) => {
    setLastEvaluatedKeys({
      ...lastEvaluatedKeys,
      [page]: {
        LastEvaluatedKey,
        lastdate,
        offset: LastEvaluatedKey,
      },
    });
  };

  const getPaginatedMessages = async (
    searchPayload: MessageSearchPayload | null,
    domain: string,
    scrollup: 'True' | 'False' = 'False'
  ) => {
    if (searchPayload) {
      const messages: any = await getChatArchiveMessages(
        { ...searchPayload, scrollup },
        domain
      );
      return messages.data;
    } else {
      return Promise.reject('Invalid payload');
    }
  };

  const getPaginatedResults = async (page: number, direction: string) => {
    let payloadForPagination: any = {};
    let paginatedResponse: any = null;

    if (lastEvaluatedKeys.hasOwnProperty(page - 1)) {
      payloadForPagination = lastEvaluatedKeys[(page - 1).toString()];
    }

    try {
      paginatedResponse = await getPaginatedMessages(
        { ...searchPayload, ...payloadForPagination },
        domain,
        direction == 'next' ? 'False' : 'False'
      );
    } catch (error) {
      throw new Error('Error in getting paginated response');
    }

    return paginatedResponse;
  };

  const onSuccess = (searchKeyword: string = '') => {
    setResultPanelLoading(false);
    sethighlightTokens(searchKeyword.split(' '));
    setIsOverlayLoading(false);
    setIsError(false);
    setErrorMessage(undefined);
  };

  const onFailure = (error: Error) => {
    console.error(error);
    setResultPanelLoading(false);
    setSearchResult(null);
    sethighlightTokens(['']);
    setIsError(true);
    setErrorMessage(error.message);
  };

  const launchSearch = async (
    filterSearchState: SearchComponentStateTypes,
    searchKeyword: string
  ) => {
    setResultPanelLoading(true);
    const {
      channel,
      startDateEpoch,
      endDateEpoch,
      school,
      sender,
    } = filterSearchState;
    const searchPayload: MessageSearchPayload = {
      channel,
      school,
      sender,
      startDate: startDateEpoch?.toString() || '',
      endDate: endDateEpoch?.toString() || '',
      content: searchKeyword,
      userId: `${userId}|${domainId}`,
      scrollup: 'False',
      offset: 0,
      LastEvaluatedKey: null,
      lastdate: null,
    };

    try {
      const messages: any = await getChatArchiveMessages(searchPayload, domain);
      const {
        error = null,
        errorType = null,
        statusCode = null,
        warningMessage = null,
        messages_count = 0,
      } = messages.data;

      if (errorType) {
        throw new Error(`Error getting search result ${errorType}`);
      }

      if (warningMessage && statusCode && statusCode === 199) {
        throw new Error(warningMessage);
      }

      if (error) {
        setAlertOpen(true);
        setResultPanelLoading(false);
      }

      setLastEvaluatedKeys({});
      setCurrentPage(1);
      setSearchResult(messages.data.messages);
      setTotalMessages(messages_count);

      const { LastEvaluatedKey = '', lastdate = '' } = messages.data;
      setPayloadForPagination(LastEvaluatedKey, lastdate, currentPage);
      setSearchPayload(searchPayload);

      onSuccess(searchKeyword);
    } catch (error) {
      onFailure(error);
    }
  };

  const formatChannelList = (option: any) => {
    return (
      <>
        <Box component='div' display='flex' width='100%'>
          <Box
            component='span'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
          >
            {option.name}
          </Box>
          <Box component='span'>
            ,<Box component='span' ml={1} />
            {option.id}
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    setrequiredFilterElements({
      ...requiredFilterElements,
      dateRange: showFilterPanel,
      channelField: showFilterPanel,
      userField: showFilterPanel,
    });
  }, [showFilterPanel]);

  useEffect(() => {
    const showPanel = isMobile ? false : true;
    toggleFilterPanel(showPanel);
  }, [isMobile]);

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          sm={4}
          className={
            !isMobile
              ? `${classes.searchFieldSection} ${classes.customScroll}`
              : ''
          }
        >
          <div className={classes.searchPanel}>
            <FormControl className={classes.formControl}>
              <InputLabel
                id='schoollbl'
                required
                classes={{ asterisk: classes.asterisk }}
              >
                School
              </InputLabel>
              <AutoComplete
                mode='data'
                id='schoollbl'
                isFreeForm={false}
                value={schoolName}
                dataFormatter={(data: any) => {
                  return data.map((school: any) => {
                    const { id, name } = school;
                    return {
                      id,
                      name,
                    };
                  });
                }}
                disabled={false}
                placeholder='Select school'
                noOptionsText='No schools'
                optionsArray={orgList}
                renderOption={formatChannelList}
                getOptionSelected={(option, value) => option.name === value}
                onChange={(event, item) => {
                  const schlId = item.id.toString() + '|' + domainId.toString();
                  setSchoolId(schlId);
                  setSchoolName(item.name);
                }}
                getOptionLabel={(item) => {
                  if (item.name) {
                    return item.name;
                  }
                  return item;
                }}
                expanded={expanded}
                setExpanded={setExpanded}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel required classes={{ asterisk: classes.asterisk }}>
                Keyword
              </InputLabel>
              <TextField
                className={classes.textField}
                data-testid='searchStr'
                placeholder='Search messages'
                type='text'
                value={searchStr}
                onChange={handleSearchOnchange}
                InputProps={inputTextFieldProps}
              />
            </FormControl>
          </div>
          <FilterSearchComponent
            showElements={requiredFilterElements}
            handleSearchMessages={launchSearch}
            searchKeyword={searchStr.trim()}
            schoolId={schoolId}
            clearSearch={clearKeyword}
            showFilterPanel={showFilterPanel}
            toggleFilterPanel={toggleFilterPanel}
          />
        </Grid>
        <Box
          className={`${classes.overflowContainer} ${classes.customScroll} `}
        >
          <Grid item xs={12} sm={8} className={classes.gridRoot}>
            {showNavigation() && (
              <Box className={classes.navigationContainer}>
                <PageNavigation
                  total={totalMessages}
                  offset={getOffsetForNavigation()}
                  currentPage={getPageForNavigation()}
                />
              </Box>
            )}
            <SearchResultPanel
              emptyMessage={isEmptyResultSet() ? 'No Results' : 'Find Messages'}
              messages={searchResult}
              isLoading={resultPanelLoading}
              isOverlayLoading={isOverlayLoading}
              highlightTokens={highlightTokens}
              maxMessageLength={MESSAGE_CARD_LENGTH}
              isError={isError}
              errorMessage={errorMessage}
              loggedInUser={userId}
            />
            {showPagination() && (
              <div className={classes.paginationWrapper}>
                <Pagination
                  totalPages={Math.ceil(totalMessages / PAGE_SIZE)}
                  currentPage={currentPage}
                  resource={getPaginatedResults}
                  setLoader={setIsOverlayLoading}
                  updateDom={(messages: any, currentPage: number) => {
                    setCurrentPage(currentPage);
                    if (messages) {
                      const { LastEvaluatedKey = '', lastdate = '' } = messages;
                      setPayloadForPagination(
                        LastEvaluatedKey,
                        lastdate,
                        currentPage
                      );
                      setSearchResult(messages?.messages);
                      onSuccess(searchPayload?.content || '');
                    } else {
                      onFailure(
                        new Error('No response from paginated request')
                      );
                    }
                  }}
                />
              </div>
            )}
          </Grid>
        </Box>
      </Grid>

      <AlertBox
        title='Message Unavailable'
        open={alertOpen}
        setOpen={setAlertOpen}
      >
        Your messages won't display because the channel is inactive. Please
        contact your administrator
      </AlertBox>
    </div>
  );
};
export default ChatArchiveMessages;
