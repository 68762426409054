import { makeStyles, fade } from '@material-ui/core/styles';

const UsageReportsStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'inherit',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(100vh - 100px)',
      overflowY: 'scroll',
    },
  },
  parentContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit'
    },
  },
  cardsWrapper: {
    height: 'calc(100vh - 285px)',
    [theme.breakpoints.up('md')]: {
      gap: '4px'  
    }
  },
  headerRow: {
    marginBottom: '12px',
  },
  heading: {
    fontSize: '24px',
    lineHeight: '36px',
  },
  downloadBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '14px',
    lineHeight: '21px',
    paddingTop: '8px',
  },
  label: {
    fontSize: '14px',
  },
  mainContainer: {
    paddingTop: '1.5rem',
  },
  overflowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto',
      paddingRight: '20px',
      paddingLeft: '30px',
      maxHeight: '100%',
      scrollbarColor: '#666666 transparent',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '8px',
      },

      /* Track */
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#666666',
        borderRadius: '10px',
        border: '1px solid #666',
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    },
  },
  filterBar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px'
  },
  formControl: {
    display: 'flex',
    fontSize: '14px',
  },
  RawDataHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 600,
  },
  subRawHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 600,
  },
  downloadAnchor: {
    display: 'inherit',
    paddingLeft: '5px',
    paddingTop: '5px',
    cursor: 'pointer',
  },
  disabled: {
    display: 'inherit',
    paddingLeft: '5px',
    paddingTop: '5px',
  },
  headerMargin: {
    margin: 0,
  },
}));

export default UsageReportsStyle;
