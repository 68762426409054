import React, { useRef, useEffect, useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, fade } from '@material-ui/core/styles';
import { getOrganizationList } from '../services/settingPageService';
import { orgItemSettingPayload } from '../Types/orgSettingInfo';
import { UserAuthCtx } from '../components/AuthComponent';
import CustomTable from './CustomTable/CustomTable';
import { StateContext } from './StateContextComponent';
import CustomCircularProgress from './ReusableComponents/CircularProgress';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularLoader: {
    //alignSelf:"center"
  },
  root: {
    margin: '20px',
    marginBottom: 0,
    height: () => {
      return `calc(${window.innerHeight}px - 105px - 20px)`;
    },
    ['@media (max-height: 500px)']: {
      height: () => {
        return `calc(${window.innerHeight}px - 105px - 5px)`;
      },
    },
  },
  textbox: {
    display: 'flex',
    flex: 1,
    borderRadius: '2px',
    border: '1px solid #CDCDCD',
    marginBottom: '35px',
  },
  focused: {
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
      borderColor: '#262626',
    },
  },
}));

const DistrictSettingComponent = () => {
  var orgListResult = new Array<orgItemSettingPayload>();
  const userDetails = useContext(UserAuthCtx).userCtx;
  const { setEnableSchoolsToSelect } = useContext(StateContext);
  var [orgDict, setorgDict] = useState<{
    [id: string]: Array<orgItemSettingPayload>;
  }>({});
  var [filterDict, setfilterDict] = useState<{
    [id: string]: Array<orgItemSettingPayload>;
  }>({});
  const textRef = useRef(null);
  const classes = useStyles();
  const [isProcessing, setStateProcessing] = useState(true);
  const [filterWord, setFilterWord] = useState('');
  var sortByColumn = 'organizationName';
  const [errorMessage, setErrorMessage] = useState('');
  const [disabledTextBox, setDisableTextBox] = useState(false);

  const customTableHeader = [
    { column: 'Item', breakpoints: 4, dataKey: 'organizationName' },
    { column: 'Reach status', breakpoints: 2, dataKey: 'ReachStatus' },
    { column: '', breakpoints: 6, dataKey: 'selectDropdown' },
  ];

  const headerConfig = {
    mobile: [
      { ...customTableHeader[0], breakpoints: 8 },
      { ...customTableHeader[1], breakpoints: 4 },
    ],
    tablet: [
      { ...customTableHeader[0], breakpoints: 8 },
      { ...customTableHeader[1], breakpoints: 4 },
    ],
    desktop: customTableHeader,
  };

  const statusDropdown = [
    { value: 'enable', text: 'Enable Reach' },
    { value: 'disable', text: 'Disable Reach' },
  ];

  function compareByDesc(a: any, b: any) {
    return b[sortByColumn].toString().localeCompare(a[sortByColumn]);
  }

  function compareByAsc(a: any, b: any) {
    return a[sortByColumn].toString().localeCompare(b[sortByColumn]);
  }

  function sortOrgDict(
    keyIndex: string,
    sortDirection: number,
    sortByColumnValue: string
  ) {
    sortByColumn = sortByColumnValue;
    if (keyIndex == 'All') {
      Object.keys(orgDict).map(function (key, index) {
        sortDirection == 1
          ? orgDict[key].sort(compareByDesc)
          : orgDict[key].sort(compareByAsc);
      });
      setorgDict(orgDict);
    } else {
      Object.keys(orgDict).map(function (key, index) {
        if (key == keyIndex) {
          sortDirection == 1
            ? orgDict[key].sort(compareByDesc)
            : orgDict[key].sort(compareByAsc);
        }
      });
      setorgDict(orgDict);
    }
  }

  function cleanDictList(orgListDict: any) {
    let updatedOrgList = { ...orgListDict };
    Object.entries(updatedOrgList).forEach(function(entry) {
      let [key, value] = entry;
      if (value instanceof Array) {
        if (value.length === 0) {
          delete updatedOrgList[key];
        }
      }
    });

    return updatedOrgList;
  }

  function mapOrgListToDict(orgListResult: Array<orgItemSettingPayload>) {
    var orgListDict: { [id: string]: Array<orgItemSettingPayload> } = {
      HIGH: [],
      MIDDLE: [],
      ELEMENTARY: []
    };
    orgListResult.map((orgItem) => {
      orgItem.isChecked = false;
      if (orgItem.organizationType in orgListDict) {
        orgListDict[orgItem.organizationType].push(orgItem);
      } else {
        orgListDict[orgItem.organizationType] = new Array<
          orgItemSettingPayload
        >();
        orgListDict[orgItem.organizationType].push(orgItem);
      }
    });

    return cleanDictList(orgListDict);
  }

  useEffect(() => {
    setEnableSchoolsToSelect(false);
    var loadOrgList = async () => {
      try {
        var result = await getOrganizationList(userDetails.userLoggedInDomain);
        if (result?.status === 200) {
          if (result?.data.length > 0) {
            orgListResult = result?.data;
            var orgResult = mapOrgListToDict(orgListResult);
            setorgDict(orgResult);
          }
        }
        setStateProcessing(false);
      } catch (e) {
        console.log(e);
        setStateProcessing(false);
      }
      setEnableSchoolsToSelect(true);
    };
    loadOrgList();
  }, []);

  function filterData(filterWord: string) {
    var filteredData: { [id: string]: Array<orgItemSettingPayload> } = {};
    if (filterWord.trim() === '') {
      return orgDict;
    }
    Object.keys(orgDict).map((name: string) => {
      const items = orgDict[name].filter((channel) => {
        return channel.organizationName
          .toLowerCase()
          .includes(filterWord.toLowerCase());
      });
      if (items.length) {
        filteredData[name] = items;
      }
    });
    return filteredData;
  }
  useEffect(() => {
    let data = filterData(filterWord);
    setfilterDict(data);
  }, [filterWord, orgDict]);

  const isDataAvailable = () => {
    return Object.keys(filterDict).length > 0;
  };

  if (isProcessing) {
    return (
      <div className={classes.loaderContainer}>
        <CustomCircularProgress
          css={classes.circularLoader}
          aria-label='This is a medium indicator'
          size='medium'
        ></CustomCircularProgress>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <TextField
        disabled={disabledTextBox}
        className={classes.textbox}
        data-testid='search-schools'
        placeholder='Search'
        type='text'
        onChange={(event) => setFilterWord(event.target.value)}
        InputProps={{
          disableUnderline: true,
          classes: { focused: classes.focused },
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {isDataAvailable() ? (
        <CustomTable
          isCheckBox={true}
          header={headerConfig}
          data={filterDict}
          statusDropdown={statusDropdown}
          sortOrgDict={sortOrgDict}
          setDisableSearchText={setDisableTextBox}
          renderFor={'district'}
        />
      ) : (
        <span>No Results found</span>
      )}
    </div>
  );
};

export default DistrictSettingComponent;
