import React, { useContext, useEffect, useState, useRef } from 'react';
import { PrimaryButton, DefaultButton } from './ReusableComponents/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from './ReusableComponents/ReachDialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import { getSearchMembers } from '../services/userAccountServices';
import ReactHtmlParser from 'react-html-parser';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import { UserAuthCtx } from './AuthComponent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { GroupType } from '../Types/GroupType';
import { GroupEssential } from '../Types/GroupEssential';
import { DirectUserData } from '../Types/DirectMessageContact';
import { StateContext } from './StateContextComponent';

const Relation = ({ Myvalue }: any) => {
  return (
    <>
      <div>
        {Myvalue.map((val: any) => {
          return ReactHtmlParser(val);
        })}
      </div>
      {Myvalue.length === 0 && <div> N/A </div>}
    </>
  );
};

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const ConfirmAlertDialogStyle = makeStyles((theme) => ({
  root: {
    padding: '16px',
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  dmContent: {
    padding: '0px',
  },
  smallAvatar: {
    position: 'inherit',
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: '15px',
  },
  textbox: {
    padding: '18px',
    paddingBottom: '0px',
    marginBottom: '10px',
    width: '100%',
  },
  tableRow: {
    '&$selected': {
      backgroundColor: 'Black',

      '&:hover': {
        backgroundColor: 'Black',
      },
      '&:focus': {
        backgroundColor: 'Black',
      },
    },
  },
  tableCell: {
    paddingLeft: '25px',
    '$selected &': {
      color: 'White',
    },
  },
  selected: {},

  tableHeadBorder:{
    border: 'None!important',
    paddingLeft: '25px',
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  progress: {
    height: '3px',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
    '& div': {
      backgroundColor: '#FF5991',
    },
  },
  paperDiv: {
    boxShadow: 'None!important',
  },
  container: {
    maxHeight: '250px', 
  },
  headerRow: {
    backgroundColor: '#262626',
  },
  errorContainer: {
    textAlign: 'center',
    paddingTop: '90px',
    color: 'grey',
  },
}));

const DialogShape = makeStyles((theme) => ({
  paper: {
    minHeight: '475px',
    maxWidth: '660px',
  },
}));

interface Column {
  id: 'name' | 'role' | 'relationship';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

interface Data {
  name: string;
  role: string;
  relationship: Array<string>;
  recipentId: number;
}

type dialogType = {
  openDialog: any;
  handleClose: any;
  domain: string;
  personId: string;
  setGroup: React.Dispatch<React.SetStateAction<GroupEssential>>;
};

const CreateDirectMsgDialog = ({
  openDialog,
  handleClose,
  personId,
  domain,
  setGroup,
}: dialogType) => {
  const classes = ConfirmAlertDialogStyle();
  const [search, setSearch] = React.useState('');
  const [rows, setRows] = useState<Array<Data>>(new Array<Data>());
  const [recipients, setRecipients] = useState([]);
  const authContext = useContext(UserAuthCtx);
  const [isTableLoading, setTableLoading] = useState(false);
  const textRef = useRef(null);
  const { selectedOrganizationId } = useContext(StateContext);
  var domainId = authContext.userCtx.domainId;
  function createData(recipient: any): Data {
    const name = recipient.lastName + ', ' + recipient.firstName;
    const role = recipient.roleName;
    const recipentId = recipient.personID;
    var relationship = [];
    for (var student of recipient.relationships)
      relationship.push(
        student.firstName +
          ' ' +
          student.lastName +
          ', <em>' +
          student.relationType.substr(0, 1).toUpperCase() +
          student.relationType.slice(1).toLowerCase() +
          '</em><br>'
      );

    return { name, role, relationship, recipentId };
  }

  function genericUserFilter(userType: any, filterStr: string):any{
    if (filterStr.indexOf(' ') >= 0){
      if ((userType.firstName.toLowerCase()+' '+userType.lastName.toLowerCase()).includes(filterStr.toLowerCase())){
        return true;
      }
      if ((userType.lastName.toLowerCase()+' '+userType.firstName.toLowerCase()).includes(filterStr.toLowerCase())){
        return true;
      }
    }
    else {
      if (userType.lastName.toLowerCase().includes(filterStr.toLowerCase())){
        return true;
      }
      if (userType.firstName.toLowerCase().includes(filterStr.toLowerCase())){
        return true;
      }
    }
  }
  function createFilteredData(recipient: any, filterStr: string): any {
    var recipientName = genericUserFilter(recipient, filterStr);
    if (recipientName){
      return true
    }

    for (var student of recipient.relationships) {
      var studentName = genericUserFilter(student, filterStr);
      if (studentName){
        return true
      }
    }
    return false;
  }

  const loadData = async (name: string) => {
    setTableLoading(true);
    var x = await getSearchMembers(
      name,
      domain,
      selectedOrganizationId.schoolId.toString(),
      personId
    );
    var clone: any = [];
    x?.data?.recipients?.map((recip: any, index: number) => {
      clone.push(recip);
    });
    setRecipients(clone);
    setTableLoading(false);
  };

  function filterData(value: string) {
    if (value === '') {
      setRows([]);
      return;
    }
    setRows(
      recipients
        .filter(function (recipient) {
          return createFilteredData(recipient, value);
        })
        .map((recipient, index) => {
          return createData(recipient);
        })
    );
  }

  useEffect(() => {
    //if (textRef !== null && textRef.current !== null)
    //textRef.current.scrollIntoView({ behavior: "smooth" });
    setRecipients([]);
    setSearch('');
    setRows([]);
  }, []);

  useEffect(() => {
    filterData(search);
  }, [recipients]);

  const columns: Column[] = [
    { id: 'name', label: 'Name', minWidth: 200 },
    { id: 'role', label: 'Role', minWidth: 100 },
    {
      id: 'relationship',
      label: 'Relationship',
      minWidth: 250,
    },
  ];

  const [noResultsFound, setNoResultsFound] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [selectedData, setSelectedData] = useState<DirectUserData>(
    new DirectUserData()
  );
  const [disabledValue, setDisabledValue] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (
      event.target.value.length === 2 ||
      (event.target.value.length >= 2 && recipients.length === 0)
    ) {
      loadData(event.target.value);
      setDisabledValue(true);
      setSelectedIndex(-1);
    }
    if (event.target.value !== '' && event.target.value.length >= 2) {
      filterData(event.target.value);
      setDisabledValue(true);
      setSelectedIndex(-1);
    }
    if (
      (event.target.value !== '' && event.target.value.length === 1) ||
      event.target.value === ''
    ) {
      setRecipients([]);
      setRows(
        recipients.map((recipient, index) => {
          return createData(recipient);
        })
      );
      setDisabledValue(true);
      setSelectedIndex(-1);
    }
  };

  useEffect(() => {
    // if the search text field has something and we dont have anything to display set 'nothing found' message
    if (search !== '' && search.length >= 2)
      setNoResultsFound(rows.length === 0);
    // clear 'nothing found' message if either table is loading or we got something to display.
    // never display table content and 'nothing found' message together
    if (rows.length !== 0 || isTableLoading) setNoResultsFound(false);
  }, [rows, isTableLoading]);

  //-----------------function handles when dialog is closed
  function dialogCloseHandle() {
    setSearch('');
    setRecipients([]);
    setSelectedIndex(-1);
    handleClose();
    setDisabledValue(true);
  }
  //------function when Go button is clicked create Group Essential

  const goHandler = () => {
    var DirectUserId;
    // creating DirectUserId based on sender and receiver id. Which ever is the smallest is used
    // first, to avoid creating 2 different id's for the same conversation.
    if (Number(personId) < selectedData.id)
      DirectUserId =
        GroupType.DirectMessage +
        '|' +
        personId +
        '|' +
        domainId +
        '|' +
        selectedData.id +
        '|' +
        domainId;
    else
      DirectUserId =
        GroupType.DirectMessage +
        '|' +
        selectedData.id +
        '|' +
        domainId +
        '|' +
        personId +
        '|' +
        domainId;
    var group = new GroupEssential();
    group.id = DirectUserId;
    group.type = GroupType.DirectMessage;
    group.name = selectedData.name;
    group.groupType = GroupType.DirectMessage;
    setGroup(group);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={dialogCloseHandle}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      classes={DialogShape()}
      className={classes.root}
    >
      <DialogTitle
        css={null}
        id='alert-dialog-title'
        onClose={dialogCloseHandle}
      >
        Create Direct Message
      </DialogTitle>
      <DialogContent className={classes.dmContent}>
        <TextField
          id='outlined-basic'
          placeholder='Search users'
          variant='outlined'
          className={classes.textbox}
          onChange={handleChange}
          value={search}
          inputRef={textRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Paper className={classes.paperDiv}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.tableHeadBorder}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  const clickHandler = () => {
                    setSelectedIndex(index);
                    var userName = row.name.split(',');
                    var userData = {
                      name: userName[1].trim() + ' ' + userName[0].trim(),
                      id: row.recipentId,
                      role: row.role,
                    };
                    setSelectedData(userData);
                    setDisabledValue(false);
                  };
                  return (
                    <TableRow
                      hover
                      classes={{ selected: classes.selected }}
                      className={classes.tableRow}
                      role='checkbox'
                      tabIndex={-1}
                      key={index}
                      onClick={clickHandler}
                      selected={selectedIndex === index}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={classes.tableCell}
                          >
                            <div className={classes.name}>
                              {column.id === 'name' && (
                                <Avatar className={classes.smallAvatar}>
                                  <PersonIcon />
                                </Avatar>
                              )}
                              {column.id === 'name' && <div>{value}</div>}
                              {column.id === 'role' &&
                                typeof value === 'string' && (
                                  <div>
                                    {value.substr(0, 1).toUpperCase() +
                                      value.slice(1).toLowerCase()}
                                  </div>
                                )}
                            </div>
                            {typeof value !== 'string' && (
                              <Relation Myvalue={value} />
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {isTableLoading && <LinearProgress className={classes.progress} />}
        </Paper>
        {noResultsFound && search.length >= 2 && (
          <div className={classes.errorContainer}>No results found !</div>
        )}
        {search.length < 2 && (
          <div className={classes.errorContainer}>Search for users by name</div>
        )}
      </DialogContent>
      <DialogActions>
        <DefaultButton css={null} onClick={dialogCloseHandle} size='large'>
          Cancel
        </DefaultButton>
        <PrimaryButton
          size='large'
          css={null}
          style={{ width: '92.36px', backgroundColor: "#000000" }}
          disabled={disabledValue}
          onClick={() => {
            dialogCloseHandle();
            goHandler();
          }}
        >
          Create
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDirectMsgDialog;