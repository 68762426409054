import React, { useState } from 'react';
import EmailUnsubscriptionStyle from './EmailUnsubscriptionStyle';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import EmailSubscriptionNav from '../EmailSubscriptionNav/EmailSubscriptionNav';
import { confirmUnsubscription } from '../../services/UserProfileService';
import NotificationBox from '../NotificationBox/NotificationBox';
import CustomCircularProgress from '../ReusableComponents/CircularProgress';

const EmailUnsubscription = () => {
    const classes = EmailUnsubscriptionStyle();
    const [showLoader, setShowLoader] = useState(false);
    const [unsubscribed, setUnsubscribed] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const [notification, setNotification] = useState({
        message: '',
        severity: '',
    });
    const emailId = urlParams.get('email') || '';
    const userId =  urlParams.get('userId') || '';

    const confirmEmailUnsubscription = () => {
        setShowLoader(true);
        confirmUnsubscription(emailId, userId).then((response: any) => {
            if(response.data && response.data.errorMessage){
                const { errorMessage } = response.data;
                setNotification({
                    message: errorMessage,
                    severity: 'error'
                });
                setUnsubscribed(false);
            } else {
                const { body: { message }} = response.data;
                setNotification({
                    message: response.data.body,
                    severity: 'success'
                });
                setUnsubscribed(true);
            }
            setShowLoader(false);
            
        }).catch((error: any) => {
            const errorResponse = error?.response?.data;
            setNotification({
                message: errorResponse && errorResponse.message || 'Something went wrong',
                severity: 'error'
            });
            setShowLoader(false);
            setUnsubscribed(false);
        });
    }

    return (
        <div className={classes.root}>
            <EmailSubscriptionNav />
            <div className={classes.mainDiv}>
                {
                    notification.message ?
                    <NotificationBox
                        message={notification.message}
                        severity={notification.severity}
                        handleNotification={() => {
                            setNotification({
                                message: '',
                                severity: ''
                            });
                        }}
                    /> : ''
                }
                <div className={classes.rootWrapper}>
                    <div className={classes.container}>
                        <div data-testid='title' className={classes.title}>
                            Are you sure you want to unsubscribe?
                        </div>
                        <p data-testid='message' className={classes.message}>
                            You are about to unsubscribe {emailId} from Reach Email Digest?
                            Click unsubscribe to complete.
                        </p>
                        <div className={classes.buttonWrapper}>
                            <Button
                                data-testid='unsubscribeBtn'
                                type='button'
                                className={clsx(classes.unsubscribeBtn, classes.buttonWrapper)}
                                disabled={unsubscribed}
                                onClick={confirmEmailUnsubscription}
                                endIcon={
                                    showLoader &&
                                    <CustomCircularProgress
                                        aria-label='Loading'
                                        size={20}
                                    />
                                }
                            >
                                Unsubscribe
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailUnsubscription;
