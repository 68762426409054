import React, { useState, useEffect }from 'react';
import StatusIcon from '../../Icons/StatusIcon';
import { Box } from '@material-ui/core';

type customSwitchTypes = {
    status: number
    handler: any
}
const CustomSwitch = ({ status, handler}: customSwitchTypes) => {
    const [enabled, setEnabled] = useState(status);

    useEffect(() => {
        setEnabled(status);
    }, [status])

    return (
        <Box onClick={handler} display='flex' data-testid='toggle-btn'>
            <StatusIcon isenabled={enabled} />
        </Box>
    );
}

export default CustomSwitch;
