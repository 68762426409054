import { makeStyles } from '@material-ui/core/styles';

 const ModalStyle = makeStyles((theme) => ({

     bgContainer: {
      top:'44px',
       position: 'fixed',
       left: '0',
       zIndex: 1200,
       background: 'rgba(60, 60, 60, 0.5)',
       width: '100%',
       height: '100%',
       paddingLeft: '135px',
         '@media screen and (max-width: 550px)': {
           paddingLeft: '53px',
     },
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'center',
       alignItems: 'center',
       paddingBottom:"40px",

     },
     textbox: {
         background: "#F8F8F8",
         boxShadow: '0px 1px 3px #CDCDCD',
         padding: '18px 29px 18px 29px',
         margin:"20px",
         marginBottom:'0px',
         '@media screen and (max-width: 480px)': {
           margin: '0px',
           marginTop:"20px",
     },
         display:"flex",
         borderRadius:"2px",
       },
       channelStyle:{
         fontSize: '14px',
         marginLeft: '27px',
         marginTop: '23px',
       },
     modalContainer: {
       backgroundColor:'white',
       width: '100%',
       height: '100%',
       display: 'flex',
       flexDirection: 'column',
     },
     headerContainer: {
       color: '#262626',
     },
     closeIconStyle:{
         cursor:"pointer",
         position:"relative",
         top: '-90%',
         left: "-1.7rem",
         marginBottom: "-83px",
         width:"2.75rem",
         zIndex:12
     },
     closeIconStyleDisabled:{
        position:"relative",
        top: '-90%',
        left: "-1.7rem",
        marginBottom: "-83px",
        width:"2.75rem",
        zIndex:12,
        opacity: 0.7
     },
     title: {
       fontSize: '24px',
       color: '#262626',
       marginLeft: '27px',
       marginBottom: '8px',
       marginTop:"-12px",
       '@media screen and (max-width: 600px)': {
         fontSize: '24px',
       },
     },
     closeIcon: {

       fontSize: '25px',
       cursor: 'pointer',
       marginTop: '10px',
     },
     imageContainer: {
       height:'100%',
       overflow: 'hidden',
     },
     image: {
       maxHeight: '90%',
       maxWidth: '90%',
     },
     footerContainer: {
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'end',
         alignItems: 'flex-end',
         borderTop: '1px solid #CDCDCD',
         backgroundColor: '#F8F8F8',
         paddingTop: '18px',
         paddingRight:"7px",
         paddingBottom: '18px',
     },
     CancelBtn:{
       marginRight: '15px',
       backgroundColor: '#FFFFFF',
       color: '#262626',
       fontWeight:600,
       borderRadius: '2px',
       fontSize: '15px',
       border: '2px solid #262626',
       height: '35px',
       width: '69px',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       cursor: 'pointer',
       '&:hover': {
        backgroundColor: '#CDCDCD',
       },
 },
     AddMemberBtn: {
       marginRight: '10px',
       backgroundColor: '#262626',
       color: '#FFFFFF',
       borderRadius: '2px',
       borderWidth: '0',
       fontSize: '15px',
       height: '35px',
       width: '119px',
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       fontWeight:600,
       cursor: 'pointer',
     },
     footerDownloadIcon: {
       marginRight: '8px',
       marginBottom:'2px',
     },
     root: {
      padding: '16px',
    },
    relativeHeadingStyle: {
      paddingTop: '10px',
      fontWeight: 600,
    },
    roleStyle: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    userNameStyle: {
      fontWeight: 400,
      ['@media (max-width: 480px)']: {
        fontWeight: 600,
      },
    },
    name: {
      display: 'flex',
      alignItems: 'center',
    },
    relativeStyle: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
    },
    dmContent: {
      padding: '0px',
    },
    smallAvatar: {
      position: 'inherit',
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginRight: '15px',
    },
    CheckBox: {
      marginLeft:"20px",
      ['@media (max-width: 480px)']: {
        marginLeft:"10px",
        paddingLeft: 0,
      },
      root: {
        color: 'white!important',
        '&$checked': {
          color: '#007D2C',
        },
        ['@media (max-width: 480px)']: {
          marginLeft:"10px",
          paddingLeft: 0,
        },
        minWidth: '20px',
        marginLeft: 0,
        paddingLeft: 0,
      },
      checked: {},
    },
    actionColumn: {
      justifyContent: 'flex-end',
    },
    avatarColumn: {
      maxWidth: '30px',
    },
    cellStyle: {
      verticalAlign: 'middle',
      padding: '12px 6px 12px 6px',
      overflowWrap: 'anywhere',
    },
    avatarCellStyle:{
      verticalAlign:'middle',
      ['@media (max-width: 480px)']: {
        verticalAlign:'top',
        borderRadius:'10px 0px 0px 10px',
        backgroundColor:"#F8F8F8"
      },
    },
    nameCellStyle:{
      ['@media (max-width: 480px)']: {
        borderRadius:'0px 10px 10px 0px',
        backgroundColor:"#F8F8F8"
      },
    },
    checkboxCellStyle:{
      ['@media (max-width: 480px)']: {
        verticalAlign:'top',
        backgroundColor:'white'
      },
    },

    tableRow: {
      '&$selected': {
        backgroundColor: 'Black',

        '&:hover': {
          backgroundColor: 'Black',
        },
        '&:focus': {
          backgroundColor: 'Black',
        },
      },
    },
    tableCell: {
      paddingLeft: '25px',
      '$selected &': {
        color: 'White',
      },
    },
    selected: {},

    tableHeadBorder:{
      borderBottom: '2px solid #CDCDCD !important', 
      paddingLeft: '6px',
      backgroundColor: '#E5E5E5'
    },
    fullWidth: {
      width: '100%',
    },
    halfWidth: {
      width: '50%',
    },
    small: {
      position: 'inherit',
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    progress: {
      height: '3px',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
      '& div': {
        backgroundColor: '#FF5991',
      },
    },
    paperDiv: {
      boxShadow: 'None!important',
      marginLeft:'19px',
      marginRight:'19px',
      ['@media (max-width: 480px)']: {
        marginLeft:'0px',
        marginRight:'10px',
      },
    },
    container: {
      maxHeight: 'calc(100vh - 300px)',
    },
    headerRow: {
      backgroundColor: '#262626',
    },
    errorContainer: {
      textAlign: 'center',
      paddingTop: '90px',
      color: 'grey',
    },
    customScroll: {
      overflow: 'auto',
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
        display:'none'
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#666666',
        borderRadius: '10px',
        border: '1px solid #666',
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    },
    checkboxStyle:{
      margin:0,
      padding: 0,
    },
    tableHeadRow:{
      backgroundColor:'#E5E5E5'
    },
    checkboxIcon:{
      backgroundColor: 'white', 
      border:'2px #767676 solid', 
      borderRadius: '2px',
      width: 16,
      height: 16,
    },
    checkedIcon: {
      backgroundColor: 'green',
      borderRadius: '2px',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
    }
   }));

  export default ModalStyle;