import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';
import { get_cluster } from '../components/clusterGlobal';

export const saveMessage = async (messageContent: any) => {
  var result = null;

  const options: AxiosRequestConfig = {
    url: API.saveMessage,
    method: 'POST',
    headers: {
      bbdomain: messageContent.bbdomain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    data: messageContent,
    withCredentials: true,
  };
  result = await axios(options);
  return result;
};

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});
