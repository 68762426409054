const Cache = () => {
  let cachedData: Record<string, any> = {}

  const get = (key: string) => {
    if(cachedData.hasOwnProperty(key)) {
      return cachedData[key]
    }
    return false
  }

  const set = (key: string, value: any) => {
    cachedData[key] = value;
  }

  const remove = (key: string) => {
    if(cachedData.hasOwnProperty(key)) {
      delete cachedData[key]
    }
  }

  const getCache = () => {
    return cachedData;
  }

  const destroy = () => {
    Object.keys(cachedData).forEach(function(key) { delete cachedData[key]; });
  }

  return {
    get,
    set,
    remove,
    getCache,
    destroy
  }
}

export default Cache;