import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MembersRelationStyle from './MembersRelationStyle';

const MembersRelation = ({ relations }: any) => {
    const classes = MembersRelationStyle();
    return (
      <>
        <div className={classes.minPadding}>
          {
            relations && relations.length > 0 ? relations.map((val: any) => {
              return ReactHtmlParser(val);
            }) : 'N/A'
          }
        </div>
      </>
    );
};

export default MembersRelation;