import React from 'react';
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) =>( {
    icon: {
      marginLeft:"14px",
      marginTop:"7px",
    }
}));

const AlertErrorIcon = (props: any) => {
  return (
    <svg className={useStyles().icon} width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 8C15 11.866 11.866 15 8 15C7.1192 15 5.5 14.5404 5.5 14.5404L1.00002 15L1.45963 10.5C1.45963 10.5 1 8.8808 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 4V9" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="8" cy="12" r="0.5" stroke="#262626"/>
    </svg>
  );
};
export default AlertErrorIcon;
