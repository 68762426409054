import React, { useState, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { EmailFieldType } from './EmailFieldTypes'

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    focused: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
        borderColor: '#262626',
      }
    }
  });
});

const EmailField = ({ emails, emailToEdit = '', setFormOutput, isValid, setIsValid }: EmailFieldType) => {
  const classes = useStyles();
  const INVALID_EMAIL = 'Entered email is invalid'
  const INVALID_UNIQUE = 'Entered email is already present'

  const [isEdited, setIsEdited] = useState(false)
  const isValidEmail = useCallback((email: string) => !!(email && /^[A-Z0-9._+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i.test(email)), [])

  return (
    <TextField
      data-testid='add-email'
      placeholder='Email address'
      type='text'
      style={{
        margin: '12px 0 34px'
      }}
      onChange={event => {
        const enteredEmail = event.target.value.trim();
        setIsEdited(false);
        const isEmailStringValid = isValidEmail(enteredEmail) 
        const isEmailUnique = !emails.some((email: {email: string}) => email.email.toLowerCase().trim() == enteredEmail.toLowerCase());
        const isFormValid = isEmailStringValid && isEmailUnique

        setIsValid({
          valid: isFormValid,
          type: !isEmailStringValid ? 1 : 2
        })

        isFormValid && setFormOutput(enteredEmail)
      }}
      onBlur={event => {
        const enteredEmail = event.target.value.trim();
        setIsEdited(enteredEmail != '')
      }}
      error={isEdited && !isValid.valid}
      helperText={isEdited && !isValid.valid && ( isValid.type == 1 ? INVALID_EMAIL : INVALID_UNIQUE)}
      FormHelperTextProps={{style: {position: 'absolute', top: '40px'}}}
      InputProps={{disableUnderline: true, classes:{focused: classes.focused}}}
      defaultValue={emailToEdit}
    />
  )
}

export default EmailField