import React, { createContext, useEffect, useState, useContext } from 'react';
import { UserContext } from '../Types/UserContext';
import { validateAuthToken } from '../services/AuthService';
import { login } from '../services/AuthService';
import auth from './auth';
import axios, { CancelTokenSource } from 'axios';
import { PreferredLanguage } from '../Types/PreferredLanguage';

//Loading datas from QueryString Parameter
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const authToken = urlParams.get('authorization');
const bbDomain = urlParams.get('bbdomain');
const personId = urlParams.get('personID');
const mobile = urlParams.get('isMobile');

//Creating ctx variable to hold the values
var loadAuthContext: any;
var userCtx: any;
var loginFlow: any;
var emptyUserCtx: any;
var getlocalStorage: any;
//var userCtx1: UserContext = new UserContext();
const UserAuthCtx = React.createContext({
  loginFlow,
  loadAuthContext,
  userCtx,
  emptyUserCtx,
  getlocalStorage,
});
export { UserAuthCtx };

function getCookie(name: string) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

// This will delete any duplicate cookie at /Home/message path as we already have valid cookie at / path.
const sessionCookies = document.cookie.split(';').filter(value => value.trim().startsWith('reachSession='))
if(sessionCookies.length > 1) {
  document.cookie = 'reachSession=false; path=/Home/message; expires=Fri, 31 Dec 1999 23:59:59 GMT;'
}

if (
  localStorage.getItem('userId') &&
  localStorage.getItem('isMobile') === 'false'
) {
  if (getCookie('reachSession') != 'true') {
    Object.keys(localStorage).forEach((key) => {
      if (!key.includes('district')) delete localStorage[key];
    });
  }
}

//Creating component to hold the context variables
var AuthComponent = (props: any) => {
  const [userCtx, setUserCtx] = useState(new UserContext());
  //setting value in the local storage
  function setlocalStorage(data: any) {
    localStorage.setItem('userName', data.userName ? data.userName : '');
    localStorage.setItem('userRole', data.userRole ? data.userRole : '');
    localStorage.setItem('organizationName', data.orgName ? data.orgName : '');
    localStorage.setItem('roleID', data.roleID ? data.roleID : '');

    if (data.personid) {
      localStorage.setItem('userId', data.personid ? data.personid : '');
      localStorage.setItem('bbDomain', data.origin ? data.origin : '');
      localStorage.setItem('domainID', data.domainId ? data.domainId : '');
      localStorage.setItem('isMobile', mobile ? mobile : 'false');
      localStorage.setItem(
        'preferredLanguage',
        data.preferredLanguage
          ? JSON.stringify(data.preferredLanguage)
          : JSON.stringify(new PreferredLanguage())
      );
    }
  }

  function emptyUserCtx() {
    setUserCtx(new UserContext());
  }
  //getting value from the local storage
  function getlocalStorage() {
    var myUserCtx = new UserContext();
    var pId = localStorage.getItem('userId');
    var domain = localStorage.getItem('bbDomain');
    var domainId = localStorage.getItem('domainID');
    var isMobile = localStorage.getItem('isMobile');
    var orgName = localStorage.getItem('organizationName');
    var userName = localStorage.getItem('userName');
    var userRole = localStorage.getItem('userRole');
    var roleID = localStorage.getItem('roleID');
    var preferredLanguage = localStorage.getItem('preferredLanguage');

    myUserCtx.userId = pId ? pId : '';
    myUserCtx.userLoggedInDomain = domain ? domain : '';
    myUserCtx.domainId = domainId ? domainId : '';
    myUserCtx.mobile = isMobile ? isMobile : 'false';
    myUserCtx.organizationName = orgName ? orgName : '';
    myUserCtx.userName = userName ? userName : '';
    myUserCtx.userRole = userRole ? userRole : '';
    myUserCtx.roleID = roleID ? roleID : '';
    myUserCtx.preferredLanguage = preferredLanguage
      ? JSON.parse(preferredLanguage)
      : new PreferredLanguage();
    setUserCtx(myUserCtx);
  }

  useEffect(() => {
    if (localStorage.getItem('userId')) {
      getlocalStorage();
    }
  }, []);

  async function loginFlow(
    username: string,
    password: string,
    mobile: string,
    source: CancelTokenSource,
    bbdomain: string,
    domainId: string,
    districtURL: string,
    orgId: string,
    passcode: string,
    personId: string,
    resend: string,
    reset: string,
  ) {
    //async function loginFlow(username: string, password: string, source: CancelTokenSource, bbdomain = 'https://spl9-pt99-1.parentlink.net/') {
    return await login(
      username,
      password,
      mobile,
      source,
      bbdomain,
      domainId,
      districtURL,
      orgId,
      passcode,
      personId,
      resend,
      reset
    )
      .then((result) => {
        if ("validated_response" in result.data) {
            if (result.data.validated_response.showpassword === true){
              return {
                showpassword: true
              };
           }
        }
        if (result.data.response === 'validated'){
          return {
            loginIdValidated: true,
            numberOfAccounts: result.data.numberOfAccounts,
            personId: result.data.personId,
          };
        }
        if (result.data.errorMessage === 'Unauthorized-401') {
          return {
            authenticated: false,
            status: result.status,
          };
        }
        if (result.data.response === 'resendOtp'){
          return {
            personId: result.data.personId,
          };
        }
        if (result.data.response === 'OTPCleared'){
          return {
            response: result.data.response,
          };
        }
        var myUserCtx = new UserContext();
        setlocalStorage(result.data);
        document.cookie = 'reachSession=true; path=/';
        myUserCtx.organizationName = result.data.orgName
          ? result.data.orgName
          : '';
        myUserCtx.userName = result.data.userName ? result.data.userName : '';
        myUserCtx.userRole = result.data.userRole ? result.data.userRole : '';
        myUserCtx.roleID = result.data.roleID ? result.data.roleID : '';
        myUserCtx.userId = result.data.personid ? result.data.personid : '';
        myUserCtx.userLoggedInDomain = result.data.origin
          ? result.data.origin
          : '';
        myUserCtx.mobile = mobile ? mobile : 'false';
        myUserCtx.domainId = domainId ? domainId : '';
        myUserCtx.districtURL = districtURL;

        if (result.data.preferredLanguage) {
          myUserCtx.preferredLanguage = result.data.preferredLanguage;
        }

        setUserCtx(myUserCtx);

        return {
          authenticated: true,
          status: result.status,
        };
      })
      .catch( e => {
        if (e.response){
            if (e.response.data.errorMessage === "OTP Incorrect-401"){
              return {
                authenticated: false,
                message: e.response.data.errorMessage,
                status: e.response && e.response.status,
                resendAttempts: e.response.data.attempt_left
              }
            }
            else if (e.response.data.statusCode === 401) {
                return {
                  authenticated: false,
                  message: e.response.data.errorMessage,
                  status: e.response && e.response.statusCode
                }
            } else {
                return {
                  authenticated: false,
                  status: e.response && e.response.status,
                };
            }
        } else if (e.message === "Network Error"){
            return {
              authenticated: false,
              status: 500
            }
        } else {
            return {
              authenticated: false,
              status: e.response && e.response.status,
            };
        }
      });
    return () => {
      source.cancel();
    };
  }

  //TODO: Need to check on response of authenticate and set userContext
  //};
  async function loadAuthContext() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var myUserCtx = new UserContext();
    myUserCtx.userId = personId ? personId : '';
    myUserCtx.districtURL = bbDomain ? bbDomain : '';
    myUserCtx.mobile = mobile ? mobile : 'false';
    await validateAuthToken(
      authToken ? authToken : '',
      bbDomain ? bbDomain : '',
      personId ? personId : '',
      mobile ? mobile : 'false',
      source
    )
      .then((result) => {
        if (result.data.Cookie) {
          localStorage.setItem('userId', personId ? personId : '');
          localStorage.setItem('bbDomain', bbDomain ? bbDomain : '');
          localStorage.setItem('isMobile', mobile ? mobile : 'false');
          setlocalStorage(result.data);
          document.cookie = 'reachSession=true; path=/';
          myUserCtx.organizationName = result.data.orgName
            ? result.data.orgName
            : '';
          myUserCtx.userName = result.data.userName ? result.data.userName : '';
          myUserCtx.userRole = result.data.userRole ? result.data.userRole : '';
          myUserCtx.roleID = result.data.roleID ? result.data.roleID : '';
          myUserCtx.domainId = result.data.domainId ? result.data.domainId : '';
          myUserCtx.userLoggedInDomain = result.data.origin
            ? result.data.origin
            : '';
          if (result.data.preferredLanguage) {
            myUserCtx.preferredLanguage = result.data.preferredLanguage;
          }
          setUserCtx(myUserCtx);
        }
      })
      .catch((e) => console.log(e));
    return () => {
      source.cancel();
    };
  }

  let ws = {
    loginFlow,
    loadAuthContext,
    userCtx,
    emptyUserCtx,
    getlocalStorage,
  };
  return (
    <UserAuthCtx.Provider value={ws}>{props.children}</UserAuthCtx.Provider>
  );
};

export default AuthComponent;
