import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FileUploadIcon = (props: any) => {
  return (
    <SvgIcon viewBox="0 -4 24 24" {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9996 6.00006C14.9996 6.00006 9.49965 11.5 6.99964 14.0001C5.86107 15.1386 3.38034 15.3808 1.99963 14C0.618925 12.6193 0.861071 10.1386 1.99964 9.00006C4.99965 6.00005 7.49962 3.50005 9.49964 1.50006C10.0689 0.93078 11.3093 0.809704 11.9996 1.50007C12.69 2.19042 12.5689 3.43078 11.9996 4.00006C9.49965 6.50005 4.99964 11.0001 4.99964 11.0001" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
};

export default FileUploadIcon;
