import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';

const TooltipStyles = makeStyles((theme: Theme) => ({
  arrow: {
    background: 'black !important',
    '&:after': {
      background: 'black !important',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}));

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    right: '20px',
    top: '-20px',
    zIndex: 1,
  },
  replyButton: {
    cursor: 'pointer',
  },
  disNone: {
    display: 'none',
  },
  disBlock: {
    display: 'block',
    marginLeft: 4,
    marginRight: 4,
  },
  mobMargin: {
    marginRight: '15px',
    marginLeft: 'auto',
    marginTop: '8px',
    marginBottom: '8px',
  },
  icons: {
    display: 'flex',
  },
}));

function BootstrapTooltip(props: TooltipProps) {
  const classes = TooltipStyles();

  return <Tooltip arrow classes={classes} {...props} />;
}

type TooltipSVGType = {
  id: string;
  selectedMsg: string;
  replyHandler: () => void;
  directMessageHandler: () => void;
  isMobile: boolean;
};

const TooltipSVG = ({
  id,
  selectedMsg,
  replyHandler,
  directMessageHandler,
  isMobile,
}: TooltipSVGType) => {
  const classes = useStylesBootstrap();
  const clas = id === selectedMsg ? classes.disBlock : classes.disNone;
  const mobMargin = isMobile ? classes.mobMargin : '';
  return (
    <>
      {isMobile && <Divider className={clas} />}
      <div className={classNames(classes.icons, mobMargin)}>
        <BootstrapTooltip
          title='Reply'
          placement='top-start'
          className={clas}
          data-testid='ReplyMessageIcon'
        >
          <svg
            onClick={replyHandler}
            className={classes.replyButton}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1 5C6.25706 5 15 5 15 5V15H1V11'
              stroke='#262626'
              strokeWidth='2'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M3 3L1 5L3 7'
              stroke='#262626'
              strokeWidth='2'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </BootstrapTooltip>
        <BootstrapTooltip
          title='Direct Message'
          placement='top-start'
          className={clas}
          data-testid='DirectMessageNavIcon'
        >
          <svg
            onClick={directMessageHandler}
            className={classes.replyButton}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M15 8C15 11.866 11.866 15 8 15C7.10858 15 6.25608 14.8334 5.47194 14.5296L1 15L1.5 10.6031C1.17745 9.79841 1 8.91993 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z'
              stroke='#262626'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </BootstrapTooltip>
      </div>
      {isMobile && <Divider className={clas} />}
    </>
  );
};

type MessageReplyIconType = {
  id: string;
  selectedMsg: string;
  replyHandler: () => void;
  directMessageHandler: () => void;
  isMobile: boolean;
};

const MessageReplyIcon = ({
  id,
  selectedMsg,
  replyHandler,
  directMessageHandler,
  isMobile,
}: MessageReplyIconType) => {
  const classes = useStylesBootstrap();
  if (isMobile)
    return (
      <TooltipSVG
        id={id}
        selectedMsg={selectedMsg}
        replyHandler={replyHandler}
        directMessageHandler={directMessageHandler}
        isMobile={isMobile}
      />
    );
  else
    return (
      <Card
        className={classNames(
          classes.root,
          id === selectedMsg ? classes.disBlock : classes.disNone
        )}
      >
        <CardActions>
          <TooltipSVG
            id={id}
            selectedMsg={selectedMsg}
            replyHandler={replyHandler}
            directMessageHandler={directMessageHandler}
            isMobile={isMobile}
          />
        </CardActions>
      </Card>
    );
};

export default MessageReplyIcon;
