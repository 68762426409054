import React from 'react';
import { useEffect, useState, useContext } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { getAuthToken } from '../services/AuthService'
import ActionableAlertBox from './ActionableAlertBox/ActionableAlertBox';
import { Card, CardContent, List, Box } from '@material-ui/core';
import { StateContext } from './StateContextComponent';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  popperDialog: {
    zIndex: 5, // should be greater than channel's drawer z-index
    position: 'absolute',
    '& div': {
      paddingLeft: 0,
      alignItems: 'center',
      display: 'flex',
      flexGrow: '1',
    }
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  paperRoot: {
    backgroundColor: '#262626',
    //unwanted parent styles
    marginLeft: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    //unwanted parent styles
  },
  menuitem: {
    color: '#A5A5A5',
    '&:before': {
      boxShadow: 'none !important',
    },
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#181818',
      cursor: 'pointer',
      '& svg': {
        stroke: '#FFF',
      },
    },
    '& svg': {
      stroke: '#A5A5A5',
      height: '24px',
      width: '24px',
      marginRight: '10px',
    },
  },
  menuitemSelect: {
    color: '#FFF',
    backgroundColor: '#181818',
    '&:hover': {
      backgroundColor: '#181818',
    },
    '& svg': {
      marginRight: '10px',
    },
  },
  arrow: {
    width: '0',
    height: '0',
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: '15px solid #262626',
    //unwanted parent styles
    marginLeft: '0px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    //unwanted parent styles
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type MenuListCompositionProps = {
  className: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.MutableRefObject<any>;
  bbdomain: any;
};

export default function MenuListComposition({
  open,
  setOpen,
  anchorRef,
  bbdomain,
}: MenuListCompositionProps) {
  const classes = useStyles();

  const {
    setNavigateToBBcoms,
  } = useContext(StateContext);
  
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [unloadConfirmation, setUnloadConfirmation] = useState({
    open: false,
    title: 'Discard changes?',
    message: 'You have unsaved attachments. Are you sure you want to leave?',
  });

  const unloadConfirmationDialogStyle = {
    paper: {
      width: '20rem',
      height: '15rem',
    },
    root: {
      padding: '0 16px',
    },
  };


  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  //handling navigation from 2way to bbcomms application
  async function handleNavigation() {
    try {
      if (localStorage.getItem('uploadstatus') === "inprogress")
      {
        setUnloadConfirmation({...unloadConfirmation,open: true});
        return true
      }
      var result = await getAuthToken(bbdomain)
      window.location.assign(bbdomain + `/main/login/reach?authToken=${result.data.authToken}`)
    }
    catch {
      window.location.assign(bbdomain)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [anchorRef, open]);

  return (
    <>
      <Popper
        className={`${classes.popperDialog} popper-element`}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div className={classes.dropdown}>

          <ActionableAlertBox
            data-testid='unloadConfirmationBox'
            title={unloadConfirmation.title}
            shouldOpen={unloadConfirmation.open}
            onAction={() => {
              setNavigateToBBcoms(true)
              localStorage.setItem('uploadstatus', 'verified')
              setUnloadConfirmation({...unloadConfirmation,open: false});
              handleNavigation();
            }}
            secondaryButtonLabel='Cancel'
            primaryButtonLabel='Discard'
            styles={unloadConfirmationDialogStyle}
            primaryButtonProps={{}}
            onClose={() => {
              setUnloadConfirmation({
                ...unloadConfirmation,
                open: false,
              });
            }}
          >
          <Box paddingBottom='74px'>{unloadConfirmation.message}</Box>
         </ActionableAlertBox>

      
              <div className={classes.arrow}></div>
              <Paper className={classes.paperRoot}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      className={classes.menuitem}
                      onClick={handleNavigation}
                      id='showpopup'
                    >
                      <svg viewBox='0 0 25 21'>
                        <g
                          strokeWidth='1'
                          fill='none'
                          fill-rule='evenodd'
                          strokeLinejoin='round'
                        >
                          <g transform='translate(-960.000000, -166.000000)'>
                            <g transform='translate(961.000000, 167.000000)'>
                              <polygon points='4.5 8.94868966 4.5 18 10.9202005 13.8902897 9.23357984 12.2640828 23.1990971 0.00504827586'></polygon>
                              <path d='M9.23357984,12.2590345 L15.7334419,18.5263448 C16.0793729,18.8606897 16.6562005,18.7233103 16.8150971,18.2689655 L23.1990971,0 L9.23357984,12.2590345 L9.23357984,12.2590345 Z'></path>
                              <path d='M23.1986833,0.000413793103 L0.545166043,4.03572414 C-0.0159374051,4.13503448 -0.195523612,4.85172414 0.252200526,5.20427586 L4.94047639,8.89696552 L23.1986833,0.000413793103 Z'></path>
                              <path d='M4.94047639,17.9533241 L9.23316604,12.263669'></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                      Mass Notification
                    </MenuItem>
                    <MenuItem
                      className={classes.menuitemSelect}
                      onClick={handleClose}
                    >
                      <svg
                        width='24'
                        height='25'
                        viewBox='0 0 24 25'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clip-path='url(#clip0)'>
                          <path
                            d='M8 21.9025C7.3887 21.6865 6.80339 21.4154 6.25 21.0953L1 22.5L2.43182 17.2966C1.52167 15.7417 1 13.9318 1 12C1 6.20101 5.70101 1.5 11.5 1.5C16.0718 1.5 19.9611 4.42183 21.4025 8.5'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9 16.5C9 20.366 12.134 23.5 16 23.5C17.3878 23.5 18.6812 23.1197 19.7692 22.4231L23 23.5L21.9231 20.2692C22.6197 19.1812 23 17.8878 23 16.5C23 12.634 19.866 9.5 16 9.5C12.134 9.5 9 12.634 9 16.5Z'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <circle cx='13' cy='16.5' r='0.5' stroke='white' />
                          <circle cx='16' cy='16.5' r='0.5' stroke='white' />
                          <circle cx='19' cy='16.5' r='0.5' stroke='white' />
                        </g>
                        <defs>
                          <clipPath id='clip0'>
                            <path d='M0 0.5H24V24.5H0V0.5Z' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                      Reach
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
}
