import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const DeleteChannelStyle = makeStyles((theme) => ({
    deleteBtnStyle: {
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginRight:"auto",
        fontWeight:600,
        fontSize:"14px",
        cursor:"pointer"
      },
    deleteBtnStyleIcon:{
    width: '16px',
    },
    deleteBtnStyleTxt:{
        marginLeft:"10px",
        color:"#C23E37",
        paddingTop:"3px"
      }
}));

function DeleteChannel(props:any) {
  const {onDelete,channelType} = props
  function onChannelDelete(){
    onDelete()
  }
  return (
    <div data-testid = "delete-channel" className={DeleteChannelStyle().deleteBtnStyle} onClick={() => {return onChannelDelete()}}>
        <img src='/images/deleteIcon.png' className={DeleteChannelStyle().deleteBtnStyleIcon}/>
        <span className={DeleteChannelStyle().deleteBtnStyleTxt}>Delete channel</span>
    </div>
  )
};

export default DeleteChannel