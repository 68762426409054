import React from 'react';
import { SvgIcon } from '@material-ui/core';

const chevronUpIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 11L8 4L1 11" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const chevronDownIcon = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4L8 11L15 4" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const filesChevronRightIcon = (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11L3.5 8.5L6 6L1 1" stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);

const filesChevronDownIcon = (
    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L3.5 3.5L6 6L11 1" stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export const ChevronUpDown = (props: any) => {
    if (props.isFile) {
        return(
            <SvgIcon>
                {props.type==='down' ? filesChevronRightIcon : filesChevronDownIcon}
            </SvgIcon>
        )
    }
    else {
        return (
            <SvgIcon>
              {props.type==='up' ? chevronUpIcon : chevronDownIcon}
            </SvgIcon>
        );
    }
};

export default ChevronUpDown;