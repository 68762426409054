export function DateFormat(dateString: string) {
  const dateObj = new Date(dateString);
  return dateObj.toLocaleDateString() === new Date().toLocaleDateString()
    ? 'Today'
    : dateObj.toDateString();
}

export function epochToJsDate(ts: number) {
  return new Date(ts * 1000);
}

// date here is expected MM/dd/yyyy
export function JsdateToEpoch(date: string) {
  return date ? new Date(date).getTime() / 1000 : null;
}

export function timeNow() {
  let d = new Date(),
    h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
    m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes(),
    s = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
  let timeStr = h + ':' + m + ':' + s;
  return timeStr;
}

export function fromJsDateToEpoch(date: string) {
  if (date) {
    date = date.replace(/-/g, '/');
    const fromDateTime = `${date} 00:00:00`;
    return new Date(fromDateTime).getTime() / 1000;
  } else {
    return 0;
  }
}

export function toJsDateToEpoch(date: string) {
  if (date) {
    date = date.replace(/-/g, '/');
    const fromDateTime = `${date} 23:59:59`;
    return new Date(fromDateTime).getTime() / 1000;
  } else {
    return 0;
  }
}
