export const isPresentInUpdate = (updated: any, emailToFind: string) => {
  let foundAt = -1
  for(let index in updated) {
    if(updated[index].new.email == emailToFind) {
      foundAt = parseInt(index);
      break;
    }
  }
  return foundAt
}

export const isPresentInAdd = (emails: any, emailToFind: string) => {
  return emails.uncommitted.added.findIndex((item: any) => item.email == emailToFind)
}

export const isIndexPresentInUpdate = (updated: any, index: number) => {
  return Object.keys(updated).includes(index.toString())
}

export const findEmailInArray = (emails: any, emailToFind: string) => {
  return emails.findIndex((item: any) => item.email == emailToFind)
}

export const isPresentInDelete = (deleted: any, email: string) => {
  return deleted.includes(email)
}

export const removeFromDelete = (deleted: any, email: string) => {
  return deleted.filter((item: any) => item != email)
}

export const buildPutPayload = (updated: any) => {
  const payload = []
  for(let index in updated) {
    payload.push({
      email: updated[index].old.email,
      userPreference: updated[index].new.userPreference,
      source: updated[index].old.source
    })
  }

  return payload
}