import React, {
    useState,
    useCallback,
    useEffect,
    useRef,
    useContext,
    useMemo,
} from 'react';
import { Card, CardContent, List, Box } from '@material-ui/core';
import BadgeAvatar from '../BadgeAvatar';
import Editor from '../../libs/Editor';
import ConfirmAlertDialog from '../confirmAlertDialog';
import ReactHtmlParser from 'react-html-parser';
import {
    getUserMessage,
    getTranslatedMessage,
    getOriginalMessage,
} from '../../services/userAccountServices';
import { UserMessageInfo } from '../../Types/UserMessageInfo';
import { UserProfileInfo } from '../../Types/UserProfileInfo';
import { ReplyToMessage } from '../../Types/ReplyToMessage';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { UserAuthCtx } from '../AuthComponent';
import MessagePanelStyles from './MessagePanelStyle';
import classNames from 'classnames';
import { GroupType } from '../../Types/GroupType';
import { GroupEssential } from '../../Types/GroupEssential';
import { useMediaQuery } from 'react-responsive';
import MessageDisplayComponent from '../MessageDisplayComponent/MessageDisplayComponent';
import { Message } from '../../Types/MessageType';
import { saveMessage } from '../../services/saveMessageService';
import { QueueContext } from '../../context/queuecontext';
import { StateContext } from '../StateContextComponent';
import {
    channelList,
    setGroupUnreadCount,
    setDirectUnreadCount,
} from '../../dataLoaders/DataHandler';
import { useWindowWidth } from '../../libs/hookLib';
import AlertBox from '../AlertBox/AlertBox';
import NotificationBox from '../NotificationBox/NotificationBox';
import { setGroupRead } from '../../services/userAccountServices';
import MessageRouteContext, {
    MessageRouteContextType,
} from '../../context/MessageRouteContext';
import EmptyBox from '../EmptyBox/EmptyBox';
import { isElementVisible } from '../../libs/common.utils';
import MessageHeading from './MessageHeading';
import { v4 as uuidv4 } from 'uuid';
import { getPresignedUrl, uploadPresignedFile } from '../../services/FilesServices';
import ImagePreviewModal from '../ImagePreviewModal/ImagePreviewModal';
// import { UserAuthCtx } from '../components/AuthComponent'
import ActionableAlertBox from '../ActionableAlertBox/ActionableAlertBox';
import { useHistory } from 'react-router-dom';
import Axios, { AxiosResponse } from 'axios';
import axios from 'axios';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import CustomCircularProgress from '../ReusableComponents/CircularProgress';
const DOMPurify = require('dompurify');

const MessagesPanel = ({
    username,
    selectedGroup,
    groupid,
    organizationid,
    personid,
    bbdomain,
    userRole,
    setGroupUnread,
    setDirectUnread,
    setGroup,
    setSelectMyGroup,
    setDirectChannelIDFromMessageQueue,
    impersonating,
    isLoading = false,
    setPanelLoading = () => { },
    isParenDrawerOpen,
    setExpireNotification=()=>{},
    expireNotification={},
}: any) => {
    let setIntervalValue = true;
    let queueContext = useContext(QueueContext);
    const editorRef = useRef<any>(null);
    const [isFileUpload, setIsFileUpload] = useState<any>(false)
    const convRef = useRef<any>(null);
    const isScrolledToBottom = useRef<boolean>(false);
    const [newMessage, setNewMessage] = useState('');
    const [messages, setMessage] = useState<any>([]);
    const [messagestemp, setMessageTemp] = useState<any>([]);
    const [offset, setOffset] = useState<any>(null);
    const [lastdate, setLastdate] = useState<any>(null);
    const authContext = useContext(UserAuthCtx);
    const classes = MessagePanelStyles()({
        isOpenedInMobile: authContext.userCtx.mobile,
    });
    const [loadEditor, setLoadEditor] = useState(
        authContext.userCtx.userRole.toLowerCase() === 'teacher' ||
        authContext.userCtx.userRole.toLowerCase() === 'admin' ||
        authContext.userCtx.userRole.toLowerCase() === 'system' ||
        authContext.userCtx.userRole.toLowerCase() === 'staff'
    );
    var domainId = authContext.userCtx.domainId;
    var preferredLanguage = authContext.userCtx.preferredLanguage;
    const [selectedMsg, setSelectedMsg] = useState('');
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const [copiedMessage, setCopiedMessage] = useState<any>({});
    const [loading, setLoading] = useState(isLoading);
    const [alertOpen, setAlertOpen] = useState(false);
    const [files, setFiles] = useState<any>([]);
    const [cloudFiles, setCloudfiles] = useState<any>([]);
    const [isCloudFiles, setisCloudfiles] = useState<boolean>(false)
    const [validateFileLoading, setvalidateFileLoading] = useState<any>([]);
    const [isFileRemoved, setisFileRemoved] = useState<boolean>(false)
    const [isFileUploadError, setisFileUploadError] = useState<boolean>(false)
    const [fileMap, setFileMap] = useState<any>({});
    const [availableTokens, setAvailableTokens] = useState([0, 1, 2, 3, 4]);
    const [currentPreferredLanguage, setCurrentPreferredLanguage] = useState(
        preferredLanguage.code
    );
    let scroll: any = {};
    const {
        impersonatingUserDetails,
        setEnableSchoolsToSelect,
        selectedOrganizationId,
        setAllowLogout,
        confirmLogout,
        navigateToBBcoms,
    } = useContext(StateContext);

    const {
        isMessageRoute,
        messageId: messageIdFromRoute = null,
        channelId: groupIdFromRoute = null,
        createdAt: createdAtFromRoute = null,
        channelType: channelTypeFromRoute = null,
    } = useContext(MessageRouteContext) as MessageRouteContextType;

    // let { handleInvalidRoute } = useMessageRoute();

    if (impersonating && impersonatingUserDetails.groupDetails !== undefined) {
        scroll = impersonatingUserDetails.groupDetails;
        //setSelectMyGroup(impersonatingUserDetails.groupDetails.groupid);
    }

    if (isMessageRoute) {
        scroll = {
            groupid: groupIdFromRoute,
            groupType: channelTypeFromRoute,
            offset: messageIdFromRoute,
            lastdate: Number(createdAtFromRoute),
        };
    }

    // scroll to the message components
    // groupId: string, messageId:string
    // {"groupId":"messageId"}
    const [messageIdScroll, setMessageIdScroll] = useState(scroll);
    const [myRefs, setMyRefs] = useState<any>({});
    const [downOffset, setDownOffset] = useState<any>(null);
    const [downLastdate, setDownLastdate] = useState<any>(null);
    const [clear, setClear] = useState(false);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [isSaveMessageError, setSaveMessageError] = useState(false);
    const [translateInProgress, setTranslateInProgress] = useState(false);
    const [translateMessageID, setTranslateMessageID] = useState('');
    const [latestMessageId, setLatestMessageId] = useState<any>(null);
    const [latestMessageVisible, setLatestMessageVisible] = useState(false);
    const [unreadMessageStatus, setUnreadMessageStatus] = useState<
        Record<string, boolean>
    >({});
    const [oldMessages, setOldMessages] = useState<any>([]);
    const [msgId, setMsgId] = useState<any>(uuidv4());
    const userDetails = useContext(UserAuthCtx).userCtx;
    const [showImagePreview, setShowImagePreview] = useState<any>(null)

    const GFG_sortFunction = (a: any, b: any) => {
        let dateA = new Date(a.dateTime).getTime();
        let dateB = new Date(b.dateTime).getTime();
        return dateA > dateB ? 1 : -1;
    };

    let domainName = process.env.REACT_APP_REACH_BASE_URL;
    var companion_url = domainName?.replace('/api', '/companion')
    let groupedMessages = messages.reduce((m: any, message: any) => {
        //(m[Date] = m[Date] || []).push(message);
        m[message.Date] = [...(m[message.Date] || []), message];
        return m;
    }, {});

    const resizeMessagesPanel = () => {
        const calcHeight =
            window.outerHeight > 500
                ? Number(window.innerHeight - 104)
                : Number(window.outerHeight - 104);
        let msgElem = document.getElementById('messagesPanel');
        if (msgElem && !impersonating && calcHeight > 160) {
            msgElem.style.height = calcHeight + 'px';
        }
    };

    const calculateMessagesPanelHeight = () => {
        const messagesEditorPanel = document.getElementById('messagesPanel');

        if (messagesEditorPanel) {
            let maxHeight = Number(messagesEditorPanel.offsetHeight * 0.4);
            let textEditor =
                document.querySelectorAll<HTMLElement>('[class*=textbar]')[0];

            if (textEditor) {
                let selElem = textEditor.querySelectorAll<HTMLElement>(
                    '.ql-container .ql-editor'
                );
                let elem = selElem[0];

                if (window.innerHeight > 500) {
                    elem.style.maxHeight = `${maxHeight}px`;
                } else {
                    // its required to handle for landscape mode
                    elem.style.removeProperty('max-height');
                }
            }
        }
    };

    const getSize = () => {
        var i = 0;
        for (const f in fileMap) {
            i = i + 1;
        }
        return i;
    }

    const popError = (id: any) => {
        var i = files.length;
        var img_list = files.filter((file: any) => file['id'] != id);
        setFiles([...img_list]);
        setisFileUploadError(true);
        showFilesErrorMsg({
            severity: 'fileLimitError',
            message:
                'Network Error',
            isShowClose: 1,
        }, true)
    }

    const syncFiles = () => {
        var fileMapSize = getSize();
        if (fileMapSize > files.length) {
            // remove files
            var removeFiles = [];
            for (const key in fileMap) {
                var removefile = true;
                for (var j = 0; j < files.length; j++) {
                    if (key === files[j].id) {
                        removefile = false;
                        break;
                    }
                }
                if (removefile) {
                    // remove file here
                    fileMap[key]['data'] = {}
                    removeFiles.push(fileMap[key]);
                    var itemNo: number = fileMap[key]["itemNo"];
                    delete fileMap[key];
                    availableTokens.push(itemNo);
                    setAvailableTokens(availableTokens);
                }
            }
            setFileMap(fileMap);
            return { "remove": removeFiles };
        } else if (fileMapSize < files.length) {
            // add files
            var addFiles = [];
            for (var i = 0; i < files.length; i++) {
                if (!(files[i].id in fileMap)) {
                    var district = localStorage.getItem('district');
                    if (district === null)
                        return;
                    var personId = localStorage.getItem('userId');
                    // add files here
                    var schoolId = domainId + '-' + localStorage.getItem('reachOrgSchoolId');
                    var itemNo = availableTokens[0];
                    availableTokens.shift();
                    setAvailableTokens(availableTokens);
                    var location = 'https://' + localStorage.getItem('bucket_name') + ".s3.amazonaws.com/" + schoolId + "/" + personId + "/" + msgId + "/" + itemNo + "/" + files[i].name
                    var payload = {
                        "id": files[i].id,
                        "name": files[i].name,
                        "type": files[i].type,
                        "size": files[i].size,
                        "itemNo": itemNo,
                        "location": location,
                        "schoolId": schoolId,
                        "personId": personId,
                        "data": files[i]
                    }
                    fileMap[files[i].id] = payload;
                    addFiles.push(payload);
                }
            }
            setFileMap(fileMap);
            return { "upload": addFiles }
        }
    }
    const option = { companionUrl: companion_url, metaFields: ['messageId', 'userId', 'schoolId', 'itemNo'] };
    const equipment_uppy = new Uppy({
        autoProceed: false,
    })
    equipment_uppy.use(AwsS3, {
        ...option,
    })
    useEffect(() => {
        var fileListMap: object = {};
        var actionFiles = syncFiles();

        if (actionFiles) {
            const bbDomain = userDetails.userLoggedInDomain;
            // let bbDomain = authContext.userCtx.userDetails.userLoggedInDomain;
            if (actionFiles.hasOwnProperty("remove")) {
                formUpdatedRef.current = false;
                // remove files here
                let flag = 'delete';
                let removedFielsSize = 0
                const removedFiles = actionFiles["remove"]
                let removedFilesIds: string[] = []

                for (let item in removedFiles) {
                    const file = removedFiles[parseInt(item)]
                    if (validateFileLoading.includes(file.id)) {
                        removedFilesIds.push(file.id)
                        removedFielsSize = removedFielsSize + file.size
                    }
                }
                const filterIds = validateFileLoading.filter(
                    (each: string) => (!removedFilesIds.includes(each))
                );
                setvalidateFileLoading([...filterIds]);
                getPresignedUrl(actionFiles["remove"], flag, bbDomain).then((response: any) => {
                    if (response) {
                        if (isFileUploadError == false) {
                            const uploadedFilesSize: any = localStorage.getItem('attachmentsSize')
                            localStorage.setItem('attachmentsSize', (parseFloat(uploadedFilesSize) - removedFielsSize).toString());
                            setisFileRemoved(!isFileRemoved)
                        }
                    }
                })
                    .catch(function(response) {
                        console.log(response)
                    });
            }
            else if (actionFiles.hasOwnProperty("upload")) {
                if (!isCloudFiles) {
                    actionFiles.upload?.forEach(element => {
                        equipment_uppy.setMeta({
                            'messageId': msgId,
                            'userId': element['personId'],
                            'schoolId': element['schoolId']
                        });
                        var fileId = equipment_uppy.addFile({
                            source: 'file input',
                            name: element['name'],
                            type: element['type'],
                            data: element['data'],
                        })
                        equipment_uppy.setFileMeta(fileId, { itemNo: element['itemNo'] })
                        equipment_uppy.upload().then((result) => {
                            if (result.successful.length > 0) {
                                setvalidateFileLoading((arr: any) => [...arr, ...[element['id']]])
                                const uploadedFilesSize: any = localStorage.getItem('attachmentsSize')
                                localStorage.setItem('attachmentsSize', (parseFloat(uploadedFilesSize) + element['size']).toString());
                                { files.map((file: any) => { if (file['id'] === element['id']) { file['url'] = element['location'] } }) }
                            }
                            if (result.failed.length > 0) {
                                if (groupid === localStorage.getItem('groupid')) {
                                    popError(element['id']);
                                }
                            }
                        })
                    });
                }
                else {
                    actionFiles.upload?.forEach(element => {
                        setvalidateFileLoading((arr: any) => [...arr, ...[element['id']]])
                        const uploadedFilesSize: any = localStorage.getItem('attachmentsSize')
                        localStorage.setItem('attachmentsSize', (parseFloat(uploadedFilesSize) + element['size']).toString());
                        { files.map((file: any) => { if (file['id'] === element['id']) { file['url'] = element['location'] } }) }
                        setisCloudfiles(false)
                    });
                }
            }
        }

        if (files.length > 0) {

            formUpdatedRef.current = true
            if (confirmLogout || navigateToBBcoms) {
                formUpdatedRef.current = false

            }
            setAllowLogout(false)
            blockNav();
            window.addEventListener('unload', () => {
                formUpdatedRef.current = false;
            });
        }
        else {
            formUpdatedRef.current = false
            setAvailableTokens([0, 1, 2, 3, 4]);
            setAllowLogout(true)
        }
        // upload files ,remove files, set list of corresponding urls, remove urls
    }, [files, confirmLogout, navigateToBBcoms]);
    useEffect(() => {
        var uid = uuidv4();
        setMsgId(uid);
        setExpireNotification({...expireNotification, isError: false, isMsgPanelError: false, message: ''})
    }, [selectedGroup]);

    useEffect(
        useCallback(() => {
            if (editorRef.current !== null && !isMobile) {
                editorRef.current.focus();
            }
        }, []),
        []
    );

    useEffect(() => {
        setLoading(isLoading);
        isLoading && clearMsgNdDetails();
    }, [isLoading]);

    const handleWindowClose = (ev: any) => {
        if (formUpdatedRef.current && ev.target.activeElement.id !== 'showpopup') {
            ev.preventDefault()
            ev.returnValue = ''
            return
        }
    };

    const getFilterFilesMap = () => {
        if (validateFileLoading.length > 0) {
            let filteredFilesMap: any = {}
            for (let item in validateFileLoading) {
                filteredFilesMap[validateFileLoading[item]] = fileMap[validateFileLoading[item]]
            }
            const strigifiedFileMap = JSON.stringify(filteredFilesMap)
            localStorage.setItem('filesToBeDeleted', strigifiedFileMap)
        }
    }

    useEffect(() => {
        const filesToBeDeleted = localStorage.getItem('filesToBeDeleted')
        if (files.length !== validateFileLoading.length) {
            localStorage.setItem('uploadstatus', 'inprogress')
        } else {
            localStorage.setItem('uploadstatus', 'success')
        }
        if (filesToBeDeleted) {
            const parsedFileMap = JSON.parse(filesToBeDeleted)
            const filesIdsToDelete: string[] = []
            for (let item in parsedFileMap) {
                filesIdsToDelete.push(item)
            }
            setFileMap((previousItems: any) => ({ ...previousItems, ...parsedFileMap }))
            setvalidateFileLoading((arr: string[]) => ([...arr, ...filesIdsToDelete]))
            localStorage.removeItem('filesToBeDeleted')
        }

        if (files.length > 0) {
            window.addEventListener('beforeunload', handleWindowClose);
            window.addEventListener('unload', getFilterFilesMap);
        }
        localStorage.setItem('filelength', files.length)
        return () => {
            window.removeEventListener('beforeunload', handleWindowClose);
            window.addEventListener('unload', getFilterFilesMap);
        };
    }, [files, validateFileLoading]);

    const unloadConfirmationDialogStyle = {
        paper: {
            width: '20rem',
            height: '15rem',
        },
        root: {
            padding: '0 16px',
        },
    };
    // this ref we are using under window event listener to track form updation
    const formUpdatedRef = useRef(false);
    // this var we will use to handle react specific navigation away from profile page
    const history = useHistory();
    const [navigateTo, setNavigateTo] = useState('');
    const [navigate, setNavigate] = useState<boolean>(false)
    const [unloadConfirmation, setUnloadConfirmation] = useState({
        open: false,
        title: 'Discard changes?',
        message: 'You have unsaved attachments. Are you sure you want to leave?',
    });
    const blockNav = () => {
        history.block(({ pathname }) => {
            if (formUpdatedRef.current) {
                setNavigateTo(pathname);
                setUnloadConfirmation({
                    ...unloadConfirmation,
                    open: true,
                });
                return false;
            }
        });
    };

    // fix for peb-4689, peb-4690 removing unwanted placeholder
    useMemo(() => {
        if (isMobile && editorRef.current) {
            (
                document.querySelector('.ql-container .ql-editor') as HTMLElement
            ).addEventListener('keyup', (event) => {
                if (document.querySelector('.ql-cursor')) {
                    const formattingValue = (
                        document.querySelector('.ql-cursor') as HTMLElement
                    ).innerText;
                    if (formattingValue.length) {
                        editorRef.current.editor.root.classList.remove('ql-blank');
                    }
                }
            });
        }

        // Calculate 40% height of the messages panel
        calculateMessagesPanelHeight();

        // Resizing messages panel w.r.t window inner height
        resizeMessagesPanel();

        window.addEventListener('resize', () => {
            calculateMessagesPanelHeight();
            resizeMessagesPanel();
            scrollToExactBottom();
        });
    }, [editorRef.current]);
    // fix for peb-4689, peb-4690 removing unwanted placeholder

    useWindowWidth();

    function clearMsgNdDetails() {
        setMessage([]);
        setMessageTemp([]);
        setDownOffset('');
        setDownLastdate('0');
        setOffset('');
        setLastdate('0');
    }

    function setEditorVisibility() {
        setLoadEditor(false);
        if (impersonating) {
            setLoadEditor(false);
            return;
        }
        if (selectedGroup.groupType === GroupType.DirectMessage ||
            (selectedGroup.type === 'CHANNEL' && (selectedGroup.userType === 'owner' || selectedGroup.twoWayEnabled))
        ) {
            setLoadEditor(true);
        } else {
            setLoadEditor(
                authContext.userCtx.userRole.toLowerCase() === 'teacher' ||
                authContext.userCtx.userRole.toLowerCase() === 'admin' ||
                authContext.userCtx.userRole.toLowerCase() === 'system' ||
                authContext.userCtx.userRole.toLowerCase() === 'staff'
            );
        }
    }

    function createMsgInfo(msg: any) {
        let obj = new UserMessageInfo();
        obj.profile = new UserProfileInfo();
        obj.profile.name = msg.senderName;
        obj.profile.role = msg.details.senderRole?.toLowerCase();
        obj.profile.available = true;
        obj.message = msg.details.messageContent;
        obj.messageId = msg.messageId;
        obj.id = msg.id;
        obj.sender = msg.sender;
        obj.recipient = msg.recipient;
        if (msg.uploadedFiles) {
            obj.uploadedFiles = msg.uploadedFiles
        }
        obj.attachment = msg.attachment
        obj.dateTime = new Date(epochToJsDate(msg.createdAt)).toLocaleString(
            'en-US'
        );
        obj.createdAt = msg.createdAt;
        obj.Date = new Date(epochToJsDate(msg.createdAt)).toLocaleDateString(
            'en-US'
        );
        obj.Time = epochToJsDate(msg.createdAt).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });
        if (msg.parentMessage) obj.parentMessage = msg.parentMessage;
        obj.channelName = msg.details.channelName;
        obj.repliedTo = msg.details.repliedTo;
        if (msg.details.preferredLanguage) {
            const { code } = msg.details.preferredLanguage;
            obj.preferredLanguage = code;
        }
        if (msg.sourceLanguage) obj.sourceLanguage = msg.sourceLanguage;
        if (msg.langConfidence) obj.langConfidence = msg.langConfidence;
        obj.readValue = msg.readValue;
        obj.isTranslated = msg.details.is_translated;
        return obj;
    }

    const messageLoadAction = (latestMessageLoad: boolean) => {
        if (!latestMessageLoad) {
            clearMsgNdDetails();
        }
        // change clearMsgNdDetails();
        setEditorVisibility();
        setNewMessage('');
        setIsFileUpload(false);
        // prevent default if jump icon is pressed
        if (messageIdScroll.groupid === selectedGroup.id) {
            scrollTomsg();
            return;
        }
        if (copiedMessage && copiedMessage?.name !== selectedGroup.name) {
            setCopiedMessage(null);
        }
        if (selectedGroup.groupType === GroupType.DirectMessage) {
            if (organizationid && groupid) {
                const loadData = async () => {
                    // change setLoading(true);
                    if (!latestMessageLoad) {
                        setLoading(true);
                    }
                    let userMessages: any = await getUserMessage(
                        impersonating ? impersonatingUserDetails.impersonatedUserId: '',
                        personid,
                        bbdomain,
                        domainId,
                        organizationid,
                        groupid,
                        selectedGroup.groupType,
                        selectedGroup.id
                    );

                    if (
                        userMessages &&
                        userMessages.data.data &&
                        userMessages.data.data.Messages &&
                        userMessages.data.data.Messages.length > 0
                    ) {
                        if (!latestMessageLoad) {
                            setMessageTemp([]);
                        }
                        // change setMessageTemp([]);

                        // if impersonating user, set emulated user preferred language
                        if (
                            impersonating &&
                            userMessages.data.data.current_preferred_language?.code
                        ) {
                            setCurrentPreferredLanguage(
                                userMessages.data.data.current_preferred_language?.code
                            );
                        }

                        let transformedMessages = userMessages.data.data.Messages.map(
                            (msg: any) => createMsgInfo(msg)
                        );
                        // change
                        if (latestMessageLoad) {
                            clearMsgNdDetails();
                        }
                        setMessageTemp(transformedMessages);

                        setOffset(userMessages.data.data.offset);
                        setLastdate(userMessages.data.data.lastdate);
                        scrollToBottom();

                        // Check if any message have readValue 0
                        let allMsgs = userMessages.data.data.Messages;
                        let msgDiff = allMsgs.filter((msg: any) => {
                            return msg.readValue !== 1;
                        });

                        if (!selectedGroup.unreadCount && msgDiff.length) {
                            setGroupRead(
                                personid,
                                bbdomain,
                                groupid,
                                selectedGroup.type,
                                domainId,
                                selectedOrganizationId.schoolId.toString() || ''
                            );
                        }
                    } else if (userMessages?.data?.data?.Messages?.length === 0) {
                        // change
                        if (latestMessageLoad) {
                            clearMsgNdDetails();
                        }
                        setMessageTemp([]);
                    }
                    setEnableSchoolsToSelect(true);
                    setLoading(false);
                    setPanelLoading(false);
                };
                loadData();
            }
        } else {
            if (organizationid && groupid) {
                const loadData = async () => {
                    if (!latestMessageLoad) {
                        setLoading(true);
                    }
                    //change setLoading(true);
                    let userMessages: any = await getUserMessage(
                        impersonating ? impersonatingUserDetails.impersonatedUserId: '',
                        personid,
                        bbdomain,
                        domainId,
                        organizationid,
                        groupid,
                        selectedGroup.groupType
                    );
                    if (
                        userMessages &&
                        userMessages.data.data &&
                        userMessages.data.data.Messages &&
                        userMessages.data.data.Messages.length > 0
                    ) {
                        // change
                        if (latestMessageLoad) {
                            clearMsgNdDetails();
                        }
                        setMessageTemp(
                            userMessages.data.data.Messages.map((msg: any, index: any) => {
                                let obj = createMsgInfo(msg);
                                // create refs for each message
                                if (myRefs[obj.id] === undefined) {
                                    let myRefsTemp = myRefs;
                                    myRefsTemp[obj.id] = React.createRef();
                                    setMyRefs(myRefsTemp);
                                }
                                // create refs for each message
                                return obj;
                            })
                        );

                        // if impersonating user, set emulated user preferred language
                        if (
                            impersonating &&
                            userMessages.data.data.current_preferred_language?.code
                        ) {
                            setCurrentPreferredLanguage(
                                userMessages.data.data.current_preferred_language?.code
                            );
                        }

                        setOffset(userMessages.data.data.offset);
                        setLastdate(userMessages.data.data.lastdate);
                        scrollToBottom();

                        // Check if any message have readValue 0
                        let allMsgs = userMessages.data.data.Messages;
                        let msgDiff = allMsgs.filter((msg: any) => {
                            return msg.readValue !== 1;
                        });
                        if (selectedGroup.unreadCount !== 0 && msgDiff.length) {
                            setGroupRead(
                                personid,
                                bbdomain,
                                groupid,
                                selectedGroup.type,
                                domainId,
                                selectedOrganizationId.schoolId.toString() || ''
                            );
                        }
                    } else if (userMessages?.data?.data?.Messages?.length === 0) {
                        // change
                        if (latestMessageLoad) {
                            clearMsgNdDetails();
                        }
                        setMessageTemp([]);
                    }
                    setEnableSchoolsToSelect(true);
                    setLoading(false);
                    setPanelLoading(false);
                };
                loadData();
            }
        }
    };

    useEffect(() => {
        messageLoadAction(false);
        localStorage.setItem('groupid', groupid)
        localStorage.setItem('uploadstatus', 'interrupted')
    }, [groupid]);

    useEffect(() => {
        const areMoreMessagesToLoad =
            downOffset !== '' && downOffset !== false && downLastdate !== 0;

        if (setIntervalValue) {
            setIntervalValue = false;
            const interval = setInterval(() => {
                const msg = onReadMessage(areMoreMessagesToLoad);
            }, 30000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [messages]);

    useEffect(() => {
        messagestemp.sort(GFG_sortFunction);
        setMessage(messagestemp);
        groupedMessages = messages.reduce((m: any, message: any) => {
            //(m[Date] = m[Date] || []).push(message);
            m[message.Date] = [...(m[message.Date] || []), message];
            return m;
        }, {});
        setOldMessages([...messagestemp]);
    }, [messagestemp]);

    const onReadMessage = (areMoreMessagesToLoad: boolean = false) => {
        const msgs = queueContext.readMessage();
        let isAppended = false;
        if (msgs) {
            if (msgs?.length > 0) {
                for (let i = 0; i < msgs.length; i++) {
                    let msg = JSON.parse(msgs[i]['Body']);
                    const matchedMsgArr = messages.filter((msgItem: any) => {
                        if (msgItem.id === msg.id) {
                            return true;
                        }
                    });
                    if (msg.groupId === groupid) {
                        if (matchedMsgArr.length === 0) {
                            if (!areMoreMessagesToLoad) {
                                receivedMessage(msg);
                                isAppended = true;
                            }
                        }
                    }
                    if (msg.groupId.startsWith('DIRECT')) {
                        if (matchedMsgArr.length === 0) {
                            setDirectChannelIDFromMessageQueue({
                                id: msg.groupId,
                                schoolId: msg.schoolId + '|' + domainId,
                                username: msg.senderName,
                            });
                            setDirectUnread({
                                id: msg.groupId,
                                unreadCount: isMessageRoute ? 0 : 1,
                            });
                        }
                    } else {
                        if (msg.groupId !== groupid || isMessageRoute) {
                            setGroupUnread({ id: msg.groupId, unreadCount: 1 });
                        }
                    }
                }
            }
        }
    };

    const scrollToBottom = () => {
        convRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    //useEffect(scrollToBottom, [messages]);
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickOpen = () => {
        setOpenDialog(true);
        editorRef.current.focus();
    };

    function handleClose() {
        setOpenDialog(false);
    }

    function handleChange(value: any) {
        setNewMessage(value);

        //scroll to bottom if the scroller is already at bottom position
        if (isScrolledToBottom.current) {
            let messagesBody = document.querySelector<HTMLElement>('#messagesPane');
            if (messagesBody) {
                messagesBody.style.setProperty('overflow', 'hidden');
                messagesBody.scrollTop = messagesBody.scrollHeight;
                messagesBody.style.setProperty('overflow', 'auto');
            }
        }
    }

    function showFilesErrorMsg(errDetails: any, isShowError: any) {
        setExpireNotification({
            ...errDetails, 
            isMsgPanelError: isShowError, 
            severity: isShowError ? errDetails.severity : expireNotification.severity,
            message: isShowError ? errDetails.message : expireNotification.message,
            isError: isShowError ? false : expireNotification.isError, 
            isMembersTabError: false, 
            isChannelExist: false})
    }

    function handleIsFileUpload(value: boolean) {
        setIsFileUpload(value);
    }
    function scrollToExactBottom() {
        //scroll to bottom if the scroller is already at bottom position
        let messagesBody = document.querySelector<HTMLElement>('#messagesPane');

        if (isScrolledToBottom.current) {
            if (messagesBody) {
                messagesBody.style.setProperty('overflow', 'hidden');
                messagesBody.scrollTop = messagesBody.scrollHeight;
                messagesBody.style.setProperty('overflow', 'auto');
            }
        }
    }

    function HandleSend() {
        var attachmentAvailable = false;
        for (const fi in fileMap) {
            attachmentAvailable = true;
            break;
        }
        if (newMessage === '' && !attachmentAvailable) {
            handleClose();
        } else {
            handleClickOpen();
            setIsSendingMessage(false);
            setShowRemoveIcon(false);
        }
    }

    const currentDate = () => {
        const dateValue = new Date();
        const date = dateValue.getDate();
        const month = dateValue.getMonth() + 1;
        const year = dateValue.getFullYear();
        return month + '/' + date + '/' + year;
    };

    const currentTime = () => {
        const dateValue = new Date().toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });
        return dateValue;
    };

    function epochToJsDate(ts: any) {
        return new Date(ts * 1000);
    }

    const addMessage = () => {
        // commenting below lines since reloading messages on send
        // TODO: Revisit this below commented code
        // let parentMessage = new Message();
        // if (copiedMessage !== null) {
        //   parentMessage.id = copiedMessage.id;
        //   parentMessage.messageid = copiedMessage.messageId;
        //   parentMessage.details.messageContent = copiedMessage.message;
        //   parentMessage.createdAt = copiedMessage.createdAt;
        //   parentMessage.senderName = copiedMessage.name;
        //   parentMessage.details.senderRole = copiedMessage.role.toLowerCase();
        //   parentMessage.details.channelName = copiedMessage.channelName;
        //   parentMessage.channelId = `${copiedMessage.channelType}|${copiedMessage.channelId}|${domainId}`;
        //   parentMessage.attachment = copiedMessage.attachment;
        //   parentMessage.uploadedFiles = copiedMessage.uploadedFiles
        //   setCopiedMessage(null);
        // }

        // var new_message_to_append = {
        //   profile: {
        //     name: username,
        //     available: true,
        //     role: authContext.userCtx.userRoleName.toLowerCase(),
        //     profileImage: 'prince.jpg',
        //   },
        //   id: msgId,
        //   messageId: msgId,
        //   message: newMessage,
        //   Date: currentDate(),
        //   Time: currentTime(),
        //   uploadedFiles: files,
        //   isAttachment: files.length > 0,
        //   parentMessage,
        // };
        // console.log(new_message_to_append);
        // setMessage([
        //   ...messages,
        //   new_message_to_append,
        // ]);
        messageLoadAction(true);
        scrollToBottom();
        setNewMessage('');
        if (copiedMessage !== null) {
            setCopiedMessage(null);
        }
        /*var i = {
            ops: [
                { delete: newMessage.length }
            ]
        }
        setNewMessage(i);*/
        //editorRef.current.getEditor().deleteText(0, newMessage.length, "user");
        //editorRef.current.getEditor().setText("", "user");
        setClear(true);
        setClear(false);
        //editorRef.current.focus();
        handleClose();
        setIsSendingMessage(false);
        setShowRemoveIcon(false);
    };
    const receivedMessage = (msg: any) => {
        let parentMessage = new Message();
        if (msg.hasOwnProperty('parentMessage') && msg.parentMessage) {
            parentMessage.id = msg.parentMessage.id;
            parentMessage.messageid = msg.parentMessage.messageId;
            parentMessage.details.messageContent = msg.parentMessage.content;
            parentMessage.createdAt = msg.parentMessage.createdAt;
            parentMessage.senderName = msg.parentMessage.senderName;
            parentMessage.details.senderRole =
                msg.parentMessage.details.senderRole.toLowerCase();
            parentMessage.details.channelName = msg.parentMessage.details.channelName;
            parentMessage.channelId = `${msg.parentMessage.channelId}|${domainId}`;
            parentMessage.attachment = msg.parentMessage.attachment;
            parentMessage.uploadedFiles = msg.parentMessage.attachment ? msg.parentMessage.uploadedFiles : [];
        }
        setMessage([
            ...messages,
            {
                id: msg.id,
                messageId: msg.messageId,
                isTranslated: msg.isTranslated,
                profile: {
                    name: msg.senderName,
                    available: true,
                    role:
                        msg.senderRole.charAt(0) + msg.senderRole.slice(1).toLowerCase(),
                    profileImage: 'prince.jpg',
                },
                message: msg.content,
                Date: currentDate(),
                Time: currentTime(),
                uploadedFiles: msg.attachment ? msg.uploadedFiles : [],
                // isAttachment: msg.uploadedFiles.length > 0,
                attachment: msg.attachment,
                parentMessage,
                sender: msg?.sender ?? '',
                recipient: personid + '|' + domainId,
                createdAt: Math.floor(new Date().getTime() / 1000),
                preferredLanguage: msg.preferredLanguage,
                sourceLanguage: msg.sourceLanguage,
                langConfidence: msg.langConfidence,
                readValue: 0,
            },
        ]);
        scrollToBottom();
    };

    function DateFormat(dateString: any) {
        const dateObj = new Date(dateString);
        const weekDay = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ];

        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];

        const week = weekDay[dateObj.getDay()];
        const month = months[dateObj.getMonth()];
        const customDateStr = week + ', ' + month + ' ' + dateObj.getDate();
        const customDate =
            dateObj.getFullYear() === new Date().getFullYear()
                ? customDateStr
                : customDateStr + ', ' + dateObj.getFullYear();

        return dateObj.toLocaleDateString() === new Date().toLocaleDateString()
            ? 'Today'
            : customDate;
    }

    function LoadMoreMessages(custom: any, scrollDown: any) {
        let customGrpId: any;
        let customGrpTyp: any;
        let customLastDt: any;
        let customMessageId: any;
        let customOffset: any;
        if (custom !== null) {
            customGrpId = custom.groupid;
            customGrpTyp = custom.groupType;
            customMessageId = custom.offset;
            customLastDt = custom.lastdate;
            customOffset = '';
        } else {
            customGrpId = groupid;
            customGrpTyp = selectedGroup.groupType;
            customMessageId = '';
            if (scrollDown) {
                customLastDt = downLastdate;
                customOffset = downOffset;
            } else {
                customLastDt = lastdate;
                customOffset = offset;
            }
        }
        const loadData = async () => {
            setLoading(true);
            let userMessages: any = await getUserMessage(
                impersonating ? impersonatingUserDetails.impersonatedUserId: '',
                personid,
                bbdomain,
                domainId,
                organizationid,
                customGrpId,
                customGrpTyp,
                customGrpId,
                customOffset,
                customLastDt,
                10,
                customMessageId,
                !scrollDown
            );
            //datamassage

            if (
                userMessages.data.data &&
                userMessages.data.data.Messages &&
                userMessages.data.data.Messages.length > 0
            ) {
                var localMsgTemp;
                // we are jumping to message
                if (custom !== null) {
                    // clearing old message
                    localMsgTemp = [];
                    // scrolling to load messages
                } else {
                    // appending to old loaded message
                    localMsgTemp = messagestemp.slice();
                }
                localMsgTemp.push(
                    ...userMessages.data.data.Messages.map((msg: any, index: any) => {
                        let obj = createMsgInfo(msg);
                        // create refs for each message
                        if (myRefs[obj.id] === undefined) {
                            let myRefsTemp = myRefs;
                            myRefsTemp[obj.id] = React.createRef();
                            setMyRefs(myRefsTemp);
                        }
                        // create refs for each message
                        return obj;
                        //setMessageTemp((messagestemp) => [...messagestemp, obj]);
                    })
                );

                // if impersonating user, set emulated user preferred language
                if (
                    impersonating &&
                    userMessages.data.data.current_preferred_language?.code
                ) {
                    setCurrentPreferredLanguage(
                        userMessages.data.data.current_preferred_language?.code
                    );
                }

                // we are jumping to message
                if (custom !== null) {
                    setOffset(userMessages.data.data.offsetUp);
                    setLastdate(userMessages.data.data.lastdateUp);
                    setDownOffset(userMessages.data.data.offsetBottom);
                    setDownLastdate(userMessages.data.data.lastdateBottom);
                    // scrolling to load messages
                } else if (scrollDown) {
                    setDownOffset(userMessages.data.data.offset);
                    setDownLastdate(userMessages.data.data.lastdate);
                } else {
                    setOffset(userMessages.data.data.offset);
                    setLastdate(userMessages.data.data.lastdate);
                }

                setMessageTemp(localMsgTemp);
            }
            setLoading(false);
            isMessageRoute && setEnableSchoolsToSelect(true);
        };
        loadData();
    }

    function loadMoreItems(event: any) {
        if (event.target.id === "group-messages-list" || event.target.id === "messagesPane") {
            let scrollTopPos = Math.round(
                event.target.scrollHeight - event.target.scrollTop
            );
            let scrollBottom = scrollTopPos === event.target.clientHeight;
            isScrolledToBottom.current =
                scrollTopPos - event.target.clientHeight <= 2 ? true : scrollBottom;
            if (impersonating && !scrollBottom) {
                scrollBottom = event.target.scrollHeight - event.target.scrollTop - 1 === event.target.clientHeight;
            }
            if (
                event.target.scrollTop === 0 &&
                offset !== '' &&
                offset !== false &&
                lastdate !== 0
            ) {
                LoadMoreMessages(null, false);
            }
            if (
                scrollBottom &&
                downOffset !== '' &&
                downOffset !== false &&
                downLastdate !== 0
            ) {
                LoadMoreMessages(null, true);
            }

            //check latest message is in viewport or not while scrolling
            if (latestMessageId) {
                let messageId = latestMessageId || messages[messages.length - 1].id;
                let msgElem = document.getElementById(messageId);
                let holderElem = document.getElementById('group-messages-list');
                let isVisible = false;
                if (msgElem && holderElem) {
                    isVisible = isElementVisible(msgElem, holderElem);
                    setLatestMessageVisible(isVisible);
                }
            }
        }
    }

    /////send and save Message
    var messageContent;
    function stripHtml(html: any) {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    }
    var userId = personid + '|' + domainId;
    const [showRemoveIcon, setShowRemoveIcon] = useState(false);
    function sendMessage() {
        setShowRemoveIcon(true);
        var cleanMessage = DOMPurify.sanitize(newMessage)
        const textContent = stripHtml(cleanMessage);
        let repliedTo = '';
        let recipientRole = 'Parent';
        setIsSendingMessage(true);

        if (copiedMessage != null) {
            repliedTo = copiedMessage.id;
            recipientRole = copiedMessage.role;
        }
        var attachments = [];
        for (const fi in fileMap) {
            attachments.push(fileMap[fi].location);
        }
        messageContent = {
            action: 'sendmessage',
            data: textContent,
            groupId: groupid,
            bbdomain: bbdomain,
            domainId: domainId,
            contentType: 'Text',
            sender: userId,
            channelType: selectedGroup.type,
            organizationId: organizationid,
            senderName: username,
            recipentRole: recipientRole,
            msgId: msgId,
            attachment: attachments,
            details: {
                senderRole: authContext.userCtx.userRole,
                repliedTo: repliedTo,
                messageContent: cleanMessage,
                channelName: selectedGroup.name,
                preferredLanguage: preferredLanguage,
            },
        };
        saveMessageHandler(messageContent);
    }
    async function saveMessageHandler(messageContent: any) {
        try {
            let result = await saveMessage(messageContent);
            if (result) {
                editorRef.current.focus();
                setFileMap({});
                addMessage();
                setAvailableTokens([0, 1, 2, 3, 4]);
                var uid = uuidv4();
                setMsgId(uid);
                setFiles([]);
                setvalidateFileLoading([]);
            }
        } catch (err) {
            console.log(err);
            setNewMessage('');
            setClear(true);
            setClear(false);
            handleClose();
            setIsSendingMessage(false);
            setShowRemoveIcon(false);
            if (err.response.status === 401){
                showFilesErrorMsg({severity: 'ownerPrivilegesErr', message: 'Message sent failed. Please contact administrator.', isShowClose: 1}, true)
            }else{
                setSaveMessageError(true);
            }
            scrollToBottom();
        }
    }

    //need to modify receive type of message
    /* wsConnection.wsClient.onmessage = (msg) => {
              const messageStatus = JSON.parse(msg.data);
              if (messageStatus.type === 'SEND') {
                  messageStatus.status === 'SUCCESS' ? addMessage() : alert('message sending failed Please try again');
              }
              if (messageStatus.type === 'RECEIVE') {
                  if (messageStatus.groupId === groupid) { receivedMessage(messageStatus) }
                  setGroupUnread({ id: messageStatus.groupId, unreadCount: 1 });
                  if (messageStatus.groupId.startsWith('DIRECT')) {
                      setDirectChannleIDFromWebsocket(messageStatus.groupId);
                      setDirectUnread({ id: messageStatus.groupId, unreadCount: 1 });
                  }
              }
          }; */

    // scroll to the message components

    function jumpToMessage(groupDetails: any) {
        setCopiedMessage(new ReplyToMessage());
        setMessageIdScroll(groupDetails);
        if (impersonating) setSelectMyGroup('old' + groupDetails.groupid);
        else setSelectMyGroup(groupDetails.groupid);
    }

    const getOriginalMsg = (messageId: string) => {
        let selectedMessage = messages.find(
            (msg: any) => msg.messageId === messageId
        );

        // if translation is in progress, dont make another api call
        if (selectedMessage.messageTranslationLoading) {
            return;
        }

        selectedMessage.messageTranslationError = '';
        selectedMessage.messageTranslationLoading = true;
        setMessage([...messages]);

        // Update specific message item showTranslatedMessage and translatedMessage prop values
        if (selectedMessage.translatedMessage) {
            selectedMessage.showTranslatedMessage =
                !selectedMessage.showTranslatedMessage;
            selectedMessage.messageTranslationLoading = false;
            setMessage([...messages]);
        } else {
            // Display the progress bar for see translation
            setTranslateInProgress(true);
            setTranslateMessageID(messageId); // set the message id
            getOriginalMessage(bbdomain, groupid, messageId)
                .then((response: any) => {
                    if (response && response.status === 200) {
                        const message = response.data;
                        selectedMessage.showTranslatedMessage =
                            !selectedMessage.showTranslatedMessage;
                        selectedMessage.translatedMessage =
                            selectedMessage.showTranslatedMessage
                                ? message
                                : selectedMessage.message;
                        selectedMessage.messageTranslationLoading = false;
                        setMessage([...messages]);
                        // Remove the progress bar for see translation
                        setTranslateInProgress(false);
                    }
                })
                .catch((error: any) => {
                    const errorResponse = error?.response?.data;
                    selectedMessage.messageTranslationError =
                        (errorResponse && errorResponse.errorMessage) ||
                        'Server error. Please try again';
                    selectedMessage.messageTranslationLoading = false;
                    setMessage([...messages]);
                });
        }
    }

    const translateMessage = (
        message: string,
        messageId: string,
        translatedMessage: string
    ) => {
        let selectedMessage = messages.find(
            (msg: any) => msg.messageId === messageId
        );

        // if translation is in progress, dont make another api call
        if (selectedMessage.messageTranslationLoading) {
            return;
        }

        selectedMessage.messageTranslationError = '';
        selectedMessage.messageTranslationLoading = true;
        setMessage([...messages]);

        // Update specific message item showTranslatedMessage and translatedMessage prop values
        if (translatedMessage) {
            selectedMessage.showTranslatedMessage =
                !selectedMessage.showTranslatedMessage;
            selectedMessage.messageTranslationLoading = false;
            setMessage([...messages]);
        } else {
            // Display the progress bar for see translation
            setTranslateInProgress(true);
            setTranslateMessageID(messageId); // set the message id
            getTranslatedMessage(currentPreferredLanguage, message, bbdomain)
                .then((response: any) => {
                    if (response && response.status === 200) {
                        const { body: message } = response.data;
                        selectedMessage.showTranslatedMessage =
                            !selectedMessage.showTranslatedMessage;
                        selectedMessage.translatedMessage =
                            selectedMessage.showTranslatedMessage
                                ? message
                                : selectedMessage.message;
                        selectedMessage.messageTranslationLoading = false;
                        setMessage([...messages]);
                        // Remove the progress bar for see translation
                        setTranslateInProgress(false);
                    }
                })
                .catch((error: any) => {
                    const errorResponse = error?.response?.data;
                    selectedMessage.messageTranslationError =
                        (errorResponse && errorResponse.errorMessage) ||
                        'Server error. Please try again';
                    selectedMessage.messageTranslationLoading = false;
                    setMessage([...messages]);
                });
        }
    };

    useEffect(() => {
        // Removing unassigned references
        let myRefLocal = myRefs;
        let messagesDiff = [];
        for (let k in myRefs) {
            if (myRefs[k].current === null) delete myRefLocal[k];
        }
        setMyRefs(myRefLocal);
        // let messageUnreadCount = getGroupUnreadCount(groupid);

        if (messages.length !== 0) {
            scrollTomsg();
            if (messages.length > oldMessages.length) {
                messagesDiff = [...messages].filter((e: any) => {
                    if (!e.hasOwnProperty('sender')) return;
                    return !oldMessages.some((item: any) => item.id === e.id);
                });

                if (messagesDiff.length > 0) {
                    if (selectedGroup.groupType.startsWith('DIRECT')) {
                        let schoolId = selectedOrganizationId.schoolId.toString();
                        let orgId = `${schoolId}|${domainId}`;
                        if (!isMessageRoute) {
                            setDirectUnreadCount(groupid, orgId, messagesDiff.length);
                        }
                    } else {
                        if (!isMessageRoute) {
                            setGroupUnread({ id: groupid, unreadCount: messagesDiff.length });
                        }
                    }

                    let updatedArr = oldMessages.concat([...messagesDiff]);
                    setOldMessages([...updatedArr]);

                    setGroup({ ...selectedGroup });
                    setLatestMessageId(messagesDiff[0].id);
                }
            } else if (
                selectedGroup.unreadCount > 0 &&
                !(isMessageRoute && groupid === groupIdFromRoute)
            ) {
                let newMessages = messages.slice(-selectedGroup.unreadCount);
                setLatestMessageId(newMessages[0].id);
            }

            if (isMessageRoute && groupid === groupIdFromRoute) {
                let isUnreadMessage: any = false;
                let index = messages.length - 1;
                for (; index >= 0; index--) {
                    if (messages[index].readValue === 0) {
                        isUnreadMessage = messages[index];
                        break;
                    }
                }

                if (isUnreadMessage) {
                    setLatestMessageId(isUnreadMessage.id);
                }
            }
        }

        const unreadStatus: Record<string, any> =
            messages.length === 0 ? {} : { ...unreadMessageStatus };
        for (let message of messages) {
            unreadStatus[message.Date] = unreadStatus[message.Date]
                ? true
                : message.readValue === 0;
        }
        setUnreadMessageStatus(unreadStatus);
    }, [messages]);

    useEffect(() => {
        // without scrolling if user seen the message
        // check latest message is in viewport or not
        let isMessageVisible = false;
        if (!impersonating) {
            if (latestMessageId) {
                let messageId = latestMessageId || messages[messages.length - 1].id;
                let msgElem = document.getElementById(messageId);
                let holderElem = document.getElementById('group-messages-list');
                if (msgElem && holderElem) {
                    isMessageVisible = isElementVisible(msgElem, holderElem);
                }
            }



            if (isMessageVisible || latestMessageVisible) {
                //marking the messages in this channel as read in DB
                setGroupRead(
                    personid,
                    bbdomain,
                    groupid,
                    selectedGroup.type,
                    domainId,
                    selectedOrganizationId.schoolId.toString() || ''
                );
                if (selectedGroup.groupType.startsWith('DIRECT')) {
                    let schoolId = selectedOrganizationId.schoolId.toString();
                    let orgId = `${schoolId}|${domainId}`;
                    setDirectUnreadCount(groupid, orgId, 0);
                } else {
                    setGroupUnreadCount(groupid, 0);
                    if (isMessageRoute && groupid === groupIdFromRoute) {
                        setTimeout(() => setGroupUnreadCount(groupid, 0), 2000);
                    }
                }

                setLatestMessageVisible(false);
                setLatestMessageId(null);
                setTimeout(() => {
                    setGroup({ ...selectedGroup });
                }, 2000);
            }
        }
    }, [latestMessageId, latestMessageVisible]);

    function scrollTomsg() {
        if (messageIdScroll.groupid === selectedGroup.id) {
            //alert('called1');
            if (myRefs[messageIdScroll.offset] !== undefined) {
                if (myRefs[messageIdScroll.offset].current !== null) {
                    myRefs[messageIdScroll.offset].current.scrollIntoView({
                        behavior: 'smooth',
                    });
                    setMessageIdScroll({});
                    return;
                }
            } else {
                LoadMoreMessages(messageIdScroll, false);
            }
        }
    }

    const jump = async () => {
        let groupDetails: any = {};
        // const { id, channelId, channelType, createdAt } = copiedMessage;
        groupDetails['groupid'] = copiedMessage.channelId;
        groupDetails['groupType'] = copiedMessage.channelType;
        groupDetails['offset'] = copiedMessage.id;
        groupDetails['lastdate'] = copiedMessage.createdAt;
        // var split_channel_id = channelId.split('|');
        // if (groupDetails['groupType'] == 'CLASSES') {
        //   groupDetails['groupid'] = split_channel_id[1] + '|' + split_channel_id[2];
        // } else {
        //   groupDetails['groupid'] = split_channel_id[1];
        // }
        //jumpToMessage(groupDetails);

        const existingChannel = channelList.find(
            (channel) => channel.id === groupDetails['groupid']
        );
        if (existingChannel) {
            jumpToMessage(groupDetails);
        } else {
            setAlertOpen(true);
            return false;
        }
    };

    function extractfiledata() {
        const data_dict: any = {}
        const filteredfiles = files.map(
            (each_item: any) => {
                data_dict[each_item.id] = fileMap[each_item.id];
                return
            }
        )
        localStorage.setItem('filesToBeDeleted', JSON.stringify(data_dict))
    }

    // scroll to the message components
    return (
        <>
            <ActionableAlertBox
                data-testid='unloadConfirmationBox'
                title={unloadConfirmation.title}
                shouldOpen={unloadConfirmation.open}
                onAction={() => {
                    formUpdatedRef.current = false;
                    setUnloadConfirmation({
                        ...unloadConfirmation,
                        open: false,
                    });
                    setAllowLogout(true);
                    extractfiledata()
                    history.push(navigateTo);
                }}
                secondaryButtonLabel='Cancel'
                primaryButtonLabel='Discard'
                styles={unloadConfirmationDialogStyle}
                primaryButtonProps={{}}
                onClose={() => {
                    setUnloadConfirmation({
                        ...unloadConfirmation,
                        open: false,
                    });
                    setNavigateTo('');
                }}
            >
                <Box paddingBottom='74px'>{unloadConfirmation.message}</Box>
            </ActionableAlertBox>
            {showImagePreview !== null && <ImagePreviewModal file={showImagePreview} setShowImagePreview={setShowImagePreview} />}
            <Box
                id='messagesPanel'
                className={
                    !impersonating
                        ? classes.messagesEditorWrapper
                        : classes.messagesWrapperWithoutEditor
                }
            >
                {expireNotification.isMsgPanelError || expireNotification.isError ? (
                    <div>
                        <Box>
                            <NotificationBox
                                message={expireNotification.message}
                                severity={expireNotification.severity}
                                isShowClose={expireNotification.isShowClose}
                                handleNotification={() => {
                                    setExpireNotification({...expireNotification, isError: false, isMsgPanelError: false, message: '', severity: ''})
                                }}
                            />
                        </Box>
                    </div>
                ) : ""
                }
                <Box
                    id='messagesPane'
                    className={classes.messagesPane}
                    onScroll={loadMoreItems}
                >
                    {!loading && Object.keys(groupedMessages).length === 0 ? (
                        <Box className={classes.emptyMessageBox}>
                            <EmptyBox emptyMessage={'No messages available'} />
                        </Box>
                    ) : null}
                    {loading && (
                        <Box className={`${classes.loader}`}>
                            <CustomCircularProgress
                                css={classes.loader}
                                aria-label='Loading'
                                size='medium'
                            />
                        </Box>
                    )}
                    <AlertBox
                        title='Message Unavailable'
                        open={alertOpen}
                        setOpen={setAlertOpen}
                    >
                        Your messages won't display because the channel is inactive. Please
                        contact your administrator
        </AlertBox>

                    <List
                        id='group-messages-list'
                        data-testid='group-messages-list'
                        subheader={<li />}
                        className={classNames(
                            classes.root,
                            impersonating // if impersonating
                                ? isMobile // if impersonating in mobile
                                    ? classes.impersonateMobile
                                    : classes.impersonateDesk
                                // : loadEditor // if normal flow
                                // ? isMobile
                                : isMobile
                                    ? classes.heightWithEditorMobile
                                    : classes.heightWithEditor
                            // : classes.heightWithOutEditor
                        )}
                    >
                        {Object.keys(groupedMessages).map((dateItem) => (
                            <li key={`section-${dateItem}`}>
                                <ul
                                    className={
                                        !isMobile ? classes.ulMessage : classes.ulMessageMobile
                                    }
                                >
                                    <MessageHeading
                                        isSpecialHeader={!!unreadMessageStatus[dateItem]}
                                        content={DateFormat(dateItem)}
                                    />
                                    {groupedMessages[dateItem].map((item: any, index: any) => {
                                        const replyHandler = () => {
                                            let copiedMessage = new ReplyToMessage();
                                            copiedMessage.name = item.profile.name;
                                            copiedMessage.role = item.profile.role;
                                            copiedMessage.message = item.message;
                                            copiedMessage.messageId = item.messageId;
                                            copiedMessage.id = item.id;
                                            copiedMessage.createdAt = item.createdAt;
                                            copiedMessage.channelName = selectedGroup.name;
                                            copiedMessage.channelId = selectedGroup.id;
                                            copiedMessage.channelType = selectedGroup.groupType;
                                            copiedMessage.attachment = item.attachment;
                                            copiedMessage.uploadedFiles = item.uploadedFiles;
                                            setCopiedMessage(copiedMessage);

                                            let group = new GroupEssential();
                                            // creating DirectUserId based on sender and receiver id. Which ever is the smallest is used
                                            // first, to avoid creating 2 different id's for the same conversation.
                                            if (
                                                Number(item.sender.split('|')[0]) <
                                                Number(item.recipient.split('|')[0])
                                            )
                                                group.id = 'DIRECT|' + item.sender + '|' + item.recipient;
                                            else
                                                group.id = 'DIRECT|' + item.recipient + '|' + item.sender;
                                            group.name = item.profile.name;
                                            group.groupType = GroupType.DirectMessage;
                                            group.type = GroupType.DirectMessage;
                                            setGroup(group);
                                        };
                                        const directMessageHandler = () => {
                                            let group = new GroupEssential();
                                            // creating DirectUserId based on sender and receiver id. Which ever is the smallest is used
                                            // first, to avoid creating 2 different id's for the same conversation.
                                            if (
                                                Number(item.sender.split('|')[0]) <
                                                Number(item.recipient.split('|')[0])
                                            )
                                                group.id = 'DIRECT|' + item.sender + '|' + item.recipient;
                                            else
                                                group.id = 'DIRECT|' + item.recipient + '|' + item.sender;
                                            group.name = item.profile.name;
                                            group.groupType = GroupType.DirectMessage;
                                            group.type = GroupType.DirectMessage;
                                            setGroup(group);
                                        };

                                        return (
                                            <MessageDisplayComponent
                                                setShowImagePreview={setShowImagePreview}
                                                key={item.id}
                                                dateItem={dateItem}
                                                message={item}
                                                index={index}
                                                setSelectedMsg={setSelectedMsg}
                                                authContextUserName={authContext.userCtx.userName}
                                                authContextUserRole={authContext.userCtx.userRole}
                                                replyHandler={replyHandler}
                                                directMessageHandler={directMessageHandler}
                                                showFilesErrorMsg={showFilesErrorMsg}
                                                selectedMsg={selectedMsg}
                                                selectedGroup={selectedGroup}
                                                jumpToMessage={jumpToMessage}
                                                myRefs={myRefs}
                                                setAlertOpen={setAlertOpen}
                                                translateMessage={translateMessage}
                                                getOriginalMsg={getOriginalMsg}
                                                translateInProgress={translateInProgress}
                                                translateMessageID={translateMessageID}
                                                currentPreferredLanguage={currentPreferredLanguage}
                                            />
                                        );
                                    })}
                                </ul>
                            </li>
                        ))}
                        {copiedMessage !== null && selectedGroup.name === copiedMessage.name && (
                            <div className={classes.tempReplyCardClass}>
                                <Card className={classes.replyToCard} variant='outlined'>
                                    <CardContent>
                                        <div className={classes.liMessage}>
                                            <div className={classes.cardTitle}>
                                                <img
                                                    className={classes.courseIconClass}
                                                    src='/images/courses.png'
                                                    alt='courses'
                                                />
                                                <span>
                                                    {copiedMessage.channelName +
                                                        ' - ' +
                                                        DateFormat(
                                                            epochToJsDate(copiedMessage.createdAt).toString()
                                                        ) +
                                                        ' at ' +
                                                        epochToJsDate(
                                                            copiedMessage.createdAt
                                                        ).toLocaleTimeString('en-US', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                </span>
                                                <img
                                                    className={classes.jumpIconClass}
                                                    alt='Jump'
                                                    src='/images/jump-to.png'
                                                    onClick={jump}
                                                />
                                            </div>
                                            <Box
                                                display='flex'
                                                flexDirection='row'
                                                width='100%'
                                                paddingTop='12px'
                                            >
                                                <Box component='div' display='flex'>
                                                    <BadgeAvatar
                                                        size={classes.medium}
                                                        name={copiedMessage.name}
                                                    />
                                                </Box>
                                                <Box display='flex' flexDirection='column' width='100%'>
                                                    <Box display='flex' flexDirection='row'>
                                                        <div className={classes.group}>
                                                            <div className={classes.profileName}>
                                                                {copiedMessage.name}
                                                            </div>
                                                            <div className={classes.profileRole}>
                                                                {copiedMessage.role}
                                                            </div>
                                                        </div>
                                                    </Box>
                                                    <Box component='div' className={classes.copiedMessage}>
                                                        {ReactHtmlParser(copiedMessage.message)}
                                                    </Box>
                                                    {copiedMessage.attachment && <span style={{ color: "#8C8C8C", fontSize: "14px" }}>{copiedMessage.uploadedFiles.length} {copiedMessage.uploadedFiles.length > 1 ? 'attachments' : 'attachment'}</span>}
                                                </Box>
                                            </Box>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                        )}
                        {isSaveMessageError && (
                            <Box
                                data-testid='save-message-error-container'
                                className={
                                    !isMobile ? classes.editorStyle : classes.editorStyleMobile
                                }
                            >
                                <NotificationBox
                                    message={'Message Sent Failed! Please Try Again Later'}
                                    handleNotification={setSaveMessageError}
                                    severity={'error'}
                                />
                            </Box>
                        )}
                        <div ref={convRef}></div>
                    </List>

                </Box>
                <div
                    className={classNames(
                        !isMobile ? classes.editorStyle : classes.editorStyleMobile,
                        !loadEditor && classes.noMargin
                    )}
                >
                    {loadEditor && !clear && (
                        <Editor
                            onSend={HandleSend}
                            handleIsFileUpload={handleIsFileUpload}
                            isFileUpload={isFileUpload}
                            onChange={handleChange}
                            editorRef={editorRef}
                            value={newMessage}
                            groupType={selectedGroup.groupType}
                            isSendingMessage={isSendingMessage}
                            sendMessage={sendMessage}
                            isParenDrawerOpen={isParenDrawerOpen}
                            showFilesErrorMsg={showFilesErrorMsg}
                            isFileUploadError={isFileUploadError}
                            setisFileUploadError={setisFileUploadError}
                            files={files}
                            setFiles={setFiles}
                            cloudFiles={cloudFiles}
                            setCloudfiles={setCloudfiles}
                            setisCloudfiles={setisCloudfiles}
                            validateFileLoading={validateFileLoading}
                            setvalidateFileLoading={setvalidateFileLoading}
                            showRemoveIcon={showRemoveIcon}
                            msgId={msgId}
                            availItems={availableTokens}
                        />
                    )}
                    {!loadEditor && !impersonating && (
                        <div
                            data-testid='RestrictUserErrorTextId'
                            className={classes.restrictParent}
                        >
                            <InfoOutlinedIcon />
                            <div className={classes.restrictParentText}>
                                Group chat isn't enabled in this channel but you can send a direct
                                message to the instructor or reply directly to a message.
            </div>
                        </div>
                    )}
                    {selectedGroup.groupType &&
                        selectedGroup.groupType !== GroupType.DirectMessage ? (
                            <ConfirmAlertDialog
                                openDialog={openDialog}
                                handleClose={handleClose}
                                sendMessage={sendMessage}
                                isSendingMessage={isSendingMessage}
                            />
                        ) : null}
                </div>
            </Box>
        </>
    );
};

export default MessagesPanel;