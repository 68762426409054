import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 79;
const westbarWidth = '100%';
const SideBarStyle = () =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      display: 'flex',
      width: drawerWidth,
    },
    drawerTwo: {
      display: 'flex',
      width: '220px',
      marginLeft: '-70px',
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#39434B',
      top: (props: any) => {
        return props.isOpenedInMobile == 'true' ? 0 : '44px';
      },
      height: (props: any) => {
        return props.isOpenedInMobile == 'true'
          ? '100vh'
          : 'calc(100vh - 44px)';
      },
      overflow: 'auto',
      scrollbarColor: '#666666 transparent',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#666666',
        borderRadius: '10px',
        border: '1px solid #666',
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    },
    drawerPaperMobile: {
      width: drawerWidth,
      background: '#39434B',
      top: (props: any) => {
        return props.isOpenedInMobile == 'true' ? 0 : '44px';
      },
      height: (props: any) => {
        return props.isOpenedInMobile == 'true' ? '100%' : 'calc(100% - 44px)';
      },
      overflow: 'auto',
    },
    listItemClass: {
      borderLeft: '3px solid transparent',
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: '7px 7px',
    },
    itemClass: {
      display: 'flex',
      borderLeft: '3px solid transparent',
      boxSizing: 'border-box',
      cursor: 'pointer',
      padding: '0 7px 0 0',
      justifyContent: 'center',
      minHeight: '50px',
    },
    selectedListItem: {
      backgroundColor: '#713046',
      borderLeft: '3px solid #FF5991',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      //flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      width: westbarWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: westbarWidth,
    },
    large: {
      borderLeft: '2px solid transparent',
      position: 'relative',
      backgroundColor: '#262626',
      width: theme.spacing(6),
      height: theme.spacing(6),
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '27px',
      textAlign: 'center',
      color: '#FFFFFF',
      marginBottom: '0.4rem',
    },
    toggleButton: {
      width: '35px',
      height: '60px',
      backgroundColor: '#FFF',
      alignContent: 'center',
      padding: '2px 2px 2px 2px',
      borderWidth: '1px',
      borderColor: '#e0e0e0',
      borderStyle: 'solid',
      outline: 'none',
      borderBottom: '1px',
    },
    divClass: {
      border: '1px solid #CDCDCD',
      backgroundColor: 'white',
    },
    spanClass: {
      width: '100%',
      color: 'lightBlue',
      display: 'inline',
    },
    searchBlock: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '60px',
      border: '1px solid #CDCDCD',
    },
    searchMenu: {
      display: 'flex',
      flexGrow: 1,
    },
    searchSvg: {
      marginLeft: '10px',
      alignSelf: 'center',
    },
    spanSearchClass: {
      paddingLeft: '7px',
      fontStyle: 'normal',
      fontWeight: 600,
      flexGrow: 1,
      alignSelf: 'center',
    },
    iconGroup: {
      display: 'flex',
    },
  }));

export default SideBarStyle;
