import { makeStyles } from '@material-ui/core/styles';

const EmailUnsubscriptionStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    mainDiv: {},
    rootWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 95px)'
    },
    container: {
        height: '200px'
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
        padding: '0 12px'
    },
    message: {
        color: '#767676',
        padding: '0 20px',
        maxWidth: '530px',
        textAlign: 'center'
    },
    buttonWrapper: {
        textAlign: 'center'
    },
    unsubscribeBtn: {
        color: '#000',
        width: 'fit-content',
        minWidth: '100px',
        border: '1px solid #666666',
        margin: '5px',
        '&:hover': {
            color: '#fff'
        }
    },
}));

export default EmailUnsubscriptionStyle;