import { useState, useLayoutEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export function useFormFields(initialState: any) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event: any) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

export function useWindowHeight() {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    const delay = 250;
    let timeout = 0;
    let lastHeight = 0;

    function updateHeight() {
      if(lastHeight != window.innerHeight) {
        setHeight(window.innerHeight);
        lastHeight = window.innerHeight;
      }
    }
    window.addEventListener('resize', () => {
      clearTimeout(timeout)
      timeout = window.setTimeout(updateHeight, delay)
    });
    return () => window.removeEventListener('resize', updateHeight);
  }, []);
  return height;
}

export function useWindowWidth() {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    const delay = 250;
    let timeout = 0;
    let lastWidth = 0;

    function updateWidth() {
      if(lastWidth != window.innerWidth) {
        setWidth(window.innerWidth);
        lastWidth = window.innerWidth;
      }
    }
    window.addEventListener('resize', () => {
      clearTimeout(timeout)
      timeout = window.setTimeout(updateWidth, delay)
    });
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return width;
}

export const useMessageRoute = () => {
  let messageId = null;
  let isNextPathMessage = false;
  const isMatch = useRouteMatch("/Home/message/:messageId") as null | {
    params: { messageId: number }
  };

  const location = useLocation()
  const nextPath = (location as any)?.state?.nextPathname ?? ''
  const routeId = nextPath.split('/').pop()

  if(location.pathname == '/Login' && (nextPath == '/Home/message/' + routeId)) {
    isNextPathMessage = true
  }

  if(isMatch) {
    messageId = isMatch.params.messageId
  }

  return {
    isMatch: !!isMatch,
    messageId,
    isNextPathMessage,
    handleInvalidRoute: () => {
      console.trace()
      window.location.assign('/Home')
    }
  }
}