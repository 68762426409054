import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton, DefaultButton } from './ReusableComponents/Button';
import CustomCircularProgress from './ReusableComponents/CircularProgress';

const ConfirmAlertDialogStyle = makeStyles((theme) => ({
  dialogButton: {
    width: '76px',
  },
}));

type dialogType = {
  openDialog: any;
  handleClose: any;
  sendMessage: any;
  isSendingMessage?: boolean;
};

const ConfirmAlertDialog = ({
  openDialog,
  handleClose,
  sendMessage,
  isSendingMessage = false,
}: dialogType) => {
  const classes = ConfirmAlertDialogStyle();

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        disableBackdropClick={isSendingMessage}
        disableEscapeKeyDown={isSendingMessage}
      >
        <DialogContent id='alert-dialog-description'>
          Are you sure you want to send the message?
        </DialogContent>
        <DialogActions>
          <DefaultButton
            css={null}
            onClick={handleClose}
            disabled={isSendingMessage}
          >
            Cancel
          </DefaultButton>
          <PrimaryButton
            css={null}
            onClick={sendMessage}
            className={classes.dialogButton}
            disabled={isSendingMessage}
          >
            {isSendingMessage ? (
              <CustomCircularProgress aria-label='Loading' size={20} />
            ) : (
              <>Confirm</>
            )}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmAlertDialog;
