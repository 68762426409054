import { makeStyles } from '@material-ui/core/styles';

const UniversalNavStyle = makeStyles((theme) => ({
    spacer: {
        height: '44px',
    },
    appBar: {
        minHeight: '44px !important',
        backgroundColor: '#181818',
        paddingRight: '0px!important',
    },
    unBlackboardLogo: {
        height: '1.2rem',
        boxSizing: 'initial',
        verticalAlign: 'middle'
    },
    logo: {
        flexGrow: 1,
        paddingRight: '10px'
    },
    title: {
        color: '#A5A5A5',
        '&:hover': {
            color: '#FFF',
            backgroundColor: '#181818',
            cursor: 'pointer'
        }
    },
    titleGrey: {
        color: '#A5A5A5',
        cursor: 'default',
    },

    svg: {
        lineHeight: '0px',
        '&:hover': {
            backgroundColor: '#181818',
            cursor: 'pointer',
            '& svg': {
                '& rect': {
                    stroke: '#FFF',
                }
            }
        },
        '& svg': {
            '& rect': {
                stroke: '#A5A5A5',
            }
        }
    },

    svgSelect: {
        backgroundColor: '#181818',
        cursor: 'pointer',
        '& svg': {
            '& rect': {
                stroke: '#FFF',
            }
        }
    },

    linkGroup: {
        backgroundColor: "#262626",
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'stretch',
        '& > div:not(.popper-element)': {
            alignItems: 'center',
            display: 'flex',
            flexGrow: '1',
            paddingRight: '14px',
            paddingLeft: '14px'
        }
    },
    noSize: {
        //unwanted parent styles
        marginLeft: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
        //unwanted parent styles
    },
    divider: {
        backgroundColor: '#000000'
    }
}));

export default UniversalNavStyle;