import { makeStyles, createStyles, fade } from '@material-ui/core/styles';

const InlineFiltersPanelStyle = makeStyles((theme: any) => {
    return createStyles({
      root: {
        flexGrow: 1,
      },
      label: {
        fontSize: '14px',
      },
      formGroup: {
        display: 'flex',
        flexDirection: 'column',
      },
      formControl: {
        display: 'flex',
        //marginTop: '12px',
      },
      formBtnControl: {
        display: 'flex',
        marginTop: '28px',
        '@media (max-width: 600px)' : {
          marginTop: '0',
        }
      },
      button: {
        color: '#FFFFFF',
        backgroundColor: '#262626',
        fontWeight: 'normal',
        fontSize: '14px',
        width: '100%',
        alignSelf: 'flex-end',
      },
      buttonHover: {
        '&:hover': {
          color: '#262626',
          backgroundColor: '#FFFFFF',
          border: '2px solid #666666',
        },
      },
      focused: {
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
          backgroundColor: '#fff',
        },
        '&$focused': {
          backgroundColor: '#fff',
          boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
          borderColor: '#262626',
        },
      },
      datePickerIcon: {
        alignSelf: 'center',
        position: 'absolute',
        paddingTop: '20px',
        paddingLeft: '35%',
        zIndex: 2,
      },
      customDatePicker: {
        marginTop: '0px',
      },
      asterisk: {
        marginLeft: '-4px'
      },
      dateIcon: {
        position: 'relative',
        top: '4px'
      },
    });
});

export default InlineFiltersPanelStyle;
