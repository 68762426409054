import { makeStyles, fade } from '@material-ui/core/styles';

const CustomTableStyles = () =>
  makeStyles((theme) => ({
    root: {
      overflow: 'auto',
    },
    rootContainer: {
      lineHeight: 'inherit',
    },
    rootContainerMobile: {
      lineHeight: 'inherit',
    },
    sortArrow: {
      display: 'flex',
      flexDirection: 'column',
      height: '12px',
      justifyContent: 'space-around',
      paddingLeft: '5px',
    },
    upArrow: {
      marginLeft: '5px',
      marginBottom: '1px',
    },
    downArrow: {
      marginLeft: '5px',
      marginBottom: '1px',
    },
    headerWrapper: {
      alignItems: 'left',
      fontWeight: 600,
      fontSize: '14px',
    },
    headerContainer: {
      alignItems: 'center',
    },
    panelContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      fontWeight: 600,
      fontSize: '14px',
    },
    contentWrapper: {
      height: (props: any) => {
        const footerHeight = props.isMobile
          ? '99'
          : props.isTablet
          ? '67'
          : '0';
        const panelHeight = props.renderFor == 'district' ? '244' : '383';
        return props.selected.length > 1 && (props.isMobile || props.isTablet)
          ? `calc(${window.innerHeight}px - ${panelHeight}px - ${footerHeight}px)`
          : `calc(${window.innerHeight}px - ${panelHeight}px)`;
      },
      minHeight: '100px',
      overflowY: 'scroll',
    },
    content: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',
      color: '#262626',
    },
    listItemLabel: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '10px',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      color: '#8C8C8C',
    },
    listColumn: {
      display: 'flex',
      alignItems: 'center',
      overflowWrap: 'anywhere',
    },
    selectedListItem: {
      backgroundColor: '#E5E5E5',
    },
    selectDropdownGridContainer: {
      justifyContent: 'flex-end',
      fontStyle: 'normal',
      fontSize: '14px',
      fontWeight: 400,
    },
    selectDropdownGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    selectDropdownGridItem: {
      paddingRight: '10px',
    },
    selectDropdown: {},
    focused: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
        borderColor: '#262626',
      },
      width: (props: any) => {
        return props.isMobile ? '100%' : '';
      },
    },
    tableColumnItem: {
      marginLeft: '5px',
    },
    tabletFooter: {
      borderTop: '1px solid #CDCDCD',
      backgroundColor: '#fff',
      padding: '20px',
      position: 'fixed',
      // left: '0',
      bottom: '0',
      height: '67px',
      width: '100%',
    },
    tabletFooterParent: {
      display: 'block',
      padding: '20px',
      height: '67px',
      width: '100%',
    },
    mobileFooterHeight: {
      height: '99px',
    },
    mobileFooterContent: {
      textAlign: 'center',
      paddingBottom: '15px',
    },
    selectedItemsText: {
      textAlign: 'start',
    },
    customDropdown: {
      textAlign: 'end',
    },
    MenuItemClass: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    formControl: {
      // width: '-webkit-fill-available',
      // width: '80%',
    },
    customLink: {
      color: '#0052CC',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    loadingLink: {
      color: '#000000',
      textDecoration: 'none',
    },
    fixedFooter: {
      backgroundColor: '#fff',
      // position: 'fixed',
      bottom: 0,
      left: 0,
      borderTop: '1px',
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
      borderStyle: 'solid',
      borderColor: '#cdcdcd',
      height: (props: any) => {
        return props.isMobile ? '99px' : '67px';
      }, // or it can be 99px for mobile
      ['@media (max-height: 500px)']: {
        height: (props: any) => {
          return props.isMobile ? '99px' : '55px';
        },
      },
      width: '100%',
      display: 'flex',
      flexDirection: (props: any) => {
        return props.isMobile ? 'column' : 'row';
      },
      padding: (props: any) => {
        return props.isMobile ? '15px' : '0 20px';
      },
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));

export default CustomTableStyles;
