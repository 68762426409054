import { makeStyles, fade } from '@material-ui/core/styles';

const SchoolSettingsComponentStyle = makeStyles((theme) => ({
  root: {},
  infoIcon: {
    backgroundColor: '#fff',
    paddingTop: '3px',
    paddingLeft: '10px',
    width: '20px',
    minWidth: 'inherit',
    minHeight: 'inherit',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  overflowContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto',
      paddingRight: '15px',
      height: 'inherit',
      maxHeight: '100%',
    },
  },
  gridRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: 'calc(100vh - 300px)',
    maxWidth: '100%',
  },
  filterBar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    ['@media (max-height: 500px)']: {
      padding: '10px 20px',
    },
  },
  formControl: {
    display: 'flex',
    fontSize: '14px',
  },
  searchTextField: {
    display: 'flex',
    flex: 1,
    borderRadius: '2px',
    border: '1px solid #CDCDCD',
  },
  focused: {
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
      borderColor: '#262626',
    },
  },
  outline: {
    padding: '30px 20px 0 20px',
    ['@media (max-height: 500px)']: {
      padding: '10px 20px 0 20px',
    },
  },
  endordment: {
    marginRight: '5px',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  accentPink: {
    color: '#a234b5',
  },
  boxConatainer: {
    height: () => {
      return `calc(${window.innerHeight}px - 239px)`;
    },
    ['@media (max-height: 500px)']: {
      height: () => {
        return `calc(${window.innerHeight}px - 185px)`;
      },
    },
  },
}));

export default SchoolSettingsComponentStyle;
