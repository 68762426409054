import React from 'react'

const MessageRouteContext = React.createContext({})

export type MessageRouteContextType = {
  isMessageRoute: boolean,
  messageId: null | string | number,
  channelId: null | string | number,
  schoolId: null | string | number,
  createdAt: null | string | number,
  channelType: null | string
}

export default MessageRouteContext