import React from 'react';
import CustomTableStyles from '../components/CustomTable/CustomTableStyles';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    downArrow: {
      marginLeft: '5px',
      marginBottom: '1px',
    },
  });
});

export const SortDownArrow = (props: any) => {
  const classes = useStyles();
  return (
    <svg
      width='8'
      height='4'
      viewBox='0 0 8 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classes.downArrow}
      onClick={props.handler}
    >
      <path d='M4 4L0 0H8L4 4Z' fill='#262626' />
    </svg>
  );
};
