import React from 'react';
import styled from 'styled-components'

const AppLogo = styled.img `
  height: 24px;
  pointer-events: none;
  animation: App-logo-spin infinite 7s linear;

  @keyframes App-logo-spin {
  from {
  transform: rotate(0deg);
  }
  to {
  transform: rotate(360deg);
  }
`;

const Spinner = (props: any) => {
  return (
    <>
      <AppLogo className= 'App-logo' src='./images/spin.jpg'/>
    </>
  );
};
export default Spinner;


