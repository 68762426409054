import React, { useCallback, useContext, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import SearchUsersPanelTypes from './SearchUsersPanelTypes';
import UserCard from '../UserCard/UserCard';
import ImpersonatingPanel from '../ImpersonatingPanel/ImpersonatingPanel';
import { StateContext } from '../StateContextComponent';
import { clearChannelList } from '../../dataLoaders/DataHandler';
import { GroupEssential } from '../../Types/GroupEssential';

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    emptyMessage: {
      fontWeight: 600,
      color: '#262626',
    },
    emptySubMessage: {
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    cardRoot: {
      '& > div': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
      },
    },
  });
});

const EmptyBox = ({ emptyMessage, emptySubMessage }: any): any => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flex='1'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      <Box className={classes.emptyMessage}>{emptyMessage}</Box>
      <Box className={classes.emptySubMessage}>{emptySubMessage}</Box>
    </Box>
  );
};

export const Loader = () => {
  return (
    <Box
      data-testid='loader'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex='1'
    >
      <CircularProgress />
    </Box>
  );
};

const SearchUsersPanel = ({
  emptyMessage = 'Find users',
  emptySubMessage = 'Enter a keyword and adjust filter controls.',
  isLoading = false,
  loggedInUser='',
  users = null,
}: SearchUsersPanelTypes) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const { setImpersonatingUserDetails, setImpersonatingState } = useContext(
    StateContext
  );
  const [selectedGroup, setGroup] = React.useState<GroupEssential>(
    new GroupEssential()
  );
  const [isParentDrawerOpen, setParentDrawerOpen] = useState(true);

  const handleClickOpen = () => {
    clearChannelList();
    setGroup(new GroupEssential());
    setOpen(true);
    setImpersonatingState(true);
  };
  const handleClose = () => {
    setOpen(false);
    clearChannelList();
    setImpersonatingState(false);
    setParentDrawerOpen(true);
  };

  if (isLoading) {
    return isLoading ? <Loader /> : <></>;
  }

  if (users === null || users.length <= 0) {
    return (
      <EmptyBox emptyMessage={emptyMessage} emptySubMessage={emptySubMessage} />
    );
  } else {
    return (
      <Box className={classes.cardRoot}>
        <ImpersonatingPanel
          open={open}
          handleClose={handleClose}
          username={username}
          selectedGroup={selectedGroup}
          setGroup={setGroup}
          isParentDrawerOpen={isParentDrawerOpen}
          setParentDrawerOpen={setParentDrawerOpen}
        />
        {isLoading ? <Loader /> : <></>}
        {users?.map((user: any) => {
          const {
            firstName,
            lastName,
            organizationID,
            organizationName,
            personID,
            relationships: [],
            role,
            roleName,
            bbdomain,
          } = user;
          const jumperAction = () => {
            setUsername(firstName + ' ' + lastName);
            setImpersonatingUserDetails({
              userId: loggedInUser,
              impersonatedUserId: personID,
              userName: firstName + ' ' + lastName,
              userLoggedInDomain: bbdomain,
              schoolId: organizationID,
            });
            handleClickOpen();
          };
          return (
            <UserCard
              key={personID}
              organizationName={organizationName}
              personId={personID}
              senderName={firstName + ' ' + lastName}
              senderRole={roleName}
              jumperAction={jumperAction}
            />
          );
        })}
      </Box>
    );
  }
};

export default SearchUsersPanel;
