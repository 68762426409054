import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const StarIcon = (props: any) => {
    const userType = props.userType
    return (
        <SvgIcon {...props}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill= {(userType == 'owner')? "black": "none"} xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1.25L14.5796 6.82309L20.9861 8.50532L16.7919 13.6319L17.1717 20.2447L11 17.84L4.82825 20.2447L5.20807 13.6319L1.01391 8.50532L7.42039 6.82309L11 1.25Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </SvgIcon>
    );
};
