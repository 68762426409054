import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import LoginFooter from './loginFooter';
import LoginStyle from '../styles/loginStyle';
import { UserAuthCtx } from './AuthComponent';
import { Box, Button, Link, TextField, InputLabel } from '@material-ui/core';
import NavBar from './navBar';
import auth from './auth';
import axios from 'axios';
import AutoComplete from './AutoComplete/AutoComplete';
import { getDomainList, validateOtpPasscode } from '../services/AuthService';
import { DistrictType } from '../Types/DistrictType';
import NotificationBox from './NotificationBox/NotificationBox';
import ActionableAlertBox from './ActionableAlertBox/ActionableAlertBox';
// import ReactCodeInput from 'react-code-input';
// import ReactCodeInput from 'react-verification-code-input';
import PinInput from 'react-pin-input';
import Timer from '../libs/timer';
import CancelAlertDialog from './cancelAlertDialog';
import { get_cluster } from './clusterGlobal';
import CustomCircularProgress from './ReusableComponents/CircularProgress';

const LoginComponent = (props: any) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorUsername, seterrorUsername] = useState('');
  const [errorPassword, seterrorPassword] = useState('');
  const [unauthorized, setUnauthorized] = useState(false);
  const [withPassword, setWithPassword] = useState(false);
  const userAuthCtx = useContext(UserAuthCtx);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [distList, setDistList] = useState<DistrictType[]>([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [district, setDistrict] = useState(localStorage.getItem('district'));
  var selectDistrict:any;
  if (district !== null) {
    selectDistrict = JSON.parse(district);
  } else {
    selectDistrict = new DistrictType();
  }
  const [selectedDist, setSelectedDist] = useState<DistrictType>(
    selectDistrict
  );
  const [expanded, setExpanded] = useState(false);
  const [loadedDistricts, setLoadedDistricts] = useState(false);
  //var enableLogin = selectedDist.DistrictName !== '';
  const [loginType, setLoginType] = useState("");
  const [enableLogin, setenableLogin] = useState(
    selectedDist.name !== ''
  );
  const [enableNext, setEnableNext] = useState(false);
  const [enableLoader, setEnableLoader] = useState(false);

  // Below States are for Passcode functionality starts
  const [enablePasscode, setEnablePasscode] = useState(false);
  // const [enableSignIn, setEnableSignIn] = useState(false);
  const [passcode, setPasscode] = useState('');
  const [personId, setPersonId] = useState('');
  const [disableResend, setDisableResend] = React.useState(false);
  const [disableCancel, setDisableCancel] = React.useState(false);
  const [passcodeTimer, setPasscodeTimer] = useState(true);
  const [passcodeIncorrect, setPasscodeIncorrect] = useState(false);
  const [resend, setResend] = useState(false);
  const [resendAttempts, setResendAttempts] = useState('');
  const [reset, setReset] = useState(false);
  const [timerKey, setTimerKey] = useState(1);
  const [isCancelOtp, setIsCancelOtp] = useState(false);

  // State for cancel button(Are you sure?) pop-up
  const [confirmation, setConfirmation] = useState({
    open: false,
    title: '',
    message: 'Are you sure you want cancel?',
  });
  // State for password expired pop-up
  const [otpExpired, setOtpExpired] = useState({
    open: false,
    title: 'Password expired',
    message: "Your one-time password has expired. Please use the 'Resend' button below to get a new one-time password and try again",
  });
  // State for one-time password sent pop-up
  const [otpSent, setOtpSent] = useState({
    open: false,
    title: 'One-time password sent',
    message: "A new one-time password has been sent to you.",
  });
  // States for Passcode functionality ends

  useEffect(() => {
    localStorage.setItem('district', JSON.stringify(selectedDist));
    // localStorage.setItem('cluster', selectedDist['cluster']);
    // localStorage.setItem('bucket_name', selectedDist['s3_bucket_name']);
    get_cluster();
  }, [selectedDist]);

  const appVersion = process.env.REACT_APP_VERSION;

  useEffect(()=>{
    let isDistExist = distList.some((dist)=>{
      return dist['id'] === selectedDist['id']
    });
    if (isDistExist === false){
      setDistrict(null)
      setSelectedDist(selectDistrict)
    };
  },[distList])

  if (urlParams.get('authorization')) {
    userAuthCtx.loadAuthContext(props).then(() => {
      auth.login(() => {
        props.history.push('/');
      });
    });
    return <div></div>;
  }
  const classes = LoginStyle();

  if (!loadedDistricts) {
    getDomainList().then((result: any) => {
      setDistList(result.data.body);
    });
    setLoadedDistricts(true);
  }

  const validateEmail = (email: any) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const validatePhone = (phone: any) => {
//     const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const re = /^\d{10}$/;
    return re.test(phone);
  }

  localStorage.setItem('district', JSON.stringify(selectedDist));
  const handleLogin = async (e: any) => {
    e.preventDefault();
    setUnauthorized(false);  // removes banner with error
    setErrorMsg('');
    setenableLogin(false);
    setEnableLoader(true);   // show loader
    let loginType:string = "";
    let orgId:string = "";
    let districtObj:any = "";

    // check if selectedDist (local storage - district object) has DistrictId/refSchoolId
    if (selectedDist){
      if ('refSchoolId' in selectedDist){
        orgId = selectedDist['refSchoolId']
      } else {
        for (districtObj in distList) {
          if (selectedDist['id'] === distList[districtObj]['id']){
            orgId = distList[districtObj]['refSchoolId']
            // set local storage with new district JSON data having DistrictId/refSchoolId key
            localStorage.setItem('district', JSON.stringify(distList[districtObj]));
            setSelectedDist(distList[districtObj]);
            break;
          }
        }
      }
    }

    // hardcoding domain name, need to get actual domain name once
    // bbcomms to reach domain mapping is ready.
    if (enableNext && !withPassword){
      //validate the format of data entered by user in login id field
      if (validateEmail(username)){
        //call reach and bbcomms api for validating email id
        //setLoginType("email");
        loginType = "email"
      } else if (validatePhone(username)){
        //call reach and bbcomms api for validating phone number
        //if (username.includes("+1")){
          //setLoginType("phone");
          loginType = "phone"
//         }else{
//           setUnauthorized(true);
//           setErrorMsg('Please enter phone number with country code (+1)');
//           setenableLogin(true);
//           setEnableLoader(false);   // hide loader
//           return;
//         }
      } else {
        // show password field
        setWithPassword(true);
        setenableLogin(true);
        setEnableNext(false);
        setEnableLoader(false);   // hide loader
        return;
      }
    }
    else{
      if (username === '' && password === '') {
        seterrorUsername('Please enter the username');
        seterrorPassword('Please enter the password');
        setenableLogin(true);
        setEnableLoader(false);
        return;
      } else if (username === '') {
        seterrorUsername('Please enter the login id');
        setenableLogin(true);
        setEnableLoader(false);
        return;
      } else if (password === '') {
        seterrorPassword('Please enter the password');
        setenableLogin(true);
        setEnableLoader(false);
        return;
      }
    }

    const urlParams = new URLSearchParams(queryString);
    const mobileflag = urlParams.get('isMobile');
    const mobile = mobileflag ? mobileflag : 'false';
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    userAuthCtx
      .loginFlow(
        username,
        password,
        mobile,
        source,
        selectedDist.ClusterUrl,
        selectedDist.id,
        selectedDist.DistrictDomainURL,
        orgId,
        passcode,
        personId,
        resend
      )
      .then((response: any) => {
        setEnableLoader(false);  // hide loader
        if (response && response.authenticated) {
          setUnauthorized(false);
          auth.login(() => {
            // Redirection URL update
            props.history.push(props.location.state.nextPathname);
          });
        }
        else if (response && response.showpassword){
          setWithPassword(true);
          setenableLogin(true);
          setEnableNext(false);
          setEnableLoader(false);   // hide loader
        }
        else if (response && response.loginIdValidated) {
          let numberOfAccounts = parseInt(response.numberOfAccounts)
          if (numberOfAccounts === 1) {
            // Redirect to passcode page
            //setUnauthorized(true);
            //setErrorMsg('Redirect to passcode page');
            setPersonId(response.personId);
            setEnablePasscode(true);
            // 30 seconds disable and enable resend
            setDisableResend(true);
            setTimeout(
              () => setDisableResend(false),
              30000
            );
          } else if (numberOfAccounts > 1) {
            setUnauthorized(true);
            if (loginType === 'phone'){
              setErrorMsg(`There were multiple accounts found with the same phone number ${username}. Please contact the administrator.`);
            } else {
              setErrorMsg(`There were multiple accounts found with the same ${loginType} ${username}. Please contact the administrator.`);
            }
            // Enable the disabled loginid textbox
            setenableLogin(true);
          } else if (numberOfAccounts === 0) {
            setUnauthorized(true);
            setErrorMsg(`No accounts have entered ${loginType} ${username}`);
            // Enable the disabled loginid textbox
            setenableLogin(true);
          }
        } else {
          setUnauthorized(true);
          if (response && response.status === 403) {
            setErrorMsg(
              'School not available/School disabled from Application. Please Contact the School Administrator/Principal'
            );
          } else if (response && response.status === 500) {
            setErrorMsg(
              'Server not available, please try after sometime'
            );
          } else if (response && response.message === "No-ReachUser") {
            setErrorMsg(
              'Your account will be activated within 24 hours'
            );
          } else if (password != ''){
            setErrorMsg('Invalid username and password');
          } else if (password === '' && loginType === 'phone'){
            setErrorMsg('Invalid phone number');
          } else if (password === '' && loginType === 'email'){
            setErrorMsg('Invalid email-id');
          }
          setenableLogin(true);
        }
      });
  };

  const handleUsernameOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    if (e.target.value.length > 0) {
      seterrorUsername('');
      if (selectedDist.name !== ''){
        setEnableNext(true);
      }
    }else{
      setEnableNext(false);
    }
  };

  const handlePasswordOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length > 1) {
      seterrorPassword('');
    }
  };

  const forgotPasswordClass = [classes.formElements, classes.rightAllign].join(
    ' '
  );

  const onDistrictSelect = (value: any, district: DistrictType) => {
    setSelectedDist(district);
    setenableLogin(true);
  };

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value.length >= 3) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }
  function filterOptions(districts: DistrictType[], state: any) {
    return districts.filter((districtVal: DistrictType) => {
      return districtVal.name.toLowerCase().includes(
        state.inputValue.toLowerCase()
      );
    });
  }

  // functions for Passcode functionality start
  const hidePasscodeTimer = () => {
    setPasscodeTimer(false);
  }

  // style for cancel pop-up
  const customdDialogStyle = {
    paper: {
      width: '400px',
      height: '200px',
    },
    root: {
      overflowY: 'hidden',
    }
  };

  const cancelOnClick = () => {
    setConfirmation({
      ...confirmation,
      open: true
    });
  }

  const otpTimerExpired = () => {
    setOtpSent({
      ...otpSent,
      open: false,
    });
    setOtpExpired({
      ...otpExpired,
      open: true,
    });
  }

  const disableResendFunc = () => {
    setDisableResend(true);
  }

  const otpSentFunction = () => {
    setOtpSent({
      ...otpSent,
      open: true,
    });
  }

  const handlePasscodeLogin = async (e: any) => {
    e.preventDefault();
    setenableLogin(false);
    setDisableResend(true);
    setDisableCancel(true);
    setEnableLoader(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let mobile:string = "false";
    let orgId:string = selectedDist.refSchoolId;
    //let loginType:string = "";
    // if (resend){
    //   setPasscode('');
    // }
    await userAuthCtx
            .loginFlow(username,password,mobile,source,selectedDist.ClusterUrl,selectedDist.id,
              selectedDist.DistrictDomainURL,orgId,passcode,personId,resend,reset,
            )
            .then((response: any) => {
              if (response.message === "OTP Incorrect-401"){
                setPasscodeIncorrect(true);
                setenableLogin(true);
                setDisableResend(false);
                setDisableCancel(false);
                setEnableLoader(false);
                setResendAttempts(response.resendAttempts);
                if (response.resendAttempts === 0){
                  setPasscodeIncorrect(false);
                  setOtpExpired({
                    ...otpExpired,
                    open: true,
                  });
                }
              }
            })
  };

  // const resendOnClick = () => {
  //   setResend(true);
  //   otherRequests();
  // }

  // function to execute after clicking cancel button in passcode page
  // const returnToLogin = () => {
  //   setReset(true);
  //   otherRequests();
    // setEnablePasscode(false);
    // setenableLogin(true);
    // setUsername('');
    // setPassword('');
    // setPasscode('');
    // setPersonId('');
    // setReset(false);
  //   window.location.reload(false);
  // }

  const resendOnClick = async (optExpiredResend:boolean = false) => {
    setDisableResend(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let mobile:string = "false";
    let orgId:string = selectedDist.refSchoolId;
    let resend:boolean = true;

    await userAuthCtx
      .loginFlow(username,password,mobile,source,selectedDist.ClusterUrl,selectedDist.id,
        selectedDist.DistrictDomainURL,orgId,passcode,personId,resend,reset,
      )
      .then((response: any) => {
        setDisableResend(false);
        setResend(false);
        //show one-time password sent popup
        otpSentFunction();

        if (optExpiredResend){
          setPasscodeTimer(true);
          setPasscodeIncorrect(false);
          setTimerKey(timerKey + 1);
          setEnableLoader(false);
          setDisableCancel(false);
          setDisableResend(true);
          setTimeout(
            () => setDisableResend(false),
            30000
          );
        }
      })
  }

  const returnToLogin = async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let mobile:string = "false";
    let orgId:string = selectedDist.refSchoolId;

    let reset:boolean = true;
    await userAuthCtx
      .loginFlow(username,password,mobile,source,selectedDist.ClusterUrl,selectedDist.id,
        selectedDist.DistrictDomainURL,orgId,passcode,personId,resend,reset,
      )
      .then((response: any) => {
        // Redirect to login page
        window.location.reload(false);
      })
  }

  const confirmCancelAlert = () => {
    returnToLogin();
    // setConfirmation({
    //   ...confirmation,
    //   open: false
    // });
    setIsCancelOtp(true);
  }

  const closeCancelAlert = () => {
    setConfirmation({
      ...confirmation,
      open: false
    });
  }
  // functions for Passcode functionality end

  return (
    <div className={classes.wrapper}>
      {unauthorized ? (
        <NotificationBox
          message={errorMsg}
          severity={'error'}
          handleNotification={() => {
            setUnauthorized(false);
          }}
        />
      ) : (
        ''
      )}
      {!enablePasscode ?
        <form onSubmit={handleLogin} className={classes.root}>
          <div className={classes.headerName}>Sign In</div>
          <InputLabel id='districtsId'>
            School District
          </InputLabel>

          <AutoComplete
            mode='data'
            id='districts'
            data-testid='districts'
            isFreeForm={false}
            value={selectedDist}
            dataFormatter={(data: any) => {
              return data;
            }}
            disabled={ withPassword ? true : false }
            placeholder='Type district'
            noOptionsText='No districts'
            optionsArray={distList}
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            getOptionSelected={(option, value) => option.Id === value.Id}
            onChange={onDistrictSelect}
            getOptionLabel={(item) => {
              return item.name;
            }}
            expanded={expanded}
            setExpanded={setExpanded}
            onChangeInput={handleSearchChange}
            onOpen={() => {}}
            filterOptions={filterOptions}
          />
          <InputLabel className={classes.topBottomSpacing}>
          {withPassword ? "Username":"Username, Email or Phone"}
          </InputLabel>
          <TextField
            disabled={!enableLogin}
            data-testid='username'
            id='username'
            type='text'
            value={username}
            onChange={handleUsernameOnchange}
            autoFocus={true}
            className={classes.formElements}
            error={errorUsername.length === 0 ? false : true}
            helperText={errorUsername}
            InputProps={{ disableUnderline: true }}
          />
          {withPassword ? (
            <>
            <InputLabel className={classes.topBottomSpacing}>
              Password
            </InputLabel>
            <TextField
              disabled={!enableLogin}
              data-testid='password'
              id='password'
              type='password'
              value={password}
              onChange={handlePasswordOnchange}
              className={classes.formElements}
              error={errorPassword.length === 0 ? false : true}
              helperText={errorPassword}
              InputProps={{ disableUnderline: true }}
            />
            <Button
              disabled={!enableLogin}
              type='submit'
              data-testid='submit'
              variant='contained'
              color='primary'
              className={classes.topSpacing}
              classes={{ disabled: classes.disabledButton }}
            >
              Sign In
            </Button>
            <Link className={classes.cancelButton} href="/Login">Cancel</Link>
            </>
          ):(
            <>
            <div className={classes.greyText}></div>
            <Button
              disabled={!enableNext}
              type='submit'
              data-testid='submit'
              variant='contained'
              color='primary'
              className={classes.formElements}
              classes={{ disabled: classes.disabledButton }}
            >
              Next
            </Button>
            </>
          )}
          {enableLoader ?
            <div className={classes.progressDivCenter}>
              <CustomCircularProgress
              css={classes.loginProgressBar}
              aria-label='Loading'
              size='2.5rem'
              />
            </div>
          :''}
          {
            //<Link href="#" className={forgotPasswordClass}>Forgot Password?</Link>
          }
        </form>
      :
        <>
        {/* Below form if for entering one-time password */}
        <form onSubmit={handlePasscodeLogin} className={classes.root2}>
          <div className={classes.headerName}>Enter your one-time password</div>
          <div>We have sent it to </div>
          <div className={classes.passcodeTo}>{username}</div>
          { passcodeTimer ?
          <div className={classes.passcodeTimer}>Expires in <Timer initialMinute={10} initialSeconds={0} hidePasscodeTimer={hidePasscodeTimer} otpTimerExpired={otpTimerExpired} disableResendFunc={disableResendFunc} key={timerKey}/></div>
          : ''}
          <PinInput
            length={6}
            initialValue=""
            onChange={(value, index) => {
              setPasscode(value);
              if (value.length < 6){setenableLogin(false)}
              }
            }
            type="custom"
            inputMode="number"
            style={{padding: '5px'}}
            inputStyle={{borderColor: '#262626', width: '40px', height: '40px', margin: '5px', borderRadius: '2px'}}
            inputFocusStyle={{borderColor: '#2073A1'}}
            onComplete={(value, index) => {setPasscode(value);setenableLogin(true);}}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          { passcodeIncorrect ?
          <div className={classes.passcodeTimer}>Incorrect password. {resendAttempts} attempts remaining</div>
          : ''}
          <div className={classes.resendBlock}>
            <div>Didn't receive? <Button className={disableResend? classes.resendDisabledStyle : classes.resendEnabledStyle} disabled={disableResend} component={Link} onClick={() => resendOnClick(false)}>Resend one-time password</Button></div>
          </div>
          <Button
              disabled={!enableLogin}
              type='submit'
              data-testid='submit'
              variant='contained'
              color='primary'
              className={classes.PasscodeButton}
              classes={{ disabled: classes.disabledButton }}
            >
            Sign In
          </Button>
          <Button disabled={disableCancel} component={Link} className={classes.cancelButton} onClick={cancelOnClick}>Cancel</Button>
          {enableLoader ?
            <div className={classes.progressDivCenter}>
              <CustomCircularProgress
              css={classes.loginProgressBar}
              aria-label='Loading'
              size='2.5rem'
              />
            </div>
          :''}
        </form>
        {/* Below is alert box for cancel button */}
        <CancelAlertDialog
            openCancelAlert={confirmation}
            closeCancelAlert={closeCancelAlert}
            confirmCancelAlert={confirmCancelAlert}
            isCancelOtp={isCancelOtp}
          />
        {/* Below is alert box for password expired */}
        <ActionableAlertBox
          data-testid='otpExpiredBox'
          title={otpExpired.title}
          shouldOpen={otpExpired.open}
          onAction={() => {
            resendOnClick(true);
            setOtpExpired({
              ...otpExpired,
              open: false
            });
          }}
          secondaryButtonLabel='Cancel'
          primaryButtonLabel='Resend'
          styles={customdDialogStyle}
          primaryButtonProps={{}}
          onClose={() => {
            setOtpExpired({
              ...otpExpired,
              open: false
            });
            window.location.reload(false);
          }}
        >
          <Box paddingBottom='74px'>{otpExpired.message}</Box>
        </ActionableAlertBox>
        {/* Below is alert box for one-time password sent */}
        <ActionableAlertBox
          data-testid='otpsent'
          title={otpSent.title}
          shouldOpen={otpSent.open}
          onAction={() => {
            setOtpSent({
              ...otpSent,
              open: false,
            });
          }}
          secondaryButtonLabel='Cancel'
          secondaryButtonProps={{style: {display: 'none'}}}
          primaryButtonLabel='Close'
          styles={customdDialogStyle}
          primaryButtonProps={{}}
          onClose={() => {
            setOtpSent({
              ...otpSent,
              open: false
            });
          }}
        >
          <Box paddingBottom='74px'>{otpSent.message}</Box>
        </ActionableAlertBox>
        </>
      }
      {
        //props.orgName === 'UnknownOrganization' ? '' : <LoginFooter orgName={props.orgName} />
      }
      {appVersion && (
        <div className={classes.versionTag}>Version: {appVersion}</div>
      )}
    </div>
  );
};

export default LoginComponent;