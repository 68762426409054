import React, { useState} from 'react';
import {GrDocumentText, GrImage } from 'react-icons/gr';
import Spinner from '../../Icons/SpinnerIcon';
import { HiOutlineVideoCamera} from 'react-icons/hi';
import {AiOutlineAudio} from 'react-icons/ai';
import ComponentSlider from "@kapost/react-component-slider";
import "./styles.css";
import {
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  RemoveFileIcon,
  ImageContainer,
  FileContainer,
  ApplicationContainer,
  FilesSizeTag ,
  FileNameTag,
  StyledReactTooltip
} from './FileUploadStyle';
import ImagePreviewModal from '../ImagePreviewModal/ImagePreviewModal';
import { imageFilesExtensions, unsupportedImgExtensions } from '../AttachmentDisplayComponent/FileExtensions';

const FileUpload = (props: any) => {
    const [showImagePreviewModal, setShowImagePreviewModal] = useState<any>(null)
    const {files,removeImg,validateFileLoading,isFileUpload,showRemoveIcon} = props;
    const KILO_BYTES_PER_BYTE = 1024;
    const convertBytesToKB: any = (bytes: any) => (bytes / KILO_BYTES_PER_BYTE).toFixed(2);

    function getBase64(file: any) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
    {files.map((file:any)=>{getBase64(file).then(data => file['updated_url'] = data);})}

    const removeIconFun = (file: any, isPdfFile: boolean=false) => {
        return(
            <>
                {!showRemoveIcon && (
                    <RemoveFileIcon
                        data-tip data-for="registerTip"
                        isIconStyle={isPdfFile}
                        id={file["id"]}
                        className="fa fa-times"
                        style={{fontSize:"12px", textAlign:"center", paddingTop:"3px"}}
                        onClick={(e: any)=>removeImg(e)}
                    >
                        <StyledReactTooltip id="registerTip" place="top" effect="solid">
                            Remove File
                        </StyledReactTooltip>
                    </RemoveFileIcon>
                )}
          </>
        )
    };
    const openImagePreviewModal = (file:any) => {
        setShowImagePreviewModal(file)
    }
    const closeModal = () => {
        setShowImagePreviewModal(null)
    }
    const renderLeftArrow = () => <i className="arrow arrow-prev" />;
    const renderRightArrow = () => <i className="arrow arrow-next" />;
    // window.console.log = () => {};
    const previewList=()=>{
        return(
            <PreviewList>
                {files.map((file: any) => {
                let [fileType, extention] = file.type.split("/")
                let isImageFile = fileType === "image" && imageFilesExtensions.includes(extention);
                let isPdfFile = fileType === "application" || unsupportedImgExtensions.includes(extention);
                let isVideoFile = fileType === "video";
                let isAudioFile = fileType === "audio";
                return (
                    <PreviewContainer key={file.id} style= {{paddingTop:"10px"}}>
                        
                        {isImageFile && (
                            <ImageContainer isLoaderStyle={validateFileLoading.includes(file.id)}>
                                {(validateFileLoading.includes(file.id)) ? (
                                <>
                                    <ImagePreview
                                        onClick={(e:any) => openImagePreviewModal(file)}
                                        src={file.updated_url ? file.updated_url : URL.createObjectURL(file)}
                                        alt={`file preview ${file.name.slice(0,13)+"... "}`}
                                    />
                                    {removeIconFun(file)}
                                </>
                                ) :  <Spinner/> }
                            </ImageContainer>
                        )}
                        {(isPdfFile  || (!isImageFile && !isVideoFile && !isAudioFile)) && (
                            <FileContainer isLoaderStyle={validateFileLoading.includes(file.id)}>
                                {(validateFileLoading.includes(file.id)) ? (
                                <>
                                    {fileType !== "image" ? <GrDocumentText style={{fontSize:"32px"}}/> : <GrImage data-testid='GrImage'  style={{fontSize:"30px"}}/>}
                                    <ApplicationContainer>
                                        {file.name.length <= 13 ? <FileNameTag>{file.name}</FileNameTag> : <FileNameTag data-tip data-for={file.id}>{file.name.slice(0,13)+"... "}</FileNameTag>}
                                        <StyledReactTooltip  id={file.id} place="top" effect="solid">
                                            {file.name}
                                        </StyledReactTooltip>
                                        <FilesSizeTag >{convertBytesToKB(file.size)} kb</FilesSizeTag >
                                    </ApplicationContainer>
                                    {removeIconFun(file,true)} 
                                </>
                                ) : <Spinner/> }
                            </FileContainer>
                        )}
                        {isVideoFile && (
                            <FileContainer isLoaderStyle={validateFileLoading.includes(file.id)}>
                                {(validateFileLoading.includes(file.id)) ? (
                                <>
                                    <HiOutlineVideoCamera style={{fontSize:"35px"}}/>
                                    <ApplicationContainer>
                                        {file.name.length <= 13 ? <FileNameTag>{file.name}</FileNameTag> : <FileNameTag data-tip data-for={file.id}>{file.name.slice(0,13)+"... "}</FileNameTag>}
                                        <StyledReactTooltip  id={file.id} place="top" effect="solid">
                                            {file.name}
                                        </StyledReactTooltip>
                                        <FilesSizeTag >{convertBytesToKB(file.size)} kb</FilesSizeTag >
                                    </ApplicationContainer>
                                    {removeIconFun(file,true)} 
                                </>
                                ) : <Spinner/> }
                            </FileContainer>
                        )}
                        {isAudioFile && (
                            <FileContainer isLoaderStyle={validateFileLoading.includes(file.id)}>
                                {(validateFileLoading.includes(file.id)) ? (
                                <>
                                    <AiOutlineAudio style={{fontSize:"37px"}}/>
                                    <ApplicationContainer>
                                        {file.name.length <= 13 ? <FileNameTag>{file.name}</FileNameTag> : <FileNameTag data-tip data-for={file.id}>{file.name.slice(0,13)+"... "}</FileNameTag>}
                                        <StyledReactTooltip  id={file.id} place="top" effect="solid">
                                            {file.name}
                                        </StyledReactTooltip>
                                        <FilesSizeTag >{convertBytesToKB(file.size)} kb</FilesSizeTag >
                                    </ApplicationContainer>
                                    {removeIconFun(file,true)} 
                                </>
                                ) : <Spinner/> }
                            </FileContainer>
                        )}
                    
                </PreviewContainer>
                
                );
            })}
            </PreviewList> 
        )
    }
    return(
    <div  data-testid='filesRender'> 
        {showImagePreviewModal !== null && <ImagePreviewModal selectedImage={showImagePreviewModal} closeModal={closeModal} /> }
        {isFileUpload &&( 
        <FilePreviewContainer renderCheck={isFileUpload} filesCheck={files.length}>
            {isFileUpload && files.length!==0 &&(
            <div  className='menu-bar'>
                {window.innerWidth>=600 ?   
                    <ComponentSlider  
                        renderLeftArrow={renderLeftArrow}
                        renderRightArrow={renderRightArrow}
                    >   
                        {previewList()}
                    
                    </ComponentSlider>
                    :
                    <>
                        {previewList()}
                    </>
                }
              
            </div>
            )}
        </FilePreviewContainer>
        )}
       
    </div>
    )
}

export default FileUpload;