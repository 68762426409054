import { makeStyles, fade } from '@material-ui/core/styles';
import { FullscreenExitTwoTone } from '@material-ui/icons';

const ProfileComponentStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  wrapperWithSidebar: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  wrapperWithoutSidebar: {
    marginLeft: '-80px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1
  },
  mainContainer: {
    overflowY: 'scroll',
    height: 'calc(100vh - 110px)',
  },
  withSidebar: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 80px)',
    },
  },
  withOutSidebar: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100vw',
    },
  },
  toggleButton: {
    width: '35px',
    height: '60px',
    backgroundColor: '#FFF',
    alignContent: 'center',
    padding: '2px 2px 2px 2px',
    borderWidth: '1px',
    borderColor: '#e0e0e0',
    borderStyle: 'solid',
    outline: 'none',
    borderBottom: '1px',
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerNameDiv: {
    display: 'flex',
    flex: '1',
    alignContent: 'space-between',
  },
  headerInnerWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '18px',
    paddingLeft: '20px',
    minWidth: '200px',
  },
  profilePicWrapper: {
    width: '100%',
    height: '300px',
    overflow: 'hidden',
    marginBottom: '10px',
    objectFit: 'cover',
  },
  profilePic: {
    position: 'relative',
    maxWidth: '370px',
    width: '100%',
    height: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  removePicLink: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px',
    maxWidth: '370px',
    cursor: 'pointer',
  },
  uploadErrorWrapper: {
    display: 'flex',
    color: 'red',
    maxWidth: '370px',
    height: '300px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 30px',
    border: '1px solid #333',
    fontWeight: 'bold',
  },
  uploadError: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '16px',
  },
  uploadErrorIcon: {
    height: '20px',
    marginRight: '5px',
  },
  button: {
    color: '#000',
    width: 'fit-content',
    minWidth: '150px',
    border: '1px solid #666666',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      color: '#fff',
    },
  },
  saveBtn: {
    color: '#fff',
    width: 'fit-content',
    minWidth: '100px',
    border: '1px solid #666666',
    backgroundColor: '#000 !important',
    margin: '5px',
    // '&:hover': {
    //   color: '#fff'
    // }
  },
  cancelBtn: {
    color: '#000',
    width: 'fit-content',
    minWidth: '100px',
    backgroundColor: '#CDCDCD !important',
    margin: '5px',
    // '&:hover': {
    //   color: '#fff'
    // }
  },
  uploadBtn: {
    color: '#000',
    width: '100%',
    maxWidth: '370px',
    border: '1px solid #666666',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      color: '#fff',
    },
  },
  buttonHover: {
    '&:hover': {
      color: '#262626',
      backgroundColor: '#FFFFFF',
      border: '2px solid #666666',
    },
  },
  focused: {
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
      borderColor: '#262626',
    },
  },
  contactInformationContainer: {},
  formControl: {
    display: 'flex',
  },
  label: {
    fontSize: '14px',
  },
}));

export default ProfileComponentStyle;
