import React from 'react';
import { Box } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import BadgeAvatar from '../BadgeAvatar';
import MessagePanelStyles from '../MessagesPanel/MessagePanelStyle';
import { makeStyles } from '@material-ui/core/styles';
import UserCardTypes from './UserCardTypes';

const UserCardStyles = makeStyles({
  cardContentRoot: {
    border: '1px solid #cdcdcd',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  senderTitle: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    marginLeft: 0,
    alignSelf: 'center',
  },
  userId: {
    fontSize: '10px',
    fontStyle: 'italic',
    color: 'grey',
    marginRight: '0px',
    textTransform: 'capitalize',
  },
  button: {
    backgroundColor: '#FFFFFF',
    border: '2px solid #666666',
    color: '#262626',
    fontWeight: 400,
    fontSize: '14px',
    width: '-webkit-fill-available',
    alignSelf: 'flex-end',
    top: 'calc(50% - 10px)',
  },
  buttonHover: {
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#262626',
    },
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  grid: {
    marginTop: '10px',
  },
});

const UserCard = ({
  organizationName,
  personId,
  senderName,
  senderRole,
  highlightTokens = [],
  jumperAction = () => {},
}: UserCardTypes) => {
  let classes = MessagePanelStyles()();
  let cardStyles = UserCardStyles();

  return (
    <CardContent className={cardStyles.cardContentRoot}>
      <Grid container className={cardStyles.gridContainer}>
        <Grid item xs={12} sm={12} md={8}>
          <div className={classes.liMessage}>
            <div className={cardStyles.cardTitle}>
              <span>{organizationName}</span>
            </div>

            <Box display='flex' flexDirection='row' paddingTop='12px'>
              <Box component='div' display='flex'>
                <BadgeAvatar size={classes.medium} name={senderName} />
              </Box>

              <Box display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='row'>
                  <div className={classes.group} role='button'>
                    <div className={classes.profileName}>{senderName}</div>
                    <div className={classes.profileRole}>
                      {senderRole.toLowerCase()}
                    </div>
                  </div>
                </Box>
                <Box component='div' className={cardStyles.userId}>
                  {'ID: ' + personId}
                </Box>
              </Box>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={cardStyles.grid}>
          <Box display='flex' flexDirection='column'>
            <Button
              type='button'
              className={cardStyles.button}
              classes={{ root: cardStyles.buttonHover }}
              onClick={jumperAction}
            >
              Views users messages
            </Button>
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default UserCard;
