import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { API } from '../properties/apiProperties';
import { get_cluster } from '../components/clusterGlobal';

export const getDirectContactList = async (
  personId: string,
  domain: string,
  domainId: string,
  impersonatedUserId: string
  
) => {
  var result = null;
  var queryParams = new URLSearchParams();
  if (impersonatedUserId !== ''){
    queryParams.append('impersonatedUserId', impersonatedUserId);
  }
  const options: AxiosRequestConfig = {
    url: API.directMessageContact,
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    params: queryParams,
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log('cancelled');
    }
    console.log(e.message);
  }
  return result;
};

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});
