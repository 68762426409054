import React, { useEffect, useState, useContext } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { GroupEssential } from '../../Types/GroupEssential';
import { GroupUnread } from '../../Types/GroupUnread';
import { UserAuthCtx } from '../AuthComponent';
import ImpersonatingPanelTypes from './ImpersonatingPanelTypes';
import ImpersonatingPanelDrawer from '../ImpersonatingPanelDrawer/ImpersonatingPanelDrawer';
import ConversationPanel from '../ConversionPanel';
import { StateContext } from '../StateContextComponent';
import { DirectMessageContact } from '../../Types/DirectMessageContact';
import AppStyle from '../../styles/appStyle';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: '#181818',
      color: 'white',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      '&:hover': {
        color: 'white',
      },
    },
  });



const mainStyles = makeStyles((theme: any) => {
  return createStyles({
    paper: { maxWidth: '97vw', height: '88vh', top: '25px' },
    mobilePaper: { maxWidth: '100vw', height: '92vh', top: '2vh' },
    dialogueContent: {
      padding: '0px',
      display: 'flex',
    },
  });
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h3'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
    height: '100%'
  },
}))(MuiDialogContent);

export default function ImpersonatingPanel({
  open,
  handleClose,
  username,
  selectedGroup,
  setGroup,
  isParentDrawerOpen,
  setParentDrawerOpen
}: ImpersonatingPanelTypes) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const showConvPanelForMobile = isMobile ? !isParentDrawerOpen : true;
  
  //dummy
  const [
    directChannelIDFromMessageQueue,
    setDirectChannelIDFromMessageQueue,
  ] = useState(new DirectMessageContact());
  const [groupUnread, setGroupUnread] = useState<GroupUnread>(
    new GroupUnread()
  );
  const authContext = useContext(UserAuthCtx);
  const { impersonatingUserDetails } = useContext(StateContext);
  const [selectMyGroup, setSelectMyGroup] = useState('');
  const [directUnread, setDirectUnread] = useState<GroupUnread>(
    new GroupUnread()
  );

  function localHandleClose() {
    setGroup(new GroupEssential());
    handleClose();
  }

  const [messagePanelLoading, setMessagePanelLoading] = useState(false)
  const [conversationTitle, setConversationTitle] = useState(true)

  const { userLoggedInDomain: domain, userRole } = authContext.userCtx;

  //dummy
  const ImpersonatingStyle = mainStyles();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const classes2 = AppStyle()({});

  return (
    <div>
      <Dialog
        onClose={localHandleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        classes={{
          paper: isMobile
            ? ImpersonatingStyle.mobilePaper
            : ImpersonatingStyle.paper,
        }}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          Viewing as {username}
        </DialogTitle>
        <DialogContent dividers className={ImpersonatingStyle.dialogueContent}>
          {open && (
            <>
              <ImpersonatingPanelDrawer
                open={isParentDrawerOpen}
                bbDomain={domain || ''}
                setGroup={setGroup}
                groupUnread={groupUnread}
                directUnread={directUnread}
                selectedGroup={selectedGroup}
                selectMyGroup={selectMyGroup}
                setSelectMyGroup={setSelectMyGroup}
                setParentDrawerOpen={setParentDrawerOpen}                
                setMessagePanelLoading={setMessagePanelLoading}
                setConversationTitle={setConversationTitle}
                directChannelIDFromMessageQueue={
                  directChannelIDFromMessageQueue
                }
              />

              {showConvPanelForMobile && (
                <div
                  className={clsx({
                    [classes2.mobileViewImpersonate]:isMobile,
                    [classes2.shiftFullSideBarImpersonate]:!isMobile && !isParentDrawerOpen,
                    [classes2.showFullSideBarImpersonate]:!isMobile && isParentDrawerOpen,
                  })}
                >
                  <ConversationPanel
                    selectedGroup={selectedGroup}
                    username={username}
                    selectedOrganizationId={impersonatingUserDetails.schoolId}
                    bbdomain={domain}
                    personid={impersonatingUserDetails.userId}
                    userRole={userRole}
                    setGroupUnread={setGroupUnread}
                    setDirectUnread={setDirectUnread}
                    setGroup={setGroup}
                    isParenDrawerOpen={isParentDrawerOpen}
                    setParentDrawerOpen={setParentDrawerOpen}
                    setSelectMyGroup={setSelectMyGroup}
                    setDirectChannelIDFromMessageQueue={
                      setDirectChannelIDFromMessageQueue
                    }
                    impersonating={true}
                    isMessagePanelLoading={messagePanelLoading}
                    setMessagePanelLoading={setMessagePanelLoading}
                    conversationTitle={conversationTitle}
                    setConversationTitle={setConversationTitle}
                  />
                </div>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
