import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import AuthContextProvider from './components/AuthComponent';
import App from './app';
import EmailSubscription from './components/EmailSubscription/EmailSubscription';
import EmailUnsubscription from './components/EmailUnsubscription/EmailUnsubscription';
import DataProviderCtx from './dataLoaders/DataHandler';
import StateContextProvider from './components/StateContextComponent';
import { BrowserRouter as Router } from 'react-router-dom';
import JssThemeProvider from './components/ReusableComponents/JssThemeProvider/JssThemeProvider';
const rootElement = document.getElementById('root');
const pathName = window.location.pathname.toLowerCase();

const renderSwitch = () => {
  switch(pathName) {
    case '/public/profile/email/verify':
      return <EmailSubscription />;
    case '/public/profile/email/unsubscribe':
      return <EmailUnsubscription />;
    default:
      return <></>;
  }
}

function AppLoader() {
  let isRoutePublic = pathName.startsWith('/public');

  return (
    isRoutePublic ? (
      <JssThemeProvider theme='light'>
        { renderSwitch() }
      </JssThemeProvider>
    ) : (
      <JssThemeProvider theme='light'>
        <AuthContextProvider>
          <DataProviderCtx>
            <StateContextProvider>
              <Router>
                <App />
              </Router>
            </StateContextProvider>
          </DataProviderCtx>
        </AuthContextProvider>
      </JssThemeProvider>
    )
  );
}

ReactDOM.render(
  AppLoader(),
  rootElement
);
