import React from 'react';
import ReadRateComponentStyle from './ReadRateComponentStyle';

const ColorCode = (rrange:any) => {
    const classes = ReadRateComponentStyle();

    if (Number(rrange.range) >= 90){
        return <span className={classes.green}></span>
    }else if (Number(rrange.range) >= 80){
        return <span className={classes.lightgreen}></span>
    }else if (Number(rrange.range) >= 70){
        return <span className={classes.yellow}></span>
    }else if (Number(rrange.range) >= 60){
        return <span className={classes.orange}></span>
    }else {
        return <span className={classes.red}> </span>
    }
}

const ReadRate = (rrate:any) => {
    return <>
        <ColorCode range={rrate.readrate}/>
        <span> {rrate.readrate}</span>
        <span>%</span>
        </>;
}

const ReadRateComponent = (props: any) => {
    return (
        <div>
          {
              (props.isLoading && !props.readRateValue && props.readRateValue!=0) ? (
                <span>Loading...</span>
              ) : (
                props.readRateValue || props.readRateValue===0 ? (
                  <ReadRate readrate={props.readRateValue} />
                ) : (
                    <span>-</span>
                )
              )
          }
        </div>
    );
};

export default ReadRateComponent;