import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  &:focus {
    outline: none;
  }
`;
interface RenderProp {
  renderCheck:boolean;
  filesCheck:number;
}
export const FilePreviewContainer = styled.article<RenderProp>`
  margin:0px;
  border-left-width:thin;
  border-right-width:thin;
  display:${(props=> (props.renderCheck && props.filesCheck === 0? "none":"pass"))};
`;
export const PreviewList = styled.section`
  display: flex;
  flex-direction:row;
  @media only screen and (max-width: 600px) {
    margin-bottom:5px;
  }
  overflow:scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-left:15px;
`;
interface LoadProps {
  isLoaderStyle:boolean;
}
export const FileContainer=styled.div <LoadProps>`
padding-top:${(props=> (props.isLoaderStyle ? "opx" : "15px"))};
padding-left:${(props=> (props.isLoaderStyle ? "10px" : "100px"))};
display:${(props=> (props.isLoaderStyle ? "flex" : "block"))};
&:hover {
  background:${(props=> (props.isLoaderStyle ? "#F3F3F3" : "white"))};
}
position: relative;
width: 224px;
height: 56px;
border: 1px solid grey;
justify-content:start;
flex-direction:row;
align-items: center;
margin-right: 15px;
border-radius: 8px;
`;
export const FilesSizeTag = styled.span`
@media only screen and (max-width: 600px) {
  display:block;
}
display:none;
${FileContainer}:hover & {
  display:block;
}
font-weight: 700;
font-size: 12px;
color: #8C8C8C;
`;
export const ImageContainer = styled.div <LoadProps>`
padding-top:${(props=> (props.isLoaderStyle ? "opx" : "15px"))};
padding-left:${(props=> (props.isLoaderStyle ? "opx" : "15px"))};
border-radius: 8px;
width: 56px;
padding-bottom:10px;
height: 56px;
border: ${(props=> (props.isLoaderStyle ? "opx" : '1px solid gray'))};
position: relative;
display: inline-block;
margin-right: 15px;
`;
export const RemoveFileIcon = styled.i <IconProps>`
@media only screen and (max-width: 600px) {
  display:block;
}
display:none;
${FileContainer}:hover & {
  display:block;
}
${ImageContainer}:hover & {
  display:block;
}
cursor:pointer;
margin:0;
background-color: white;
justify-content: center;
align-items: center;
height: 20px;
width: 20px;
border-radius: 16px;
border: 1px solid grey;
position: absolute;
left:${(props) => (props.isIconStyle ? "93%" : "73%")};
top:${(props) => (props.isIconStyle ? "-12%" : "-8%")};
`;

export const PreviewContainer = styled.section`

`;
export const ImagePreview = styled.img`
border: 1px solid gray;
border-radius:8px;
height: 56px;
width: 56px;
cursor: pointer;
`;
interface IconProps {
    isIconStyle: boolean;
}
export const ApplicationContainer = styled.div`
margin-left:13px;
display:flex;
flex:start;
flex-direction:column;
`;
export const FileNameTag = styled.span`
padding-top:4px;
${FileContainer}:hover & {
  padding-top:13px;
}
font-style: inherit;
font-weight: 700;
font-size: 14px;
color: #000000;
`;
export const StyledReactTooltip = styled(ReactTooltip)`
display:flex;
font-style: inherit;
font-weight: 500;
font-size: 14px !important;
color: #FFFFFF;
`;


