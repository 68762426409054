import React from 'react';
import {
    Checkbox,
    withStyles,
    CheckboxProps,
} from '@material-ui/core';

const CustomCheckbox = withStyles({
    root: {
      color: '#CDCDCD',
      '&$checked': {
        color: '#007D2C',
      },
      minWidth: '20px',
      marginLeft: 0,
      paddingLeft: 0,
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

export default CustomCheckbox;