import React, { useState } from 'react';
import { SvgIcon } from '@material-ui/core';

export const AddWithCircleIcon = (props: any) => {
  const [strokeColour, setStrokeColour] = useState('#262626');

  return (
    <SvgIcon {...props}>
      <svg
        width={props.iconWidth?props.iconWidth:"16"}
        height={props.iconHeight?props.iconHeight:"16"}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        onMouseEnter={() => setStrokeColour('#000000')}
        onMouseLeave={() => setStrokeColour('#262626')}
      >
        <path
          d='M1 8C1 4.13401 4.13401 1 8 1V1C11.866 1 15 4.13401 15 8V8C15 11.866 11.866 15 8 15V15C4.13401 15 1 11.866 1 8V8Z'
          stroke={strokeColour}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 8H5'
          stroke={strokeColour}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 5V11'
          stroke={strokeColour}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

export default AddWithCircleIcon;
