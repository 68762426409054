import React, { useContext, useEffect, useState, useRef } from 'react'; 
import ModalStyle from './AddMembersComponentStyle'
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { CloseButtonIcon } from '../../Icons/CloseButtonIcon';
import { getAddChannelMembersSearch } from '../../services/userAccountServices';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import MembersRelation from '../MembersRelation/MembersRelation';
import { Checkbox, Box, CircularProgress, Button } from '@material-ui/core';

import { addMemberApi } from '../../services/ChannelService';
import { Data } from '../../Types/MembersData';
import { SaveLoader } from '../Loader/Loader';


function AddMembersComponent(props:any) {
  const {closeModal,channelId,channelName, bbdomain, selectedOrganizationId, loadMembers, setIsMemberError} = props
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState<Array<Data>>(new Array<Data>());
  const [recipients, setRecipients] = useState([]);
  const [isTableLoading, setTableLoading] = useState(false);
  const [Clearheader, setClearheader] = useState(false);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [addDisabled, setAddDisabled] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isLandscape = useMediaQuery({ query: '(orientation: landscape) and (max-height: 500px)' });
  let newMembersArray:Array<number> = [];
  
  const classes = ModalStyle();  
  const TableCell = withStyles({
    root: {
      borderBottom: !isMobile?"0.0625rem solid rgba(205, 205, 205, 0.5)":'none',
    }
  })(MuiTableCell);

  const HandleHideModal = (loading?:Boolean) => {
    if (typeof(loading) === "undefined" || loading === false) {
      closeModal()
    }
  }
  interface Column {
    id: 'name' | 'role' | 'relationship' | 'avatar' | 'checkbox';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
    width?:number; 
  }

  let columns: Column[] = [
    { id:  'checkbox',label:'', minWidth: 10,width:20},
    { id: 'avatar', label: '', minWidth: 50,width:60 },
    { id: 'name', label: 'Name', minWidth:100}, 
    { id: 'role', label: 'Role', minWidth: 120,width:100 },
    {
      id: 'relationship',
      label: 'Relationship',
      minWidth: 250,
    },
  ];
  const newColumns = isMobile ?columns.splice(3,2):''
  
  function createData(recipient: any): Data {
    const name = recipient.firstName +' '+ recipient.lastName;
    const role = recipient.roleName;
    const recipientId = recipient.personID;
    var relationship = [];
    var avatar= ''
    var checkbox=false
    for (var members of recipient.relationships)
      relationship.push(
        members.firstName +
          ' ' +
          members.lastName +
          ', <em>' +
          members.relationType.substr(0, 1).toUpperCase() +
          members.relationType.slice(1).toLowerCase() +
          '</em><br>'
      );

    return { name, role, avatar, checkbox, relationship, recipientId};
  }

  function genericUserFilter(userType: any, filterStr: string):any{
    if (filterStr.indexOf(' ') >= 0){
      if ((userType.firstName.toLowerCase()+' '+userType.lastName.toLowerCase()).includes(filterStr.toLowerCase())){
        return true;
      }
      if ((userType.lastName.toLowerCase()+' '+userType.firstName.toLowerCase()).includes(filterStr.toLowerCase())){
        return true;
      }
    }
    else {
      if (userType.lastName.toLowerCase().includes(filterStr.toLowerCase())){
        return true;
      }
      if (userType.firstName.toLowerCase().includes(filterStr.toLowerCase())){
        return true;
      }
    }
  }
  function createFilteredData(recipient: any, filterStr: string): any {
    var recipientName = genericUserFilter(recipient, filterStr);
    if (recipientName){
      return true
    }
    return false;
  }

  // Get members from the corresponding school based on search value
  const loadData = async (name: string) => {
    setTableLoading(true);
    var memberList: any = [];
    getAddChannelMembersSearch(name,bbdomain, selectedOrganizationId.schoolId.toString(), channelId)
        .then((result:any)=>{
          result?.data?.recipients?.map((recip: any, index: number) => {
            memberList.push(recip);
          });
          setRecipients(memberList);
          setTableLoading(false);
        })
        .catch((error:any)=>{
          setNoResultsFound(true);
        })
    
  };

  function filterData(value: string) {
    if (value === '') {
      setRows([]);
      return;
    }
    setRows(
      recipients
        .filter(function (recipient) {
          return createFilteredData(recipient, value);
        })
        .map((recipient, index) => {
          return createData(recipient);
        })
    );
  }

  useEffect(() => {
    filterData(search);
  }, [recipients]);

  useEffect(() => {
    if (search !== '' && search.length >= 2)
      setNoResultsFound(rows.length === 0);
    if (rows.length !== 0 || isTableLoading) setNoResultsFound(false);

    const filteredMembers = rows.filter(memberRow => memberRow.checkbox)
    if (filteredMembers.length > 0){
      setAddDisabled(false);
    } else {
      setAddDisabled(true);
    }
  }, [rows, isTableLoading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (
      event.target.value.length === 2 ||
      (event.target.value.length >= 2 && recipients.length === 0)
    ) {
      loadData(event.target.value);
      setClearheader(true);
    }
    if (event.target.value !== '' && event.target.value.length >= 2) {
      filterData(event.target.value);
    }
    if (
      (event.target.value !== '' && event.target.value.length === 1) ||
      event.target.value === ''
    ) {
      setRecipients([]);
      setRows(
        recipients.map((recipient, index) => {
          return createData(recipient);
        }) 
      );
      setClearheader(false);
    }
  }

  const handleMemberSelect = (event:any) => {
    let itemName = String(event.target.name);
    let checked = event.target.checked;
    if (itemName === "checkAll") {
      setAllChecked(checked);
      setRows(rows.map(item => ({ ...item, checkbox: checked })));
    } 
    else {
      setRows(rows.map(item =>
        String(item.recipientId) === itemName ? { ...item, checkbox: checked } : item
      ));
    }
  }

  const handleAddMember = () => {
    setLoader(true);
    // check rows state to fetch the selected checkbox (member id's)
    rows.map((data) => {
      if (data.checkbox) {
        newMembersArray.push(data.recipientId);
      }
    })
    // api call to add members
    addMemberApi(channelId, newMembersArray, bbdomain)
        .catch((error:any)=>{
          setIsMemberError({
            isError: false,
            isMembersTabError: true,
            severity: 'ownerPrivilegesErr',
            message: error.response.status === 401 ? 
            'Failed to add members to Channel. Please contact administrator' :
            'Failed to add members to Channel'
          })
        })
        .finally(()=>{
          closeModal()
          loadMembers()
        })
  }

  const renderTableCellData = (
    columnId: any,
    cellValue: any,
    name: string,
    rowData: any
  ) => {
    switch (columnId) {
      case 'name':
        return (
              <div>
                <div className={classes.userNameStyle}>{cellValue}</div>
                {isMobile ? (
                  <div className={classes.roleStyle}>
                    {rowData.role.substr(0, 1).toUpperCase() +
                      rowData.role.slice(1).toLowerCase()}
                  </div>
                ) : null}
                {isMobile  ? (
                  <>
                    <div className={classes.relativeHeadingStyle}>Relationship</div>
                    <div className={classes.relativeStyle}>
                      <MembersRelation relations={rowData.relationship} />
                    </div>
                  </>
                ) : null}
            </div>
        );
      case 'avatar':
        return ( 
          <Avatar className={classes.small}>
            <PersonIcon />
          </Avatar>
        );
      case 'checkbox':
      return ( 
              <Checkbox 
                key={rowData.recipientId}
                name={rowData.recipientId}
                className={classes.checkboxStyle} 
                color='default'  
                icon={<span className={classes.checkboxIcon}></span>}
                checkedIcon={<span className={classes.checkedIcon} />}
                onClick={handleMemberSelect}
                checked={rowData.checkbox}
                data-testid={rowData.recipientId}
              />
      );
      case 'role':
        return (
          <>
          {!isMobile ?
          <div>
            {cellValue.substr(0, 1).toUpperCase() +
              cellValue.slice(1).toLowerCase()}
          </div>:null
          } 
          </>
        );
      case 'relationship':
        return (
          <>
          {!isMobile ? 
                  <MembersRelation relations={cellValue} />
                  :null
          }
          </>
        )
  
      default:
        return <div className={classes.userNameStyle}>{cellValue}</div>;
    }
  };

  const cellRenderer = (
    cellValue: any,
    column: any,
    name: string,
    rowData: any
  ) => {
    return (
      <TableCell
        key={column.id}
        align={column.align}
        className={`${classes.cellStyle} ${column.id==='avatar' && classes.avatarCellStyle} ${column.id==='name' && classes.nameCellStyle} ${column.id==='checkbox' && classes.checkboxCellStyle}`} 
        style={{ width: column.width}}      
      >
        <div
          className={clsx(classes.name, {
            [classes.avatarColumn]: column.id === 'avatar',
          })}
          style={{ minWidth: column.cellMinWidth }} 
        >
          {renderTableCellData(column.id, cellValue, name, rowData)}
        </div>
      </TableCell>
    );
  };

  return (
    <div data-testid='addMembersLayout' className={ModalStyle().bgContainer}>
      <div className={ModalStyle().modalContainer}>
        {isLoading ? <SaveLoader /> : <></>}
        <div className={ModalStyle().headerContainer}>
          <p className={ModalStyle().channelStyle}>{channelName}</p>
          <p className={ModalStyle().title}>Add members</p>
          <div className={isLoading ? ModalStyle().closeIconStyleDisabled:
                                          ModalStyle().closeIconStyle}
                onClick={() => HandleHideModal(isLoading)}
                data-testid='closeIcon'>
            <CloseButtonIcon/>
          </div>
        </div>
        <div className={ModalStyle().imageContainer}>
          <TextField
            id='outlined-basic'
            placeholder='Search'          
            variant='outlined'
            className={ModalStyle().textbox}
            onChange={handleChange}
            value={search}
            InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
              ),
            }}
            data-testid='search-bar'
          />
          {Clearheader &&
          <Paper className={classes.paperDiv}>
            <TableContainer className={`${classes.container} ${classes.customScroll}`}>
              <Table stickyHeader aria-label='sticky table'>
                {!isMobile && !isLandscape && 
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={classes.tableHeadBorder}
                        >
                          {column.id=='checkbox' ?
                            <Checkbox 
                              name="checkAll"
                              data-testid="checkAll-box"
                              className={classes.checkboxStyle} 
                              color='default'  
                              icon={<span className={classes.checkboxIcon}></span>}
                              checkedIcon={<span className={classes.checkedIcon} />}
                              onClick={handleMemberSelect}
                              checked={allChecked}
                            />  
                          :
                          column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                }
                <TableBody>
                  {rows.map((row, index) => {
                  return (
                    <>
                    {isMobile && <br/>}
                    <TableRow
                      hover
                      classes={{ selected: classes.selected }}
                      className={classes.tableRow}
                      role='checkbox'
                      tabIndex={-1}
                      key={index}
                    >
                      {columns.map((column) =>
                        cellRenderer(
                          row[column.id],
                          column,
                          row.name,
                          row
                        )
                      )}
                    </TableRow>
                    </>
                  );
                })}
                </TableBody>
                
              </Table>
          </TableContainer>
          {isTableLoading && <LinearProgress className={classes.progress} />}
          </Paper>
          }
          {noResultsFound && search.length >= 2 && (
          <div className={classes.errorContainer}>No results found !</div>
        )}
        </div>
        <div className={ModalStyle().footerContainer}>
            <Button className={ModalStyle().CancelBtn} 
              onClick={() => HandleHideModal()} 
              data-testid='cancelBtn'
            >
              <span>Cancel</span>
            </Button>
            <Button className={ModalStyle().AddMemberBtn} 
              onClick={handleAddMember} 
              data-testid='addMmebersBtn'
              disabled={isLoading||addDisabled}
            >
              <span>Add members</span>
            </Button>
        </div>

      </div>
    </div>
  );
}

export default AddMembersComponent

