import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';

export const channelCreationApi = async (channelName: string, togglestatus: boolean, bbDomain: string) => {
    let result = null;
    const requestOptions: AxiosRequestConfig = {
        url: API.channelApi,
        method: 'POST',
        headers: {
            bbdomain: bbDomain,
            cluster: localStorage.getItem('cluster'),
            domainID: localStorage.getItem('domainID'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
        data: {
            channelName: channelName,
            reachOrgSchoolId: localStorage.getItem('reachOrgSchoolId'),
            togglestatus: togglestatus
        }
    };
    result = await axios(requestOptions);
    return result

};

export const channelEditApi = async (channelId: string, channelName: string, togglestatus: boolean, bbDomain: string) => {
    let result = null;
    const requestOptions: AxiosRequestConfig = {
        url: API.channelApi,
        method: 'PUT',
        headers: {
            bbdomain: bbDomain,
            cluster: localStorage.getItem('cluster'),
            domainID: localStorage.getItem('domainID'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
        data: {
            channelName: channelName,
            channelId: channelId,
            reachOrgSchoolId: localStorage.getItem('reachOrgSchoolId'),
            togglestatus: togglestatus,
        }
    };
    result = await axios(requestOptions);
    return result
};

export const setChannelDelete = async (
    channelid: string,
    Cluster: string | null,
    domain: string | null
) => {
    var result = null;
    const options: AxiosRequestConfig = {
        url: API.channelApi,
        method: 'DELETE',
        headers: {
            cluster: Cluster,
            bbdomain: domain,
            domainId: localStorage.getItem('domainID'),
            personid: localStorage.getItem('userId')
        },
        withCredentials: true,
        data: {
            channelId: channelid,
            reachOrgSchoolId: localStorage.getItem('reachOrgSchoolId'),
        }
    };
    result = await axios(options);
    return result;
};

export const addMemberApi = async (channelId: number, addMembersList: Array<number>, bbDomain: string) => {
    let result = null;
    const requestOptions: AxiosRequestConfig = {
        url: API.channelMemberApi,
        method: 'POST',
        headers: {
            bbdomain: bbDomain,
            cluster: localStorage.getItem('cluster'),
            domainID: localStorage.getItem('domainID'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
        data: {
            addMembersList: addMembersList,
            groupId: channelId
        }
    };
    result = await axios(requestOptions);
    return result
};

export const removeMemberApi = async (channelId: String, removeMembersList: Array<number>, bbDomain: string) => {
    let result = null;
    const requestOptions: AxiosRequestConfig = {
        url: API.channelMemberApi,
        method: 'DELETE',
        headers: {
            bbdomain: bbDomain,
            cluster: localStorage.getItem('cluster'),
            domainID: localStorage.getItem('domainID'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
        data: {
            memberList: removeMembersList,
            groupId: channelId
        }
    };
    result = await axios(requestOptions);
    return result
};

export const OwnerApi = async (channelId: number, memberId: string, userType: string) => {
    let result = null;
    const requestOptions: AxiosRequestConfig = {
        url: API.channelMemberApi,
        method: 'PUT',
        headers: {
            bbdomain: localStorage.getItem('bbDomain'),
            cluster: localStorage.getItem('cluster'),
            domainID: localStorage.getItem('domainID'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
        data: {
            groupId: channelId,
            userType: userType,
            memberId: String(memberId),
        }
    };
    result = await axios(requestOptions);
    return result
};


axios.interceptors.request.use(function(config) {
    config.headers.ACCEPT = 'application/json';
    return config;
});
