import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';
import { selectedChannel } from '../Types/channelItem';
import { get_cluster } from '../components/clusterGlobal';

export const getOrganizationList = async (domain: string) => {
    var result = null;
    const options: AxiosRequestConfig = {
        url: API.settingPage,
        method: 'GET',
        headers: {
            bbdomain: domain,
            domainId: localStorage.getItem('domainID'),
            personId: localStorage.getItem('userId'),
        },
        withCredentials: true,
    };
    try {
        result = await axios(options);
    } catch (e) {
        console.log(e.message);
    }
    return result;
};

export const getChannelsList = async (
    domain: string,
    personId: string,
    schoolId: string,
) => {

    var result = null;
    const options: AxiosRequestConfig = {
        url: API.channelSettings,
        method: 'GET',
        headers: {
            personid: personId,
            bbDomain: domain,
            domainId: localStorage.getItem('domainID'),
            cluster: get_cluster(),
        },
        withCredentials: true,
    };

    let queryParams = new URLSearchParams();
    queryParams.append('schoolId', schoolId);

    options.params = queryParams;

    try {
        result = await axios(options);
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

export const setReachAccess = async (
    domain: string,
    personId: string,
    schoolIdList: number[],
    enableReach: boolean,
    enableChannel: boolean
) => {
    var result = null;
    const options: AxiosRequestConfig = {
        url: API.settingPage,
        method: 'PUT',
        headers: {
            bbdomain: domain,
            personid: personId,
            domainId: localStorage.getItem('domainID'),
        },
        data: {
            organizationId: schoolIdList,
            reach: enableReach ? 1 : 0,
            enableChannel: enableChannel ? 1 : 0,
        },
        withCredentials: true,
    };
    try {
        result = await axios(options);
    } catch (e) {
        console.log(e.message);
    }
    return result;
};

axios.interceptors.request.use(function(config) {
    config.headers.ACCEPT = 'application/json';
    return config;
});

export const updateChannelAccess = async (
    domain: string,
    personId: string,
    selectedChannels: Array<selectedChannel>,
    enableChannel: boolean,
    domainId: string,
    schoolId: string
) => {
    var result = null;
    const options: AxiosRequestConfig = {
        url: API.channelSettings,
        method: 'PUT',
        headers: {
            bbdomain: domain,
            personid: personId,
            domainId: localStorage.getItem('domainID'),
            cluster: get_cluster(),
        },
        data: {
            selectedChannels: selectedChannels,
            domainId: domainId,
            enabled: enableChannel,
            schoolId: schoolId
        },
        withCredentials: true,
    };
    try {
        result = await axios(options);
    } catch (e) {
        console.log(e.message);
    }
    return result;
};

export const getChannelUsersCount = async (
    domain: string,
    personId: string,
    groupType: string,
    groupId: string
) => {
    var result = null;
    const options: AxiosRequestConfig = {
        url: API.channelUsersCountApi,
        method: 'GET',
        headers: {
            personid: personId,
            bbDomain: domain,
            domainId: localStorage.getItem('domainID'),
            cluster: get_cluster(),
            groupId: groupId,
        },
        withCredentials: true,
    };

    let queryParams = new URLSearchParams();
    queryParams.append('membercount', 'true');
    queryParams.append('channeltype', groupType);
    queryParams.append('groupid', groupId);

    options.params = queryParams;

    try {
        result = await axios(options);
    } catch (e) {
        console.log(e.message);
    }

    return result;
};

axios.interceptors.request.use(function(config) {
    config.headers.ACCEPT = 'application/json';
    return config;
});
