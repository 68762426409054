import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const Discussion = (props: any) => {
  return (
    <SvgIcon viewBox='0 0 16 16' {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15 8C15 11.866 11.866 15 8 15C7.10858 15 6.25608 14.8334 5.47194 14.5296L1 15L1.5 10.6031C1.17745 9.79841 1 8.91993 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z'
          stroke='#666666'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle cx='5' cy='8' r='0.5' stroke='#666666' />
        <circle cx='8' cy='8' r='0.5' stroke='#666666' />
        <circle cx='11' cy='8' r='0.5' stroke='#666666' />
      </svg>
    </SvgIcon>
  );
};

export default Discussion;
