export interface ProfileComponentType {
  StaffId: string;
  LoginId: string;
  FirstName: string;
  LastName: string;
  Role: string;
  Gender: string;
  Schools: string;
  Languages: any;
  PreferredLanguage: any;
  Phones: string;
  ProfilePicURL: any;
  Relationship: any[];
}

export const UserProfileDetails: ProfileComponentType = {
  StaffId: '',
  LoginId: '',
  FirstName: '',
  LastName: '',
  Role: '',
  Gender: '',
  Schools: '',
  Languages: {},
  PreferredLanguage: { name: 'English', code: 'en' },
  Phones: '',
  ProfilePicURL: './images/no-image.png',
  Relationship: [],
};

export interface commitedStateType {
  image: string;
  displayEmails: any[];
}
