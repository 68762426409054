import { makeStyles, createStyles } from '@material-ui/core/styles';

const AdminToolingStyle = makeStyles((theme: any) => {
  return createStyles({
    root: {
      flexGrow: 1,
    },
    wrapperWithSidebar: {
      marginLeft: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
      }),
    },
    wrapperWithoutSidebar: {
        marginLeft: '-80px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        flexGrow: 1
    },
    tabButton: {
      textTransform: 'none',
      flexShrink: 1,
      minHeight: '32px',
      padding: '0 12px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    wrapper: {
      minHeight: '32px',
      paddingLeft: '20px',
      '& [class^="MuiTabsscroller"]': {
        display: 'flex',
        '& [class^="PrivateTabIndicatorroot"]': {
          backgroundColor: '#FF5991',
        },
      },
    },
    tabBar: {
      display: 'flex',
      flexDirection: 'row',
    },
    tabButtons: {
      display: 'flex',
      fontSize: '16px',
      fontWeight: 600,
    },
    tabbarWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
    },
    groupNameDiv: {
      display: 'flex',
      flex: '1',
      alignContent: 'space-between',
    },
    groupName: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: '18px',
      paddingLeft: '20px',
      minWidth: '200px',
    },
    toggleButton: {
      width: '35px',
      height: '60px',
      backgroundColor: '#FFF',
      alignContent: 'center',
      padding: '2px 2px 2px 2px',
      borderWidth: '1px',
      borderColor: '#e0e0e0',
      borderStyle: 'solid',
      outline: 'none',
      borderBottom: '1px',
    },
  });
});

export default AdminToolingStyle;
