import React, { createContext, useState, useContext, useEffect } from 'react';
import { UserAuthCtx } from '../components/AuthComponent';
import { queueManager } from '../services/queueService';
import AWS from 'aws-sdk';

//const variables
const QueueNotFoundException: string =
  'AWS.SimpleQueueService.NonExistentQueue';
const QueueContext = createContext({ queueUrl, readMessage, readImage });
export { QueueContext };

//variables
var queueUrl: any;
var readMessage: any;
var readImage: any;
var messages: any;
var accessKey: string = '';
var sqs: AWS.SQS;
var s3: AWS.S3;

var isEmailSubscription = false;

if(window.location.pathname.toLowerCase() === '/profile/email/verify') {
  isEmailSubscription = true;
}

var isLogOut = false;
if(window.location.pathname.toLowerCase() === '/logout') {
  isLogOut = true;
}

var env: String = "";
if (process.env.REACT_APP_ENV) {
  env = "-" + process.env.REACT_APP_ENV.toLowerCase();
}

const QueueManager = (props: any) => {
  var [queueUrl, setQueueUrl] = useState('');
  const authContext = useContext(UserAuthCtx);
  var personId = authContext.userCtx.userId ? authContext.userCtx.userId : '';
  var domain = authContext.userCtx.userLoggedInDomain
    ? authContext.userCtx.userLoggedInDomain
    : '';
  var domainId = authContext.userCtx.domainId;
  async function addQueue(domain: string, personId: string) {
    try {
      if (personId !== '' && domain !== '') {
        var result = await queueManager(domain, personId, domainId);
        if (result) {
          setQueueUrl(result?.data['body']);
          createAWSObject(result?.data['identitypoolid']);
          //sessionStorage.setItem('queueUrl', result?.data["body"]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function initateQueue() {
    //var QueueUrl=sessionStorage.getItem('queueUrl');
    if (!queueUrl && !isEmailSubscription && !isLogOut) {
      await addQueue(domain, personId);
    }
  }

  initateQueue();

  const createAWSObject = (identityPoolId: string) => {
    if (accessKey === '') {
      accessKey = identityPoolId;
      AWS.config.region = identityPoolId.split(':')[0];
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
      });
      sqs = new AWS.SQS()

      s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        params: {Bucket: 'reach-user-profile' + env}
      });
    }
  };
  const readMessage = () => {
    var params: AWS.SQS.ReceiveMessageRequest = {
      AttributeNames: ['SentTimestamp'],
      MaxNumberOfMessages: 10,
      MessageAttributeNames: ['All'],
      QueueUrl: queueUrl,
      VisibilityTimeout: 20,
    };
    sqs.receiveMessage(params, async function (
      err: AWS.AWSError,
      data: AWS.SQS.ReceiveMessageResult
    ) {
      messages = null;
      if (err) {
        if (err.name === QueueNotFoundException) {
          var result = await queueManager(domain, personId, domainId);
          if (result) {
            queueUrl = result.data['body'];
          }
        }
      } else if (data.Messages) {
        messages = data.Messages;
        if (messages.length > 0) {
          for (var i = 0; i < messages.length; i++) {
            var deleteParams: AWS.SQS.DeleteMessageRequest = {
              QueueUrl: queueUrl,
              ReceiptHandle: messages[i].ReceiptHandle,
            };
            await sqs.deleteMessage(deleteParams, function (err, data) {
              if (err) {
                console.log('Delete Error', err);
              } else {
                console.log('Message Deleted', data);
              }
            });
          }
        }
      }
    });
    return messages;
  };

  const readImage = async (imageUrl:any) => {
    let splitUrl = imageUrl.split("s3.amazonaws.com/")[1]
    var s3params = {
      Bucket: 'reach-user-profile' + env,
      Key: splitUrl
     };
    
    try {
      return await s3.getObject(s3params).promise().then((response: any) => {
        return response;
      });
    } catch (err) {
      console.log(err);
      await initateQueue();
      return await s3.getObject(s3params).promise().then((response: any) => {
        return response;
      });
    }
  };

  return (
    <QueueContext.Provider value={{ queueUrl, readMessage, readImage }}>
      {props.children}
    </QueueContext.Provider>
  );
};

export default QueueManager;
