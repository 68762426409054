export function sortByKey(
    data: any[],
    sortDirection: number,
    key: string,
    type: string
) {
    if (sortDirection == 1 && data.length > 0) {
        data.sort((a: any, b: any) => {
            return type == 'string' ? b[key].localeCompare(a[key]) : b[key] - a[key];
        });
    } else {
        data.sort((a: any, b: any) => {
            return type == 'string' ? a[key].localeCompare(b[key]) : a[key] - b[key];
        });
    }
    return data;
  }
