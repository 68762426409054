import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';

let domainName = process.env.REACT_APP_REACH_BASE_URL;
domainName = domainName && domainName.replace('/api/', '');

export interface AddEmailPayloadType {
  userId: string;
  email: string;
  userPreference: boolean;
  source: 'Reach' | 'BBComms';
}

export interface UpdateEmailPayloadType {
  oldEmail: string;
  updatedEmail: string;
  userPreference: boolean;
  source: 'Reach' | 'BBComms';
  email?: string;
}

export const getUserProfile = async (domain: string) => {
  let result = null;

  const options: AxiosRequestConfig = {
    url: API.userProfileApi,
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      domain: domainName,
    },
    withCredentials: true,
  };

  result = await axios(options);
  return result;
};

export const addEmail = (
  domain: string,
  addedEmails: AddEmailPayloadType[]
) => {
  let result = null;

  const options: AxiosRequestConfig = {
    url: API.userProfileApi,
    method: 'POST',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      domain: domainName,
    },
    withCredentials: true,
    data: addedEmails,
  };

  result = axios(options);
  return result;
};

export const updateProfile = (
  domain: string,
  profileInfoBody: any,
  userId: string,
  updatedEmails: UpdateEmailPayloadType[],
  prefferedLanguagePayload: string
) => {
  let result = null;
  let apiUrl = `${API.userProfileApi}?userId=${userId}`;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'PUT',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      domain: domainName,
    },
    data: {
      ...profileInfoBody,
      emailDigest: updatedEmails,
      preferredLanguage: prefferedLanguagePayload,
    },
    withCredentials: true,
  };

  result = axios(options);
  return result;
};

export const deleteProfile = (
  domain: string,
  profileInfoBody: any,
  userId: string,
  deletedEmails: []
) => {
  let result = null;
  let apiUrl = `${API.userProfileApi}?userId=${userId}`;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'DELETE',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      domain: domainName,
    },
    data: { ...profileInfoBody, emailList: deletedEmails },
    withCredentials: true,
  };

  result = axios(options);
  return result;
};

export const confirmSubscription = (
  token: string,
  email: string,
  userId: string
) => {
  let result = null;
  userId = encodeURIComponent(userId);
  let apiUrl = `${API.emailDigestConfirmApi}?token=${token}&email=${email}&userId=${userId}`;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'PUT',
    withCredentials: true,
  };

  result = axios(options);
  return result;
};

export const confirmUnsubscription = (email: string, userId: string) => {
  let result = null;
  userId = encodeURIComponent(userId);
  let apiUrl = `${API.emailDigestUnsubscriptionApi}?email=${email}&userId=${userId}`;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'PUT',
    withCredentials: true,
  };

  result = axios(options);
  return result;
};

export const resendEmail = (domain: string, userId: string, payload: any) => {
  let result = null;
  let apiUrl = `${API.userProfileApi}?userId=${userId}`;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'POST',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      domain: domainName,
    },
    data: { payload },
    withCredentials: true,
  };

  result = axios(options);
  return result;
};

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});
