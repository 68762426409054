import React, { useContext, useEffect } from 'react';
import EmailSubscriptionNavStyle from './EmailSubscriptionNavStyle';
import { API } from '../../properties/apiProperties';
import { useMediaQuery } from 'react-responsive';
import { AppBar, Toolbar, Divider, Link } from '@material-ui/core';

const EmailSubscriptionNav = () => {
  const classes = EmailSubscriptionNavStyle();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  
  return (
    <>
      <AppBar position='static' className={classes.root}>
        <Toolbar className={classes.appBar}>
          <div className={classes.logo}>
            <img
              className={classes.unBlackboardLogo}
              src={API.blackboardLogo}
              alt='Blackboard'
            ></img>
          </div>
          <div className={classes.linkGroup}>
            <div
              data-testid='signInLink'
              onClick={() => window.location.href = '/'}
              className={classes.title}
            >
              Sign In
            </div>
            <Divider className={classes.divider} orientation='vertical' />
            <div
              data-testid='helpLink'
              className={classes.title}
              onClick={() => {
                window.open(
                  'https://cesupport.finalsite.com/hc/en-us',
                  '_blank'
                );
              }}
            >
              Help
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.spacer}></div>
    </>
  );
};

export default EmailSubscriptionNav;
