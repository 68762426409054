import React from 'react'


export const DownloadIcon1=()=> {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 15V1" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8 13L12 17L16 13" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1 7V23H23V7H19M1 7C1 7 1.64437 7 5 7H1Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


  )
}

export default DownloadIcon1