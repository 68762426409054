import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';

export const queueManager = async (
  domain: string,
  personId: string,
  domainId: string,
  queryUrl: string = '',
  isCreate: string = '1',
  isMobile: string = 'false'
) => {
  var result = null;
  var queryParams = new URLSearchParams();
  queryParams.append('personId', personId);
  queryParams.append('domainId', domainId);
  queryParams.append('isMobile', isMobile);
  queryParams.append('isCreate', isCreate);
  queryParams.append('queryUrl', queryUrl);

  const options: AxiosRequestConfig = {
    url: API.queueManager,
    method: 'POST',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
    },
    withCredentials: true,
    params: queryParams,
  };

  try {
    result = await axios.request(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});
