import { PreferredLanguage } from "./PreferredLanguage";

export class Message {
  channelId: string = '';
  content: string = '';
  contentType: string = '';
  createdAt: number = 0;
  details: any = {};
  id: string = '';
  messageid: string = '';
  parentMessage: any = {};
  readValue: number = 0;
  recipient: string = '';
  schoolId: number = 0;
  sender: string = '';
  senderName: string = '';
  sent: boolean = false;
  preferredLanguage: PreferredLanguage = new PreferredLanguage();
  attachment: boolean = false;
  uploadedFiles: any = [];
}
