import React, { useState, useEffect, useRef, useContext } from 'react';
import Slide from '@material-ui/core/Slide';
import { orgList } from '../../dataLoaders/DataHandler';
import { StateContext } from '../StateContextComponent';
import ImpersonatingPanelDrawerType from './ImpersonatingPanelDrawerType';
import GroupComponent from '../groupComponent';
import DirectMessageComponent from '../DirectMessageComponent';
import ImpersonatingPanelDrawerStyle from './ImpersonatingPanelDrawerStyle';
import { useMediaQuery } from 'react-responsive';

export default function ImpersonatingPanelDrawer({
  open,
  bbDomain,
  setGroup,
  setParentDrawerOpen,
  groupUnread,
  directUnread,
  selectedGroup,
  selectMyGroup,
  setSelectMyGroup,
  directChannelIDFromMessageQueue,
  setMessagePanelLoading = () => {},
  setConversationTitle = () => {},
}: ImpersonatingPanelDrawerType) {
  const classes = ImpersonatingPanelDrawerStyle();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { impersonatingUserDetails } = useContext(StateContext);
  const impGrpDetails = impersonatingUserDetails.groupDetails;
  if (impGrpDetails !== undefined) {
    setSelectMyGroup(impGrpDetails.groupid);
  }
  const [orgListCountDirect, setOrgListCountDirect] = useState<{
    [key: string]: number;
  }>({});
  const [orgListCountGroup, setOrgListCountGroup] = useState<{
    [key: string]: number;
  }>({});

  const [activePanel, setActivePanel] = React.useState(0);

  const impersonatedSchool = orgList.find(
    (org) => org.id === parseInt(impersonatingUserDetails.schoolId)
  );

  return (
    <Slide direction='right' in={open}>
      <div
        className={
          isMobile
            ? open
              ? classes.mobileRoot
              : classes.hideMobileRoot
            : classes.root
        }
      >
        <div className={classes.searchBlock}>
          <div className={classes.searchMenu}>
            <span
              data-testid='drawer-header'
              className={classes.spanSearchClass}
            >
              {impersonatedSchool?.name}
            </span>
          </div>
        </div>

        <div className={classes.leftScrollContainer}>
          <GroupComponent
            className={classes.divClass}
            setGroup={setGroup}
            setOrgListCountGroup={setOrgListCountGroup}
            setParentDrawerOpen={setParentDrawerOpen}
            groupUnread={groupUnread}
            selectedGroup={selectedGroup}
            selectMyGroup={selectMyGroup}
            setSelectMyGroup={setSelectMyGroup}
            impersonating={true}
            setMessagePanelLoading={setMessagePanelLoading}
            setConversationTitle={setConversationTitle}
            activePanel={activePanel}
            setActivePanel={setActivePanel}
          ></GroupComponent>
          <DirectMessageComponent
            personId={impersonatingUserDetails.userId}
            domain={bbDomain}
            selectedGroup={selectedGroup}
            setGroup={setGroup}
            directUnread={directUnread}
            setOrgListCountDirect={setOrgListCountDirect}
            setParentDrawerOpen={setParentDrawerOpen}
            directChannelIDFromMessageQueue={directChannelIDFromMessageQueue}
            impersonating={true}
            selectMyGroup={selectMyGroup}
            setSelectMyGroup={setSelectMyGroup}
            setMessagePanelLoading={setMessagePanelLoading}
            setConversationTitle={setConversationTitle}
            activePanel={activePanel}
            setActivePanel={setActivePanel}
          ></DirectMessageComponent>
        </div>  
      </div>
    </Slide>
  );
}
