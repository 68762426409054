import React, { useState, useEffect, useRef, useContext } from 'react';
import clsx from 'clsx';
import westBarStyle from '../styles/westBarStyle';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import GroupComponent from './groupComponent';
import { GroupEssential } from '../Types/GroupEssential';
import { GroupUnread } from '../Types/GroupUnread';
import DirectMessageComponent from './DirectMessageComponent';
import { OrganizationInfo } from '../Types/OrganizationInfo';
import { orgList, loadOrgData, channelList } from '../dataLoaders/DataHandler';
import { UserAuthCtx } from '../components/AuthComponent';
import { StateContext } from '../components/StateContextComponent';
import { useMediaQuery } from 'react-responsive';
import { DirectMessageContact } from '../Types/DirectMessageContact';

type westBarType = {
  personId: string;
  bbDomain: string;
  setGroup: React.Dispatch<React.SetStateAction<GroupEssential>>;
  setParentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  groupUnread: GroupUnread;
  directUnread: GroupUnread;
  selectedGroup: GroupEssential;
  selectMyGroup: string;
  setSelectMyGroup: React.Dispatch<React.SetStateAction<string>>;
  directChannelIDFromMessageQueue: DirectMessageContact;
  setMessagePanelLoading?: Function;
  setConversationTitle?: Function;
  OnDeleted?: boolean;
  setOnDeleted?: Function;
  setIsChannelEdited?: Function;
  isChannelEdited: boolean;
};
const WestBar = ({
  personId,
  bbDomain,
  isChannelEdited,
  setGroup,
  setParentDrawerOpen,
  groupUnread,
  directUnread,
  selectedGroup,
  selectMyGroup,
  setSelectMyGroup,
  directChannelIDFromMessageQueue,
  setMessagePanelLoading = () => {},
  setConversationTitle = () => {},
  OnDeleted,
  setOnDeleted,
  setIsChannelEdited = () => {},
}: westBarType) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const { mobile: isOpenedInMobile } = useContext(UserAuthCtx).userCtx;
  const {
    isSidebarOpenPrev,
    setSidebarPrevState,
    isSidebarOpen,
    setSidebarState,
    setOrgListCount,
    selectedOrganizationId,
  } = useContext(StateContext);

  //const [selectedSection, setSection] = useState('organisation');
  const listItemRef = useRef(null);
  const [orgListCountDirect, setOrgListCountDirect] = useState<{
    [key: string]: number;
  }>({});
  const [orgListCountGroup, setOrgListCountGroup] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const orgListCountLocal: { [key: string]: number } = {};
    orgList.map((org: OrganizationInfo, index: number) => {
      orgListCountLocal[org.name] =
        (orgListCountDirect[org.name] === undefined
          ? 0
          : orgListCountDirect[org.name]) +
        (orgListCountGroup[org.name] === undefined
          ? 0
          : orgListCountGroup[org.name]);
    });
    setOrgListCount(orgListCountLocal);
  }, [orgListCountGroup, orgListCountDirect]);

  //styles
  const classes = westBarStyle()({
    isOpenedInMobile,
  });

  //Event Handler functions
  const toggleSidebar = () => {
    setSidebarState(!isSidebarOpen);
    setSidebarPrevState(!isSidebarOpenPrev);
  };

  const [activePanel, setActivePanel] = React.useState(0);

  return (
    <main
      className={clsx(isMobile ? classes.contentMobile : classes.content, {
        [isMobile
          ? classes.contentShiftMobile
          : classes.contentShift]: isSidebarOpen,
      })}
    >
      <div className={classes.leftScrollContainerWithSearchBlock}>
        <div className={classes.searchBlock}>
          <button onClick={toggleSidebar} className={classes.toggleButton}>
            {isSidebarOpen ? (
              <ArrowBackIosOutlinedIcon
                fontSize={'default'}
              ></ArrowBackIosOutlinedIcon>
            ) : (
              <ArrowForwardIosOutlinedIcon
                fontSize={'default'}
              ></ArrowForwardIosOutlinedIcon>
            )}
          </button>
          <div className={classes.searchMenu}>
            <span className={classes.spanSearchClass}>
              {selectedOrganizationId.schoolName}
            </span>
          </div>
        </div>
        <div className={classes.leftScrollContainer}>
          <GroupComponent
            className={classes.divClass}
            isChannelEdited={isChannelEdited}
            setIsChannelEdited={setIsChannelEdited}
            setGroup={setGroup}
            setOrgListCountGroup={setOrgListCountGroup}
            setParentDrawerOpen={setParentDrawerOpen}
            groupUnread={groupUnread}
            selectedGroup={selectedGroup}
            selectMyGroup={selectMyGroup}
            setSelectMyGroup={setSelectMyGroup}
            impersonating={false}
            setMessagePanelLoading={setMessagePanelLoading}
            setConversationTitle={setConversationTitle}
            activePanel={activePanel}
            setActivePanel={setActivePanel}
            OnDeleted={OnDeleted}
            setOnDeleted={setOnDeleted}
          ></GroupComponent>
          <DirectMessageComponent
            personId={personId}
            domain={bbDomain}
            selectedGroup={selectedGroup}
            setGroup={setGroup}
            directUnread={directUnread}
            setOrgListCountDirect={setOrgListCountDirect}
            setParentDrawerOpen={setParentDrawerOpen}
            directChannelIDFromMessageQueue={directChannelIDFromMessageQueue}
            impersonating={false}
            selectMyGroup={selectMyGroup}
            setSelectMyGroup={setSelectMyGroup}
            setMessagePanelLoading={setMessagePanelLoading}
            setConversationTitle={setConversationTitle}
            activePanel={activePanel}
            setActivePanel={setActivePanel}
          ></DirectMessageComponent>
        </div>
      </div>
    </main>
  );
};

export default WestBar;
