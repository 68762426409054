import React, { useContext, useEffect, useState } from 'react';
import UniversalNavStyle from '../styles/universalNavStyle';
import MenuListComposition from './menuListComp';
import { logoutService } from '../services/logOutService';
import { API } from '../properties/apiProperties';
import { UserAuthCtx } from './AuthComponent';
import { useMediaQuery } from 'react-responsive';
import { QueueContext } from '../context/queuecontext';
import { queueManager } from '../services/queueService';
import { AppBar, Toolbar, Divider } from '@material-ui/core';
import { StateContext } from './StateContextComponent';
import ActionableAlertBox from './ActionableAlertBox/ActionableAlertBox';
import ClampLines from 'react-clamp-lines';
import { Box } from '@material-ui/core';

const UniversalNav = () => {
  var user = useContext(UserAuthCtx);
  var queueContext = useContext(QueueContext);
  var userDetails = user.userCtx;
  var redirect_url = window.location.origin;
  var districtUrl = '';
  var district = localStorage.getItem('district');
  if (district) {
    districtUrl = JSON.parse(district).DistrictDomainURL.toString();
  }
  const logoutUrl =
    (userDetails.districtURL ? userDetails.districtURL : districtUrl) +
    '/main/logout/?redirect_url=' +
    redirect_url;
  const classes = UniversalNavStyle();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {
    allowLogout,
    setAllowLogout,
    confirmLogout,
    setConfirmLogout,
    selectedOrganizationId,
  } = useContext(StateContext);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [logoutConfirmation, setLogoutConfirmation] = useState({
    open: false,
    title: 'Discard changes?',
    message: 'You have unsaved changes. Are you sure you want to leave?',
  });

  const doConfirmLogout = () => {
    setLogoutConfirmation({
      ...logoutConfirmation,
      open: true,
    });
  };

  const logout = async () => {

    try {
      //To Delete the Queue
      await queueManager(
        userDetails.userLoggedInDomain,
        userDetails.userId,
        userDetails.domainId,
        queueContext.queueUrl,
        '0'
      );
      var result = await logoutService();
      if (result) {
        console.log('sucessfully cleared the cookies');
      }
      document.cookie = 'reachSession=false; path=/';
      Object.keys(localStorage).forEach((key) => {
        if (!key.includes('district')) delete localStorage[key];
      });
      window.location.assign(logoutUrl);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    const handleInvalidToken = (e: any) => {
      if (e.key === 'userId' && e.oldValue && !e.newValue) {
        logout();
      }
    };
    if (localStorage.getItem('isMobile') === 'false') {
      window.addEventListener('storage', handleInvalidToken);
    }

    return function cleanup() {
      if (localStorage.getItem('isMobile') === 'false') {
        window.removeEventListener('storage', handleInvalidToken);
      }
    };
  }, [logout]);

  useEffect(() => {
    confirmLogout && logout();
  }, [confirmLogout]);

  const unloadConfirmationDialogStyle = {
    paper: {
      width: '20rem',
      height: '15rem',
    },
    root: {
      padding: '0 16px',
    },
  };

  return (
    <>
      <ActionableAlertBox
        data-testid='logoutConfirmationBox'
        title={logoutConfirmation.title}
        shouldOpen={logoutConfirmation.open}
        onAction={() => {
          setLogoutConfirmation({
            ...logoutConfirmation,
            open: false,
          });
          setConfirmLogout(true);
          //logout();
        }}
        secondaryButtonLabel='Cancel'
        primaryButtonLabel='Discard'
        styles={unloadConfirmationDialogStyle}
        primaryButtonProps={{}}
        onClose={() => {
          setLogoutConfirmation({
            ...logoutConfirmation,
            open: false,
          });
        }}
      >
        <Box paddingBottom='74px'>{logoutConfirmation.message}</Box>
      </ActionableAlertBox>
      <AppBar
        position='static'
        style={{
          height: '44px',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
        }}
      >
        <Toolbar className={classes.appBar}>
          <div className={classes.logo}>
            <img
              className={classes.unBlackboardLogo}
              src='/images/finalsite-logo.svg'
              alt='Reach'
            ></img>
          </div>
          <div className={classes.linkGroup}>
            {!isMobile && (
              <div className={classes.titleGrey}>
                {selectedOrganizationId.schoolName}
              </div>
            )}
            {userDetails.organizationName !== 'UnknownOrganization' && (
              <>
                <div
                  className={open ? classes.svgSelect : classes.svg}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                >
                  <svg
                    width='24'
                    height='24'
                    fill='none'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      x='1'
                      y='1'
                      width='8'
                      height='8'
                      stroke='none'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <rect
                      x='1'
                      y='15'
                      width='8'
                      height='8'
                      stroke='none'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <rect
                      x='15'
                      y='1'
                      width='8'
                      height='8'
                      stroke='none'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <rect
                      x='15'
                      y='15'
                      width='8'
                      height='8'
                      stroke='none'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <Divider className={classes.divider} orientation='vertical' />
                <MenuListComposition
                  className={classes.noSize}
                  open={open}
                  setOpen={setOpen}
                  anchorRef={anchorRef}
                  bbdomain={
                    userDetails.districtURL
                      ? userDetails.districtURL
                      : districtUrl
                  }
                />
              </>
            )}
            {userDetails.userName === '' ? (
              <div
                className={classes.title}
                onClick={() => {
                  window.open(
                    'https://help.blackboard.com/Community_Engagement/Administrator/Mass_Notifications/Blackboard_Reach',
                    '_blank'
                  );
                }}
              >
                Help
              </div>
            ) : (
              <div
                className={classes.title}
                onClick={() => {
                  if (!allowLogout) {
                    doConfirmLogout();
                  } else {
                    logout();
                  }
                }}
              >
                <ClampLines
                  text={`Sign Out (${userDetails.userName})`}
                  id='userName-id'
                  lines={2}
                  ellipsis='...'
                  buttons={false}
                />
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.spacer}></div>
    </>
  );
};

export default UniversalNav;
