import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton, DefaultButton } from './ReusableComponents/Button';
import CustomCircularProgress from './ReusableComponents/CircularProgress';

const ConfirmAlertDialogStyle = makeStyles((theme) => ({
  dialogButton: {
    width: '76px',
  },
}));

type dialogType = {
  openCancelAlert: any;
  closeCancelAlert: any;
  confirmCancelAlert: any;
  isCancelOtp?: boolean;
};

const CancelAlertDialog = ({
  openCancelAlert,
  closeCancelAlert,
  confirmCancelAlert,
  isCancelOtp = false,
}: dialogType) => {
  const classes = ConfirmAlertDialogStyle();

  return (
    <div>
      <Dialog
        open={openCancelAlert.open}
        onClose={closeCancelAlert}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        disableBackdropClick={isCancelOtp}
        disableEscapeKeyDown={isCancelOtp}
      >
        <DialogContent id='alert-dialog-description'>
          {openCancelAlert.message}
        </DialogContent>
        <DialogActions>
          <DefaultButton
            css={null}
            onClick={closeCancelAlert}
            disabled={isCancelOtp}
          >
            No
          </DefaultButton>
          <PrimaryButton
            css={null}
            onClick={confirmCancelAlert}
            className={classes.dialogButton}
            disabled={isCancelOtp}
          >
            {isCancelOtp ? (
              <CustomCircularProgress aria-label='Loading' size={20} />
            ) : (
              <>Yes</>
            )}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelAlertDialog;
