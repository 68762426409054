import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ErrorComponent from './ErrorComponent';
import { UserAuthCtx } from './AuthComponent';
import LoginComponent from './loginComponent';
import LogoutComponent from './LogoutComponent';
import ProfileComponent from './Profile/ProfileComponent';
import AdminToolingComponent from './adminToolingComponent';
import SettingsComponent from './SettingsComponent';
import { makeStyles } from '@material-ui/core/styles';
import ChatHome from './ChatHome';
import { USER_ROLES } from '../components/Sidebar/SideBar.constant';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexGrow: 1,
  },
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const authToken = urlParams.get('authorization');
const redirectUrl = urlParams.get('redirect_url');
if (authToken) {
  Object.keys(localStorage).forEach((key) => {
    if (!key.includes('district')) delete localStorage[key];
  });
}

const RouteComponent = () => {
  const ws = useContext(UserAuthCtx);
  const storageUserRole = localStorage.getItem('userRole');
  const storageRoleId = localStorage.getItem('roleID');
  const {
    userCtx: { userRole, roleID },
  } = ws;
  const classes = useStyles();
  const isAllowedReports = [
    USER_ROLES.ADMIN,
    USER_ROLES.SYSTEM,
    USER_ROLES.STAFF,
  ].includes(userRole || storageUserRole);

  // const allowSettingsAccess = [
  //   USER_ROLES.ADMIN,
  //   USER_ROLES.SYSTEM,
  //   USER_ROLES.STAFF,
  // ].includes(userRole || storageUserRole);
  const currentRole = userRole || storageUserRole;
  const currentRoleId = roleID || storageRoleId;

  const allowSettingsAccess = USER_ROLES.SYSTEM === currentRole && currentRoleId == 2;

  const Routes = () => {
    return (
      <Switch>
        <RealProtectedRoute component={ChatHome} path='/' exact strict />
        <RealProtectedRoute path='/Home/message/:messageId' component={ChatHome} exact strict />
        <RealProtectedRoute path='/Home' component={ChatHome} exact strict />
        <RealProtectedRoute
          path='/Profile'
          component={ProfileComponent}
          exact
          strict
        />
        <Route path='/Admin' exact strict>
          <Redirect to='/Report'/>
        </Route>
        <PermissionRoute
          path='/Report'
          component={AdminToolingComponent}
          isPermitted={isAllowedReports}
          exact
          strict
        />
        <PermissionRoute
          path='/Settings'
          component={SettingsComponent}
          isPermitted={allowSettingsAccess}
          exact
          strict
        />
        <PublicRoute path='/Login' component={LoginComponent} exact strict />
        <Route path='/error' component={ErrorComponent} exact strict />
        <Route
          path='/logout'
          render={(props: any) => (
            <LogoutComponent redirectUrl={redirectUrl} {...props} />
          )}
          exact
          strict
        />
      </Switch>
    );
  };
  var pID = localStorage.getItem('userId');
  var userID = pID ? pID : ws.userCtx.userId;

  const RealProtectedRoute = ({
    component: Component,
    isAllowed = true,
    ...rest
  }: any) => {
    return (
      <Route
        exact={true}
        {...rest}
        render={(props) => {
          if (userID !== '' && isAllowed) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/Login',
                  state: { nextPathname: props.location.pathname },
                }}
              />
            );
          }
        }}
      />
    );
  };

  const PublicRoute = ({ component: Component, ...props }: any) => {
    return (
      <Route
        {...props}
        render={(props) => {
          const nextPathname: any = ((props as any).location?.state?.nextPathname)
          return userID !== '' ? (
            <Redirect to={{pathname: nextPathname ?? '/Home'}} />
          ) : (
            <Component {...props} />
          );
        }}
      />
    );
  };

  const PermissionRoute = ({component: Component, isPermitted = false, ...props}: any) => {
    return (
      <Route 
        {...props}
        render={props => {
          return isPermitted ? <Component {...props} /> : <Redirect to='/Home' />
        }}
      />
    )
  }

  return (
    <div className={classes.container}>
      <Routes />
    </div>
  );
};

export default RouteComponent;