import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ReportingIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.5 7.5L4.5 11.5'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M5 12.5C5 13.3 4.3 14 3.5 14C2.7 14 2 13.3 2 12.5C2 11.7 2.7 11 3.5 11C4.3 11 5 11.7 5 12.5Z'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M17 8L21 3'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M11 7L14.5 9'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M9.5 8C10.3 8 11 7.3 11 6.5C11 5.7 10.3 5 9.5 5C8.7 5 8 5.7 8 6.5C8 7.3 8.7 8 9.5 8Z'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M15.5 11C16.3 11 17 10.3 17 9.5C17 8.7 16.3 8 15.5 8C14.7 8 14 8.7 14 9.5C14 10.3 14.7 11 15.5 11Z'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <path
          d='M21.5 4C22.3 4 23 3.3 23 2.5C23 1.7 22.3 1 21.5 1C20.7 1 20 1.7 20 2.5C20 3.3 20.7 4 21.5 4Z'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeMiterlimit='10'
        />
        <rect
          x='2'
          y='19'
          width='3'
          height='4'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='8'
          y='13'
          width='3'
          height='10'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='14'
          y='16'
          width='3'
          height='7'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <rect
          x='20'
          y='10'
          width='3'
          height='13'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};
