import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';
import { config } from 'aws-sdk';
import { stringList } from 'aws-sdk/clients/datapipeline';
import { get_cluster } from '../components/clusterGlobal';

export const getUsageReportsData = async (filters: any, bbdomain: string) => {
  let result = null;
  let queryParams = new URLSearchParams();

  const apis = [API.usersCountApi, API.messagesSentChartApi];
  let apiConfigs = [];

  queryParams.append('schoolId', filters.school);
  queryParams.append('startDate', filters.startDateEpoch);
  queryParams.append('endDate', filters.endDateEpoch);

  let options: AxiosRequestConfig = {
    url: '',
    method: 'GET',
    headers: {
      bbdomain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    params: queryParams,
    withCredentials: true,
  };

  for (let api of apis) {
    options.url = api;
    apiConfigs.push(axios(options));
  }

  result = axios.all(apiConfigs);
  return result;
};

export const downloadUsageReportsData = async (
  filters: any,
  bbdomain: string,
  requestType: string
) => {
  let result = null;
  let queryParams = new URLSearchParams();

  const apis = API.downloadCSV;

  queryParams.append('schoolId', filters.school);
  queryParams.append('startDate', filters.startDateEpoch);
  queryParams.append('endDate', filters.endDateEpoch);
  queryParams.append('requestType', requestType);

  let options: AxiosRequestConfig = {
    url: API.downloadCSV,
    method: 'GET',
    headers: {
      bbdomain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    params: queryParams,
    withCredentials: true,
  };

  result = axios(options);
  return result;
};

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});
