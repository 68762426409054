import React from 'react';
import { 
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import  DialogTitle from '../ReusableComponents/ReachDialogTitle/DialogTitle';
import  {PrimaryButton}  from '../ReusableComponents/Button';

const AlertBox = (props: any) => {
  const { title, children, open, setOpen } = props;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-confirm-dialog"
    >
        <DialogTitle hideCloseButton css={null} id='alert-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
            <PrimaryButton
                css={null}
                id='alert-default'
                onClick={() => {
                    setOpen(false);
                }}
            >
                Close
            </PrimaryButton>
        </DialogActions>
    </Dialog>
  );
};

export default AlertBox;
