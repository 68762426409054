import * as React from 'react';
import { create } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import jssRtl from 'jss-rtl';
import { BbThemeProvider } from './BbThemeProvider';
import pxToRem from './px-to-rem';
export var JssThemeProvider = function (props) {
    var theme = props.theme, jssInsertionPoint = props.jssInsertionPoint, isRtl = props.isRtl, generateClassName = props.generateClassName;
    var jss = create({
        plugins: jssPreset().plugins.concat([pxToRem(), jssRtl()]),
        insertionPoint: jssInsertionPoint,
    });
    return (React.createElement(StylesProvider, { jss: jss, generateClassName: generateClassName },
        React.createElement(BbThemeProvider, { theme: theme, isRtl: isRtl },
            React.createElement(CssBaseline, null),
            props.children)));
};
export default JssThemeProvider;