import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SettingsIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21.4277 6.20621L18.6487 6.88022C18.1407 6.22103 17.5447 5.65078 16.8836 5.17937L17.4607 2.37558C17.5092 2.14037 17.3817 1.90416 17.1583 1.81621L15.1728 1.03446C14.9495 0.946507 14.6945 1.0315 14.57 1.23707L13.0796 3.68112C12.2742 3.57438 11.4509 3.58525 10.6287 3.72164L9.05629 1.33293C8.92386 1.13231 8.6669 1.05621 8.44651 1.15108L6.48969 2.002C6.2693 2.09787 6.14972 2.33802 6.20704 2.57225L6.88106 5.35034C6.22088 5.85931 5.65063 6.45427 5.17922 7.11544L2.37543 6.53926C2.14021 6.49083 1.905 6.61832 1.81605 6.84168L1.03431 8.82716C0.946354 9.05051 1.03234 9.30451 1.23691 9.43002L3.68096 10.9194C3.57522 11.7248 3.58609 12.5491 3.72148 13.3703L1.33278 14.9437C1.13314 15.0761 1.05605 15.3331 1.15192 15.5535L2.00284 17.5103C2.0987 17.7307 2.33886 17.8493 2.5721 17.793L5.35118 17.1189C5.85916 17.7791 6.4551 18.3494 7.11627 18.8208L6.53911 21.6236C6.49068 21.8588 6.61817 22.095 6.84251 22.183L8.82701 22.9657C9.05036 23.0536 9.30534 22.9677 9.42987 22.7631L10.9202 20.319C11.7257 20.4248 12.5499 20.4139 13.3712 20.2785L14.9436 22.6662C15.076 22.8669 15.3329 22.9439 15.5533 22.8481L17.5102 21.9972C17.7305 21.9013 17.8501 21.6611 17.7938 21.4269L17.1198 18.6478C17.779 18.1398 18.3492 17.5449 18.8206 16.8837L21.6244 17.4609C21.8596 17.5093 22.0958 17.3808 22.1838 17.1575L22.9655 15.172C23.0535 14.9487 22.9685 14.6947 22.7629 14.5691L20.3189 13.0798C20.4256 12.2743 20.4147 11.4501 20.2784 10.6288L22.6671 9.05546C22.8677 8.92401 22.9438 8.66607 22.8479 8.44568L21.997 6.48985C21.9011 6.26946 21.661 6.14987 21.4277 6.20621Z'
          stroke='#CDCDCD'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12 16C9.79429 16 8 14.2057 8 12C8 9.79429 9.79429 8 12 8C14.2057 8 16 9.79429 16 12C16 14.2057 14.2057 16 12 16Z'
          stroke='#CDCDCD'
          strokeWidth='2'
        />
      </svg>
    </SvgIcon>
  );
};

export default SettingsIcon;
