import { makeStyles } from '@material-ui/core/styles';
const groupComponentSytle = () => makeStyles((theme) => ({
  root: {
    padding: '10px 0 10px 0',
    border: '1px solid #CDCDCD',
    borderRight: 0,
    borderTop: 0
  },
  channelHeaderClass: {
    display: 'flex',
    padding: '0 10px 0 15px',
  },
  courseIconClass: {
    height: '16px',
  },
  iconStyle: {
    paddingTop: '2px',
  },
  spanChannelClass: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '15px',
    textTransform: 'uppercase',
    color: '#666666',
    padding: '3px 3px 3px 3px',
  },
  addCircleItemClass: {
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  channelNameSpanEl: {
    height: '21px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize:'0.875rem',
    lineHeight: '21px',
    color: '#262626',
  },
  channelNameDuplicateSpanEl:{
    height: '21px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize:'0.875rem',
    lineHeight: '21px',
    color: '#C23E37',
  },
  channelNameInputEl: {
    border:(props: any) => {
      return props.charLeft == 0 ? '1px solid #C23E37':'1px solid #666666';
    },
    width:'100%',
    height:'37px',
    fontSize:'0.875rem',
    padding:'8px 12px',
    borderRadius: '2px',
    outline:"none",
    fontWeight:400,
    marginTop:'4px',
    color: '#262626',
    '&::placeholder': {
      color: 'rgb(38,38,38,0.5)',
      fontSize: '14px',
      fontWeight:400,
      fontStyle: 'normal',
    },
    '&::-moz-placeholder': {
      color: 'rgb(38,38,38)'
    }
  },
  charsLeftSpanEl: {
    color: (props: any) => {
      return props.charLeft == 0 ? '#C23E37':'#666666';
    },
    fontSize:'12px',
    fontWeight:400
  },
  toggleSwitchCon : {
    display:'flex',
    marginTop:'10px'
  },
  toggleSwitchEl: {
    cursor:'pointer',
    marginLeft:'-12px',
    marginRight:'10px'
  },
  toggleSwitchSpanEl: {
    marginBottom:"12px"
  },
  addGroupButtonClass: {
    float: 'right',
    width: '16px',
    height: '16px',
  },
  channelListItemClass: {
    minHeight: '32px',
    padding: '5px 5px 5px 15px',
    '&$selected': {
      backgroundColor: '#FF5991',
      '&:hover': {
        backgroundColor: '#FF5991',
      },
      '&:focus': {
        backgroundColor: '#FF5991',
      },
    },
  },
  selected: {},
  notifyNumber: {
    background: '#5178D0',
    borderRadius: theme.spacing(2),
    color: '#ffffff',
    textAlign: 'center',
    width: theme.spacing(4),
  },
  groupName: {
    flex: '1',
  },
  groupNameText: {
    display: 'block',
    width: '230px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: '#262626'
  },
  progress: {
    height: '5px',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& div': {
      backgroundColor: '#FF5991',
    },
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: '100%',
    fontWeight: 600,
  }
}));

export default groupComponentSytle;
