import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const FilesPreviewContainer= styled.div<ChevronProps>`
    display:${(props) => (props.showAttachement ? 'flex' : 'none')};
    overflow-x:auto;
    &::-webkit-scrollbar-track {
        display:none;
    }
    max-width:90%;
`;
interface ChevronProps {
    showAttachement: boolean;
}
export const ImageContainer = styled.div`
    position:relative;
    text-align:center;
    margin-right:6px;
`

export const ImagePreview = styled.img`
    border-radius: 8px;
    margin-right:6px;
    width:200px;
    height:200px;
    object-fit:cover;
    cursor: pointer;
`;

export const SmallImagePreview = styled.img`
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    width: 48px;
    height: 48px;
    cursor: pointer;
`;

export const ChevronContainer = styled.div`
    display:flex;
    color: #8C8C8C;
`;

export const FileContainer = styled.div`
position:relative;
&:hover {
    background: #F8F8F8;
  }
    text-align:center;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width: 188px;
    height: 56px;
    border: 1px solid #CDCDCD;
    border-radius: 8px;
    margin-right:6px;
    padding-left:8px
`;

export const FileName = styled.h4`
    margin-left:10px;
    font-weight: 700;
    font-size: 12px;
    position:relative;
`;

export const DeleteIconContainer = styled.div`
    padding-top:8px;
    background-color:white;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    border-radius:30px;
    width:40px;
    height:40px;
`;

export const DownloadIconContainer = styled.button`
    // padding-top:8px;
    background-color:white;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    border-radius:30px;
    width:40px;
    height:40px;
    margin-right:4px;
    cursor: pointer;
    border-width: 0px;
`;


export const SpanElement = styled.span`
cursor: pointer;
margin-top: 6px;
margin-left:7px;
`
export const IconsContainer = styled.div<IconProps>`
    @media only screen and (max-width: 600px) {
        display:flex;
    }
    position:absolute;
    top:${(props) => (props.isImage ? "3%" : "13%")};
    right:${(props) => (props.isImage ? "5%" : "3%")};
    display:none;
    ${FileContainer}:hover & {
        display:flex;
    }
    ${ImageContainer}:hover & {
        display:flex;
    }
`
interface IconProps {
    isImage : boolean;
}

export const StyledReactTooltip = styled(ReactTooltip)`
font-style: normal;
font-weight: 500;
font-size: 14px !important;
line-height: 21px;
color: #FFFFFF;
`;
