import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import NotificationBoxStyle from './NotificationBoxStyle';
import AlertErrorIcon from "../../Icons/AlertErrorIcon"

const NotificationBox = (props: any) => {
  const { message, handleNotification } = props;
  const classes = NotificationBoxStyle();
  const severity = props.severity || 'error';
  const closeIcon = props.isShowClose === 0 ? false : true;
  let iconSvg: any;

  if (severity === 'success') {
    iconSvg = (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='12' cy='12' r='11' stroke='#007D2C' strokeWidth='2' />
        <path
          d='M7 12L11 16L18 8'
          stroke='#007D2C'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }else if(severity === 'fileLimitError'|| severity === 'fileLimitWarning'){
    iconSvg = <AlertErrorIcon/>
  }
   else {
    iconSvg = (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='12' cy='12' r='11' stroke='#262626' strokeWidth='2' />
        <path
          d='M12.75 18.5C12.75 18.9142 12.4142 19.25 12 19.25C11.5858 19.25 11.25 18.9142 11.25 18.5C11.25 18.0858 11.5858 17.75 12 17.75C12.4142 17.75 12.75 18.0858 12.75 18.5Z'
          stroke='#262626'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M12 15L11.5 5H12.5L12 15Z'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  }

  return (
    <Box className={classes.cardRoot}>
      <Alert
        data-testid='notification'
        severity={severity === "fileLimitError" ? "error" : severity === "fileLimitWarning" ? "warning" : severity}
        icon={iconSvg}
        className={severity === 'fileLimitError' ? 
        classes.fileError : severity === 'fileLimitWarning' ? 
        classes.fileWarning : severity === 'ownerPrivilegesErr' ? classes.ownerPrivilegErr : classes.toaster}
        action={
          closeIcon ? (
            <IconButton
              aria-label='close'
              color='inherit'
              size='medium'
              onClick={() => handleNotification(false)}
            >
              <CloseIcon />
            </IconButton>
          ) : null
        }
      >
        {message}
      </Alert>
    </Box>
  );
};

export default NotificationBox;
