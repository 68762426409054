
import React from 'react'
import { ListSubheader, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const headingStyles = () => makeStyles(() => ({
  headerDivider: {
    display: 'flex',
    flex: 1
  },
  specialRuler: {
    backgroundColor: '#046BF1'
  },
  headerText: {
    fontSize: '12px',
    marginLeft: '15px',
    marginRight: '15px',
  },
  header: {
    textAlign: 'center',
    height: '10px',
    background: 'transparent',
    display: 'flex',
    color: ({isSpecialHeader}: any) => isSpecialHeader ? '#046BF1' : '#767676',
    '& .newLabelContainer': {
      position: 'relative', display: 'flex', flex: 1
    },
    '& .newLabel': {
      position: 'absolute', top: '-18px', right: 0, fontSize: '12px'
    }
  }
}))

const MessageHeading = ({isSpecialHeader = false, label = 'new', content = ''}) => {
  const classes = headingStyles()({
    isSpecialHeader
  })

  return (
    <ListSubheader disableSticky={true} className={classes.header}>
      <Divider className={classNames(classes.headerDivider, isSpecialHeader ? classes.specialRuler : null) } />
      <span className={classes.headerText}>
        {content}
      </span>
      {isSpecialHeader ? withSpecialHeading(<Divider className={classNames(classes.headerDivider, classes.specialRuler)} />) : <Divider className={classes.headerDivider} />}
    </ListSubheader>
  )
}

const withSpecialHeading = (Element: JSX.Element): JSX.Element => {
  return (
    <span className='newLabelContainer'>
      <span className='newLabel'>NEW</span>
      {Element}
    </span>
  )
}

export default React.memo(MessageHeading)


