import { UserProfileInfo } from './UserProfileInfo';
import { Message } from './MessageType';

export class UserMessageInfo {
  public profile: UserProfileInfo = new UserProfileInfo();
  public message: string = '';
  public messageId: string = '';
  public id: string = '';
  public sender: string = '';
  public recipient: string = '';
  public datevalue: number = Date.now();
  public Time: string = '';
  public createdAt: number = 0;
  public repliedTo: string = '';
  public channelName: string = '';
  public parentMessage: Message = new Message();
  public dateTime: string = '';
  public Date: string = '';
  public translatedMessage: string = '';
  public showTranslatedMessage: boolean = false;
  public preferredLanguage: string = '';
  public sourceLanguage: string = '';
  public langConfidence: string = '';
  public messageTranslationError: string = '';
  public messageTranslationLoading: boolean = false;
  public readValue: number = 1;
  public isTranslated: boolean = false;
  public uploadedFiles: any = []
  public attachment: boolean = false
}

//sample data

// profile: {
//     name: "Craig Bothman",
//     available: false,
//     role: "Teacher",
//     profileImage: "prince.jpg",
// },
// message:
//     "Everyone, don’t forget that we have an quiz tomorrow. Have all your materials ready. ",
// Date: "05/3/2020",
// Time: "11:02AM",

// {
//   "sender": "2001536905|https://spl9-pt99-1.parentlink.net/",
//   "content": "hi how r u ",
//   "recipient": "2012174498|https://spl9-pt99-1.parentlink.net/",
//   "messageId": "21cfe5a73a2041f5bc317d78708469fb",
//   "groupId": "878069",
//   "createdAt": 1591079064,
//   "sent": false,
//   "details": {
//     "repliedTo": "MessageId",
//     "senderName": "praveen",
//     "recipentName": "",
//     "recipentRole": "Parent",
//     "senderRole": "Teacher",
//     "messageContent": "<p>hi how r u </p>"
//   },
//   "schoolId": 2000002463,
//   "id": "f1416436403a4471893ac1b97a75e81f",
//   "contentType": "Text",
//   "readValue": 0
// }
