import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
    Grid,
    FormControl,
    InputLabel,
    IconButton,
    Tooltip,
    Box,
    Divider,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import AutoComplete from '../AutoComplete/AutoComplete';
import SchoolSettingsComponentStyle from './SchoolSettingsComponentStyle';
import InfoIcon from '../../Icons/InfoIcon';
import SearchIcon from '@material-ui/icons/Search';
import {
    getChannelsList,
    getChannelUsersCount,
} from '../../services/settingPageService';
import Alert from '@material-ui/lab/Alert';
import CustomTable from '../CustomTable/CustomTable';
import { UserAuthCtx } from '../../components/AuthComponent';
import {
    SchoolSettingsComponentType,
    SchoolSettingsStateTypes,
    ChannelCollection,
} from './SchoolSettingsTypes';
import Cache from '../../libs/object.cache';
import { StateContext } from '../../components/StateContextComponent';
import CustomCircularProgress from '../ReusableComponents/CircularProgress';

const SchoolSettingsComponent = ({
    emptyMessage = 'Choose a school to get started',
}: SchoolSettingsComponentType) => {
    const headerConfig = [
        { column: 'Channel Name', breakpoints: 3, dataKey: 'channelName' },
        { column: 'Channel status', breakpoints: 2, dataKey: 'channelStatus' },
        { column: 'Channel Leader', breakpoints: 2, dataKey: 'channelOwnerName' },
        { column: 'ID', breakpoints: 1, dataKey: 'channelId' },
        { column: '# of Users', breakpoints: 1, dataKey: 'totalMembers' },
        { column: '', breakpoints: 3, dataKey: 'selectDropdown' },
    ];

    const headers = {
        desktop: headerConfig,
        mobile: [
            { ...headerConfig[0], breakpoints: 7 },
            { ...headerConfig[1], breakpoints: 5 },
        ],
        tablet: [
            { ...headerConfig[0], breakpoints: 4 },
            { ...headerConfig[1], breakpoints: 3 },
            { ...headerConfig[2], breakpoints: 3 },
            { ...headerConfig[3], breakpoints: 2 },
        ],
    };

    const statusDropdown = [
        { value: 'enable', text: 'Enable Channel' },
        { value: 'disable', text: 'Disable Channel' },
    ];

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [keyword, setKeyword] = useState('');
    const [emptyPlaceholder, setEmptyPlaceholder] = useState('');
    const [channels, setChannels] = useState<ChannelCollection>({});
    const [filteredChannels, setFilteredChannels] = useState<ChannelCollection>(
        {}
    );
    const [school, setSchool] = useState<SchoolSettingsStateTypes>({
        id: null,
        name: '',
    });
    const [disabledTextBox, setDisableTextBox] = useState(false);
    const [isGettingUserCount, setGettingUserCount] = useState(false);

    const userDetails = useContext(UserAuthCtx).userCtx;
    const { schools } = useContext(StateContext);
    const classes = SchoolSettingsComponentStyle();
    const {
        get: getCache,
        set: setCache,
        getCache: getAllCache,
        destroy: destroyCache,
    } = useMemo(() => Cache(), []);

    const COLUMN_TYPE: any = {
        channelName: 'string',
        organizationName: 'string',
        channelStatus: 'boolean',
        channelOwnerName: 'string',
        channelId: 'number',
        totalMembers: 'number',
        mapper: {
            organizationName: 'channelName',
            channelName: 'channelName',
            channelStatus: 'channelStatus',
            channelOwnerName: 'channelOwnerName',
            channelId: 'channelId',
            totalMembers: 'totalMembers',
        },
    };

    function formatChannelList(option: any) {
        return (
            <>
                <Box display='flex' width='100%'>
                    <Box
                        component='span'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                    >
                        {option.name}
                    </Box>
                    <Box component='span'>
                        ,<Box component='span' ml={1} />
                        {option.id}
                    </Box>
                </Box>
            </>
        );
    }

    const onSchoolSelect = (
        event: Event | {},
        school: SchoolSettingsStateTypes
    ) => {
        setLoading(true);
        setSchool({
            name: school.name,
            id: school.id,
        });
    };

    const onSuccess = () => {
        setLoading(false);
        setIsError(false);
        setErrorMessage(null);
        setKeyword('');
    };

    const onFailure = (error: any) => {
        console.error(error);
        setLoading(false);
        setIsError(true);
        setErrorMessage(error.message);
    };

    const isOkToShowTable = () => {
        return (
            !isError &&
            !loading &&
            !errorMessage &&
            Object.keys(filteredChannels).length > 0
        );
    };

    const getFilteredChannels = (keyword: string) => {
        if (keyword.trim() === '') {
            setEmptyPlaceholder('');
            return channels;
        }

        const filtered: any = {};
        Object.keys(channels).map((name: string) => {
            const items = channels[name].filter((channel) => {
                return channel.channelName
                    .toLowerCase()
                    .includes(keyword.toLowerCase());
            });
            if (items.length) {
                filtered[name] = items;
            }
        });

        const isNoSearchResults = Object.keys(filtered).length <= 0 ? true : false;
        const emptyMessage = isNoSearchResults ? 'No results found' : '';

        setEmptyPlaceholder(emptyMessage);

        return filtered;
    };

    const ascending = (collection: any, key: string, type: string) => {
        key = COLUMN_TYPE.mapper[key];

        return [...collection].sort((a, b): number => {
            return type == 'string' ? a[key].localeCompare(b[key]) : a[key] - b[key];
        });
    };

    const descending = (collection: any, key: string, type: string) => {
        key = COLUMN_TYPE.mapper[key];

        return [...collection].sort((a, b): number => {
            return type == 'string' ? b[key].localeCompare(a[key]) : b[key] - a[key];
        });
    };

    function sortChannels(category: string, direction: number, column: string) {
        if (category.toLowerCase() == 'all') {
            let sorted: any = {};
            Object.keys(channels).map((category) => {
                sorted[category] =
                    direction == 0
                        ? ascending(channels[category], column, COLUMN_TYPE[column])
                        : descending(channels[category], column, COLUMN_TYPE[column]);

                setChannels({ ...sorted });
            });
        } else {
            let sorted: any;
            const categorised = channels[category];
            sorted =
                direction == 0
                    ? ascending(categorised, column, COLUMN_TYPE[column])
                    : descending(categorised, column, COLUMN_TYPE[column]);

            setChannels({ ...channels, [category]: sorted });
        }
    }

    const getTotalUserCount = async (key: any, list: any) => {
        setGettingUserCount(true);
        const { userLoggedInDomain, userId } = userDetails;
        const userCount: any = await getChannelUsersCount(
            userLoggedInDomain,
            userId,
            list.channelType,
            list.channelId
        );
        const {
            data: { total = 0 },
        } = userCount.data ? userCount : { data: { total: 0 } };

        setUserCountInData(key, list, total);
    };

    const setUserCountInData = (key: any, list: any, userCount: number) => {
        filteredChannels[key] = filteredChannels[key].map((listItem: any) => {
            if (list.channelId === listItem.channelId) {
                return {
                    ...listItem,
                    totalMembers: userCount ? userCount : 0,
                    fetchTotalCount: true,
                };
            } else {
                return listItem;
            }
        });

        setFilteredChannels({ ...filteredChannels });
        setGettingUserCount(false);
    };

    useEffect(() => {
        const setChannelAndBootstrap = (
            channels: any,
            schoolId: string,
            updateCache: boolean = true
        ) => {
            if (channels) {
                updateCache && setCache(schoolId, { ...channels });

                Object.keys(channels).map((name: string) => {
                    channels[name] = channels[name].map((channel: any) => {
                        return {
                            ...channel,
                            isChecked: false,
                        };
                    });
                });
                setChannels(channels);
                onSuccess();
            } else {
                setIsError(true);
                onFailure(new Error('Invalid response structure or Cache'));
            }
        };

        const fetchChannels = async (schoolId: string) => {
            const { userLoggedInDomain, userId } = userDetails;
            const channels: any = await getChannelsList(
                userLoggedInDomain,
                userId,
                schoolId
            );
            const channelsData = channels.data.data.channels.length > 0 ? channels.data.data : {}
            const {
                data: { data: categorized = null },
            } = channels;
            setChannelAndBootstrap(removeEmptyChannels(channelsData), schoolId, true);
        };

        const removeEmptyChannels = (channelList: any) => {
            Object.keys(channelList).map((name: string) => {
                const channelListObj = channelList[name].filter(
                    (channelItem: any) => channelItem.channelName.length !== 0
                );
                channelList[name] = channelListObj;
            });
            return channelList;
        };

        const cachedChannels = getCache(school.id?.toString() as string);

        if (school.id && cachedChannels) {
            setChannelAndBootstrap(cachedChannels, school.id.toString(), false);
        } else {
            school.id && fetchChannels(school.id.toString());
        }
    }, [school]);

    useEffect(() => {
        const displayChannels = getFilteredChannels(keyword);
        setFilteredChannels(displayChannels);
    }, [keyword, channels]);

    useEffect(() => {
        return () => {
            destroyCache();
        };
    }, []);
    const [expanded, setExpanded] = useState(false);
    return (
        <div className={classes.root}>
            <div className={classes.filterBar}>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id='schoollbl'>
                                School
                <Tooltip
                                    title='Only schools that have enabled reach at the district level will appear in the list'
                                    arrow={true}
                                    placement='bottom-start'
                                    enterTouchDelay={0}
                                    leaveTouchDelay={100000}
                                >
                                    <IconButton color='primary' className={classes.infoIcon}>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputLabel>

                            <AutoComplete
                                mode='data'
                                id='schools'
                                data-testid='schools'
                                isFreeForm={false}
                                value={school.name}
                                dataFormatter={(data: any) => {
                                    return data.map((school: any) => {
                                        const { id, name } = school;
                                        return {
                                            id,
                                            name,
                                        };
                                    });
                                }}
                                disabled={false}
                                placeholder='Select school'
                                noOptionsText='No schools'
                                optionsArray={schools}
                                renderOption={formatChannelList}
                                getOptionSelected={(option, value) => option.name === value}
                                onChange={onSchoolSelect}
                                getOptionLabel={(item) => {
                                    if (item.name) {
                                        return item.name;
                                    }
                                    return item;
                                }}
                                expanded={expanded}
                                setExpanded={setExpanded}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <Divider />
            <Box
                className={classes.boxConatainer}
                overflow='auto'
                data-testid='with-height'
            >
                <Grid item className={classes.gridRoot}>
                    {loading && (
                        <Box
                            data-testid='loader-container'
                            className={`${classes.outline} ${classes.flexCenter}`}
                        >
                            <CustomCircularProgress
                                css={classes.flexCenter}
                                aria-label='Loading'
                                size='medium'
                            />
                        </Box>
                    )}
                    {!loading &&
                        !isError &&
                        (!school.id || Object.keys(channels).length <= 0) ? (
                            <Box className={classes.overflowContainer}>
                                {!school.id ? emptyMessage : 'No channel(s) found'}
                            </Box>
                        ) : (
                            <>
                                <Box className={classes.outline}>
                                    {!loading && (
                                        <TextField
                                            className={classes.searchTextField}
                                            data-testid='search-channels'
                                            placeholder='Search'
                                            type='text'
                                            disabled={disabledTextBox}
                                            onChange={(event) => setKeyword(event.target.value)}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: { focused: classes.focused },
                                                startAdornment: (
                                                    <InputAdornment
                                                        position='start'
                                                        classes={{
                                                            positionStart: classes.endordment,
                                                        }}
                                                    >
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Box>
                                {isError && !loading && (
                                    <Box
                                        data-testid='error-container'
                                        className={`${classes.outline}`}
                                    >
                                        <Alert severity='error'>{errorMessage}</Alert>
                                    </Box>
                                )}
                                <Box
                                    data-testid='table-container'
                                    className={`${classes.outline}`}
                                >
                                    {isOkToShowTable() && (
                                        <CustomTable
                                            isCheckBox={true}
                                            header={headers}
                                            statusDropdown={statusDropdown}
                                            data={filteredChannels}
                                            sortOrgDict={sortChannels}
                                            setDisableSearchText={setDisableTextBox}
                                            renderFor={'school'}
                                            SelectedSchool={school}
                                            getUserCount={getTotalUserCount}
                                            isGettingTotalCount={isGettingUserCount}
                                        />
                                    )}
                                    {emptyPlaceholder && <Box>{emptyPlaceholder}</Box>}
                                </Box>
                            </>
                        )}
                </Grid>
            </Box>
        </div>
    );
};
export default SchoolSettingsComponent;
