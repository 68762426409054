import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';

const useradioStyles = makeStyles({
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      backgroundColor: 'white',
        border: '2px solid #CDCDCD',
    },
    checkedIcon: {
        border: '5px solid green',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          content: '""',
        },
      },
  });

var CustomRadio = function (props:any) {
    const classes = useradioStyles();
    return(
    <Radio 
    {...props}
    icon={<span className={classes.icon} />}
    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon) } />}
    />)
};
export default CustomRadio