import React, { createContext, useEffect, useState, useContext } from 'react';
import UserDetailsType from '../Types/UserDetailsType';
import { SchoolInfo } from '../Types/SchoolInfo';
import { OrganizationInfo } from '../Types/OrganizationInfo';

//Creating ctx variable to hold the values
var isSidebarOpenPrev: any;
var setSidebarPrevState: any;
var isSidebarOpen: any;
var setSidebarState: any;
var impersonatingUserDetails: any;
var setImpersonatingUserDetails: any;
var impersonating: any;
var setImpersonatingState: any;
var orgListCount: { [key: string]: number } = {};
var setOrgListCount: React.Dispatch<React.SetStateAction<{
  [key: string]: number;
}>> = () => {};
var selectedOrganizationId: SchoolInfo = new SchoolInfo();
var setSelectedOrganizationId: React.Dispatch<React.SetStateAction<
  SchoolInfo
>> = () => {};
var enableSchoolsToSelect: boolean = false;
var setEnableSchoolsToSelect: React.Dispatch<React.SetStateAction<
  boolean
>> = () => {};
const schools: OrganizationInfo[] = [new OrganizationInfo()]
const setSchools: React.Dispatch<React.SetStateAction<OrganizationInfo[]>> = () => {};
const allowLogout: boolean = true;
const setAllowLogout: React.Dispatch<React.SetStateAction<
  boolean
>> = () => {};
const confirmLogout: boolean = true;
const setConfirmLogout: React.Dispatch<React.SetStateAction<
  boolean
>> = () => {};
const navigateToBBcoms: boolean = true;
const setNavigateToBBcoms: React.Dispatch<React.SetStateAction<
  boolean
>> = () => {};

const StateContext = React.createContext({
  isSidebarOpenPrev,
  setSidebarPrevState,
  isSidebarOpen,
  setSidebarState,
  impersonatingUserDetails,
  setImpersonatingUserDetails,
  impersonating,
  setImpersonatingState,
  orgListCount,
  setOrgListCount,
  selectedOrganizationId,
  setSelectedOrganizationId,
  enableSchoolsToSelect,
  setEnableSchoolsToSelect,
  schools,
  setSchools,
  allowLogout,
  setAllowLogout,
  confirmLogout,
  setConfirmLogout,
  navigateToBBcoms,
  setNavigateToBBcoms
});
export { StateContext };

const StateContextProvider = (props: any) => {
  const [orgListCount, setOrgListCount] = useState<{
    [key: string]: number;
  }>({});
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isSidebarOpenPrev, setSidebarOpenPrev] = useState(true);
  const [impersonatingUserDetails, setImpersonatingUserDetails] = useState(
    new UserDetailsType()
  );
  const [impersonating, setImpersonating] = React.useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    new SchoolInfo()
  );
  const [enableSchoolsToSelect, setEnableSchoolsToSelect] = React.useState(
    false
  );
  const [schools, setSchools] = React.useState<OrganizationInfo[]>([new OrganizationInfo()])
  const [allowLogout, setAllowLogout] = React.useState(true);
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const [navigateToBBcoms, setNavigateToBBcoms] = React.useState(false);
  const all = {
    isSidebarOpenPrev,
    setSidebarPrevState,
    isSidebarOpen,
    setSidebarState,
    impersonatingUserDetails,
    setImpersonatingUserDetails,
    impersonating,
    setImpersonatingState,
    orgListCount,
    setOrgListCount,
    selectedOrganizationId,
    setSelectedOrganizationId,
    enableSchoolsToSelect,
    setEnableSchoolsToSelect,
    schools,
    setSchools,
    allowLogout,
    setAllowLogout,
    confirmLogout,
    setConfirmLogout,
    navigateToBBcoms,
    setNavigateToBBcoms
  };
  return (
    <StateContext.Provider value={all}>{props.children}</StateContext.Provider>
  );

  function setSidebarState(flag: boolean) {
    setSidebarOpen(flag);
  }

  function setSidebarPrevState(flag: boolean) {
    setSidebarOpenPrev(flag);
  }

  function setImpersonatingState(flag: boolean) {
    setImpersonating(flag);
  }
};

export default StateContextProvider;
