import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    IconButton,
    Button,
    withStyles,
    Box,
} from '@material-ui/core';
import { ITableComponent } from './ITableComponent';
import TableComponentStyle from './TableComponentStyle';
import PersonIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import MembersRelation from '../MembersRelation/MembersRelation';
import ReadRateComponent from '../ReadRateComponent/ReadRateComponent';
import { SortIcon } from '../../Icons/SortIcon';
import { MessageIcon } from '../../Icons/MessageIcon';
import { StarIcon } from '../../Icons/StarIcon';
import InfoIcon from '../../Icons/InfoIcon';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { sortByKey } from '../../libs/sort.utils';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { StateContext } from '../StateContextComponent';
import { GroupType } from '../../Types/GroupType';
import { Checkbox } from '@material-ui/core';
import MuiTableCell from "@material-ui/core/TableCell";
import ActionableAlertBox from '../ActionableAlertBox/ActionableAlertBox';
import { OwnerApi } from '../../services/ChannelService';

const TableComponent = ({
    columnsConfig,
    rows,
    rowsAll,
    isParenDrawerOpen,
    noResultsFound,
    errorMessage,
    setGroup,
    readRate,
    loadMoreMembers,
    handleToUpdate,
    searchData,
    isReachChannel,
    channelId,
    loadMembersData,
    setisOwnerError,
    isLoggedInUserOwner,
    isParent,
    parentColumnConfig,
    ChannelCreator,
    setRemoveDisabled,
    setAllChecked,
    allChecked,
    roleValue
}: any) => {
    const classes = TableComponentStyle();
    const [openTooltip, setOpenTooltip] = useState(false);
    const { selectedOrganizationId } = useContext(StateContext);
    const [tableData, setTableData] = useState<ITableComponent>({
        data: [],
        sortedColumnName: 'name',
        isSortAscending: 0,
    });
    const [columns, setHeader] = React.useState(columnsConfig.desktop);
    const sortColumn = (column: any) => {
        const sortByColumnValue = column.id ? column.id : 'name';
        const sortType = column.sortType ? column.sortType : 'string';
        let dataToSort;
        if (searchData || roleValue) {
            dataToSort = tableData.data;
        } else {
            dataToSort = rowsAll;
        }
        if (tableData.isSortAscending == 2) {
            const sortedData = sortByKey(
                dataToSort,
                1,
                sortByColumnValue,
                sortType
            );
            handleToUpdate(sortedData);
            setTableData({
                ...tableData,
                sortedColumnName: sortByColumnValue,
                isSortAscending: 1,
            });
        } else {
            const sortedData = sortByKey(
                dataToSort,
                0,
                sortByColumnValue,
                sortType
            );
            handleToUpdate(sortedData);
            setTableData({
                ...tableData,
                sortedColumnName: sortByColumnValue,
                isSortAscending: 2,
            });
        }
    };

    const handleMemberSelect = (event: any) => {
        let itemName = String(event.target.name);
        let checked = event.target.checked;
        if (itemName === "checkAll") {
            setAllChecked(checked);
            for (var row in tableData.data) {
                if (tableData.data[row].personId != ChannelCreator && !tableData.data[row].isLoggedInUser) {
                    tableData.data[row].checkbox = checked
                }
            }
            setTableData({ ...tableData })
        }
        else {
            for (var row in tableData.data) {
                if (tableData.data[row].personId != ChannelCreator) {
                    if (String(tableData.data[row].personId) == String(itemName)) {
                        tableData.data[row].checkbox = checked
                        break
                    }
                }
            }
            setTableData({ ...tableData })
        }
    }
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
    const isTablet = useMediaQuery({
        query: '(min-width: 481px) and (max-width: 850px)',
    });
    const isDesktop = useMediaQuery({ query: '(min-width: 830px)' });
    const isLandscape = useMediaQuery({
        query: '(max-height: 500px)',
    });
    const [editOwnerConfirmation, setEditOwnerConfirmation] = useState({
        open: false,
        title: 'Are you sure?',
        userName: "",
        userId: "",
        userType: "",
    });
    const ownerroles = [8, 3, 4, 5, 6]

    const TableCell = withStyles({
        root: {
            borderBottom: !isMobile ? "0.0625rem solid rgba(205, 205, 205, 0.5)" : 'none',
        }
    })(MuiTableCell);
    const editOwnerConfirmationDialogStyle = {
        paper: {
            width: '27rem',
            maxWidth: '95%',
            minHeight: '18rem',
            margin: "0",
            padding: "0"
        },
        root: {
            padding: '0 16px',
            marginTop: '16px',
        },
        dialogActionsBtns: {
            flexWrap: "nowrap"
        },
    };

    const responsiveData = () => {
        if (isMobile) {
            setHeader(columnsConfig.mobile);
        }
        if (isTablet)
            !isParent ?
                !isParenDrawerOpen
                    ? setHeader(columnsConfig.tabletExpand)
                    : setHeader(columnsConfig.tablet)
                : !isParenDrawerOpen ? setHeader(parentColumnConfig) : setHeader(columnsConfig.parenttablet)

        if (!isMobile && !isTablet) {
            if (isParent) { setHeader(parentColumnConfig) }
            else
                setHeader(columnsConfig.desktop);
        }
    };

    useEffect(() => {
        responsiveData();
    });

    useEffect(() => {
        responsiveData();
        setOpenTooltip(false);
    }, [isParenDrawerOpen]);

    useEffect(() => {
        setTableData({ ...tableData, data: [...rows] });
    }, [rows]);
    useEffect(() => {
        const filteredMembers = tableData.data.filter(memberRow => memberRow.checkbox)
        setRemoveDisabled(filteredMembers.length > 0);
    }, [tableData]);

    const actionHandler = (personId: any, name: string) => {
        let DirectUserId;
        const domainId = localStorage.getItem('domainID');
        const userId = localStorage.getItem('userId');

        if (Number(userId) < Number(personId))
            DirectUserId = `${GroupType.DirectMessage}|${userId}|${domainId}|${personId}|${domainId}`;
        else
            DirectUserId = `${GroupType.DirectMessage}|${personId}|${domainId}|${userId}|${domainId}`;

        setGroup({
            name: name,
            id: DirectUserId,
            type: GroupType.DirectMessage,
            groupType: GroupType.DirectMessage,
            orgName: `${selectedOrganizationId.schoolId}`,
        });
    };

    const MessageButton = (props: any) => {
        const [openMessageTooltip, setOpenMessageTooltip] = useState(false);
        return <>
            <Tooltip
                id='Message'
                open={openMessageTooltip}
                interactive={true}
                placement={'top'}
                title='Message'
                arrow={true}
                classes={{
                    arrow: classes.arrow,
                    tooltip: classes.tooltip,
                    tooltipArrow: classes.Messagetooltip
                }}>
                <button
                    color='primary'
                    className={clsx(classes.messageBtn, classes.hoverOnIcon)}
                    data-testid={`action-button-${props.rowIndex}`}
                    onClick={props.handler}
                    onMouseEnter={() => {
                        setOpenMessageTooltip(!openMessageTooltip);
                    }}
                    onMouseLeave={() => {
                        setOpenMessageTooltip(false);
                    }}
                    onTouchStart={() => {
                        setOpenMessageTooltip(!openMessageTooltip);
                    }}
                    onTouchMove={() => {
                        setOpenMessageTooltip(false);
                    }}>
                    <MessageIcon />
                </button>
            </Tooltip>
        </>;
    }
    const ownerHandler = (name: string, personId: string, userType: string) => {
        setEditOwnerConfirmation({ ...editOwnerConfirmation, userName: name, userId: personId, userType: userType == 'owner' ? 'member' : 'owner', open: true });
    }
    const Ownerbutton = (props: any) => {
        const [openOwnerTooltip, setOpenOwnerTooltip] = useState(false);
        return <>
            {(!props.isLoggedInUser && isLoggedInUserOwner && props.personId != ChannelCreator) ? (
                <Tooltip
                    id='OwnerTooltip'
                    open={openOwnerTooltip}
                    interactive={true}
                    placement={'top'}
                    title={
                        (props.userType == 'owner') ?
                            ('Remove as owner') :
                            ('Set as owner')
                    }
                    arrow={true}
                    classes={{
                        arrow: classes.arrow,
                        tooltip: classes.tooltip,
                        tooltipArrow: classes.Ownertooltip
                    }}>
                    <button
                        color='primary'
                        className={clsx(classes.messageBtn, classes.hoverOnIcon)}
                        data-testid={`owner-icon-${props.rowIndex}`}
                        onClick={() => {
                            ownerHandler(props.name, props.personId, props.userType);
                        }}
                        onMouseEnter={() => {
                            setOpenOwnerTooltip(!openOwnerTooltip);
                        }}
                        onMouseLeave={() => {
                            setOpenOwnerTooltip(false);
                        }}
                        onTouchStart={() => {
                            setOpenOwnerTooltip(!openOwnerTooltip);
                        }}
                        onTouchMove={() => {
                            setOpenOwnerTooltip(false);
                        }}>
                        <StarIcon userType={props.userType} />
                    </button>
                </Tooltip>
            ) :
                props.userType == 'owner' ? (
                    <button
                        data-testid={`logged-owner-icon-${props.rowIndex}`}
                        disabled={true}
                        className={classes.messageBtn}>
                        <StarIcon userType={props.userType} />
                    </button>
                ) :
                    null
            }
        </>;
    }


    const renderSwitch = (
        columnId: any,
        cellValue: any,
        name: string,
        personId: number,
        rowData: any,
        rowIndex: number,
    ) => {
        switch (columnId) {
            case 'name':
                return (
                    <div className={classes.userNameContainer}>
                        <div className={classes.userNameStyle}>
                            <div>
                                {cellValue}

                                {isMobile || (isTablet && isParenDrawerOpen) ? (
                                    <div className={classes.roleStyle}>
                                        {rowData.role.substr(0, 1).toUpperCase() +
                                            rowData.role.slice(1).toLowerCase()}
                                    </div>
                                ) : null}
                            </div>
                            {isMobile && ownerroles.includes(rowData.roleID) && !isParent ?
                                (!rowData.isLoggedInUser && isLoggedInUserOwner ? (
                                    <Button
                                        startIcon={<StarIcon userType={rowData.userType} />}
                                        onClick={() => {
                                            ownerHandler(name, rowData.personId, rowData.userType)
                                        }}
                                    ></Button>
                                ) :
                                    rowData.userType === 'owner' ? (<Button startIcon={<StarIcon userType={rowData.userType} />}></Button>) :
                                        null
                                ) :
                                null
                            }
                        </div>
                        {isMobile || (isTablet && isParenDrawerOpen) ? (
                            <>
                                <div className={classes.relativeHeadingStyle}>
                                    <div>
                                        Relationship
                      <div className={classes.relativeStyle}>
                                            <MembersRelation relations={rowData.relationship} />
                                        </div>
                                    </div>
                                    {isMobile && !rowData.isLoggedInUser && !isParent ? (

                                        <Button
                                            data-testid='action-button'
                                            onClick={() => {
                                                actionHandler(personId, name);
                                            }}
                                            style={{ alignSelf: 'start' }}
                                            startIcon={<MessageIcon />}
                                        >
                                        </Button>
                                    ) : null}
                                </div>
                                {!isParent ?
                                    <>
                                        <div className={classes.relativeHeadingStyle}>Read Rate</div>
                                        <div>
                                            <ReadRateComponent isLoading={readRate.isLoading} readRateValue={readRate['data'][personId]} />
                                        </div>
                                    </>
                                    : null}
                            </>
                        ) : null}
                    </div>
                );
            case 'avatar':
                return (
                    <Avatar className={classes.small}>
                        <PersonIcon />
                    </Avatar>
                );
            case 'checkbox':
                return (!rowData.isLoggedInUser && rowData.personId != ChannelCreator ?
                    <Checkbox
                        name={rowData.personId}
                        data-testid={rowData.personId}
                        className={classes.checkboxStyle}
                        color='default'
                        icon={<span className={classes.checkboxIcon}></span>}
                        checkedIcon={<span className={classes.checkedIcon} />}
                        onClick={handleMemberSelect}
                        checked={rowData.checkbox}
                    /> : null
                );
            case 'role':
                return (
                    <div data-testid={`role-${rowIndex}`}>
                        {cellValue.substr(0, 1).toUpperCase() +
                            cellValue.slice(1).toLowerCase()}
                    </div>
                );
            case 'relationship':
                return <MembersRelation relations={cellValue} />;
            case 'readRate':
                return <ReadRateComponent
                    isLoading={readRate.isLoading}
                    readRateValue={readRate['data'][personId]}
                />
            case 'action':
                return (
                    <>
                        {
                            !rowData.isLoggedInUser && (
                                <MessageButton rowIndex={rowIndex} handler={() => { actionHandler(personId, name) }} />
                            )}
                    </>
                );
            case 'owner':
                return (
                    <>
                        {(
                            ownerroles.includes(rowData.roleID) && (
                                <Ownerbutton name={name} personId={rowData.personId} userType={rowData.userType} rowIndex={rowIndex} isLoggedInUser={rowData.isLoggedInUser} />
                            )
                        )}
                    </>
                );
            default:
                return <div className={classes.userNameStyle}>{cellValue}</div>;
        }
    };

    const cellRenderer = (
        cellValue: string,
        column: any,
        name: string,
        personId: number,
        rowData: any,
        rowIndex: number,
    ) => {
        return (
            <TableCell
                key={column.id}
                align={column.align}
                className={`${classes.cellStyle} ${column.id === 'avatar' && classes.avatarCellStyle} ${column.id === 'name' && classes.nameCellStyle} ${column.id === 'checkbox' && classes.checkboxCellStyle}`}
                style={{ width: column.width }}
            >
                <div
                    className={clsx(classes.name, {
                        [classes.actionColumn]: column.id === 'action',
                        [classes.ownerColumn]: column.id === 'owner',
                        [classes.avatarColumn]: column.id === 'avatar',
                    })}
                    style={{ minWidth: column.cellMinWidth }}

                >
                    {renderSwitch(column.id, cellValue, name, personId, rowData, rowIndex)}
                </div>
            </TableCell>
        );
    };
    const updateOwnerDetails = () => {
        OwnerApi(channelId, editOwnerConfirmation.userId, editOwnerConfirmation.userType).then((response: any) => {
            loadMembersData();
        }).catch((error) => {
            console.log('error', error)
            if (error.response.status == 401) {
                setisOwnerError({ 
                    isError: false,
                    isMembersTabError: true, 
                    severity: 'ownerPrivilegesErr',
                    message: editOwnerConfirmation.userType == 'owner' ? 
                    'Failed to make as owner. Please contact administrator.' :
                    'Failed to remove as owner. Please contact administrator.' })
            }else{
                editOwnerConfirmation.userType == 'owner' ? 
                (setisOwnerError({ isError: false, isMembersTabError: true, message: 'Failed to make as owner ', severity: 'ownerPrivilegesErr'})) : 
                (setisOwnerError({ isError: false, isMembersTabError: true, message: 'Failed to remove as owner ', severity: 'ownerPrivilegesErr'}))
            }
        })
    }

    return (
        <>
            <ActionableAlertBox
                data-testid='ownerConfirmationBox'
                title={editOwnerConfirmation.title}
                shouldOpen={editOwnerConfirmation.open}
                disableBackdropClick={true}
                onAction={() => {
                    updateOwnerDetails();
                    setEditOwnerConfirmation({ ...editOwnerConfirmation, open: false });
                }}
                secondaryButtonLabel='No'
                primaryButtonLabel='Yes'
                styles={editOwnerConfirmationDialogStyle}
                secondaryButtonProps={{}}
                primaryButtonProps={{}}
                onClose={() => {
                    setEditOwnerConfirmation({
                        ...editOwnerConfirmation,
                        open: false,
                    });
                }}
            >
                <Box data-testid='ownerConfirmationMessage' className={classes.ownerPopupStyle}>
                    {(editOwnerConfirmation.userType === 'member') ? (
                        <Box>{`Do you want to remove ${editOwnerConfirmation.userName} from owner?They cannot edit or delete the channel.`}</Box>
                    ) : (
                            <Box>{`Do you want to make ${editOwnerConfirmation.userName} as an owner?They can edit or delete the channel.`}</Box>
                        )}
                </Box>
            </ActionableAlertBox>
            <TableContainer
                className={`${classes.container} ${classes.customScroll}`}
                onScroll={(event: any) => {
                    setOpenTooltip(false);
                    loadMoreMembers(event);
                }}
            >
                <Table stickyHeader aria-label='sticky table'>
                    {!isMobile && !isLandscape &&
                        <TableHead>
                            <TableRow className={classes.tableHeadRow}>
                                {columns.map((column: any) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        className={classes.tableHeadBorder}
                                        data-testid={`header-${column.id}`}
                                    >
                                        {column.id === 'checkbox' ?
                                            <Checkbox
                                                className={classes.checkboxStyle}
                                                name='checkAll'
                                                color='default'
                                                data-testid="header-checkbox-cell"
                                                icon={<span className={classes.checkboxIcon}></span>}
                                                checkedIcon={<span className={classes.checkedIcon} />}
                                                disabled={!rows.isLoggedInUser && rows.personId != ChannelCreator ? false : true}
                                                onClick={handleMemberSelect}
                                                checked={allChecked}
                                            />
                                            :
                                            column.label}
                                        {column.id === 'readRate' && (
                                            <Tooltip
                                                open={openTooltip}
                                                interactive={true}
                                                placement={'bottom-start'}
                                                title={
                                                    <React.Fragment>
                                                        <Typography className={classes.tooltipHeader}>
                                                            Read rate
                          </Typography>
                                                        <p>
                                                            The read rates help you gage the read activity of
                                                            channel members. The rate is a pecentage of sent
                                                            messages a member has viewed over the last 30 days.
                                                            Viewing the channel does not ensure the user has
                                                            actually read the message contents. If there is no
                                                            activity within the last 30 days read rates will not
                                                            be displayed.
                          </p>
                                                    </React.Fragment>
                                                }
                                                arrow={true}
                                                classes={{
                                                    arrow: classes.arrow,
                                                    tooltip: classes.tooltip,
                                                }}
                                            >
                                                <IconButton
                                                    color='primary'
                                                    style={{ backgroundColor: "transparent" }}
                                                    className={classes.infoIcon}
                                                    onMouseEnter={() => {
                                                        setOpenTooltip(!openTooltip);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setOpenTooltip(false);
                                                    }}
                                                    onTouchStart={() => {
                                                        setOpenTooltip(!openTooltip);
                                                    }}
                                                    onTouchMove={() => {
                                                        setOpenTooltip(false);
                                                    }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {column.label && column.allowSorting && !(isMobile || (isTablet && isParenDrawerOpen)) && (
                                            <SortIcon
                                                handler={() => {
                                                    sortColumn(column);
                                                }}
                                                sortBy={
                                                    tableData.sortedColumnName === column.id
                                                        ? tableData.isSortAscending
                                                        : 0
                                                }
                                                id={column.id}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                    }
                    <TableBody>
                        {tableData.data && tableData.data.map((rowData: any, rowIndex: number) => (
                            <>
                                {isMobile && <br />}
                                <TableRow
                                    data-testid={`row-${rowIndex}`}
                                    key={rowIndex}
                                    hover
                                    role='checkbox'
                                    tabIndex={-1}
                                >
                                    {columns.map((column: any) =>
                                        cellRenderer(
                                            rowData[column.id],
                                            column,
                                            rowData.name,
                                            rowData.personId,
                                            rowData,
                                            rowIndex
                                        )
                                    )
                                    }
                                </TableRow>
                            </>
                        ))}
                        {noResultsFound && (
                            <TableRow>
                                <TableCell colSpan={6} className={classes.alertSpacing}>
                                    {errorMessage === 'No results found !' ? (
                                        errorMessage
                                    ) : (
                                            <Alert severity='error'>{errorMessage}</Alert>
                                        )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

};

export default TableComponent;