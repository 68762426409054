import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SettingConfirmationWindowStyles from './SettingConfirmationWindowStyles';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import  DialogTitle  from '../ReusableComponents/ReachDialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import  DialogContentText  from '@material-ui/core/DialogContentText';
import { PrimaryButton, DefaultButton } from '../ReusableComponents/Button';
import CustomRadio from '../ReusableComponents/RadioButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { SchoolItem } from '../../Types/SchoolItem';
import SettingConfirmationDialogTypes from './SettingConfirmationWindowTypes';

const SettingConfirmationDialog = ({
  openDialog,
  dialogTitle,
  dialogContent,
  isEnablePopUp,
  handleCloseDialog,
  handleSaveCloseDialog,
}: SettingConfirmationDialogTypes) => {
  const DialogShape = makeStyles((theme) => ({
    paper: {
      minHeight: '70px',
      maxWidth: '350px',
    },
  }));
  const radioButtonHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value === 'true' ? setRadioValue(true) : setRadioValue(false);
  };

  const [radioValue, setRadioValue] = React.useState(true);

  let classes = SettingConfirmationWindowStyles();
  const dialogId = 'setting-confirmation-popup';
  useEffect(() => {
    setRadioValue(true)
}, [openDialog])

  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth='sm'
        onClose={handleCloseDialog}
        aria-labelledby={`${dialogId}-dialog-title`}
        classes={DialogShape()}
      >
        <DialogTitle hideCloseButton css={null} id={`${dialogId}-dialog-title`}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent >
          <DialogContentText className={classes.ContentColor} id={`${dialogId}-dialog-description`}>
            {dialogContent}
          </DialogContentText>
          {isEnablePopUp && (
            <>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>Channel Status</FormLabel>
                <RadioGroup
                  aria-label='Favorite color'
                  value={radioValue}
                  name='color'
                >
                  <FormControlLabel
                    value='enable'
                    control={
                      <CustomRadio
                        value={true}
                        onChange={radioButtonHandler}
                      />
                    }
                    label='Enabled channels for classes and groups'
                  />
                  <FormControlLabel
                    value='disable'
                    control={
                      <CustomRadio
                        value={false}
                        onChange={radioButtonHandler}
                      />
                    }
                    label='Disable channels for classes and groups'
                  />
                </RadioGroup>
              </FormControl>
              <div className={classes.itatlicContent}>
                <DialogContentText
                  className={classes.ContentColor}
                  id={`${dialogId}-dialog-disclaimer-description`}
                >
                  Settings will affect all current and future SIS imported
                  classes and groups, and can be overwritten at any time.
                </DialogContentText>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <DefaultButton
            css={null}
            id={`${dialogId}-primary`}
            onClick={handleCloseDialog}
          >
            Cancel
          </DefaultButton>
          <PrimaryButton
            css={null}
            id={`${dialogId}-default`}
            onClick={() => {
              isEnablePopUp? handleSaveCloseDialog(isEnablePopUp, radioValue): handleSaveCloseDialog(isEnablePopUp, false)
            }}
          >
            {isEnablePopUp ? 'Save' : 'Disable'}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SettingConfirmationDialog;