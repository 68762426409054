import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';
import { get_cluster } from '../components/clusterGlobal';

export const getUserAccount = async (personId: string, domain: string) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.account,
    method: 'GET',
    headers: {
      PersonId: personId,
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
    },
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

export const getUserGroup = async (
  personId: string,
  domain: string,
  domainId: string = '',
  impersonatedUserId: string
) => {
  var result = null;
  let queryParams = new URLSearchParams();
  
  if (impersonatedUserId) {
    queryParams.append('impersonatedUserId', impersonatedUserId);
  }

  const options: AxiosRequestConfig = {
    url: API.userGroup,
    method: 'GET',
    headers: {
      PersonId: personId,
      bbDomain: domain,
      domainId: domainId,
      cluster: get_cluster()
    },
    params: queryParams,
    withCredentials: true
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

export const getUserMessage = async (
  impersonatedUserId: string,
  personId: string,
  domain: string,
  domainId: string,
  orgId: string,
  groupId: string,
  channelType: string,
  directContactId: string = '',
  offset: string = '',
  lastdate: Number = 0,
  limit: Number = 10,
  messageId: string = '',
  scrollup: boolean = true
) => {
  var result = null;
  var queryParams = new URLSearchParams();
  queryParams.append('channelType', channelType.toString());
  if (directContactId !== '') {
    queryParams.append('directContactId', directContactId);
  }
  if (offset !== '') {
    queryParams.append('offset', offset.toString());
  }

  if (lastdate !== 0) {
    queryParams.append('lastdate', lastdate.toString());
  }
  if (messageId !== '') {
    queryParams.append('messageId', messageId.toString());
  }
  if (!scrollup) {
    queryParams.append('scrollup', 'false');
  }
  if (impersonatedUserId){
     queryParams.append('impersonatedUserId',impersonatedUserId);
   }
  queryParams.append('limit', limit.toString());
  const options: AxiosRequestConfig = {
    url: API.messages,
    method: 'GET',
    headers: {
      PersonId: personId,
      bbDomain: domain,
      orgId: orgId,
      groupId: groupId,
      domainId: domainId,
    },
    params: queryParams,
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

export const setGroupRead = async (
  personId: string,
  domain: string,
  groupId: string,
  channelType: string,
  domainId: string,
  schoolId: string
) => {
  var result = null;
  var queryParams = new URLSearchParams();
  queryParams.append('channelType', channelType.toString());
  queryParams.append('schoolId', schoolId);
  const options: AxiosRequestConfig = {
    url: API.markRead,
    method: 'GET',
    headers: {
      PersonId: personId,
      bbDomain: domain,
      groupid: groupId,
      domainid: domainId,
      cluster: get_cluster(),
    },
    params: queryParams,
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

export const getGroupMembers = async (
  channelType: string,
  domain: string,
  groupId: string
) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.groupMembers
      .replace('<channelType>', channelType),
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
      groupId: groupId,
    },
    withCredentials: true,
  };
  
  result = await axios(options);
  return result;
};


export const getChannelReadRate = async (
  domain: string,
  groupid: string,
  groupType: string,
  userIds: Array<any>
  ) => {
  let result = null;

  var queryParams = new URLSearchParams();
  queryParams.append('channelType', groupType)

  const options: AxiosRequestConfig = {
    url: API.readrateApi,
    method: 'GET',
    headers: {
      bbdomain: domain,
      domainid: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      groupid: groupid,
      userids: JSON.stringify(userIds),
    },
    params: queryParams,
    withCredentials: true,
  };

  result = await axios(options);
  return result;
};

export const getSearchMembers = async (
  name: string,
  domain: string,
  schoolId: string,
  personId: string
) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.searchMembers
      .replace('<name>', name)
      .replace('<schoolId>', schoolId)
      .replace('<personId>', personId),
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

export const getAddChannelMembersSearch = async (
  name: string,
  domain: string,
  schoolId: string,
  channelId: string,
) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.addChannelMemberssearch
      .replace('<name>', encodeURIComponent(name))
      .replace('<schoolId>', schoolId)
      .replace('<channelId>', channelId)
      .replace('<addChannelMembersearch>', "true"),
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId:localStorage.getItem('userId'),
      orgId:localStorage.getItem('reachOrgSchoolId'),
      cluster: get_cluster(),
    },
    withCredentials: true,
  };
  result = await axios(options);
  return result;
};

export const getOriginalMessage = async (
  domain: string,
  groupid: string,
  messageId: string
) => {
  let apiUrl = API.originalMsg;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'GET',
    headers: {
      bbdomain: domain,
      groupid: groupid,
      messageid: messageId,
      domainid: localStorage.getItem('domainID'),
      personid: localStorage.getItem('userId'),
    },
    withCredentials: true,
  };

  let result = await axios(options);
  return result;
}

export const getTranslatedMessage = async (
  recipientPreferredLang: string,
  message: string,
  domain: string
) => {
  let apiUrl = API.translateApi;

  const options: AxiosRequestConfig = {
    url: apiUrl,
    method: 'POST',
    data: {
      message: [message],
      target: recipientPreferredLang
    },
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
    },
    withCredentials: true,
  };

  let result = await axios(options);
  return result;
}

export const getMessageDetails = async (
  personId: string,
  domain: string,
  domainId: string,
  messageId: string = '',
) => {
  let result = null;
  let queryParams = new URLSearchParams();

  queryParams.append('individualMessage', 'true')
  queryParams.append('messageId', messageId.toString());
  queryParams.append('limit', '1');
  queryParams.append('isUserImpersonate', 'false');
  queryParams.append('channelType', ' ');

  const options: AxiosRequestConfig = {
    url: API.messages,
    method: 'GET',
    headers: {
      personId: personId,
      bbDomain: domain,
      domainId: domainId,
    },
    params: queryParams,
    withCredentials: true
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.error(e.message);
    throw new Error('Network Error')
  }
  return result;
}

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});