import React, { useState, useEffect } from 'react'
import { useMessageRoute } from '../../libs/hookLib'
import MessageRouteContext from '../../context/MessageRouteContext';
import { Box } from '@material-ui/core';
import { getMessageDetails } from '../../services/userAccountServices'
import { GroupType } from '../../Types/GroupType';
import CustomCircularProgress from '../ReusableComponents/CircularProgress';

const PreRoute = ({render}: any) => {
  const [messageDetails, setMessageDetails] = useState<any>({
    channelId: null, 
    schoolId: null,
    createdAt: null,
    channelType: null
  })

  let { isMatch: isMessageRoute, messageId, isNextPathMessage, handleInvalidRoute } = useMessageRoute()

  const [showLoader, setShowLoader] = useState(isMessageRoute)
  const [messageLoaded, setMessageLoaded] = useState(false)

  const extractSchool = (school: string = '') => school.trim().split('|')[0]
  const extractChannel = (channel: string = '') => {
    if (channel.trim().split('|')[0] === "CLASSES"){
      var splitted_channel = channel.trim().split('|');
      return splitted_channel[1] + "|" + splitted_channel[2]
    } else {
      return channel.trim().split('|')[1]
    }
  }

  const isNotAuthenticated = isMessageRoute && (localStorage.getItem('userId') == '' ||  localStorage.getItem('userId') == null)

  useEffect(() => {
    const fetchMessage = async () => {
      setShowLoader(true)
      const requiredSafeParams: any = {
        channelId: null, 
        schoolId: null, 
        createdAt: null, 
        details: { channelType: null }, 
        recipient: null
      }

      let minimalMessageDetails: any = {...requiredSafeParams}

      try {
        const response = await getMessageDetails(
          localStorage.getItem('userId') ?? '',
          localStorage.getItem('bbDomain') ?? '',
          localStorage.getItem('domainID') ?? '',
          messageId as any as string
        )

        minimalMessageDetails = (response?.data) ?? {...requiredSafeParams}
        let selectedChannelId = minimalMessageDetails?.channelId
        if([GroupType.Groups, GroupType.Classes, GroupType.Channel].includes(minimalMessageDetails?.details?.channelType)) {
          selectedChannelId = extractChannel(minimalMessageDetails.channelId)
        }
        minimalMessageDetails.channelId = selectedChannelId
        minimalMessageDetails.schoolId = extractSchool(minimalMessageDetails.schoolId)
        
      } catch(error) {
        minimalMessageDetails = {...requiredSafeParams}
        handleInvalidRoute()
      }

      const {channelId = null, schoolId = null, createdAt = null, details: { channelType = null }, recipient = null} = minimalMessageDetails

      const loggedInUser = `${localStorage.getItem('userId')}|${localStorage.getItem('domainID')}`

      if(!recipient || (recipient && (recipient.trim() != loggedInUser.trim()))) {
        handleInvalidRoute()
      }

      if(!isNotAuthenticated && !channelId || !schoolId || !createdAt || !channelType) {
        setShowLoader(false)
        handleInvalidRoute()
      }

      setMessageDetails({
        channelId,
        schoolId,
        createdAt,
        channelType
      })

     setMessageLoaded(true)
    }
    isMessageRoute && !isNotAuthenticated && fetchMessage()

    return () => {
      setShowLoader(false)
    }
  }, [messageId])

  useEffect(() => {
    isMessageRoute && messageDetails.channelId && messageDetails.schoolId && setShowLoader(false)
    isNotAuthenticated && setShowLoader(false)
  }, [messageDetails])
  
  return (
    <>
      {showLoader && <Box style={{ position: 'absolute', top: '50%', left: '50%'}}>
        <CustomCircularProgress
          aria-label='Loading'
          size='medium'
        />
      </Box>}
      {!showLoader && 
        <MessageRouteContext.Provider value={{
          isMessageRoute,
          messageId,
          channelId: messageDetails.channelId,
          schoolId: messageDetails.schoolId,
          createdAt: messageDetails.createdAt,
          channelType: messageDetails.channelType
        }}>
          {render(!(isNextPathMessage || (isMessageRoute && !messageLoaded)))}
        </MessageRouteContext.Provider>}
    </>
  )
}

export default PreRoute