import { makeStyles } from '@material-ui/core/styles';
import { ImportantDevices } from '@material-ui/icons';

const MembersPanelStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'None!important',
        border: 'None!important',
    },
    container: {
        maxHeight: 440,
    },
    memberDiv: {
        margin: '20px 20px 0 20px',
    },
    memberTitle: {
        fontSize: '18px',
        fontWeight: 600,
    },
    searchBoxWidth: {
        padding: '0.5rem 0!important',
        width: '100%',
    },
    selectBoxWidth: {
        margin: '8px 0',
        width: '100%',
    },
    iconPadding: {
        marginRight: '5px!important',
    },
    gridRightDiv: {
        display: 'flex',
        margin: '8px 0',
        gridGap: '10px',
        justifyContent: 'right',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    gridRightDivOpened: {
        display: 'flex',
        margin: '8px 0',
        gridGap: '10px',
        justifyContent: 'right',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            gridGap: '10px',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            gridGap: '10px',
        },
    },
    addButtonStyle: {
        fontWeight: 600,
        backgroundColor: '#CDCDCD',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '&:hover': {
            backgroundColor: '#CDCDCD',
            border: '1px solid #000000',
        },
    },
    removeButtonStyle: {
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#CDCDCD',
        },
    },
    progress: {
        height: '3px',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1),
        },
        '& div': {
            backgroundColor: '#FF5991',
        },
    },
    halfWidth: {
        width: '50%',
    },
    attach_icon_style: {
        padding: '3px',
    },
    delete_icon_style: {
        padding: '3px',
    },
    RemoveMemberStyle: {
        height: '21px',
        fontWeight: 400,
        fontSize: '14px',
        alignItems: 'center',
        textAlign: 'center'
    }
}));

export default MembersPanelStyle;
