export class UserProfileInfo {
  public name: string = "";
  public available: boolean = false;
  public role: string = "";
  public profileImage: string = "";
}

//sample data

//name: "Craig Bothman",
//available: false,
//role: "Teacher",
//profileImage: "prince.jpg",
