import React, { useState, useCallback } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { TextField } from '@material-ui/core';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import AutoCompleteTypes from './AutoCompleteTypes'
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    autocomplete: {
      display: 'flex',
      flex: 1
    },
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column'
    },
    option: {
      '&[data-focus="true"]': {
        textDecoration: 'none',
        backgroundColor: 'rgba(38, 38, 38, 0.06)'
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.white
      }
    },
    endAdornment: {
      position: 'absolute',
      right: 0,
      top: 'calc(50% - 18px)'
    },
    focused: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
        borderColor: '#262626',
      },
    },
  })
});

const AutoComplete = ({
  mode,
  id = undefined,
  noOptionsText = 'No options',
  placeholder,
  isFreeForm = false,
  dataFormatter,
  disabled = false,
  value = null,
  optionsPromise = () => Promise.resolve(false),
  optionsArray = [],
  onChange = () => {},
  getOptionLabel = (item: any): string => item, 
  getOptionSelected = (): boolean => false,
  renderOption = () => <></>,
  expanded,
  setExpanded,
  onChangeInput = () => { },
  onOpen = () => { setExpanded(true) },
  filterOptions = undefined,
}: AutoCompleteTypes<any>) => {
  const [options, setOptions] = useState([]);

  const classes = useStyles();
  const loading = expanded && options.length === 0;

  const handleModePromise = async (optionsPromise: Function) => {
    const response = await optionsPromise();
    const resolved = await response.json();
    const items = dataFormatter(resolved);
    
    return items;
  }

  const handleModeData = useCallback((optionsArray: Array<any>, dataFormatter: Function) => {
    return dataFormatter(optionsArray)
  }, []);

  React.useEffect(() => {
    let operations = true;

    if(!loading) {
      return undefined;
    }

    if(operations) {
      let listItems = (mode === 'promise' 
        ? handleModePromise(optionsPromise)
        : handleModeData(optionsArray, dataFormatter));

      (mode === 'data') && setOptions(listItems);
      
      (mode === 'promise') && (async () => {
        listItems = await listItems;
        setOptions(listItems);
      })();
    }
    
    return () => {
      operations = false;
    }
  }, [loading])

  return (
    <div>
      <Autocomplete
        id={id}
        freeSolo={isFreeForm}
        disableClearable
        autoSelect
        disableListWrap
        disabled={disabled}
        data-testid='form-school'
        classes={{option: classes.option, endAdornment: classes.endAdornment}}
        options={options}
        open={expanded}
        loading={loading}
        noOptionsText={noOptionsText}
        value={value}
        onOpen={onOpen}
        onClose={() => { setExpanded(false) }}
        onChange={onChange}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField 
            {...params}
            onChange={onChangeInput}
            placeholder={placeholder}
            className={classes.autocomplete}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              classes: {focused:classes.focused},
              ...isFreeForm && {endAdornment: (
                <>
                  {loading ? <CircularProgress color='inherit' size={20} /> : null}
                  {expanded ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                </>
              )}
            }}
          />
        )}
      />
    </div>
  )
}

export default AutoComplete;
