import React, { useState, useEffect, useContext } from 'react';
import { AddWithCircleIcon } from '../Icons/AddWithCircleIcon';
import { Discussion } from '../Icons/Discussion';
import DirectMessageComponentStyle from '../styles/DirectMessageComponentStyle';
import { List, Box } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { GroupEssential } from '../Types/GroupEssential';
import { GroupType } from '../Types/GroupType';
import { DirectMessageContact } from '../Types/DirectMessageContact';
import CreateDirectMsgDialog from './CreateDirectMsgDialog';
import { GroupUnread } from '../Types/GroupUnread';
import { setGroupRead } from '../services/userAccountServices';
import { useMediaQuery } from 'react-responsive';
import { orgList, setDirectUnreadCount } from '../dataLoaders/DataHandler';
import { StateContext } from './StateContextComponent';
import { SchoolInfo } from '../Types/SchoolInfo';
import {
  loadDirectContactData,
  directContactList,
  setGroupUnreadCount,
} from '../dataLoaders/DataHandler';
import { OrganizationInfo } from '../Types/OrganizationInfo';
import { UserAuthCtx } from './AuthComponent';
import MessageRouteContext, {
  MessageRouteContextType,
} from '../context/MessageRouteContext';
import { useMessageRoute } from '../libs/hookLib';
import ActionableAlertBox from './ActionableAlertBox/ActionableAlertBox';
import AlertBox from './AlertBox/AlertBox'
import CustomCircularProgress from './ReusableComponents/CircularProgress';


type DirectMessageComponentType = {
  personId: string;
  domain: string;
  selectedGroup: GroupEssential;
  directUnread: GroupUnread;
  setGroup: React.Dispatch<React.SetStateAction<GroupEssential>>;
  setOrgListCountDirect: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  setParentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  directChannelIDFromMessageQueue: DirectMessageContact;
  impersonating: boolean;
  selectMyGroup: string;
  setSelectMyGroup: React.Dispatch<React.SetStateAction<string>>;
  setMessagePanelLoading?: Function;
  setPanelLoading?: Function;
  setConversationTitle?: Function;
  activePanel?: number;
  setActivePanel?: Function;
};
const DirectMessageComponent = ({
  personId,
  domain,
  selectedGroup,
  setGroup,
  directUnread,
  setOrgListCountDirect,
  setParentDrawerOpen,
  directChannelIDFromMessageQueue,
  impersonating,
  selectMyGroup,
  setSelectMyGroup,
  setMessagePanelLoading = () => {},
  setConversationTitle = () => {},
  activePanel = 0,
  setActivePanel = () => {},
}: DirectMessageComponentType) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const classes = DirectMessageComponentStyle()({
    isMobile,
  });
  var [contacts, SetContacts] = useState<Array<DirectMessageContact>>(
    new Array<DirectMessageContact>()
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isProcessing, setIsProcessing] = useState(true);
  const [isEmpty, setIsEmpty] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [debouncedTime, setDebouncedTime] = useState(0);
  function handleClickOpen() {
    setOpenDialog(true);
  }
  function handleClose() {
    setOpenDialog(false);
  }

  const directMessageOnClickHandler = () => {
    if (localStorage.getItem("filelength") !== "0" && localStorage.getItem("filelength") !==null)
    {
      setUnloadConfirmation({...unloadConfirmation,open: true, message:"Your are not able to initiate direct chat.\nPlease remove attachments and try again!",direct:true});
      return false;
    }
    handleClickOpen();
  };

  let schoolInfo = new SchoolInfo();
  const { impersonatingUserDetails, selectedOrganizationId } = useContext(
    StateContext
  );
  const authContext = useContext(UserAuthCtx);
  var domainId = authContext.userCtx.domainId;

  const { isMessageRoute, channelType: channelTypeFromRoute = '' } = useContext(
    MessageRouteContext
  ) as MessageRouteContextType;
  let { handleInvalidRoute } = useMessageRoute();

  //check if user is impersonated, then use impersonating user school id and name
  if (impersonating) {
    const impersonatedSchool = orgList.find(
      (org) => org.id === parseInt(impersonatingUserDetails.schoolId)
    );
    if (impersonatedSchool) {
      schoolInfo = {
        schoolId: impersonatedSchool.id,
        schoolName: impersonatedSchool.name,
      };
    }
  } else {
    schoolInfo = selectedOrganizationId;
  }

  useEffect(() => {
    var contactFromQueue;
    if (directContactList) {
      contactFromQueue = directContactList.find(
        (contact) =>
          contact.id === directChannelIDFromMessageQueue?.id &&
          contact.schoolId.includes(directChannelIDFromMessageQueue?.schoolId)
      );
    }

    if (
      typeof contactFromQueue === 'undefined' &&
      directChannelIDFromMessageQueue
    ) {
      setIsProcessing(true);
      contacts = new Array<DirectMessageContact>();
      SetContacts(contacts);

      if (directChannelIDFromMessageQueue.id) {
        var contact = new DirectMessageContact();
        contact.id = directChannelIDFromMessageQueue.id;
        contact.username = directChannelIDFromMessageQueue.username;
        contact.schoolId = directChannelIDFromMessageQueue.schoolId;
        directContactList.push(contact);
        mapContactList();
        setDirectUnreadCount(contact?.id, contact?.schoolId, 1);
        setOrgListCountValue();
        directContactList?.length === 0 && setIsEmpty(true);
        setIsProcessing(false);
      }
    } else {
      if (contactFromQueue?.id !== selectedGroup.id || isMessageRoute) {
        setDirectUnreadCount(
          contactFromQueue?.id,
          contactFromQueue?.schoolId,
          1
        );
      }
    }

    setIsProcessing(false);
  }, [directChannelIDFromMessageQueue]);

  function mapContactList() {
    const mappedContacts = directContactList?.filter(
      (contact: DirectMessageContact, index: number) => {
        if (impersonating) {
          return (
            schoolInfo.schoolId === parseInt(contact.schoolId.split('|')[0])
          );
        } else {
          return (
            selectedOrganizationId.schoolId.toString() ===
            contact.schoolId.split('|')[0]
          );
        }
      }
    );

    SetContacts(mappedContacts);
    if (directContactList?.length !== 0 && mappedContacts?.length == 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }

  useEffect(() => {
    if (directUnread.id !== null) {
      setOrgListCountValue();
    }
  }, [directUnread]);

  useEffect(() => {
    mapContactList();
    setOrgListCountValue();
  }, [directContactList]);

  function setDirectUnread(directUnread: GroupUnread) {
    directContactList.map((contact) => {
      if (contact.id === directUnread.id) {
        contact.unreadCount = 0;
      }
    });
  }

  const loadContacts = async () => {
    if (
      directContactList &&
      directContactList.length == 0 &&
      personId &&
      domain &&
      selectedOrganizationId.schoolId != 0
    ) {
      loadDirectContactData(personId, domain, domainId, impersonating ? impersonatingUserDetails.impersonatedUserId: '').then(() => {
        mapContactList();
        setOrgListCountValue();
        directContactList?.length === 0 && setIsEmpty(true);
        setIsProcessing(false);
      });
    } else {
      mapContactList();
      setOrgListCountValue();
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    setSelectedIndex(-1);
    setIsProcessing(true);
    contacts = new Array<DirectMessageContact>();
    SetContacts(contacts);
    loadContacts();
  }, [selectedOrganizationId]);

  function setOrgListCountValue() {
    const orgListCountLocal: { [key: string]: number } = {};
    const orgListMap: { [key: string]: string } = {};
    orgList.forEach((org: OrganizationInfo, index) => {
      orgListMap[org.id.toString()] = org.name;
    });
    directContactList?.map((contact: DirectMessageContact, index) => {
      let schoolIdTemp = contact.schoolId.split('|')[0];
      if (orgListCountLocal[orgListMap[schoolIdTemp]] === undefined)
        orgListCountLocal[orgListMap[schoolIdTemp]] = 0;
      orgListCountLocal[orgListMap[schoolIdTemp]] =
        orgListCountLocal[orgListMap[schoolIdTemp]] + contact.unreadCount;
    });
    setOrgListCountDirect(orgListCountLocal);
  }
  useEffect(() => {
    setOrgListCountValue();
    if (impersonating || isMessageRoute) groupSelector();
  }, [contacts]);

  function groupSelector() {
    //alert(selectMyGroup);
    let matchCounter = 0;
    if (selectMyGroup !== '') {
      for (var index = 0; index < contacts.length; index++) {
        var contact = contacts[index];
        //alert(contact.id + ' - ' + selectMyGroup);
        if (contact.id === selectMyGroup) {
          // simulate a channel click
          //groupListOnClick(groupToDisplay);
          var group = new GroupEssential();
          group.name = contact.username;
          group.id = contact.id;
          group.unreadCount = contact.unreadCount;
          group.groupType = GroupType.DirectMessage;
          group.type = GroupType.DirectMessage;
          setGroup(group);
          setSelectedIndex(index);
          setSelectMyGroup('');
          isMessageRoute && isMobile && setParentDrawerOpen(false);
          return;
        } else {
          matchCounter++;
        }
      }

      if (
        contacts.length &&
        isMessageRoute &&
        matchCounter == contacts.length &&
        channelTypeFromRoute?.toUpperCase() == GroupType.DirectMessage
      ) {
        // redirect to home page as the contact not found
        handleInvalidRoute();
      }
    }
  }

  useEffect(() => {
    if (selectedGroup.groupType === GroupType.DirectMessage) {
      //find the index value
      let indexValue = -1;
      contacts?.forEach((contact, number) => {
        // selection using group id
        if (contact.id === selectedGroup.id) {
          indexValue = contacts.indexOf(contact);
        }
      });
      if (indexValue === -1 && !impersonating) {
        //Add the DirectMessageContact
        var contact = new DirectMessageContact();
        contact.username = selectedGroup.name;
        contact.id = selectedGroup.id;
        contact.schoolId =
          selectedOrganizationId.schoolId.toString() + '|' + domainId;
        directContactList.push(contact);
        SetContacts((contacts) => [contact, ...contacts]);
        setSelectedIndex(0);
        setConversationTitle(contact.username);
        setIsEmpty(false);
      } else {
        debouncedTime == 0 && setSelectedIndex(indexValue);
      }
    }
  }, [selectedGroup]);

  const setGroupAndLoadMessages = (contact: any, index: any) => {
    var group = new GroupEssential();
    group.name = contact.username;
    group.id = contact.id;
    group.unreadCount = contact.unreadCount;
    group.groupType = GroupType.DirectMessage;
    group.type = GroupType.DirectMessage;
    setGroup(group);
  };

  useEffect(() => {
    activePanel == 0 && setSelectedIndex(-1);
  }, [activePanel]);


  const [unloadConfirmation, setUnloadConfirmation] = useState({
    open: false,
    title: 'Discard changes?',
    message: 'You have unsaved attachments. Are you sure you want to leave?',
    index: 0,
    direct:false,
    groups: {name: "", id: "", type:"", unreadCount:0, groupType: GroupType.Groups, orgName:"" },
  });

  const unloadConfirmationDialogStyle = {
    paper: {
      width: '19rem',
      height: '15rem',
    },
    root: {
      padding: '0 16px',
    },
  };

  const debouncedHandler = (contact: any, index: any) => {

    if (localStorage.getItem('uploadstatus') === "inprogress" && contact.id != selectedGroup.id)
    {
      setUnloadConfirmation({...unloadConfirmation,open: true,index: index, groups: contact});
      return true
    }
    setSelectedIndex(index);

    if (isMobile) {
      setParentDrawerOpen(false);
      setGroupAndLoadMessages(contact, index);
    } else {
      if (contact.id != selectedGroup.id) {
        setConversationTitle(contact.username);
        setMessagePanelLoading(true);

        clearInterval(debouncedTime);
        let timeOut = window.setTimeout(function () {
          setGroupAndLoadMessages(contact, index);
          setDebouncedTime(0);
        }, 500);
        setDebouncedTime(timeOut);
      } else {
        window.setTimeout(function () {
          setGroupAndLoadMessages(contact, index);
          setDebouncedTime(0);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (selectedIndex != -1) {
      // this sets direct mesasge component as active to unselects any item present in group component
      setActivePanel(1);
    } else {
      setActivePanel(0);
    }
  }, [selectedIndex]);

  var dataTestId = 'DM-list-item-';
  return (
    <div className={classes.root}>

    <ActionableAlertBox
        data-testid='unloadConfirmationBox'
        title={unloadConfirmation.title}
        shouldOpen={unloadConfirmation.open}
        onAction={() => {
          if(unloadConfirmation.direct === false)
          {
            localStorage.setItem('uploadstatus', 'verified')
            setConversationTitle(unloadConfirmation.groups);
            debouncedHandler(unloadConfirmation.groups, unloadConfirmation.index)
            setUnloadConfirmation({...unloadConfirmation,open: false, index: 0, direct:false});
          }
          else{
            setUnloadConfirmation({
              ...unloadConfirmation,
              open: false,
              direct: false,
              message: 'You have unsaved attachments. Are you sure you want to leave?',
            });
          }

        }}
        secondaryButtonLabel= 'Cancel'
        primaryButtonLabel= {unloadConfirmation.direct ? "Close" : "Discard"}
        styles={unloadConfirmationDialogStyle}
        secondaryButtonProps={{ style: {display: unloadConfirmation.direct ? "none" : "flex"}}}
        primaryButtonProps={{}}
        onClose={() => {
          setUnloadConfirmation({
            ...unloadConfirmation,
            open: false,
            direct: false,
          });
        }}
      >
        <Box paddingBottom='74px'>{unloadConfirmation.message}</Box>
      </ActionableAlertBox>

      <div
        className={
          impersonating
            ? classes.impDirectMessageHeaderClass
            : classes.directMessageHeaderClass
        }
      >
        <span className={classes.iconStyle}>
          <Discussion className={classes.iconFont} />
        </span>
        <span data-testid='dm-span' className={classes.spanMessageClass}>
          Direct Messages
        </span>
        {!impersonating && (
          <AddWithCircleIcon
            data-testid='DirectMessageIcon'
            className={classes.addCircleItemClass}
            onClick={directMessageOnClickHandler}
          ></AddWithCircleIcon>
        )}
        <CreateDirectMsgDialog
          openDialog={openDialog}
          handleClose={handleClose}
          domain={domain}
          personId={personId}
          setGroup={setGroup}
        />
      </div>
      <div>
        <List data-testid='dm-list'>
          {isEmpty ? (
            <ListItem className={classes.empty}>
              <Box>No direct messages</Box>
            </ListItem>
          ) : (
            <></>
          )}
          {isProcessing ? (
            <ListItem className={classes.empty}>
              <CustomCircularProgress
                css={classes.empty}
                aria-label='Loading'
                size='medium'
              />
            </ListItem>
          ) : (
            <></>
          )}
          {contacts?.map((contact, index) => {
            return (
              <ListItem
                className={classes.messageListItemClass}
                classes={{ selected: classes.selected }}
                button
                key={index}
                data-testid={dataTestId.concat(index.toString())}
                onClick={() =>
                  (isMobile || index != selectedIndex) &&
                  debouncedHandler(contact, index)
                }
                selected={selectedIndex === index}
              >
                <div className={classes.username}>
                  <span className={classes.userNameText}>
                    {contact.username}
                  </span>
                </div>
                {contact.unreadCount !== 0 && (
                  <div className={classes.notifyNumber}>
                    {contact.unreadCount}
                  </div>
                )}
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
  //}
};

export default DirectMessageComponent;
