import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';

const ChartDisplayCardStyle = makeStyles((theme) => ({
    root: {
        height: '100%',
        color: '#262626',
        boxShadow: 'none',
    },
    header: {
        paddingBottom: 0,
    },
    chartBox: {
        width: '100%',
        height: '100%',
        '& .highcharts-container': {
            width: '100% !important'
        },
        '& .highcharts-container svg': {
            width: '100% !important'
        }
    }
}));

export default ChartDisplayCardStyle;