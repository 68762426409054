import React from 'react'
import PageNavigationTypes from './PageNavigationTypes'
import { Box } from '@material-ui/core';

const PageNavigation = ({ 
  total = 0, 
  offset = 0, 
  currentPage = 0,
  viewingLabel = 'Viewing',
  offsetLabel = 'of'
}: PageNavigationTypes) => {
  return (
    <Box 
    display='flex' flexDirection='column' flex={1} 
    fontWeight={600} fontSize='12px' lineHeight='18px'>
      {`${viewingLabel} ${currentPage}-${offset} ${offsetLabel} ${total}`}
    </Box>
  )
}

export default PageNavigation;