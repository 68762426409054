import React from 'react'

function EditIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_566_23131)">
        <path d="M15 4.5L12 1.5L2 11.5L1 15.5L5 14.5L15 4.5Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 6.5L10 4.5" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 12.5L4 10.5" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_566_23131">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
    </svg>

  )
}

export default EditIcon