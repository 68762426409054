import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchResultPanelTypes from './SearchResultPanelTypes';
import MessageCard from '../MessageCard/MessageCard';
import ImpersonatingPanel from '../ImpersonatingPanel/ImpersonatingPanel';
import { StateContext } from '../StateContextComponent';
import Alert from '@material-ui/lab/Alert';
import AlertBox from '../AlertBox/AlertBox';
import { clearChannelList } from '../../dataLoaders/DataHandler';
import { GroupEssential } from '../../Types/GroupEssential';
import { getChannelStatusUsingId } from '../../services/ChatArchiveService';
import { UserAuthCtx } from '../AuthComponent';

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    emptyMessage: {
      fontWeight: 600,
      color: '#262626',
    },
    emptySubMessage: {
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    cardRoot: {
      display: 'table-row',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
      },
      '& .message-result-container': {
        position: 'relative',
      },
    },
    paginationLoader: {
      position: 'absolute',
      background: 'rgba(128, 128, 128, .3)',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 0,
      display: (props: any) => {
        return props.isLoaderVisible ? 'flex' : 'none';
      },
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '50%',
    },
    toaster: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      color: '#262626',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      background: '#FFB6AE',
    },
  });
});

export const highlightMatchedChars = (message: string, tokens: string[]) => {
  tokens = tokens.filter((token: string) => token.length);
  if (tokens.length === 0) {
    return message;
  }

  const matcher = tokens?.join('|');
  const replacer = (match: string) => `<span class="highlight">${match}</span>`;
  const betwenTagsRegex = new RegExp(`[\>][^\<\>]*${matcher}[^\<\>]*[\<]`,'gi');
  
  let replaced = message;

  if(betwenTagsRegex.test(message)) {
    replaced = message.replace(betwenTagsRegex, (item) => {
      var subRegex = new RegExp(matcher, 'gi');
      return item.replace(subRegex, replacer);
    });
  } else {
    replaced = message.replace(new RegExp(`(${matcher})`, 'gi'), replacer)
  }

  return replaced;
};

const EmptyBox = ({ emptyMessage, emptySubMessage }: any): any => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flex='1'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      <Box className={classes.emptyMessage}>{emptyMessage}</Box>
      <Box className={classes.emptySubMessage}>{emptySubMessage}</Box>
    </Box>
  );
};

export const Loader = () => {
  return (
    <Box
      data-testid='loader'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex='1'
    >
      <CircularProgress />
    </Box>
  );
};

const SearchResultPanel = ({
  emptyMessage = 'Find Messages',
  emptySubMessage = 'Enter a keyword and adjust filter controls.',
  isLoading = false,
  messages = null,
  highlightTokens = [],
  maxMessageLength = 0,
  isOverlayLoading = false,
  isError = false,
  errorMessage = undefined,
  loggedInUser
}: SearchResultPanelTypes) => {
  const classes = useStyles({ isLoaderVisible: isOverlayLoading });
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const { setImpersonatingUserDetails, setImpersonatingState } = useContext(
    StateContext
  );
  const [selectedGroup, setGroup] = React.useState<GroupEssential>(
    new GroupEssential()
  );
  const [isErrorState, setIsErrorState] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [useralertOpen, setUserAlertOpen] = useState(false);
  const [isParentDrawerOpen, setParentDrawerOpen] = useState(true);
  var domain = useContext(UserAuthCtx).userCtx.userLoggedInDomain;

  useEffect(() => {
    setIsErrorState(isError);
  }, [isError, isLoading]);

  const handleClickOpen = () => {
    setOpen(true);
    setImpersonatingState(true);
  };
  const handleClose = () => {
    setOpen(false);
    clearChannelList();
    setImpersonatingUserDetails({
      userId: '',
      userName: '',
      userLoggedInDomain: '',
      schoolId: null,
    });
    setGroup(new GroupEssential());
    setImpersonatingState(false);
    setParentDrawerOpen(true);
  };

  const trimMessage = useCallback(
    (content: string) => {
      let lookahead: string = '';
      let limitReached: boolean = false;
      if (content === undefined || content === "")
        return "";
      const betwenTagsRegex = new RegExp(`[\>][^\<\>]*[^\<\>]*[\<]`, 'gi');
      let trimmed = content.replace(betwenTagsRegex, (item) => {
        if (item == '><') return '><';

        item = item.slice(1, -1);

        if (lookahead.length >= maxMessageLength) {
          limitReached = true;
          return '><';
        } else {
          let toBeAttached = item.substr(
            0,
            maxMessageLength - lookahead.length
          );
          lookahead = lookahead + toBeAttached;
          return `>${toBeAttached}<`;
        }
      });

      limitReached && (trimmed = trimmed.replaceAll('<br>', ''));

      return trimmed;
    },
    [maxMessageLength]
  );

  if (isLoading) {
    return isLoading ? <Loader /> : <></>;
  }

  if (messages === null || messages.length <= 0 || (isErrorState && isError)) {
    return isErrorState ? (
      <Box className={classes.cardRoot}>
        <Alert
          severity='error'
          className={classes.toaster}
          icon={
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='12' cy='12' r='11' stroke='#262626' strokeWidth='2' />
              <path
                d='M12.75 18.5C12.75 18.9142 12.4142 19.25 12 19.25C11.5858 19.25 11.25 18.9142 11.25 18.5C11.25 18.0858 11.5858 17.75 12 17.75C12.4142 17.75 12.75 18.0858 12.75 18.5Z'
                stroke='#262626'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
              />
              <path
                d='M12 15L11.5 5H12.5L12 15Z'
                stroke='#262626'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          }
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='medium'
              onClick={() => setIsErrorState(false)}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {errorMessage}
        </Alert>
      </Box>
    ) : (
      <EmptyBox emptyMessage={emptyMessage} emptySubMessage={emptySubMessage} />
    );
  } else {
    return (
      <Box className={classes.cardRoot}>
        <ImpersonatingPanel
          open={open}
          handleClose={handleClose}
          username={username}
          selectedGroup={selectedGroup}
          setGroup={setGroup}
          isParentDrawerOpen={isParentDrawerOpen}
          setParentDrawerOpen={setParentDrawerOpen}
        />
        <AlertBox
          title='Message Unavailable'
          open={alertOpen}
          setOpen={setAlertOpen}
        >
          Your messages won't display because the channel is inactive. Please
          contact your administrator
        </AlertBox>
        <AlertBox
          title='Message Unavailable'
          open={useralertOpen}
          setOpen={setUserAlertOpen}
        >
          Your messages won't display because the user not part of the channel. Please
          contact your administrator
        </AlertBox>
        {isLoading ? <Loader /> : <></>}
        <Box className='message-result-container'>
          <Box className={classes.paginationLoader}>
            <Box position='sticky' top='50%'>
              <CircularProgress />
            </Box>
          </Box>
          {messages?.map((message: any) => {
            const {
              id,
              channelId,
              content,
              details: {
                channelName = '',
                senderRole,
                senderName: senderNameDetails,
                messageContent
              },
              createdAt,
              sender,
              senderName: senderNameMessage,
              schoolId,
              uploadedFiles
            } = message;
            const senderName = senderNameDetails || senderNameMessage;

            const jumperAction = async () => {
              const senderDetail = sender.split('|');
              setUsername(senderName);
              var groupDetails: { [key: string]: string } = {};

              groupDetails['groupType'] = channelId.split('|')[0];
              if (channelId.split('|')[0] === 'DIRECT') {
                groupDetails['groupid'] = channelId;
              } else if (channelId.split('|')[0] === 'CLASSES') {
                var channelIdSplit = channelId.split('|');
                groupDetails['groupid'] = (channelIdSplit[1] + "|" + channelIdSplit[2]);
              } else {
                groupDetails['groupid'] = channelId.split('|')[1];
              }
              /*groupDetails['groupid'] =
                channelId.split('|')[0] === 'DIRECT'
                  ? channelId
                  : channelId.split('|')[1];*/
              groupDetails['offset'] = id;
              groupDetails['lastdate'] = createdAt;

              // check channel is enabled/disabled
              const { groupid, groupType } = groupDetails;
              const selChannelId = groupid.toString();
              const selChannelType = groupType.toString();

              if (
                selChannelId &&
                selChannelType &&
                selChannelType !== 'DIRECT' &&
                senderDetail[1] &&
                senderDetail[0]
              ) {
                try {
                  const channelResponse = await getChannelStatusUsingId(
                    selChannelId,
                    selChannelType,
                    senderDetail[0],
                    domain
                  );
                  if (channelResponse?.data?.status == 401){
                    setAlertOpen(true);
                    return false;
                  }
                  if (channelResponse?.data?.status == 409){
                    setUserAlertOpen(true);
                    return false;
                  }
                } catch (error) {
                  setAlertOpen(true);
                  return false;
                }
              }
              clearChannelList();
              setGroup(new GroupEssential());
              setImpersonatingUserDetails({
                userId: loggedInUser,
                impersonatedUserId: senderDetail[0],
                userName: senderName,
                userLoggedInDomain: domain,
                schoolId: schoolId.split('|')[0],
                groupDetails: groupDetails,
                highlightTokens: highlightTokens
              });
              handleClickOpen();
            };

            return (
              <MessageCard
                key={id}
                message={trimMessage(messageContent)}
                attachmentCount={uploadedFiles?.length}
                channelName={channelName}
                senderName={senderName}
                senderRole={senderRole}
                createdAt={createdAt}
                jumperAction={jumperAction}
                highlightTokens={highlightTokens}
                highlighter={highlightMatchedChars}
                trimEmptyTags={true}
              />
            );
          })}
        </Box>
      </Box>
    );
  }
};

export default SearchResultPanel;
