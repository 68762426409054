import { makeStyles } from '@material-ui/core/styles';

const ImpersonatingPanelDrawerStyle = makeStyles((theme) => ({
  root: {
    minWidth: 290,
    height: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    ['@media (max-height: 500px)']: {
      height: 'calc(100vh - 45px)',
    },
  },
  mobileRoot: {
    width: '100vw',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  hideMobileRoot: {
    width: '0',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  searchMenu: {
    display: 'flex',
    flexGrow: 1,
  },
  searchSvg: {
    marginLeft: '10px',
    alignSelf: 'center',
  },
  spanSearchClass: {
    paddingLeft: '7px',
    fontStyle: 'normal',
    fontWeight: 600,
    flexGrow: 1,
    alignSelf: 'center',
  },
  searchBlock: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '60px',
    border: '1px solid #CDCDCD',
  },
  divClass: {
    border: '1px solid #CDCDCD',
    backgroundColor: 'white',
  },
  leftScrollContainer: {
    borderRight: '1px solid #CDCDCD',
    overflow: 'auto',
    height: 'calc(100% - 60px)',
    scrollbarColor: '#666666 transparent',
    scrollbarWidth: 'thin',
    /* width */
    '&::-webkit-scrollbar': {
      width: '8px',
    },

    /* Track */
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#666666',
      borderRadius: '10px',
      border: '1px solid #666',
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#666666',
    }
  }
}));

export default ImpersonatingPanelDrawerStyle;
