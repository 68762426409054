import React, { useState, useContext, useEffect } from 'react';
import { Box, Tabs, Tab, Divider } from '@material-ui/core';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { useMediaQuery } from 'react-responsive';
import SettingsStyle from '../styles/SettingsStyle';
import { SettingsTabs } from '../constants/Settings';
import { StateContext } from './StateContextComponent';
import DistrictSettingComponent from './DistrictSettingComponent';
import SchoolSettingsComponent from './SchoolSettingsComponent/SchoolSettingsComponent';
import { useWindowHeight } from '../libs/hookLib'
import clsx from 'clsx';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      position='relative'
      component='div'
      role='tabpanel'
      flexDirection='column'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const SettingsComponent = () => {
  const classes = SettingsStyle();
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isLandscape = useMediaQuery({ query: '(orientation: landscape) and (max-height: 500px)' });
  const { isSidebarOpen, setSidebarState, setEnableSchoolsToSelect } = useContext(StateContext);
  setEnableSchoolsToSelect(true);
  const windowHeight = useWindowHeight()

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const toggleSidebar = () => {
    setSidebarState(!isSidebarOpen);
  };

  // Hide sidebar by default for mobile view
  useEffect(() => {
    if (isMobile || isLandscape) { 
      setSidebarState(false);
    } else {
      setSidebarState(true);
    }
  }, [isMobile, isLandscape]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.wrapperWithSidebar]: isSidebarOpen,
        [classes.wrapperWithoutSidebar]: !isSidebarOpen,
      })}
    >
      <div className={classes.tabBar}>
        <div className={classes.groupNameDiv}>
          <button onClick={toggleSidebar} className={classes.toggleButton}>
            {isSidebarOpen ? (
              <ArrowBackIosOutlinedIcon
                fontSize={'default'}
              ></ArrowBackIosOutlinedIcon>
            ) : (
              <ArrowForwardIosOutlinedIcon
                fontSize={'default'}
              ></ArrowForwardIosOutlinedIcon>
            )}
          </button>
          <span className={classes.groupName}>Settings</span>
        </div>
        <Tabs
          id='settings-tabs'
          data-testid='settings-tabs'
          value={value}
          onChange={handleChange}
          className={classes.wrapper}
        >
          {SettingsTabs.map((tab, index: number) => (
            <Tab
              key={index}
              className={classes.tabButton}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </div>
      <Divider />
        <TabPanel value={value} index={0}>
            <DistrictSettingComponent />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <SchoolSettingsComponent />
        </TabPanel>
    </div>
  );
};

export default SettingsComponent;
