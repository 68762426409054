import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import ChartDisplayCardStyle from './ChartDisplayCardStyle';
import ChartDisplayCardType from './ChartDisplayCardType';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ChartDisplayCard = (props: ChartDisplayCardType) => {
    const classes = ChartDisplayCardStyle();
    const { title, options } = props;
    const chartOptions: Highcharts.Options = options;

    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });

    return (
        <Card className={classes.root}>
            <CardHeader 
                className={classes.header} 
                title={title}
                titleTypographyProps={{variant:'h4' }}
            />
            <CardContent>
                <Box
                    position="relative"
                    className={classes.chartBox}
                >
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Box>
            </CardContent>
        </Card>
    )
}

export default ChartDisplayCard;