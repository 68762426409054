import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DialogTitle } from '../ReusableComponents/ReachDialogTitle'; 
import { PrimaryButton, DefaultButton } from '../ReusableComponents/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteChannel from '../ReusableComponents/DeleteChannel';
import { AlertBoxLoader } from '../Loader/Loader';

type ActionableAlertBoxType = {
  title: string,
  children: React.ReactNode,
  shouldOpen: boolean,
  onClose?: () => void,
  onCancel?: () => void,
  onAction: () => void,
  onExited?: () => void,
  secondaryButtonLabel: string,
  primaryButtonLabel: string,
  secondaryButtonProps?: any,
  primaryButtonProps?: any,
  styles?: any,
  onEdited?: boolean,
  channelType?: string,
  isLoading?:boolean,
  disableBackdropClick?:boolean,
  onDelete?: () => void,
};

const ActionableAlertBox = ({
  title,
  children,
  shouldOpen,
  onClose,
  onCancel,
  onAction,
  onExited,
  secondaryButtonLabel = 'Cancel',
  primaryButtonLabel = 'Send',
  secondaryButtonProps = {},
  primaryButtonProps = {},
  styles = {},
  onEdited = false,
  channelType = "",
  isLoading = false,
  disableBackdropClick = false,
  onDelete,
  ...dialogProps
  
}: ActionableAlertBoxType) => {
  const classes: any = makeStyles(_ => (styles))()
  return (
    <div>
      <Dialog
        open={shouldOpen}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        className={classes?.dialog}
        classes={{paper: classes?.paper}}
        maxWidth={false}
        {...dialogProps}
        onExited={onExited}
        disableBackdropClick={disableBackdropClick}
      >
        {isLoading?<AlertBoxLoader stylesprop={styles}/>:<></>}
        <DialogTitle data-test-id='actionable-alert-title' css={null} onClose={onClose}>{title}</DialogTitle>
        <DialogContent
          classes={{root: classes?.root}}
          data-test-id='actionable-alert-description'
        > 
          {children} 
        </DialogContent>
        <DialogActions className={classes?.dialogActionsBtns}>
        { onEdited && <DeleteChannel onDelete={onDelete} channelType={channelType}></DeleteChannel>}
          <DefaultButton 
            css={null}
            onClick={onClose} 
            {...secondaryButtonProps}
            data-testid='secondary-button'
          >
            {secondaryButtonLabel}
          </DefaultButton>
          <PrimaryButton
            className={classes?.colorChange}
            css={null}
            onClick={onAction}
            data-testid='primary-button'
            {...primaryButtonProps}
          >
            {primaryButtonLabel}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActionableAlertBox;