import React, { useState, useCallback, useContext } from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DatePickerIcon } from '../../Icons/DatePickerIcon';
import AutoComplete from '../AutoComplete/AutoComplete';
import { orgList } from '../../dataLoaders/DataHandler';
import { fromJsDateToEpoch, toJsDateToEpoch } from '../../libs/date.utils';
import InlineFiltersPanelStyle from './InlineFiltersPanelStyle';
import { SearchComponentStateType } from './InlineFiltersPanelTypes';
import { UserAuthCtx } from '../AuthComponent';
function formatSchoolsList(option: any) {
  return (
    <>
      <Box component='div' display='flex' width='100%'>
        <Box
          component='span'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {option.name}
        </Box>
        <Box component='span'>
          ,<Box component='span' ml={1} />
          {option.id}
        </Box>
      </Box>
    </>
  );
}

const InlineFiltersPanel = (props: any) => {
  const authContext = useContext(UserAuthCtx);
  var domainId = authContext.userCtx.domainId;
  const classes = InlineFiltersPanelStyle();
  const defaultMinDate = '2020-01-01';

  const { handleSearchReports } = props;

  const inputTextFieldProps = {
    disableUnderline: true,
    autoFocus: false,
    classes: { focused: classes.focused},
    style: { paddingRight: 0 },
  };

  const initialState: SearchComponentStateType = {
    startDate: null,
    endDate: null,
    school: '',
    startDateEpoch: null,
    endDateEpoch: null,
  };

  const [filterValues, setFilterValues] = useState<SearchComponentStateType>(
    initialState
  );
  const [schoolName, setSchoolName] = useState(null);

  const clearAllFields = () => {
    setFilterValues(initialState);
    setSchoolName(null);
  };

  const handleStartDateChange = useCallback(
    (date: any, value: any) => {
      setInputStartValue(value);
      setFilterValues({
        ...filterValues,
        startDate: value,
        startDateEpoch: fromJsDateToEpoch(value),
      });
    },
    [filterValues]
  );

  const handleEndDateChange = useCallback(
    (date: any, value: any) => {
      setInputEndValue(value);
      setFilterValues({
        ...filterValues,
        endDate: value,
        endDateEpoch: toJsDateToEpoch(value),
      });
    },
    [filterValues]
  );
  const [expanded, setExpanded] = useState(false);
  const [inputStartValue, setInputStartValue] = useState('');
  const [inputEndValue, setInputEndValue] = useState('');
  const [isValidStartDate, setStartDateError] = useState(true);
  const [isValidEndDate, setEndDateError] = useState(true);
  const [startDateErrorMsg, setStartDateErrorMsg] = useState('');
  const [endDateErrorMsg, setEndDateErrorMsg] = useState('');

  const dateFormatter = (str: string) => {
    if(str === '--' || str === '-'){
      return '';
    }
    return str.replace(/[^\d\-]+/gi, '');
  };

  const isValidDateRange = () => {
    if (
      isValidStartDate &&
      isValidEndDate &&
      (filterValues.startDateEpoch &&
      filterValues.endDateEpoch && 
      filterValues.startDateEpoch <= filterValues.endDateEpoch)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleStartDatePickerError = useCallback(
    (error: any, value: any) => {
      if (!error) {
        setStartDateError(true);
        setStartDateErrorMsg('');
      } else {
        setStartDateError(false);
        setStartDateErrorMsg(error);
      }
    },
    [filterValues]
  );

  const handleEndDatePickerError = useCallback(
    (error: any, value: any) => {
      if (!error) {
        setEndDateError(true);
        setEndDateErrorMsg('');
      } else {
        setEndDateError(false);
        setEndDateErrorMsg(error);
      }
    },
    [filterValues]
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id='schoollbl'
              required
              classes={{
                asterisk: classes.asterisk,
              }}
              className={classes.label}
            >
              School
            </InputLabel>
            <AutoComplete
              mode='data'
              id='schoollbl'
              data-testid='school-search'
              isFreeForm={false}
              value={schoolName}
              dataFormatter={(data: any) => {
                return data.map((school: any) => {
                  const { id, name } = school;
                  return {
                    id,
                    name,
                  };
                });
              }}
              disabled={false}
              placeholder='Select school'
              noOptionsText='No schools'
              optionsArray={orgList}
              renderOption={formatSchoolsList}
              getOptionSelected={(option, value) => option.name === value}
              onChange={(event, item) => {
                setFilterValues({
                  ...filterValues,
                  school: item.id.toString() + '|' + domainId.toString(),
                });
                setSchoolName(item.name);
              }}
              getOptionLabel={(item) => {
                if (item.name) {
                  return item.name;
                }
                return item;
              }}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id='fromDateLabel'
              required
              classes={{
                asterisk: classes.asterisk,
              }}
              className={classes.label}
            >
              Date Range - From
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                data-testid='startDate'
                className={classes.customDatePicker}
                disableToolbar
                autoOk
                variant='inline'
                format='yyyy-MM-dd'
                margin='normal'
                placeholder='Start'
                value={filterValues.startDate}
                keyboardIcon={<DatePickerIcon className={classes.dateIcon} data-testid="start-date"/>}
                InputAdornmentProps={{
                  style: { marginLeft: 0, paddingRight: 0 }
                }}
                onError={handleStartDatePickerError}
                onChange={handleStartDateChange}
                InputProps={inputTextFieldProps}
                minDate={defaultMinDate}
                maxDate={new Date()}
                rifmFormatter={dateFormatter}
                inputValue={inputStartValue}
                maxDateMessage='Date should not be greater than current date'
                invalidDateMessage='Invalid Date Format (YYYY-MM-DD)'
                helperText={isValidStartDate && 'YYYY-MM-DD' || startDateErrorMsg}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <InputLabel
              id='fromDateLabel'
              required
              classes={{
                asterisk: classes.asterisk,
              }}
              className={classes.label}
            >
              Date Range - To
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                data-testid='endDate'
                className={classes.customDatePicker}
                disableToolbar
                autoOk
                variant='inline'
                format='yyyy-MM-dd'
                margin='normal'
                placeholder='End'
                minDate={isValidStartDate && filterValues.startDate || defaultMinDate}
                maxDate={new Date()}
                value={filterValues.endDate}
                keyboardIcon={<DatePickerIcon className={classes.dateIcon} data-testid="end-date"/>}
                InputAdornmentProps={{
                  style: { marginLeft: 0, paddingRight: 0 }
                }}
                onChange={handleEndDateChange}
                onError={handleEndDatePickerError}
                InputProps={inputTextFieldProps}
                rifmFormatter={dateFormatter}
                inputValue={inputEndValue}
                minDateMessage={() => {
                  let errMsg = 'Date should not be before minimal date';
                  if(filterValues.startDate && isValidStartDate){
                    errMsg = 'Date should not be before start date'
                  }
                  return errMsg;
                }}
                maxDateMessage='Date should not be greater than current date'
                invalidDateMessage='Invalid Date Format (YYYY-MM-DD)'
                helperText={isValidEndDate && 'YYYY-MM-DD' || endDateErrorMsg}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl className={classes.formBtnControl}>
            <Button
              className={classes.button}
              type='button'
              disabled={
                !(
                  schoolName && isValidDateRange()
                )
              }
              classes={{ root: classes.buttonHover }}
              onClick={() => handleSearchReports(filterValues)}
            >
              Go
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
export default InlineFiltersPanel;
