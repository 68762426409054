import React, { useState, useEffect, useContext } from 'react';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Avatar, Badge, Drawer, List, ListItem } from '@material-ui/core';
import { OrganizationInfo } from '../../Types/OrganizationInfo';
import { SchoolInfo } from '../../Types/SchoolInfo';
import SidebarStyle from './sidebarStyle';
import { ProfileIcon } from '../../Icons/ProfileIcon';
import { ReportingIcon } from '../../Icons/ReportingIcon';
import { SettingsIcon } from '../../Icons/SettingsIcon';
import { Link } from 'react-router-dom';
import { loadOrgData } from '../../dataLoaders/DataHandler';
import { UserAuthCtx } from '../../components/AuthComponent';
import { StateContext } from '../../components/StateContextComponent';
import { withRouter } from 'react-router';
import { USER_ROLES, SECTION } from './SideBar.constant';
import { useMediaQuery } from 'react-responsive';
import MessageRouteContext, { MessageRouteContextType } from '../../context/MessageRouteContext'

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#EB1700',
      boxShadow: `0 0 0 2px #713046`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        border: '1px solid none',
        content: '""',
      },
    },
  })
)(Badge);

const SideBar = (props: any) => {
  const userDetails = useContext(UserAuthCtx).userCtx;
  const { userId, userRole, roleID, mobile: isOpenedInMobile } = userDetails;
  const classes = SidebarStyle()({
    isOpenedInMobile
  });
  const {
    isSidebarOpen,
    orgListCount,
    selectedOrganizationId,
    setSelectedOrganizationId,
    enableSchoolsToSelect,
    setEnableSchoolsToSelect,
    schools,
    setSchools,
  } = useContext(StateContext);
  const [orgListCountSideBar, setOrgListCount] = useState<{
    [key: string]: number;
  }>({});
  const [showReporting, setShowReporting] = useState(false)
  const [showSettings, setShowSettings] = useState(false);
  const { pathname } = props.history.location;
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  
  const { schoolId: selectedSchool, isMessageRoute } = useContext(MessageRouteContext) as MessageRouteContextType
  useEffect(() => {
    setOrgListCount(orgListCount);
  }, [orgListCount]);

  const selectOrganization = (org: OrganizationInfo) => {
    let school = new SchoolInfo();
    school.schoolName = org.name;
    school.schoolId = org.id;
    localStorage.setItem('reachOrgSchoolId', org.reachOrgSchoolId);
    setSelectedOrganizationId(school);
    setEnableSchoolsToSelect(false);
    props.history.push('/Home');
  };

  useEffect(() => {
    if (userId) {
      loadOrgData(userDetails).then((orgList) => {
        if (orgList && orgList[0] !== undefined) {
          setSchools(orgList);
          let school = new SchoolInfo();
          school.schoolName = orgList[0].name;
          school.schoolId = orgList[0].id;
          localStorage.setItem('reachOrgSchoolId', orgList[0].reachOrgSchoolId);
          if(isMessageRoute && selectedSchool) {
            const schoolToJump = orgList.filter((school: any) => school.id == selectedSchool)

            if(schoolToJump[0]) {
              school.schoolName = schoolToJump[0].name;
              school.schoolId = schoolToJump[0].id;
            }
          }
          setSelectedOrganizationId(school);
        }
      });

      const { ADMIN, SYSTEM, STAFF } = USER_ROLES;
      // set flag for displaying admin tooling icon or not
      if ([ADMIN, SYSTEM, STAFF].includes(userRole)) {
        setShowReporting(true);
      }

      // set flag for displaying settings icon or not
      // Temporarily commenting this code, to overcome one issue at BBComms
      // if ([ADMIN, SYSTEM, STAFF].includes(userRole)) {
      //   setShowSettings(true);
      // }
      if((SYSTEM === userRole) && (roleID == 2)) {
        setShowSettings(true);
      }

      // enables schools in sidebar to select
      if (
        [SECTION.SETTINGS, SECTION.PROFILE, SECTION.REPORT].includes(pathname)
      ) {
        setEnableSchoolsToSelect(true);
      }
    }
  }, [userId]);

  var dataTestId = 'school-list-item-';
  return (
    <div className={classes.root}>
      {userDetails && userDetails.userId ? (
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={isSidebarOpen}
          //adding this change to show sidebar list in mobile correctly ISSUE: PEB-4688
          classes={{
            paper: isMobile ? classes.drawerPaperMobile : classes.drawerPaper,
          }}
        >
          <List>
            <ListItem
              className={clsx(classes.itemClass, {
                [classes.selectedListItem]: pathname === SECTION.PROFILE,
              })}
            >
              <Link to='/Profile'>
                <ProfileIcon data-testid='ProfileIcon' />
              </Link>
            </ListItem>
            {showReporting ? (
              <ListItem
                className={clsx(classes.itemClass, {
                  [classes.selectedListItem]: pathname === SECTION.REPORT,
                })}
              >
                <Link to='/Report'>
                  <ReportingIcon data-testid='AdminToolIcon' />
                </Link>
              </ListItem>
            ) : (
              ''
            )}
            {showSettings ? (
              <ListItem
                className={clsx(classes.itemClass, {
                  [classes.selectedListItem]: pathname === SECTION.SETTINGS,
                })}
              >
                <Link to='/Settings' data-testid='SettingToolIcon'>
                  <SettingsIcon />
                </Link>
              </ListItem>
            ) : (
              ''
            )}
            {schools.map((org: OrganizationInfo, index: number) => {
              const splittedName = org.name.split(' ');
              return org.id > 0 ? (
                <ListItem
                  key={index}
                  data-testid={`${dataTestId}${index}`}
                  className={clsx(classes.listItemClass, {
                    [classes.selectedListItem]:
                      ![
                        SECTION.SETTINGS,
                        SECTION.PROFILE,
                        SECTION.REPORT
                      ].includes(pathname) &&
                      org.id === selectedOrganizationId.schoolId,
                  })}
                  onClick={() => {
                    if (!enableSchoolsToSelect) return;
                    selectOrganization(org);
                  }}
                >
                  <StyledBadge
                    overlap='circle'
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    badgeContent={orgListCountSideBar[org.name]}
                  >
                    <Avatar className={classes.large}>
                      {splittedName[0] && splittedName[0][0].toUpperCase()}
                      {splittedName[1] && splittedName[1][0].toUpperCase()}
                    </Avatar>
                  </StyledBadge>
                </ListItem>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              );
            })}
          </List>
        </Drawer>
      ) : (
        ''
      )}
    </div>
  );
};

export default withRouter(SideBar);
