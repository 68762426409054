const BASE_URL = process.env.REACT_APP_REACH_BASE_URL;
export const API = {
  authenticate: `${BASE_URL}login`,
  logout: `${BASE_URL}logout`,
  account: `${BASE_URL}account`,
  userGroup: `${BASE_URL}usergroup`,
  messages: `${BASE_URL}messages`,
  markRead: `${BASE_URL}markread`,
  groupMembers: `${BASE_URL}memberdetails/?owner_data=true&channeltype=<channelType>`,
  blackboardLogo:
    'https://storage.googleapis.com/static_pl/images/BB%20Logo%20Vector%20white.svg',
  directMessageContact: `${BASE_URL}directcontacts`,
  searchMembers: `${BASE_URL}memberdetails/?name=<name>&schoolId=<schoolId>&personId=<personId>`,
  addChannelMemberssearch: `${BASE_URL}memberdetails/?name=<name>&schoolId=<schoolId>&channelId=<channelId>&addChannelMembersearch=<addChannelMembersearch>`,
  queueManager: `${BASE_URL}queue`,
  saveMessage: `${BASE_URL}sendmessage`,
  chatArchiveMessagesApi: `${BASE_URL}searchmessage`,
  settingPage: `${BASE_URL}schoolsettings`,
  channelSettings: `${BASE_URL}channelsettings`,
  usersCountApi: `${BASE_URL}totalusers`,
  messagesSentChartApi: `${BASE_URL}totalmessages`,
  district: `${BASE_URL}district`,
  channelStatus: `${BASE_URL}channelstatus`,
  downloadCSV: `${BASE_URL}downloadcsv`,
  channelUsersCountApi: `${BASE_URL}memberdetails`,
  userProfileApi: `${BASE_URL}userprofile`,
  emailDigestConfirmApi: `${BASE_URL}userprofile/confirmation`,
  emailDigestUnsubscriptionApi: `${BASE_URL}userprofile/unsubscribe`,
  translateApi: `${BASE_URL}translate`,
  readrateApi: `${BASE_URL}channelreadrate`,
  originalMsg: `${BASE_URL}originalmessage`,
  filetransferApi: `${BASE_URL}filestransfer`,
  channelApi: `${BASE_URL}channel`,
  channelMemberApi: `${BASE_URL}channelmembers`,
};
