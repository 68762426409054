import React from 'react';
export const DownloadIcon = (props: any) => {
  return (
      <svg className={props.style}  width="16" height="16" viewBox="0 0 16 16"  fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4.5H1V15.5H15V4.5H12" stroke={props.isHeader ? "#262626" : "white"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 1.5V12.5" stroke={props.isHeader ? "#262626" : "white"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 10.5L8 12.5L10 10.5" stroke={props.isHeader ? "#262626" : "white"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  );
};
export default DownloadIcon;
