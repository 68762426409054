import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    upDownArrow: {
      position: 'relative',
      top: '2px',
      left: '12px',
      cursor: 'pointer',
    },
  });
});

const switchRender = (sort: number) => {
  switch (sort) {
    case 1:
      return (
        <>
          <path d='M8 3L12 7H4L8 3Z' fill='#262626' />
        </>
      );
    case 2:
      return (
        <>
          <path d='M8 13L4 9H12L8 13Z' fill='#262626' />
        </>
      );
    default:
      return (
        <>
          <path d='M8 13L4 9H12L8 13Z' fill='#262626' />
          <path d='M8 3L12 7H4L8 3Z' fill='#262626' />
        </>
      );
  }
};

export const SortIcon = (props: any) => {
  const classes = useStyles();
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classes.upDownArrow}
      onClick={props.handler}
      data-testid={`sortIcon-${props.id}`}
    >
      {switchRender(props.sortBy)}
    </svg>
  );
};
