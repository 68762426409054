import React, { useEffect, useState } from 'react';
import EmailSubscriptionStyle from './EmailSubscriptionStyle';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { Loader } from '../Loader/Loader';
import EmailSubscriptionNav from '../EmailSubscriptionNav/EmailSubscriptionNav';
import { confirmSubscription } from '../../services/UserProfileService';

const EmailSubscription = () => {
    const classes = EmailSubscriptionStyle();
    const [showLoader, setShowLoader] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);
    const [notification, setNotification] = useState({
        title: '',
        message: '',
        success: false
    });
    const tokenId = urlParams.get('token');
    const emailId = urlParams.get('email');
    const userId =  urlParams.get('userId');

    const showNotification = (response: any) => {
        const { message, status } = response.body;
        switch(status){
            case 0:
                setNotification({
                    title: 'Token expired!',
                    message,
                    success: false
                });
                break;
            case 1:
                setNotification({
                    title: 'Thanks for confirming your subscription!',
                    message: 'If you clicked the confirmation by mistake, click the below link to unsubscribe.',
                    success: true
                });
                break;
            case 2:
                setNotification({
                    title: 'Your request for confirmation is pending!',
                    message,
                    success: false
                });
            case undefined:
                setNotification({
                    title: 'You already subscribed!',
                    message,
                    success: true
                });
        }
    }

    const redirectToUnsubscriptionPage = () => {
        let baseUrl = window.location.origin;
        let unsubscriptionUrl = `${baseUrl}/public/profile/email/unsubscribe?email=${emailId}&userId=${userId}`;
        window.location.href = unsubscriptionUrl;
    }

    useEffect(() => {
        if(tokenId && emailId && userId){
            confirmSubscription(tokenId, emailId, userId).then((response: any) => {
                setShowLoader(false);
                showNotification(response.data);
            }).catch((error: any) => {
                setShowLoader(false);
                setNotification({
                    title: 'Error!',
                    message: error.message || 'Please try again after sometime',
                    success: false
                });
            });
        }
    }, []);

    return (
        <div className={classes.root}>
        <EmailSubscriptionNav />

        <div className={classes.mainDiv}>
            <div className={classes.rootWrapper}>
                <div className={classes.container}>
                    {
                    showLoader ? <Loader /> : (
                    <>    
                        <div data-testid='title' className={classes.title}>
                            {notification.title}
                        </div>
                        <p data-testid='message' className={classes.message}>
                            {notification.message}
                        </p>
                        {notification.success && <div className={classes.buttonWrapper}>
                            <Button
                                data-testid='unsubscribeBtn'
                                type='button'
                                className={clsx(classes.unsubscribeBtn, classes.buttonWrapper)}
                                onClick={redirectToUnsubscriptionPage}
                            >
                                Unsubscribe
                            </Button>
                        </div>}
                    </>
                    )}
                </div>
            </div>
        </div>
        </div>
    );
}

export default EmailSubscription;
