import { PreferredLanguage } from "./PreferredLanguage";

export class UserContext {
  userId: string = '';
  userLoggedInDomain: string = '';
  domainId: string = '';
  userName: string = '';
  organizationName: string = 'UnknownOrganization';
  mobile: string = 'false';
  userRole: string = '';
  userRoleName: string = '';
  roleID: string | number = '';
  districtURL: string = '';
  preferredLanguage: PreferredLanguage = new PreferredLanguage();
}
