import React, { useState, useContext } from 'react';
import { Box, Divider } from '@material-ui/core';
import { Grid, Container, CircularProgress } from '@material-ui/core';
import UsageReportsStyle from './UsageReportsStyle';
import DownloadIcon from '../../Icons/DownloadIcon';
import InlineFiltersPanel from '../InlineFiltersPanel/InlineFiltersPanel';
import InfoCard from '../InfoCard/InfoCard';
import ChartDisplayCard from '../ChartDisplayCard/ChartDisplayCard';
import {
  infoCards,
  getChartOptions,
  numberWithCommas,
  dataModification,
} from './UsageReports.utils';
import InfoCardType from '../InfoCard/InforCardType';
import NotificationBox from '../NotificationBox/NotificationBox';
import { UserAuthCtx } from '../AuthComponent';
import {
  getUsageReportsData,
  downloadUsageReportsData,
} from '../../services/UsageReportsService';

export interface FilterStateTypes {
  school: string;
}

export const Loader = () => {
  return (
    <Box
      data-testid='loader'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      flex='1'
    >
      <CircularProgress />
    </Box>
  );
};

const UsageReports = () => {
  const [showReports, setShowReports] = useState(false);
  const [infoCardsState, setInfoCardsState] = useState(infoCards);
  const [messagesChartConfig, setMessagesChartConfig] = useState({
    title: '',
    options: {},
  });
  const [platformAccessChartConfig, setPlatformAccessChartConfig] = useState({
    title: '',
    options: {},
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [disableTotalUser, setDisableTotalUser] = useState(true);
  const [disableMessageSent, setDisableMessageSent] = useState(true);

  const initialState = {
    school: '',
  };
  const [filterValues, setFilterValues] = useState<FilterStateTypes>(
    initialState
  );
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [userCSVPath, setUsersCSVPath] = useState('');
  const [messagesCSVPath, setMessagesCSVPath] = useState('');
  const classes = UsageReportsStyle();
  const authContext = useContext(UserAuthCtx);
  const { userLoggedInDomain: domain } = authContext.userCtx;
  const downloadErrorMessage = 'Download failed. Try again Later';

  const downloadUserReport = (requestType: string) => {
    try {
      downloadUsageReportsData(filterValues, domain, requestType)
        .then((responses: any) => {
          if (responses?.data?.csvPath !== undefined)
            window.location.href = responses?.data?.csvPath;
          else {
            setIsDownloadError(true);
          }
        })
        .catch((errors: any) => {
          console.log('error while downloading....', errors);
          setIsDownloadError(true);
        });
    } catch {}
  };

  const onFailure = (error: string) => {
    setShowLoader(false);
    setErrorMessage(error);
    setshowNotification(true);
  };

  const launchSearch = (filterValues: any) => {
    setIsDownloadError(false);
    setFilterValues(filterValues);
    setShowLoader(true);
    setErrorMessage('');
    setshowNotification(false);

    try {
      getUsageReportsData(filterValues, domain)
        .then((responses: any) => {
          // API response for counts
          const cardsResponse = responses[0];
          // API response for message sent chart data
          const messagesChartResponse = responses[1];

          // Show counts in cards
          const { data, platformData, status } = cardsResponse.data;
          if (status === 200) {
            let newInfoCardsState = [...infoCardsState];
            newInfoCardsState[0].count = numberWithCommas(data.activeUserCount);
            newInfoCardsState[1].count = numberWithCommas(data.teachercount);
            newInfoCardsState[1].caption =
              numberWithCommas(data.activeTeachersCount) + ' Active teachers';
            newInfoCardsState[2].count = numberWithCommas(data.parentcount);
            newInfoCardsState[2].caption =
              numberWithCommas(data.activeParentsCount) + ' Active parents';
            newInfoCardsState[3].count = data.studentcount
              ? numberWithCommas(data.studentcount)
              : 0;
            newInfoCardsState[3].caption =
              numberWithCommas(data.activeStudentsCount) + ' Active students';

            newInfoCardsState[4].count = numberWithCommas(data.channelCount);
            setInfoCardsState(newInfoCardsState);

            setDisableTotalUser(
              !(data.activeUserCount && data.activeUserCount > 0)
            );
          }

          // update messages sent chart config
          const { data: msgData, status: msgStatus } = messagesChartResponse;
          if (msgStatus === 200 && msgData.message) {
            const {
              channelMessagesCount,
              directMessagesCount,
            } = msgData.message;
            const messagesSentData = [
              {
                name: 'Channel Messages',
                y: channelMessagesCount,
                color: '#5FD1FF',
              },
              {
                name: 'Direct Messages',
                y: directMessagesCount,
                color: '#2073A1',
              },
            ];

            setDisableMessageSent(
              !(
                msgData.message &&
                (channelMessagesCount > 0 || directMessagesCount > 0)
              )
            );

            const messagesConfig = getChartOptions(
              'Messages sent',
              messagesSentData
            );
            setMessagesChartConfig(messagesConfig);
          }

          // Update platform access chart config

          const platformModifiedData = dataModification(platformData);
          const platformAccessConfig = getChartOptions(
            'All time platform access',
            platformModifiedData
          );
          setPlatformAccessChartConfig(platformAccessConfig);

          setShowReports(true);
          setShowLoader(false);
        })
        .catch((errors: any) => {
          onFailure(errors.message);
        });
    } catch (error) {
      onFailure(error.message);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.parentContainer}>
        <Container maxWidth={false} className={classes.filterBar}>
          <Grid container className={classes.headerRow}>
            <Grid item xs={12} className={classes.heading}>
              School Usage
            </Grid>
          </Grid>
          <InlineFiltersPanel handleSearchReports={launchSearch} />
        </Container>
        <Divider />
        <Container
          maxWidth={false}
          className={`${classes.mainContainer} ${classes.overflowContainer}`}
        >
          {showNotification ? (
            <NotificationBox
              message={errorMessage}
              handleNotification={setshowNotification}
              severity={'error'}
            />
          ) : (
            ''
          )}
          {showLoader ? <Loader /> : ''}
          {!showLoader && showReports ? (
            <Grid
              container
              spacing={2}
              className={classes.cardsWrapper}
              justify='center'
            >
              {infoCardsState.map((cardInfo: InfoCardType, index: number) =>
                index <= 2 ? (
                  <Grid item md sm={4} xs={12} key={index}>
                    <InfoCard {...cardInfo} />
                  </Grid>
                ) : index > 2 && index <= 4 ? (
                  <Grid item md sm={6} xs={12} key={index}>
                    <InfoCard {...cardInfo} />
                  </Grid>
                ) : null
              )}

              <Grid item sm={12} xs={12}>
                <ChartDisplayCard {...messagesChartConfig} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <ChartDisplayCard {...platformAccessChartConfig} />
              </Grid>

              <Grid item sm={12} xs={12}>
                <div className={classes.RawDataHeader}>
                  <h4 className={classes.headerMargin}>Raw Data</h4>
                </div>
                <div className={classes.subRawHeader}>
                  <h5 className={classes.headerMargin}>Total users</h5>
                  <a
                    className={
                      disableTotalUser
                        ? classes.disabled
                        : classes.downloadAnchor
                    }
                    download='Users'
                    onClick={() => {
                      if (!disableTotalUser) {
                        setIsDownloadError(false);
                        downloadUserReport('users');
                      }
                    }}
                    target='_self'
                  >
                    <DownloadIcon disable={disableTotalUser} />
                  </a>
                </div>
                <div className={classes.subRawHeader}>
                  <h5 className={classes.headerMargin}>Messages sent</h5>
                  <a
                    className={
                      disableMessageSent
                        ? classes.disabled
                        : classes.downloadAnchor
                    }
                    onClick={() => {
                      if (!disableMessageSent) {
                        setIsDownloadError(false);
                        downloadUserReport('messages');
                      }
                    }}
                    download='Messages'
                    target='_self'
                  >
                    <DownloadIcon disable={disableMessageSent} />
                  </a>
                </div>
                {isDownloadError && (
                  <NotificationBox
                    errorMessage={downloadErrorMessage}
                    handleNotification={setIsDownloadError}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Container>
      </div>
    </div>
  );
};

export default UsageReports;
