import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import { makeStyles } from '@material-ui/core/styles';
import 'react-quill/dist/quill.snow.css';
import { useMediaQuery } from 'react-responsive';
import { getSVGIcon } from '../Icons/EditorIcons';
import FileUploadIcon from '../Icons/FileUploadIcon';
import CloudUploadIcon from '../Icons/CloudUploadIcon';
import { AddWithCircleIcon } from '../Icons/AddWithCircleIcon';
import { v4 as uuid } from 'uuid';
import FileUpload from '../components/FileUpload/FileUpload';
import { FormField } from '../components/FileUpload/FileUploadStyle';
import CloudStorageComponent from '../components/CloudStorageComponent/CloudStorageComponent';
import CustomCircularProgress from '../components/ReusableComponents/CircularProgress';

const EditorStyle = makeStyles((theme) => ({
  boxDiv: {
    display: 'flex',
  },
  toolDiv: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  textbar: {
    '& div': {
      '& div': {
        minHeight: '40px',
        overflow: 'auto',
        maxHeight: 'calc(35vh - 80px) !important',
        paddingLeft: '12px',
      },
      borderBottom: 'none!important',
    },
  },
  textbarEx: {
    '& div': {
      '& div': {
        minHeight: '40px',
        overflow: 'auto',
        maxHeight: '35vh !important',
        paddingLeft: '12px',
      },
      borderBottom: 'none!important',
    },
  },
  customToolbar: {
    maxHeight: '40vh',
  },
  icon_spacing: {
    paddingLeft: '12px!important',
    paddingRight: '15px!important',
    height: '40px!important',
    width: '40px!important',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: '#f2f2f2!important',
    },
    '&:hover .ql-stroke': {
      stroke: '#262626!important',
    },
    '&:active': {
      backgroundColor: '#CDCDCD!important',
    },
    '&.ql-active': {
      backgroundColor: '#A234B5!important',
    },
    '&.ql-active .ql-stroke': {
      stroke: '#ffffff!important',
    },
    '@media (max-width: 900px)': {
      paddingLeft: '6px!important',
      paddingRight: '10px!important',
      width: '30px!important',
    },
    '@media (max-width: 600px)': {
      '&:hover': {
        backgroundColor: '#ffffff!important',
      },
      '&:hover .ql-stroke': {
        stroke: '#262626!important',
      },
      '&.ql-active': {
        backgroundColor: '#A234B5!important',
      },
      '&.ql-active .ql-stroke': {
        stroke: '#ffffff!important',
      },
    },
  },
  attach_icon_style: {
    paddingTop: '4px',
  },
  ql_send: {
    height: '40px!important',
    '&:hover': {
      backgroundColor: '#f2f2f2!important',
      '& span': {
        color: '#262626!important',
      },
    },
    '&:active': {
      backgroundColor: '#CDCDCD!important',
    },
  },
}));

type CustomToolbarType = {
  setIsError: (isError: boolean) => void;
  setCloudModal: (cloudModal: boolean) => void;
  closeModal?: any ;
  isError: boolean;
  onSend: () => void;
  isValuePresent: boolean;
  handleIsFileUpload: (value: any) => void;
  groupType: string;
  isFileUpload: boolean;
  sendMessage?: any;
  isSendingMessage?: boolean;
  isParenDrawerOpen?: boolean;
  showTokenExpireNotification?: boolean;
  expireNotification?: boolean;
  showFilesErrorMsg: any;
  setDropFiles: any;
  dropFiles: any;
  files: any;
  cloudFiles : any,
  setCloudfiles : (value: any) => void;
  setisCloudfiles : any;
  validateFileLoading: any;
  setvalidateFileLoading: any;
  setFiles: (value: any) => void;
  msgId: any;
  availItems: any;
};

type EditorType = {
  onSend: () => void;
  onChange: (value: any) => void;
  editorRef: any;
  isFileUpload: boolean;
  handleIsFileUpload: (value: any) => void;
  value: string;
  groupType: string;
  sendMessage?: any;
  isSendingMessage?: boolean;
  isParenDrawerOpen?: boolean;
  showTokenExpireNotification?: boolean;
  expireNotification?: boolean;
  showFilesErrorMsg: any;
  isFileUploadError:any;
  setisFileUploadError:any;
  files: any;
  validateFileLoading: any;
  setvalidateFileLoading: any;
  setFiles: (value: any) => void;
  cloudFiles : any;
  setCloudfiles : (value: any) => void;
  setisCloudfiles : any;
  showRemoveIcon: boolean;
  msgId: any;
  availItems: any;

};
//  * Event handler to be attached using Quill toolbar module (see line 73) https://quilljs.com/docs/modules/toolbar/

const UPLOAD_FILES_LIMIT = 5;
const UPLOAD_FILES_SIZE_LIMIT = 52428800;
const UPLOAD_FILES_SIZE_LIMIT_IN_MB = 50;
const USER_UPLOAD_BUCKET_LIMIT = 5368709120;
const USER_UPLOAD_LOWER_LIMIT = 4294967296;

const CustomToolbar = ({
  setIsError,
  setCloudModal,
  isError,
  onSend,
  isValuePresent,
  groupType,
  isFileUpload,
  handleIsFileUpload,
  sendMessage,
  isSendingMessage,
  isParenDrawerOpen,
  showFilesErrorMsg,
  setDropFiles,
  dropFiles,
  validateFileLoading,
  setvalidateFileLoading,
  files,
  setFiles,
  cloudFiles,
  setCloudfiles,
  setisCloudfiles
}: CustomToolbarType) => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const uploadedFilesSize: any = localStorage.getItem('attachmentsSize');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleUploadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (files.length < 5) {
      setAnchorEl(event.currentTarget);
    } else {
      showFilesErrorMsg(
        {
          severity: 'fileLimitError',
          message: `Sorry, you can attach only ${UPLOAD_FILES_LIMIT} files at a time.`,
          isShowClose: 1,
        },
        true
      );
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    resizeWindow();
    if (!isFileUpload) {
      setFiles([]);
      showFilesErrorMsg({}, false);
    }
    window.addEventListener(
      'resize',
      resizeWindow.bind(null, isParenDrawerOpen)
    );
    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, [isParenDrawerOpen, isMobile, isFileUpload]);

  const handleLinkClick = () => {
    resizeWindow();
  };
  const handleImageClick = () => {
    handleMenuClose();
    resizeWindow();
    handleMenuClose()
    fileInputField.current.click();
  };
  const handleStorageClick = () => {
    handleMenuClose()
    setCloudModal(true)
  }

  const resizeWindow = () => {
    const tooltipElem = document.querySelector('.ql-tooltip') as HTMLElement;
    if (tooltipElem && tooltipElem.style.left) {
      if (isMobile) {
        tooltipElem.style.zIndex = isParenDrawerOpen ? '0' : '5';
      } else {
        tooltipElem.style.zIndex = '5';
      }
      tooltipElem.style.top = '';
      tooltipElem.style.marginTop = '';
      tooltipElem.style.bottom = '-40px';
      tooltipElem.style.left = '0';
      tooltipElem.style.transform = 'none';
    }
  };
  useEffect(() => {
    if (dropFiles.length > 0) {
      handleNewFileUpload(dropFiles);
      setDropFiles([]);
    }
  }, [dropFiles]);

  useEffect(() => {
    if (cloudFiles.length > 0) {
      setisCloudfiles(true)
      handleNewFileUpload(cloudFiles);
      setCloudfiles([]);
    }
  }, [cloudFiles]);
  const fileInputField = useRef<any>(null);
  const handleNewFileUpload = (e: any) => {
    var updatedFiles;
    try {
      updatedFiles = e.target.files;
    } catch (ex) {
      updatedFiles = e;
    }
    handleIsFileUpload(true);

    if (updatedFiles.length + files.length <= UPLOAD_FILES_LIMIT) {
      let size = 0;
      let new_added_files_size = 0;
      let newly_added_files = [...updatedFiles];
      newly_added_files.map((eachFile: any) => {
        new_added_files_size += eachFile.size;
        return;
      });
      let total_files = [...files, ...updatedFiles];
      total_files.map((eachFile: any) => {
        size += eachFile.size;
        return;
      });
      const totalUploadedFilesSize =
        parseInt(uploadedFilesSize) + new_added_files_size;
      if (totalUploadedFilesSize <= USER_UPLOAD_BUCKET_LIMIT) {
        if (size <= UPLOAD_FILES_SIZE_LIMIT) {
          showFilesErrorMsg({}, false);
          if (totalUploadedFilesSize > USER_UPLOAD_LOWER_LIMIT) {
            showFilesErrorMsg(
              {
                severity: 'fileLimitWarning',
                message: `You are almost going to reach your max upload limit.`,
                isShowClose: 1,
              },
              true
            );
          }
          for (var file in Object.keys(updatedFiles)) {
            updatedFiles[file]['id'] = uuid();
          }
          setFiles([...files, ...updatedFiles]);
        } else {
          setIsError(true);
          showFilesErrorMsg(
            {
              severity: 'fileLimitError',
              message: `Sorry, your total file size exceeds ${UPLOAD_FILES_SIZE_LIMIT_IN_MB} MB.`,
              isShowClose: 1,
            },
            true
          );
        }
      } else {
        showFilesErrorMsg(
          {
            severity: 'fileLimitError',
            message:
              'Sorry, you have reached maximum total upload limit, please contact admin.',
            isShowClose: 1,
          },
          true
        );
      }
    } else {
      setIsError(true);
      showFilesErrorMsg(
        {
          severity: 'fileLimitError',
          message: `Sorry, you can attach only ${UPLOAD_FILES_LIMIT} files at a time.`,
          isShowClose: 1,
        },
        true
      );
    }
    fileInputField.current.value = null;
  };
  useEffect(() => {
    if (isError === false) {
      showFilesErrorMsg({}, false);
    }
    if (
      USER_UPLOAD_LOWER_LIMIT < uploadedFilesSize &&
      uploadedFilesSize < USER_UPLOAD_BUCKET_LIMIT &&
      files.length > 0
    ) {
      showFilesErrorMsg(
        {
          severity: 'fileLimitWarning',
          message: `You are almost going to reach your max upload limit.`,
          isShowClose: 1,
        },
        true
      );
    } else {
      showFilesErrorMsg({}, false);
    }
  }, [uploadedFilesSize]);

  let isSendButtonVisible = false;
  if (files.length > 0 && files.length === validateFileLoading.length) {
    isSendButtonVisible = true;
  } else if (isValuePresent === true && files.length < 1) {
    isSendButtonVisible = true;
  }
  return (
    <>
      <Box className={EditorStyle().boxDiv} id='toolbar'>
        <Box className={EditorStyle().toolDiv} id='toolbar1'>
          <button className={'ql-bold ' + EditorStyle().icon_spacing} />
          <button className={'ql-italic ' + EditorStyle().icon_spacing} />
          <button className={'ql-underline ' + EditorStyle().icon_spacing} />
          <button className={'ql-strike ' + EditorStyle().icon_spacing} />
          <button
            className={'ql-list ' + EditorStyle().icon_spacing}
            value='bullet'
          />
          <button
            className={'ql-list ' + EditorStyle().icon_spacing}
            value='ordered'
          />
          <button className={'ql-code-block ' + EditorStyle().icon_spacing} />
          <button
            className={'ql-link ' + EditorStyle().icon_spacing}
            onClick={handleLinkClick}
          />
          <button
            data-testid='fileUploadBtn'
            onClick={handleUploadClick}
            className={EditorStyle().icon_spacing}
          >
            <AddWithCircleIcon
              className={EditorStyle().attach_icon_style}
            ></AddWithCircleIcon>
          </button>
          <Menu
            data-testid='fileUploadMenu'
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <MenuItem onClick={handleImageClick}>
              <ListItemIcon>
                <FileUploadIcon />
              </ListItemIcon>
              Attachment
            </MenuItem>
            <MenuItem data-testid='cloudStorageBtn' onClick={handleStorageClick}>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              Cloud storage
            </MenuItem>
          </Menu>
          <FormField
            type='file'
            multiple
            ref={fileInputField}
            style={{ display: 'none' }}
            onChange={(e: any) => handleNewFileUpload(e)}
          />
        </Box>
        <Box>
          <Button
            data-testid='editorSendBtn'
            className={EditorStyle().ql_send}
            
            disabled={!isSendButtonVisible}
          >
            {groupType === 'DIRECT' && isSendingMessage ? (
              <CustomCircularProgress css={null} aria-label='Loading' size={20} />
            ) : (
              <><Button style={{paddingRight: '16px'}} disabled={!isSendButtonVisible} onClick={groupType === 'DIRECT' ? sendMessage : onSend}>Send</Button></>
            )}
          </Button>
        </Box>
      </Box>
    </>
  );
};
// FIX FOR RCH-63----------------------
const Link = Quill.import('formats/link');
class MyLink extends Link {
  static create(value: any) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (!value.startsWith('http')) {
      value = 'http://' + value;
    }
    node.setAttribute('href', value);
    return node;
  }
}

const icons = Quill.import('ui/icons');
icons['bold'] = getSVGIcon('bold');
icons['italic'] = getSVGIcon('italic');
icons['underline'] = getSVGIcon('underline');
icons['strike'] = getSVGIcon('strike');
icons['list'].bullet = getSVGIcon('list_bullet');
icons['list'].ordered = getSVGIcon('list_ordered');
icons['code-block'] = getSVGIcon('code_block');
icons['link'] = getSVGIcon('link');
Quill.register(MyLink);

const Editor = ({
  onSend,
  onChange,
  editorRef,
  isFileUpload,
  handleIsFileUpload,
  value,
  groupType,
  sendMessage,
  isSendingMessage = false,
  isParenDrawerOpen,
  showFilesErrorMsg,
  isFileUploadError,
  setisFileUploadError,
  files,
  setFiles,
  cloudFiles,
  setCloudfiles,
  setisCloudfiles,
  validateFileLoading,
  setvalidateFileLoading,
  showRemoveIcon,
  msgId,
  availItems,
}: EditorType) => {
  const [isValueContent, setValueContent] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [dropFiles, setDropFiles] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [cloudModal, setCloudModal] = useState<boolean>(false)

  useEffect(() => {
    var reachRoot = document.getElementById('root');
    if (reachRoot != null) {
      reachRoot.addEventListener('dragover', handleDragOver);
      reachRoot.addEventListener('drop', handleDrop);
    }
    return () => {
      if (reachRoot != null) {
        reachRoot.addEventListener('dragover', handleDragOver);
        reachRoot.addEventListener('drop', handleDrop);
      }
    };
  }, []);

  const closeModal = () => {
    setCloudModal(false)
  }

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;

    if (files && files.length) {
      setDropFiles(files);
    }
  };

  function stripHtml(html: any) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  function handleChange(value: any) {
    const textContent = stripHtml(value);
    onChange(value);

    if (textContent.trim() !== '') {
      setValueContent(true);
    } else {
      value = '';
      setValueContent(false);
    }
  }

  function clearPlaceholder() {
    if (isMobile) editorRef.current.editor.root.classList.remove('ql-blank');
  }

  function setPlaceholder() {
    if (value.trim() === '' && isMobile)
      editorRef.current.editor.root.classList.add('ql-blank');
  }
  const removeImg = (e: any) => {
    setIsError(false);
    showFilesErrorMsg({}, false);
    var img_list = files.filter((image: any) => image['id'] != e.target.id);
    setisFileUploadError(false)
    setFiles([...img_list]);
  };
  return (
      <div className={EditorStyle().customToolbar}>
        <ReactQuill
          className={files.length > 0 ? EditorStyle().textbar : EditorStyle().textbarEx}
          ref={editorRef}
          modules={Editor.modules}
          placeholder='Type a message'
          onChange={handleChange}
          onFocus={clearPlaceholder}
          onBlur={setPlaceholder}
          value={value}
        />
        <FileUpload
          files={files}
//           cloudFiles={cloudFiles}
          removeImg={removeImg}
          validateFileLoading={validateFileLoading}
          showRemoveIcon={showRemoveIcon}
          isFileUpload={isFileUpload}
        />

      {cloudModal && <>
        <CloudStorageComponent closeModal={closeModal} cloudFiles={cloudFiles} setCloudfiles={setCloudfiles} setisCloudfiles={setisCloudfiles} files={files} msgId={msgId} availItems={availItems}/>
        </>
        }
      <CustomToolbar
        setIsError={setIsError}
        setCloudModal={setCloudModal}
        closeModal={closeModal}
        isError={isError}
        handleIsFileUpload={handleIsFileUpload}
        isFileUpload={isFileUpload}
        data-testid='customeEditorSendBtn'
        onSend={onSend}
        groupType={groupType}
        isValuePresent={isValueContent}
        sendMessage={sendMessage ? sendMessage : null}
        isSendingMessage={isSendingMessage ? isSendingMessage : false}
        isParenDrawerOpen={isParenDrawerOpen}
        showFilesErrorMsg={showFilesErrorMsg}
        setDropFiles={setDropFiles}
        dropFiles={dropFiles}
        files={files}
        cloudFiles = {cloudFiles}
        setCloudfiles = {setCloudfiles}
        setisCloudfiles = {setisCloudfiles}
        setFiles={setFiles}
        validateFileLoading={validateFileLoading}
        setvalidateFileLoading={setvalidateFileLoading}
        msgId={msgId}
        availItems={availItems}
      />
    </div>
  );
};
Editor.modules = {
  toolbar: {
    container: '#toolbar',
  },
  clipboard: {
    matchVisual: false,
  },
};
Editor.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'video',
  'attach',
];

/*
 * PropType validation
 */
Editor.propTypes = {
  placeholder: PropTypes.string,
};
export default Editor;
