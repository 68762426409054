import { makeStyles } from '@material-ui/core/styles';

const EmailSubscriptionNavStyle = makeStyles((theme) => ({
    root: {
        height: '44px',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0
    },
    spacer: {
        height: '44px',
    },
    appBar: {
        minHeight: '44px !important',
        backgroundColor: '#181818',
        paddingRight: '0px'
    },
    unBlackboardLogo: {
        height: '15px',
        boxSizing: 'initial',
        verticalAlign: 'middle'
    },
    logo: {
        flexGrow: 1,
        paddingRight: '10px'
    },
    title: {
        color: '#A5A5A5',
        '&:hover': {
            color: '#FFF',
            backgroundColor: '#181818',
            cursor: 'pointer'
        }
    },
    linkGroup: {
        backgroundColor: "#262626",
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'stretch',
        '& > div:not(.popper-element)': {
            alignItems: 'center',
            display: 'flex',
            flexGrow: '1',
            paddingRight: '14px',
            paddingLeft: '14px'
        }
    },
    divider: {
        backgroundColor: '#000000'
    }
}));

export default EmailSubscriptionNavStyle;