import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const MessageIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1187_796)">
                <path d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C10.6629 22.5 9.38411 22.2501 8.20791 21.7944L1.5 22.5L2.25 15.9046C1.76618 14.6976 1.5 13.3799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_1187_796">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
                </defs>
            </svg>

        </SvgIcon>
    );
};