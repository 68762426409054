import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const InfoIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='8'
          cy='8'
          r='7'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 7H8V11'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 11H10'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle cx='8' cy='4' r='0.5' stroke='#262626' />
      </svg>
    </SvgIcon>
  );
};

export default InfoIcon;
