import { makeStyles, fade } from '@material-ui/core/styles';

const ProfileRelativesComponentStyle = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: '8px',
  },
  relativeName: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#2073A1',
    fontWeight: 400,
  },
  relativeinfo: {
    color: '#262626',
    fontWeight: 400,
  },
}));

export default ProfileRelativesComponentStyle;
