var district:any=localStorage.getItem('district');
var cluster:any=localStorage.getItem('cluster');

function get_cluster(){
  cluster =localStorage.getItem('cluster');
  if(cluster !== null){
    return cluster;
  }
}

export {get_cluster};