import React, { useContext, useState, useEffect } from 'react';
import { Drawer, LinearProgress } from '@material-ui/core';
import WestBar from './westBar';
import ConversationPanel from './ConversionPanel';
import AppStyle from '../styles/appStyle';
import clsx from 'clsx';
import { GroupEssential } from '../Types/GroupEssential';
import { GroupUnread } from '../Types/GroupUnread';
import { UserAuthCtx } from './AuthComponent';
import { useMediaQuery } from 'react-responsive';
import { StateContext } from '../components/StateContextComponent';
import { DirectMessageContact } from '../Types/DirectMessageContact';
import MessageRouteContext, { MessageRouteContextType } from '../context/MessageRouteContext'

const ChatHome = () => {
  const {
    isSidebarOpenPrev,
    isSidebarOpen,
    setSidebarState,
    selectedOrganizationId,
  } = useContext(StateContext);

  //State Variable for toggle drawer functionality
  const [isParentDrawerOpen, setParentDrawerOpen] = useState(true);

  const authContext = useContext(UserAuthCtx);

  const { isMessageRoute, channelId: groupFromMessageRoute = null } = useContext(MessageRouteContext) as MessageRouteContextType

  const {
    userName: personName,
    organizationName,
    mobile: isOpenedInMobile,
    userId: personId,
    userLoggedInDomain: domain,
    userRole,
  } = authContext.userCtx;

  const classes = AppStyle()({
    isOpenedInMobile
  });
  const [selectedGroup, setGroup] = useState<GroupEssential>(
    new GroupEssential()
  );
  const [isChannelEdited, setIsChannelEdited] = useState(false)

  // custom group selector for navigating to group onClick of 'jump icon'
  // Set this to a valid group id and it will be automatically set selected
  const [selectMyGroup, setSelectMyGroup] = useState((isMessageRoute && groupFromMessageRoute) ? groupFromMessageRoute as string : '');

  const [groupUnread, setGroupUnread] = useState<GroupUnread>(
    new GroupUnread()
  );

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const showConvPanelForMobile = () => (isMobile ? !isParentDrawerOpen : true);

  const [
    directChannelIDFromMessageQueue,
    setDirectChannelIDFromMessageQueue,
  ] = useState(new DirectMessageContact());

  const [directUnread, setDirectUnread] = useState<GroupUnread>(
    new GroupUnread()
  );

  const [messagePanelLoading, setMessagePanelLoading] = useState(false);
  const [conversationTitle, setConversationTitle] = useState(true);

  useEffect(() => {
    if (!isParentDrawerOpen) {
      setSidebarState(false);
    } else {
      setSidebarState(isSidebarOpenPrev);
    }
  }, [isParentDrawerOpen]);

  useEffect(() => {
    if (!isMobile) setParentDrawerOpen(true);
  }, [isMobile]);


  const [OnDeleted, setOnDeleted] = useState(false);

  return (
    <>
      <div
        className={clsx(classes.westbarDiv, {
          [classes.westbarHide]: !isParentDrawerOpen,
          [classes.westbarDivSidebarShrink]: !isSidebarOpen,
        })}
      >
        <Drawer
          variant='persistent'
          anchor='left'
          open={isParentDrawerOpen}
          classes={{
            paper: isSidebarOpen
              ? classes.drawerPaper
              : classes.drawerPaperSidebarShrink,
          }}
        >
          <WestBar
            personId={personId ? personId : ''}
            isChannelEdited={isChannelEdited}
            setIsChannelEdited={setIsChannelEdited}
            bbDomain={domain || ''}
            setGroup={setGroup}
            setParentDrawerOpen={setParentDrawerOpen}
            groupUnread={groupUnread}
            directUnread={directUnread}
            selectedGroup={selectedGroup}
            selectMyGroup={selectMyGroup}
            setSelectMyGroup={setSelectMyGroup}
            directChannelIDFromMessageQueue={directChannelIDFromMessageQueue}
            setMessagePanelLoading={setMessagePanelLoading}
            setConversationTitle={setConversationTitle}
            OnDeleted={OnDeleted}
            setOnDeleted={setOnDeleted}
          ></WestBar>
        </Drawer>
      </div>
      {selectedGroup.id !== '' && showConvPanelForMobile && (
        <div
          className={clsx(classes.convDiv, {
            [classes.convDivShift]: isMobile,
            [classes.convDivShiftSideBarShrink]:
              isMobile && !isParentDrawerOpen,
            [classes.convDivDesk]: !isMobile && !isSidebarOpen,
            [classes.convDivDeskShrink]: !isMobile && isSidebarOpen,
            [classes.convDivShiftFullSideBar]:
              !isMobile && !isParentDrawerOpen,
          })}
        >
          <ConversationPanel
            selectedGroup={selectedGroup}
            setIsChannelEdited={setIsChannelEdited}
            username={personName}
            selectedOrganizationId={selectedOrganizationId.schoolId}
            bbdomain={domain}
            personid={personId}
            userRole={userRole}
            setGroupUnread={setGroupUnread}
            setDirectUnread={setDirectUnread}
            setGroup={setGroup}
            isParenDrawerOpen={isParentDrawerOpen}
            setParentDrawerOpen={setParentDrawerOpen}
            setSelectMyGroup={setSelectMyGroup}
            setDirectChannelIDFromMessageQueue={
              setDirectChannelIDFromMessageQueue
            }
            impersonating={false}
            isMessagePanelLoading={messagePanelLoading}
            setMessagePanelLoading={setMessagePanelLoading}
            conversationTitle={conversationTitle}
            setConversationTitle={setConversationTitle}
            setOnDeleted={setOnDeleted}
          />
        </div>
      )}
    </>
  );
};

export default ChatHome;
