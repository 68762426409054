import { makeStyles } from '@material-ui/core/styles';

const ModalStyle = makeStyles((theme) => ({

    bgContainer: {
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 1200,
      background: 'rgba(60, 60, 60, 0.5)',
      width: '100%',
      height: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContainer: {
      backgroundColor:'white',
      border: '1px solid #CDCDCD',
      borderRadius: '5px',
      width: '97%',
      height: '93%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '15px',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #CDCDCD',
      overflow: 'hidden',
      color: '#262626',
    },
    title: {
      fontSize: '23px',
      fontWeight: 400,
      marginLeft: '17px',
      marginBottom: '8px',
      '@media screen and (max-width: 600px)': {
        fontSize: '17px',
        fontWeight: 500,
      },
    },
    closeIcon: {
      fontSize: '25px',
      cursor: 'pointer',
      marginRight: '15px',
      marginTop: '10px',
    },
    imageContainer: {
      height: '87%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto',
    },
    image: {
      maxHeight: '90%',
      maxWidth: '90%',
    },
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        borderTop: '1px solid #CDCDCD',
        backgroundColor: '#F8F8F8',
        padding: '18px',
        
    },
    downalodBtn: {
      marginRight: '10px',
      backgroundColor: '#262626',
      color: '#FFFFFF',
      borderRadius: '2px',
      borderWidth: '0',
      fontSize: '15px',
      height: '35px',
      width: '160px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '@media screen and (max-width: 550px)': {
        width: '130px',
      },
    },
    footerDownloadIcon: {
      marginRight: '8px',
      marginBottom:'2px',
    },
  }))

  export default ModalStyle