import React, { useState } from 'react';
import PaginationTypes from './PaginationTypes'
import { Box } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const Pagination = ({ 
  totalPages, 
  currentPage, 
  resource, 
  setLoader = () => {},
  updateDom = () => {}
}: PaginationTypes) => {
  let [page, setPage] = useState(currentPage)

  const nextPageToGo = (by: number) => page + by;

  const handleError = (error: Error, page: number) => {
    console.error(error)
    setPage(page)
    updateDom(null, page)
    setLoader(false)
  }

  const canForward = () => (page < totalPages);
  const canBackward = () => (page > 1);

  const forward = async () => {
    if(!canForward()) return;

    setLoader(true)
    const nextPage = nextPageToGo(1)
    try {
      let pageInfo = await resource(nextPage, 'next')
      setPage(nextPage)
      updateDom(pageInfo, nextPage)
      setLoader(false)
    } catch(error) {
      handleError(error, page)
    }
  }

  const backward = async () => {
    if(!canBackward()) return;

    setLoader(true)
    const nextPage = nextPageToGo(-1)
    try {
      let pageInfo = await resource(nextPage, 'back')
      setPage(nextPage)
      updateDom(pageInfo, nextPage)
      setLoader(false)
    } catch(error) {
      handleError(error, page)
    }
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center' mt={1} mb={1}>
      <ArrowBackIosRoundedIcon
        fontSize='small'
        data-testid='back-icon' 
        color={!canBackward() ? 'disabled' : undefined} 
        cursor='pointer' 
        onClick={backward}/>
        <Box component='span' pl={0.5} pr={0.5}>Page {page} of {totalPages}</Box>
      <ArrowForwardIosRoundedIcon
        fontSize='small'
        data-testid='forward-icon'
        cursor='pointer'
        color={!canForward() ? 'disabled' : undefined} 
        onClick={forward}/>
    </Box>
  )
}

export default Pagination;