import React, { useState, useContext, useEffect } from 'react';
import SideBar from './components/Sidebar/Sidebar';
import NavBar from './components/navBar';
import 'react-quill/dist/quill.snow.css';
import AppStyle from './styles/appStyle';
import { UserAuthCtx } from './components/AuthComponent';
import QueueContextProvider from './context/queuecontext';
import RouteComponent from './components/RouteComponent';
import PreRoute from './components/PreRoute/PreRoute';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function App() {
  useEffect(() => {
    var GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
      ? process.env.REACT_APP_GA_TRACKING_ID
      : '';

    var ENV = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'DEV';
    if (GA_TRACKING_ID != '') {
      const script = document.createElement('script');
      script.async = true;
      script.src =
        'https://www.googletagmanager.com/gtag/js?id=' + GA_TRACKING_ID;
      script.onload = () => loadGA(GA_TRACKING_ID);
      document.body.appendChild(script);
    }
  });

  const loadGA = (tracking_id: string) => {
    var domain_url = '';
    if (process.env.REACT_APP_ENV?.toUpperCase() === 'STAGE') {
      domain_url = 'stage.finalsitereach.com';
    } else if (process.env.REACT_APP_ENV?.toUpperCase() === 'PROD') {
      domain_url = 'finalsitereach.com';
    } else if (process.env.REACT_APP_ENV?.toUpperCase() === 'DEV') {
      domain_url = 'dev.finalsitereach.com';
    }
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', tracking_id, { cookie_domain: domain_url });
    gtag('page_location', window.location.href);
  };

  const classes = AppStyle()();
  const authContext = useContext(UserAuthCtx);

  const {
    userName: personName,
    organizationName,
    mobile,
    userLoggedInDomain: domain,
  } = authContext.userCtx;

  const [username, setUsername] = useState(
    personName ? personName : 'UnknownUser'
  );

  const [orgName, setOrgName] = useState(
    organizationName ? organizationName : 'UnknownOrganization'
  );

  const [bbDomain, setDomain] = useState(domain ? domain : '');
  //State Variable for toggle drawer functionality
  const [isParentDrawerOpen, setParentDrawerOpen] = useState(true);
  const [isSideBarOpen, setSidebarOpen] = useState(true);

  let isEmailSubscription = false;

  if (window.location.pathname.toLowerCase() === '/profile/email/verify') {
    isEmailSubscription = true;
  }

  var isLogOut = false;
  if (window.location.pathname.toLowerCase() === '/logout') {
    isLogOut = true;
  }
  return (
    <QueueContextProvider>
      <div className={classes.root}>
        <NavBar
          orgName={orgName || 'UnknownOrganization'}
          username={username || 'UnknownUser'}
          isMobile={mobile == 'true'}
          bbdomain={bbDomain || ''}
          isEmailSubscription={isEmailSubscription}
          hideNavBar={isLogOut}
        />
        <PreRoute
          render={(renderSidebar: boolean = true) => (
            <div className={classes.mainDiv}>
              {!isLogOut && !isEmailSubscription && renderSidebar && (
                <SideBar />
              )}
              <RouteComponent />
            </div>
          )}
        ></PreRoute>
      </div>
    </QueueContextProvider>
  );
}
