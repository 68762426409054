import React from 'react';
import { Badge, Avatar, makeStyles, withStyles } from '@material-ui/core';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const BadgeAvatar = (props: any) => {
  const classes = useStyles();
  const name = props.name;
  const profileImage = '/images/avatar/' + props.profileImage;
  const isAvailable = props.available;
  const sizeClass = props.size ? props.size : classes.small;
  return (
    <div className={classes.root}>
      {isAvailable ? (
        <StyledBadge
          overlap='circle'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant='dot'
        >
          <Avatar alt={name} className={sizeClass} src={profileImage} />
        </StyledBadge>
      ) : (
        <Avatar alt={name} className={sizeClass} src={profileImage} />
      )}
    </div>
  );
};
export default BadgeAvatar;
