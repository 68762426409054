import { makeStyles } from '@material-ui/core/styles';

const ModalStyle = makeStyles((theme) => ({

    bgContainer: {
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 1200,
      background: 'rgba(60, 60, 60, 0.5)',
      width: '100%',
      height: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContainer: {
      backgroundColor:'white',
      border: '1px solid #CDCDCD',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
    '@media screen and (max-width: 750px)': {
      width: '100%',
    },
    '@media screen and (min-width: 751px) and (max-width:991px)': {
      width: '90%',
    },
    '@media screen and (min-width: 992px) and (max-width:1225px)': {
      width: '70%',
    },
    '@media screen and (min-width:1226px)': {
      width: '50%',
    },
    },
    headerContainer: {
      marginLeft: '17px',
      color: '#262626',
    },
    subHeaderContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
      fontSize: '24px',
      fontWeight: 400,
      marginBottom: '8px',
      marginTop:'10px',
      width: '60%',
      '@media screen and (max-width: 600px)': {
        fontSize: '20px',
        fontWeight: 500,
      },
    },
    instructionCloud: {
      marginTop:0,
      marginBottom:"1px",
      fontSize:"15px",
      fontFamily: "'Open Sans', 'Helvetica', 'Arial', sans-serif"
    },
    warningMsg: {
      color:"#FFBF00"
    },
    closeIcon: {
      fontSize: '30px',
      cursor: 'pointer',
      marginRight: '15px',
      marginLeft: '15px',
      marginTop: '5px',
      '@media screen and (max-width: 600px)': {
        marginTop: '0px',
      },
    },
    imageContainer: {
      textAlign:"center",
      height: '100%',
      width:"100%",
      overflow: 'auto',
    },
    
    footerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        borderTop: '1px solid #CDCDCD',
        backgroundColor: '#F8F8F8',
        padding: '18px',
        paddingTop:'10px',
        paddingBottom:'10px'
        
    },
    CancelBtn: {
      marginRight: '10px',
      backgroundColor: '#cdcdcd',
      color: '#262626',
      borderRadius: '2px',
      borderWidth: '0',
      fontSize: '15px',
      height: '35px',
      width: '160px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '@media screen and (max-width: 550px)': {
        width: '130px',
      },
    },
  }))

  export default ModalStyle