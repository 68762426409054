import React from 'react';
import ActionableAlertBox from '../../../ActionableAlertBox/ActionableAlertBox';
import { Box } from '@material-ui/core';
import ProfileEmailListContext from '../../ProfileEmailListContext';
import { isPresentInUpdate, isPresentInAdd } from '../../profileEmail.utils';
import { DeleteProfileEmailType } from '../ProfileEmailFormTypes';

const DeleteProfileEmail = ({
  show,
  index,
  onClose,
}: DeleteProfileEmailType) => {
  const styles = {
    paper: {
      width: '450px',
    },
  };

  const [disableForm, setDisableForm] = React.useState(false);

  const { emails, setEmails }: any = React.useContext(ProfileEmailListContext);

  return (
    <ActionableAlertBox
      title='Delete email address'
      shouldOpen={show}
      onAction={() => {
        setDisableForm(true);
        if (index >= 0) {
          const buffer = [...emails.deleteBuffer];
          let deletedEmail = emails.display[index].email;
          const displayEmails = [...emails.display];
          displayEmails.splice(index, 1);

          // check if the same email is in the add or edit also, if yes delete that too.
          const presentInAddAt = isPresentInAdd(emails, deletedEmail);
          let uncommittedAdded = [...emails.uncommitted.added];

          if (presentInAddAt > -1) {
            uncommittedAdded.splice(presentInAddAt, 1);
          }

          let toPushInBuffer = undefined;
          let presentInUpdateAt = -1;
          let uncommittedUpdated = { ...emails.uncommitted.updated };

          presentInUpdateAt = isPresentInUpdate(
            uncommittedUpdated,
            deletedEmail
          );
          if (presentInUpdateAt != -1) {
            deletedEmail = uncommittedUpdated[presentInUpdateAt].old.email;
            toPushInBuffer = uncommittedUpdated[presentInUpdateAt].old;
            delete uncommittedUpdated[presentInUpdateAt];
          }

          let uncommittedDeleted = [...emails.uncommitted.deleted];
          if (presentInAddAt == -1) {
            uncommittedDeleted.push(deletedEmail);
            if (toPushInBuffer == undefined) {
              toPushInBuffer = emails.display[index];
            }
            buffer.push(toPushInBuffer);
          }

          setEmails({
            ...emails,
            display: [...displayEmails],
            uncommitted: {
              ...emails.uncommitted,
              added: [...uncommittedAdded],
              updated: {
                ...uncommittedUpdated,
              },
              deleted: [...uncommittedDeleted],
            },
            deleteBuffer: [...buffer],
          });
        }
        onClose();
        setDisableForm(false);
      }}
      secondaryButtonLabel='Cancel'
      primaryButtonLabel='Delete'
      styles={styles}
      primaryButtonProps={{ disabled: disableForm }}
      onClose={onClose}
    >
      <Box paddingBottom='74px'>
        Are you sure you want to delete this email address?
      </Box>
    </ActionableAlertBox>
  );
};

export default DeleteProfileEmail;
