import React from 'react';
import { OrganizationInfo } from '../Types/OrganizationInfo';
import { getUserAccount, getUserGroup } from '../services/userAccountServices';
import { getDirectContactList } from '../services/DirectMessageService';
import { GroupInfo } from '../Types/GroupInfo';
import { ClassInfo } from '../Types/ClassInfo';
import { GroupEssential } from '../Types/GroupEssential';
import { GroupType } from '../Types/GroupType';
import { SchoolInfo } from '../Types/SchoolInfo';
import UserDetailsType from '../Types/UserDetailsType';
import { DirectMessageContact } from '../Types/DirectMessageContact';
import { stringList } from 'aws-sdk/clients/datapipeline';

var orgList = new Array<OrganizationInfo>();
var channelList = new Array<GroupEssential>();
var directContactList = new Array<DirectMessageContact>();
let selectedOrganizationId = new SchoolInfo();
export {
  loadChannelData,
  loadOrgData,
  getGroupUnreadCount,
  setGroupUnreadCount,
  selectedOrganizationId,
  setSelectedOrganizationId,
  orgList,
  channelList,
  clearChannelList,
  loadDirectContactData,
  directContactList,
  setDirectUnreadCount,
  remove_channel_id
};
const DataProvider = React.createContext({
  loadChannelData,
  loadOrgData,
  getGroupUnreadCount,
  setGroupUnreadCount,
  selectedOrganizationId,
  setSelectedOrganizationId,
  orgList,
  channelList,
  loadDirectContactData,
  directContactList,
  setDirectUnreadCount,
  remove_channel_id
});

const DataProviderCtx = (props: any) => {
  const all = {
    loadChannelData,
    loadOrgData,
    getGroupUnreadCount,
    setGroupUnreadCount,
    selectedOrganizationId,
    setSelectedOrganizationId,
    orgList,
    channelList,
    loadDirectContactData,
    directContactList,
    setDirectUnreadCount,
    remove_channel_id
  };
  return (
    <DataProvider.Provider value={all}>{props.children}</DataProvider.Provider>
  );
};


function setDirectUnreadCount(
  directId: string | undefined,
  schoolId: string | undefined,
  unreadMsgCnt: number
) {
  directContactList = directContactList.map((contact) => {
    if (contact.id === directId && contact.schoolId === schoolId) {
      return {
        ...contact,
        unreadCount:
          unreadMsgCnt !== 0 ? contact.unreadCount + unreadMsgCnt : 0,
      };
    }
    return contact;
  });
}

function setGroupUnreadCount(groupId: string, unreadMsgCnt: number) {
  channelList = channelList.map((grpEss) => {
    if (grpEss.id === groupId) {
      return {
        ...grpEss,
        unreadCount: unreadMsgCnt !== 0 ? grpEss.unreadCount + unreadMsgCnt : 0,
      };
    }
    return grpEss;
  });
}

function getGroupUnreadCount(groupId: string) {
  let selGroup = channelList.find((grpEss) => {
    return grpEss.id === groupId;
  });
  return selGroup?.unreadCount || 0;
}

async function loadDirectContactData(
  userId: string,
  domain: string,
  domainId: string,
  impersonatedUserId: string
) {
  try {
    var contactResult = await getDirectContactList(userId, domain, domainId, impersonatedUserId);
    directContactList = contactResult?.data;
  } catch (e) {
    console.log(e);
  }
}
async function remove_channel_id(channelId: string) {
  channelList = channelList.filter((group:GroupEssential) => (group.id !== channelId));
}
async function loadChannelData(userDetails: any) {
  try {
    var userGroup = await getUserGroup(
      userDetails.userId,
      userDetails.userLoggedInDomain,
      userDetails.domainId ? userDetails.domainId : '',
      userDetails.impersonatedUserId
    );
    channelList.length = 0;
    channelList.push(
      ...userGroup?.data.groups
        .filter(
          (group: GroupInfo) =>
            typeof group.matchString !== 'undefined' &&
            group.matchString !== '' &&
            group.name !== ''
        )
        .map((group: GroupInfo) => {
          return {
            name: group.name,
            id: group.id + '',
            unreadCount: group.unreadCount,
            type: group.type,
            groupType: group.type === "GROUPS" ? GroupType.Groups:GroupType.Channel,
            orgId: Number(group.organizationID),
            orgName: group.matchString,
            owner: group.owner,
            userType: group.userType,
            twoWayEnabled: group.twoWayEnabled,
          };
        })
    );
    channelList.push(
      ...userGroup?.data.classes
        .filter(
          (classInfo: ClassInfo) =>
            typeof classInfo.organizationName !== 'undefined' &&
            classInfo.organizationName !== '' &&
            classInfo.name !== ''
        )
        .map((classInfo: ClassInfo) => {
          return {
            name: classInfo.name,
            id: classInfo.uniqueID + '',
            unreadCount: classInfo.unreadCount,
            type: classInfo.type,
            groupType: GroupType.Classes,
            orgId: Number(classInfo.organizationID),
            orgName: classInfo.organizationName,
            owner: classInfo.owner
          };
        })
    );
  } catch (err) {
    console.error('Error while loading channel list');
    console.error(err);
  }
}

async function loadOrgData(userDetails: UserDetailsType) {
  try {
    var result = await getUserAccount(
      userDetails.userId,
      userDetails.userLoggedInDomain
    );
    let districKey: any=localStorage.getItem('district')
    localStorage.setItem('attachmentsSize', result?.data.attachmentsSize )
    localStorage.setItem('bucket_name',result?.data.bucket_name);
    localStorage.setItem('cluster',result?.data.cluster);
    

    if (result?.data.organizations) {
      orgList = result?.data.organizations.map((org: OrganizationInfo) => {
        return org;
      });

      // set organization id, if not currently set
      let school = new SchoolInfo();
      school.schoolName = orgList[0].name;
      school.schoolId = orgList[0].id;
      localStorage.setItem('reachOrgSchoolId', orgList[0].reachOrgSchoolId);
      setSelectedOrganizationId(school);
      return orgList;
    } else if (result?.data.errorMessage === "'Cookie'") {
      window.location.reload();
    }
  } catch (err) {
    console.error('Error while loading organization details');
    console.error(err);
  }
}

function setSelectedOrganizationId(orgId: SchoolInfo) {
  selectedOrganizationId = orgId;
}

function clearChannelList() {
  channelList.length = 0;
  directContactList.length = 0;
}

export default DataProviderCtx;
