import React from "react";
import UniversalNav from "./universalNav";
import EmailSubscriptionNav from './EmailSubscriptionNav/EmailSubscriptionNav';

type NavBarType = {
  orgName: string;
  username: string;
  isMobile: boolean;
  bbdomain: any;
  hideNavBar : boolean;
  isEmailSubscription: boolean;
};

const NavBar = ({ isMobile, isEmailSubscription, hideNavBar }: NavBarType) => {
  if(isEmailSubscription){
    return <EmailSubscriptionNav />;
  }

  return <>
    {!isMobile && !hideNavBar && <UniversalNav/>}
  </>
};

export default NavBar;
