import { makeStyles, fade } from '@material-ui/core/styles';

const ProfileComponentStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  item: {
    padding: 0,
  },
  itemText: {
    wordBreak: 'break-word',
    // display: 'flex',
  },
  addEmailLink: {
    color: '#2073A1',
    textDecoration: 'underline',
    textDecorationLine: 'underline',
    fontSize: '14px',
    lineHeight: '18px',
    cursor: 'pointer',
  },
  resendEmailLink: {
    color: '#2073A1',
    textDecoration: 'underline',
    textDecorationLine: 'underline',
    fontSize: '12px',
    lineHeight: '18px',
    cursor: 'pointer',
  },
  actionBtns: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '4px',
  },
  noPadding: {
    padding: 0,
    minHeight: '30px',
  },
  pointer: {
    cursor: 'pointer',
  },
  linkExpired: {
    color: '#C23E37',
    display: 'contents',
    paddingRight: '5px',
    fontSize: '12px',
  },
  resendParent: {
    display: 'flex',
  },
  italicText: {
    fontStyle: 'italic',
  },
  container: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  conditionalContainer: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'column',
  },
  leftDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  listLineAlignment: {
    paddingLeft: '35px',
  },
  trashIcon: {
    justifyContent: 'flex-end',
    textAlign: 'end',
  },
  rightDiv: {
    display: 'flex',
  },
}));

export default ProfileComponentStyle;
