import { makeStyles } from '@material-ui/core';
const SettingConfirmationWindowStyles = makeStyles((theme) => ({
  itatlicContent: {
    fontStyle: 'italic',
  },
  ContentColor: {
    color: 'inherit'
  },
}));

export default SettingConfirmationWindowStyles;