import React, {useContext} from 'react'
import ModalStyle from './ImagePreviewModalStyle'
import { IoClose } from "react-icons/io5";
import DownloadIcon from '../../Icons/ModalDownloadIcon';
import { getPresignedUrl } from '../../services/FilesServices';
import { UserAuthCtx } from '../AuthComponent';

function ImagePreviewModal(props:any) {
  const {selectedImage, closeModal, setShowImagePreview, file} = props
  const showFilesErrorMsg = props.showFilesErrorMsg
  const userDetails = useContext(UserAuthCtx).userCtx;
  const HandleHideModal = () => {
    if (file) {setShowImagePreview(null)}else {closeModal()}
  }
  const DownloadFile = () => {
    if(file !== undefined){
      var image_url = file.imageUrl
    }else
    {
      var image_url = selectedImage.url
    }
    var url_link = image_url.split("?")[0];
    var splitedFileName = url_link.split("/")
    var fileName = splitedFileName[splitedFileName.length - 1]
    const encoded_filename = fileName.replace(/^.*\/([^/]+?)\?.*$/, '$1');
    const plain_filename= decodeURIComponent(encoded_filename);
    const bbDomain = userDetails.userLoggedInDomain
    const link = url_link.replace(fileName, plain_filename)
    var downloadFiles = [];
    var payload = {
    "name": plain_filename,
    "location" : link
    }
    downloadFiles.push(payload);
    getPresignedUrl(downloadFiles,'download', bbDomain).then((response: any) => {
      if ("generated_url" in response.data) {
        var url = response.data.generated_url[plain_filename];
        const obj = document.createElement('a')
        obj.href = url
        obj.download = plain_filename
        obj.click() 
      }
      }).catch(function(response) {
        if(response instanceof Error) {
          console.log(response);
          showFilesErrorMsg({
            severity: 'fileLimitError',
            message:
              'Network Error',
            isShowClose: 1,
          },true)
        } else {console.log(response.data);}
      });
  }

const imageUrl = file === undefined ? selectedImage.updated_url : file.imageUrl
let imageName = file === undefined ? selectedImage.name : file.imageName

const maxNameLength = window.innerWidth / 20
imageName = imageName.length < maxNameLength ? imageName.slice(0, maxNameLength) : imageName.slice(0, maxNameLength) + ' ...'

  return (
    <div data-testid="modalContainer" className={ModalStyle().bgContainer}>
      <div className={ModalStyle().modalContainer}>
        <div className={ModalStyle().headerContainer}>
          <p className={ModalStyle().title}>{imageName}</p>
          <IoClose onClick={HandleHideModal} className={ModalStyle().closeIcon} />
        </div>
        <div className={ModalStyle().imageContainer}>
          <img alt={imageName} className={ModalStyle().image} src={imageUrl}/>
        </div>
        <div className={ModalStyle().footerContainer}>
          <button className={ModalStyle().downalodBtn} onClick={DownloadFile} data-testid='donwloadBtn' id='showpopup'>
            <DownloadIcon style={ModalStyle().footerDownloadIcon}/>
              <span >Download</span>
          </button>
        </div>
        
      </div>
    </div>
  );
}
  
export default ImagePreviewModal