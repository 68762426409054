export class DirectMessageContact {
    secondUser: string = "";
    lastSent: number = 0;
    schoolId: string = "";
    id: string = "";
    firstUser: string = "";
    userId: string = "";
    username: string = "";
    unreadCount: number = 0;
}

export class DirectUserData {
    public name: string = "";
    public id: number = 0;
    public role: string="";
  }