import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    emptyMessage: {
      fontWeight: 600,
      color: '#262626',
    },
    emptySubMessage: {
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
  });
});

const EmptyBox = (props: any) => {
  const { emptyMessage, emptySubMessage } = props;
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flex='1'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
    >
      <Box className={classes.emptyMessage}>{emptyMessage}</Box>
      <Box className={classes.emptySubMessage}>
        {emptySubMessage?.emptySubMessage}
      </Box>
    </Box>
  );
};

export default EmptyBox;
