import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Link,
  CircularProgress,
  Select,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ProfileEmailList from '../ProfileEmailList/ProfileEmailList';
import ProfileComponentStyle from './ProfileComponentStyle';
import { StateContext } from '../StateContextComponent';
import ProfileRelativesComponent from '../ProfileRelatives/ProfileRelativesComponent';
import ProfileRelativesComponentType from '../ProfileRelatives/ProfileRelativesComponentType';
import { UserAuthCtx } from '../../components/AuthComponent';
import { QueueContext } from '../../context/queuecontext';

import {
  getUserProfile,
  addEmail,
  UpdateEmailPayloadType,
  updateProfile,
  deleteProfile,
} from '../../services/UserProfileService';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  ProfileComponentType,
  UserProfileDetails,
  commitedStateType,
} from './ProfileComponentType';
import {
  buildPutPayload,
  findEmailInArray,
  isPresentInUpdate,
} from '../ProfileEmailList/profileEmail.utils';
import ProfileEmailListContext from '../ProfileEmailList/ProfileEmailListContext';
import NotificationBox from '../NotificationBox/NotificationBox';
import ActionableAlertBox from '../ActionableAlertBox/ActionableAlertBox';
import { PreferredLanguage } from '../../Types/PreferredLanguage';
import { useHistory } from 'react-router-dom';
import { SaveLoader } from '../Loader/Loader';


const ProfileComponent = () => {
  const classes = ProfileComponentStyle();
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [profilePicUpdateTracker, setProfilePicUpdateTracker]: any = useState(
    null
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [
    showTokenExpireNotification,
    setShowTokenExpireNotification,
  ] = useState(false);
  const [expireNotification, setExpireNotification] = useState({
    message: '',
    severity: '',
    isShowClose: 1,
  });
  const [notification, setNotification] = useState({
    message: '',
    severity: '',
  });
  const [formValues, setFormValues] = useState<ProfileComponentType>(
    UserProfileDetails
  );

  const [isLoading, setLoader] = useState(false);
  const [confirmation, setConfirmation] = useState({
    open: false,
    title: 'Unsubscribe',
    message: '',
  });
  const [unloadConfirmation, setUnloadConfirmation] = useState({
    open: false,
    title: 'Discard changes?',
    message: 'You have unsaved changes. Are you sure you want to leave?',
  });
  const {
    isSidebarOpen,
    setSidebarState,
    setEnableSchoolsToSelect,
    setAllowLogout,
    confirmLogout,
  } = useContext(StateContext);

  let queueContext = useContext(QueueContext);
  const inputTextFieldProps = {
    disableUnderline: true,
    autoFocus: false,
    classes: { focused: classes.focused },
    style: { paddingRight: 0 },
  };
  setEnableSchoolsToSelect(true);
  const toggleSidebar = () => {
    setSidebarState(!isSidebarOpen);
  };

  const [emails, setEmails] = useState({
    display: [],
    uncommitted: {
      added: [],
      updated: {},
      deleted: [],
    },
    deleteBuffer: [],
  });

  const [committedState, setCommittedState] = useState<commitedStateType>({
    image: '',
    displayEmails: [],
  });

  const authContext = useContext(UserAuthCtx);
  const userId = authContext.userCtx.userId ? authContext.userCtx.userId : '';
  const domain = authContext.userCtx.userLoggedInDomain
    ? authContext.userCtx.userLoggedInDomain
    : '';
  const domainId = authContext.userCtx.domainId
    ? authContext.userCtx.domainId
    : '';
  const preferredLanguage = authContext.userCtx.preferredLanguage
    ? authContext.userCtx.preferredLanguage
    : new PreferredLanguage();
  const userRole = authContext.userCtx.userRole
    ? authContext.userCtx.userRole
    : '';

  const GENDERS: any = {
    M: 'Male',
    F: 'Female',
  };

  // this ref we are using under window event listener to track form updation
  const formUpdatedRef = useRef(false);

  // this var we will use to handle react specific navigation away from profile page
  const history = useHistory();
  const [navigateTo, setNavigateTo] = useState('');
  let staffVal = 'Staff ID';
  if (userRole.toLowerCase() === 'student') {
    staffVal = 'Student ID';
  } else if (userRole.toLowerCase() === 'parent') {
    staffVal = 'Parent ID';
  }

  const getUserProfileDetails = async () => {
    setLoader(true);
    getUserProfile(domain)
      .then(async (response: any) => {
        if (response.status === 200) {
          let userProfile = response.data;
          userProfile.PreferredLanguage = preferredLanguage;
          userProfile.Languages = getSortedList(userProfile.Languages);

          setFormValues({
            ...userProfile,
            ProfilePicURL: ""});

          if (userProfile.ProfilePicURL) {
            let respImage = await queueContext.readImage(userProfile.ProfilePicURL).then((response: any) => {
              var img = new Image();
              img.src = window.URL.createObjectURL(new Blob([response.Body], { type: 'image/png' }));
              setProfilePicUrl(img.src);
              setFormValues({
                ...userProfile,
                ProfilePicURL: img.src,
              });
            });
          }
          const sortedMails = [...(userProfile?.emails?.Items ?? [])];
          sortedMails.sort((left: any, right: any) =>
            left.source.localeCompare(right.source)
          );
          setEmails({
            ...emails,
            display: [...sortedMails] as any,
          });

          setCommittedState({
            image: userProfile.ProfilePicURL,
            displayEmails: sortedMails.map((email) => ({ ...email })),
          });

          showEmailExpireNotification(userProfile.emails.Items);
        }
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
        setShowNotification(true);
        setNotification({
          severity: 'error',
          message: error.message || 'Network error',
        });
      });
  };

  const handleWindowClose = (ev: any) => {
    if (formUpdatedRef.current) {
      ev.preventDefault();
      return (ev.returnValue = 'Changes you made may not be saved');
    }
  };

  const blockNav = () => {
    history.block(({ pathname }) => {
      if (formUpdatedRef.current) {
        setNavigateTo(pathname);
        setUnloadConfirmation({
          open: true,
          title: 'Discard changes?',
          message: 'You have unsaved changes. Are you sure you want to leave?',
        });
        return false;
      }
    });
  };

  useEffect(() => {
    if (userId) {
      getUserProfileDetails();
      blockNav();
      window.addEventListener('beforeunload', handleWindowClose);
      window.addEventListener('unload', () => {
        formUpdatedRef.current = false;
      });
    }
        
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [userId]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const updatedFormValues = {
      ...formValues,
      [name]: value,
    };
    setFormValues(updatedFormValues);
  };

  const handlePrefferedLanguageChange = (e: any) => {
    const { name, value } = e.target;
    const languagename = getLanguageCodeFromList(value);
    const updatedFormValues = {
      ...formValues,
      [name]: languagename,
    };
    setFormValues(updatedFormValues);
  };

  const getLanguageCodeFromList = (languageCode: any) => {
    const selectedLanguage = formValues.Languages.filter(
      (language: any) => language.code === languageCode
    );
    return selectedLanguage[0];
  };

  const getSortedList = (array: any[]) => {
    array.sort(function (prev, next) {
      var namePrev = prev.name.toLowerCase(),
        nameNext = next.name.toLowerCase();
      if (namePrev < nameNext)
        //sort string ascending
        return -1;
      if (namePrev > nameNext) return 1;
      return 0; //default return value (no sorting)
    });

    return array;
  };

  const selectionHandler = (selectedIndex: any, val: boolean) => {
    const updatedFormValues = { ...formValues };
    const updatedEmails = { ...emails };
    updatedEmails.display.length &&
      ((updatedEmails.display[selectedIndex] as any).userPreference = val);

    const uncommittedAdded = [...emails.uncommitted.added];
    const selectedEmail: string = (emails.display[selectedIndex] as any).email;
    const presentInAddAt = findEmailInArray(uncommittedAdded, selectedEmail);

    if (presentInAddAt > -1) {
      (uncommittedAdded[presentInAddAt] as any).userPreference = val;
    }

    const ifPresentInDelete = (emails.uncommitted.deleted as any).includes(
      selectedEmail
    );
    const uncommittedUpdated: any = { ...emails.uncommitted.updated };

    //check if already present in update
    const presentInUpdateAt = isPresentInUpdate(
      uncommittedUpdated,
      selectedEmail
    );
    if (presentInUpdateAt > -1) {
      const oldEmail = uncommittedUpdated[presentInUpdateAt].old.email;
      const newEmail = uncommittedUpdated[presentInUpdateAt].new.email;

      if (oldEmail == newEmail) {
        delete uncommittedUpdated[presentInUpdateAt];
      } else {
        uncommittedUpdated[presentInUpdateAt].new.userPreference = val;
      }
    }

    if (!ifPresentInDelete && presentInAddAt == -1 && presentInUpdateAt == -1) {
      // add new object for update.
      uncommittedUpdated[selectedIndex] = {
        old: {
          ...(updatedEmails.display[selectedIndex] as any),
          userPreference: !val,
        },
        new: {
          ...(updatedEmails.display[selectedIndex] as any),
          userPreference: val,
        },
      };
    }

    setEmails({
      ...updatedEmails,
      uncommitted: {
        ...emails.uncommitted,
        added: [...uncommittedAdded],
        updated: {
          ...uncommittedUpdated,
        },
      },
    });

    setFormValues(updatedFormValues);
  };

  const handleFileChange = (e: any) => {
    let selectedFile = e.target.files[0];
    e.target.value = '';

    setErrorMessage('');
    // validate selected file
    if (selectedFile) {
      var img = new Image();
      img.src = window.URL.createObjectURL(selectedFile);
      img.onload = function () {
        var width = img.naturalWidth;
        var height = img.naturalHeight;
        window.URL.revokeObjectURL(img.src);
        if (width <= 255 && height <= 255) {
          setErrorMessage(
            'File too small. File dimension must be greater 255 x 255 pixels.'
          );
          setProfilePicUpdateTracker(null);
        }
      };

      if (
        !['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(
          selectedFile.type
        )
      ) {
        setErrorMessage(
          'Unsupported file format. Only jpeg, jpg and png files are allowed'
        );
        setProfilePicUpdateTracker(null);
      } else if (selectedFile.size > 1024 * 1024) {
        setErrorMessage('File size exceeds 1 MB');
        setProfilePicUpdateTracker(null);
      } else {
        let reader = new FileReader();

        reader.onload = function (file) {
          let picElem = document.getElementById('profilePic');
          if (picElem) {
            let profilePicUrl = reader.result as string;
            setFormValues({
              ...formValues,
              ProfilePicURL: profilePicUrl,
            });
            setProfilePicUpdateTracker({
              action: 'PUT',
              body: {
                profileImage: profilePicUrl,
                fileType: selectedFile.type.split('/')[1],
              },
            });
          }
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const removeProfilePic = () => {
    resetProfilePic();
    if (profilePicUrl) {
      let fileName = profilePicUrl.split('/').pop();
      setProfilePicUpdateTracker({
        action: 'DELETE',
        body: {
          key: fileName,
        },
      });
    } else {
      setProfilePicUpdateTracker(null);
    }
    setErrorMessage('');
  };

  const appendIsError = (error: any = {}) => ({
    isError: true,
    error,
  });

  const onAddEmailFail = (error: any) => {
    const flatUncommitedAdded = emails.uncommitted.added.map(
      (item: any) => item.email
    );
    const displayEmails = [...emails.display].filter(
      (item: any) => !flatUncommitedAdded.includes(item.email)
    );

    setEmails({
      ...emails,
      display: [...displayEmails],
      uncommitted: {
        ...emails.uncommitted,
        added: [],
      },
    });
  };

  const onDeleteProfileFail = (error: any) => {
    const displayEmails = [...emails.display, ...emails.deleteBuffer];
    setEmails({
      ...emails,
      display: displayEmails,
      uncommitted: {
        ...emails.uncommitted,
        deleted: [],
      },
      deleteBuffer: [],
    });
  };

  const onUpdateProfileFail = (error: any) => {
    //for each updated in display, replace the display

    const displayEmails: any = [...emails.display];
    const updatedEmails: any = emails.uncommitted.updated;

    for (let index in updatedEmails) {
      const newEmail = updatedEmails[index].new.email;
      const oldEmailObject = updatedEmails[index].old;

      // search new email in display
      const presentAt = findEmailInArray(displayEmails, newEmail);
      if (presentAt > -1) {
        displayEmails[presentAt] = oldEmailObject;
      }
    }

    setEmails({
      ...emails,
      display: displayEmails,
      uncommitted: {
        ...emails.uncommitted,
        updated: {},
      },
    });
  };

  const buildMessage = (putPayload: any, postPayload: any) => {
    let message = 'Profile Settings saved. ';
    let unsubscribedEmails: any = [];
    let subscribedEmails: any = [];

    if (putPayload && putPayload.length) {
      putPayload.forEach((item: any) => {
        item.userPreference && subscribedEmails.push(item.email);
        !item.userPreference && unsubscribedEmails.push(item.email);
      });
    }

    if (postPayload && postPayload.length) {
      postPayload.forEach((item: any) => {
        item.userPreference && subscribedEmails.push(item.email);
      });
    }

    if (unsubscribedEmails.length) {
      message += `Email digest unsubscribed from ${unsubscribedEmails.join(
        ', '
      )}. `;
    }

    if (subscribedEmails.length) {
      message += `Email confirmation for daily digest sent to ${subscribedEmails.join(
        ', '
      )}. `;
    }

    return message;
  };

  const submitForm = () => {
    setLoader(true);
    const promises = new Array(3).fill(Promise.resolve({}));

    const postPayload = [...emails.uncommitted.added];
    postPayload.length &&
      (promises[0] = addEmail(domain, postPayload).catch(appendIsError));

    const putPayload = (buildPutPayload(
      emails.uncommitted.updated
    ) as unknown) as UpdateEmailPayloadType[];

    let prefferedLanguagePayload: any = null;
    if (preferredLanguage.code != formValues.PreferredLanguage.code) {
      prefferedLanguagePayload = formValues.PreferredLanguage;
    }

    if (
      putPayload.length ||
      (profilePicUpdateTracker && profilePicUpdateTracker.action == 'PUT') ||
      prefferedLanguagePayload
    ) {
      const imagePayload =
        profilePicUpdateTracker && profilePicUpdateTracker.action == 'PUT'
          ? profilePicUpdateTracker.body
          : {};
      promises[1] = updateProfile(
        domain,
        imagePayload,
        userId,
        putPayload,
        prefferedLanguagePayload
      ).catch(appendIsError);
    }

    const deletePayload: any = [...emails.uncommitted.deleted];
    if (
      deletePayload.length ||
      (profilePicUpdateTracker && profilePicUpdateTracker.action == 'DELETE')
    ) {
      const imagePayload =
        profilePicUpdateTracker && profilePicUpdateTracker.action == 'DELETE'
          ? profilePicUpdateTracker.body
          : {};
      promises[2] = deleteProfile(
        domain,
        imagePayload,
        userId,
        deletePayload
      ).catch(appendIsError);
    }

    Promise.all(promises).then(async (values) => {
      const allSettled = values.every(
        (item) =>
          !(item && item.isError) && !(item.data && item.data.errorMessage)
      );

      if (!allSettled) {
        setShowNotification(true);
        setNotification({
          severity: 'error',
          message: 'Error saving profile',
        });
        values.forEach((item, index) => {
          if (index == 0 && item?.isError) {
            //create failed, revert create
            onAddEmailFail(item);
          }
          if (index == 1 && item?.isError) {
            onUpdateProfileFail(item);
          }
          if (index == 2 && item?.isError) {
            onDeleteProfileFail(item);
          }
        });
      } else {
        const displayEmailList: any[] = updateDisplayEmailOnSave();
        showEmailExpireNotification(displayEmailList);
        const successMsg = buildMessage(putPayload, postPayload);
        // set global success message
        setShowNotification(true);
        setNotification({
          severity: 'success',
          message: successMsg,
        });

        setEmails({
          ...emails,
          display: [...displayEmailList] as any,
          uncommitted: {
            added: [],
            updated: {},
            deleted: [],
          },
          deleteBuffer: [],
        });

        //set profile pic url before resetting the profile pic update tracker
        let imageUrl = '';
        if (profilePicUpdateTracker) {
          if (profilePicUpdateTracker.action === 'PUT') {
            imageUrl = values[1]?.data?.profileURL ?? '';
            if (imageUrl) {
              let respImage = await queueContext.readImage(imageUrl).then((response: any) => {
                var img = new Image();
                img.src = window.URL.createObjectURL(new Blob([response.Body], { type: 'image/png' }));
                //let picElem = document.getElementById('profilePic');
                setFormValues({
                  ...formValues,
                  ProfilePicURL: img.src,
                });
              });
            }
          } else if (profilePicUpdateTracker.action === 'DELETE') {
            imageUrl = '';
          }
          setProfilePicUrl(imageUrl);
        }
        setProfilePicUpdateTracker(null);
        setProfilePicUpdateTracker(null);
        localStorage.setItem(
          'preferredLanguage',
          JSON.stringify(formValues.PreferredLanguage)
        );
        authContext.userCtx.preferredLanguage = formValues.PreferredLanguage;
        // save this state as committed state to revert to this if cancel clicked.
        setCommittedState({
          image: profilePicUpdateTracker ? imageUrl : profilePicUrl,
          displayEmails: emails.display.map((email: any) => ({ ...email })),
        });
      }
      setLoader(false);
    });
  };

  const updateDisplayEmailOnSave = () => {
    const updatedEmails: any = { ...emails.uncommitted.updated };
    const addedEmails: any = [...emails.uncommitted.added];
    const displayEmailList: any[] = [...emails.display];

    for (let index in updatedEmails) {
      const newEmail = updatedEmails[index].new;
      const presentAt = findEmailInArray(displayEmailList, newEmail.email);
      if (newEmail.userPreference) {
        displayEmailList[presentAt].emailStatus = 2;
      } else {
        displayEmailList[presentAt].emailStatus = -1;
      }
    }
    for (let index = 0; index < addedEmails.length; index++) {
      const presentAt = findEmailInArray(
        displayEmailList,
        addedEmails[index].email
      );
      if (addedEmails[index].userPreference) {
        displayEmailList[presentAt].emailStatus = 2;
      } else {
        displayEmailList[presentAt].emailStatus = -1;
      }
    }
    return displayEmailList;
  };

  const showEmailExpireNotification = (emailList: any) => {
    setShowTokenExpireNotification(false);
    for (let index in emailList) {
      if (emailList[index].emailStatus === 0) {
        setShowTokenExpireNotification(true);
        setExpireNotification({
          severity: 'error',
          message:
            'Email subscription unconfirmed. Resend the confirmation.' ||
            'Network error',
          isShowClose: 0,
        });
        break;
      }
    }
  };

  const confirmProfileUpdate = () => {
    const putPayload = (buildPutPayload(
      emails.uncommitted.updated
    ) as unknown) as UpdateEmailPayloadType[];
    const unsubscribedEmails =
      putPayload.filter(
        (emailItem) => !emailItem.userPreference && emailItem.email
      ) || [];

    if (unsubscribedEmails.length) {
      const unsubscribedEmailsStr = `Are you sure you want to unsubscribe from the Reach Daily Digest for 
            ${unsubscribedEmails.map((emailObj) => emailObj.email).join(', ')}`;

      setConfirmation({
        ...confirmation,
        open: true,
        message: unsubscribedEmailsStr,
      });
    } else {
      submitForm();
    }
  };

  const resetProfilePic = () => {
    setFormValues({
      ...formValues,
      ProfilePicURL: './images/no-image.png',
    });
  };

  const isAnythingToUpdate = () => {
    let formUpdated =
      !profilePicUpdateTracker &&
      !emails.uncommitted.added.length &&
      !emails.uncommitted.deleted.length &&
      !Object.keys(emails.uncommitted.updated).length &&
      preferredLanguage.code == formValues.PreferredLanguage.code;

    formUpdatedRef.current = confirmLogout ? false : !formUpdated;
    setAllowLogout(formUpdated);

    return formUpdated;
  };

  const resetEmailsForm = () => {
    const { displayEmails = [] } = committedState as commitedStateType;

    const beforeCommitEmails = displayEmails.map((email) => ({ ...email }));

    setEmails({
      display: beforeCommitEmails as any,
      uncommitted: {
        added: [],
        updated: {},
        deleted: [],
      },
      deleteBuffer: [],
    });
  };

  const resetProfileImageForm = () => {
    setProfilePicUpdateTracker(null);
    setProfilePicUrl(committedState.image);
    setFormValues(formValues => {
      return {
        ...formValues,
        ProfilePicURL: committedState.image,
      }
    });
  };

  const resetPreferredLanguage = () => {
    setFormValues((formValues) => {
      return {
        ...formValues,
        PreferredLanguage: preferredLanguage
      }
    })
  };

  const resetform = () => {
    resetEmailsForm();
    resetProfileImageForm();
    resetPreferredLanguage();
  };

  const customdDialogStyle = {
    paper: {
      width: '800px',
    },
  };

  const unloadConfirmationDialogStyle = {
    paper: {
      width: '20rem',
      height: '15rem',
    },
    root: {
      padding: '0 16px',
    },
  };

  return (
    <div 
      className={clsx(classes.root, {
        [classes.wrapperWithSidebar]: isSidebarOpen,
        [classes.wrapperWithoutSidebar]: !isSidebarOpen,
      })}
    >
      {isLoading ? <SaveLoader /> : <></>}
      <div className={classes.headerBar}>
        <div className={classes.headerNameDiv}>
          <button onClick={toggleSidebar} className={classes.toggleButton}>
            {isSidebarOpen ? (
              <ArrowBackIosOutlinedIcon
                fontSize={'default'}
              ></ArrowBackIosOutlinedIcon>
            ) : (
              <ArrowForwardIosOutlinedIcon
                fontSize={'default'}
              ></ArrowForwardIosOutlinedIcon>
            )}
          </button>
          <div className={classes.headerInnerWrapper}>
            <div className={classes.headerName}>Profile settings</div>
          </div>
        </div>
      </div>
      <Divider />
      <div
        className={clsx(classes.mainContainer, {
          [classes.withSidebar]: isSidebarOpen,
          [classes.withOutSidebar]: !isSidebarOpen,
        })}
      >
        {showTokenExpireNotification ? (
          <NotificationBox
            message={expireNotification.message}
            severity={expireNotification.severity}
            isShowClose={expireNotification.isShowClose}
            handleNotification={() => {
              setShowTokenExpireNotification(!showTokenExpireNotification);
            }}
          />
        ) : (
          ''
        )}
        {showNotification ? (
          <NotificationBox
            message={notification.message}
            severity={notification.severity}
            handleNotification={() => {
              setShowNotification(!showNotification);
            }}
          />
        ) : (
          ''
        )}
        <ActionableAlertBox
          data-testid='confirmationBox'
          title={confirmation.title}
          shouldOpen={confirmation.open}
          onAction={() => {
            submitForm();
            setConfirmation({
              ...confirmation,
              open: false,
              message: '',
            });
          }}
          secondaryButtonLabel='Cancel'
          primaryButtonLabel='Confirm'
          styles={customdDialogStyle}
          primaryButtonProps={{}}
          onClose={() => {
            setConfirmation({
              ...confirmation,
              open: false,
              message: '',
            });
          }}
        >
          <Box paddingBottom='74px'>{confirmation.message}</Box>
        </ActionableAlertBox>
        <ActionableAlertBox
          data-testid='unloadConfirmationBox'
          title={unloadConfirmation.title}
          shouldOpen={unloadConfirmation.open}
          onAction={() => {
            formUpdatedRef.current = false;
            setUnloadConfirmation({
              ...unloadConfirmation,
              open: false,
            });
            setAllowLogout(true);
            history.push(navigateTo);
          }}
          secondaryButtonLabel='Cancel'
          primaryButtonLabel='Discard'
          styles={unloadConfirmationDialogStyle}
          primaryButtonProps={{}}
          onClose={() => {
            setUnloadConfirmation({
              ...unloadConfirmation,
              open: false,
            });
            setNavigateTo('');
          }}
        >
          <Box paddingBottom='74px'>{unloadConfirmation.message}</Box>
        </ActionableAlertBox>
        <Container maxWidth={false}>
          <Grid container spacing={3} justify='space-between'>
            <Grid item md={3} xs={12}>
              <div>
                <h4>Profile photo</h4>
                <div className={classes.profilePicWrapper}>
                  {errorMessage ? (
                    <div className={classes.uploadErrorWrapper}>
                      <span className={classes.uploadError}>
                        <ErrorOutlineIcon className={classes.uploadErrorIcon} />
                        {errorMessage}
                      </span>
                    </div>
                  ) : (
                    <img
                      id='profilePic'
                      src={formValues.ProfilePicURL || './images/no-image.png'}
                      className={classes.profilePic}
                      onError={resetProfilePic}
                    />
                  )}
                </div>
                <Button
                  variant='contained'
                  component='label'
                  className={classes.uploadBtn}
                >
                  Upload an image
                  <input
                    id='profilePicInput'
                    type='file'
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                <div className={classes.removePicLink}>
                  <Link onClick={removeProfilePic} data-testid='remove-photo'>
                    Remove Photo
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item md={9} xs={12}>
              <h4>Contact Information</h4>
              <div className={classes.contactInformationContainer}>
                <Grid container spacing={3} justify='space-between'>
                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>
                        {staffVal}
                      </InputLabel>
                      <TextField
                        name='StaffId'
                        data-testid='StaffId'
                        placeholder='Staff ID'
                        type='text'
                        value={formValues.StaffId}
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>
                        Login ID
                      </InputLabel>
                      <TextField
                        name='LoginId'
                        data-testid='LoginId'
                        placeholder='Login ID'
                        type='text'
                        value={formValues.LoginId}
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>
                        First name
                      </InputLabel>
                      <TextField
                        name='FirstName'
                        data-testid='FirstName'
                        placeholder='First name'
                        type='text'
                        value={formValues.FirstName}
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>
                        Last name
                      </InputLabel>
                      <TextField
                        name='LastName'
                        data-testid='LastName'
                        placeholder='Last name'
                        type='text'
                        value={formValues.LastName}
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>Role</InputLabel>
                      <TextField
                        name='Role'
                        data-testid='Role'
                        placeholder='Role'
                        type='text'
                        value={formValues.Role}
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>Gender</InputLabel>
                      <TextField
                        name='Gender'
                        data-testid='Gender'
                        placeholder='Gender'
                        type='text'
                        value={
                          formValues.Gender ? GENDERS[formValues.Gender] : ''
                        }
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>Schools</InputLabel>
                      <TextField
                        name='Schools'
                        data-testid='Schools'
                        placeholder='Schools'
                        type='text'
                        value={formValues.Schools}
                        InputProps={inputTextFieldProps}
                        onChange={handleChange}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>
                        Preferred language
                      </InputLabel>
                      {formValues.Languages.length > 0 ? (
                        <Select
                          data-testid='PreferredLanguage'
                          labelId='demo-simple-select-label'
                          name='PreferredLanguage'
                          disableUnderline
                          value={
                            formValues.PreferredLanguage.code
                              ? formValues.PreferredLanguage.code
                              : 'en'
                          }
                          onChange={handlePrefferedLanguageChange}
                        >
                          <MenuItem value='' disabled>
                            --Select--
                          </MenuItem>
                          {formValues.Languages.map(
                            (language: any, index: number) => (
                              <MenuItem key={index} value={language.code}>
                                {language.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item md={6} sm={6} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel className={classes.label}>Phone</InputLabel>
                      <TextField
                        name='Phones'
                        data-testid='Phones'
                        placeholder='Phone'
                        type='text'
                        value={formValues.Phones}
                        InputProps={inputTextFieldProps}
                        disabled
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <InputLabel className={classes.label}>
                      Email preferences
                    </InputLabel>
                    <Divider />
                    <Box paddingTop='8px' paddingBottom='4px'>
                      Email me a daily digest of my Reach messages:
                    </Box>
                    {emails && Array.isArray(emails.display) ? (
                      <ProfileEmailListContext.Provider
                        value={{
                          emails,
                          setEmails,
                          userId: `${userId}|${domainId}`,
                        }}
                      >
                        <ProfileEmailList
                          selectionHandler={selectionHandler}
                          handleNotificationDisplay={setShowNotification}
                          handleNotification={setNotification}
                          handleUserProfileDetails={getUserProfileDetails}
                          handleSetEmail={setEmails}
                          emailList={emails}
                          handleShowTokenExpireNotification={
                            setShowTokenExpireNotification
                          }
                          handleExpireNotification={setExpireNotification}
                        />
                      </ProfileEmailListContext.Provider>
                    ) : null}
                  </Grid>
                  {formValues.Relationship.length > 0 ? (
                    <Grid item md={6} sm={6} xs={12}>
                      <InputLabel className={classes.label}>
                        Relatives
                      </InputLabel>

                      {formValues.Relationship.map(
                        (
                          relatives: ProfileRelativesComponentType,
                          index: number
                        ) => (
                          <ProfileRelativesComponent
                            {...relatives}
                          ></ProfileRelativesComponent>
                        )
                      )}
                    </Grid>
                  ) : null}
                </Grid>

                <Box display='flex' justifyContent='flex-end' pt={2}>
                  <Button
                    type='button'
                    onClick={resetform}
                    className={classes.cancelBtn}
                    disabled={isLoading || isAnythingToUpdate()}
                    data-testid='cancel-button'
                  >
                    Cancel
                  </Button>
                  <Button
                    type='button'
                    onClick={confirmProfileUpdate}
                    className={classes.saveBtn}
                    disabled={isLoading || isAnythingToUpdate()}
                    data-testid='save-button'
                  >
                    Save
                  </Button>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ProfileComponent;
