import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';

export const logoutService = async () => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.logout,
    method: 'GET',
    headers: {
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
    },
    withCredentials: true,
  };
    result = await axios(options);
    return result;
};
