import React from 'react';
 export const CloseButtonIcon = (props: any) => {
   return (
    <svg width="40" height="57" viewBox="0 0 40 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M24.918 40H0L25 57L24.918 40Z" fill="#9D3659"/>
      <rect width="40" height="40" fill="#FF5991"/>
      <path d="M10 10L30 30" stroke="white" strokeWidth="4" strokeLinecap="round"/>
      <path d="M10 30L30 10" stroke="white" strokeWidth="4" strokeLinecap="round"/>
      <path d="M10 10L30 30" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <path d="M10 30L30 10" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </svg>
    
   );
 };