import { makeStyles } from '@material-ui/core/styles';

const DirectMessageComponentStyle = () =>
  makeStyles((theme) => ({
    root: {
      padding: '10px 0 10px 0',
      borderRight: 0,
      borderLeft: '1px solid #CDCDCD',
    },
    directMessageHeaderClass: {
      display: 'flex',
      padding: '0 10px 0 15px',
      justifyContent: 'space-around',
    },
    impDirectMessageHeaderClass: {
      display: 'flex',
      paddingLeft: '10px',
    },
    messageIconClass: {
      //width: "16px",
      height: '16px',
    },
    iconStyle: {
      paddingTop: '2px',
    },
    iconFont: {
      height: '16px',
      width: '16px',
    },
    spanMessageClass: {
      //width: "54px",
      //height: "15px",
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#666666',
      padding: '3px 3px 3px 3px',
    },
    addCircleItemClass: {
      marginLeft: 'auto',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    messageListItemClass: {
      minHeight: '32px',
      padding: '5px 5px 5px 15px',
      '&$selected': {
        backgroundColor: '#FF5991',
        '&:hover': {
          backgroundColor: '#FF5991',
        },
        '&:focus': {
          backgroundColor: '#FF5991',
        },
      },
    },
    selected: {},
    notifyNumber: {
      background: '#5178D0',
      borderRadius: theme.spacing(2),
      color: '#ffffff',
      textAlign: 'center',
      width: theme.spacing(4),
    },
    username: {
      flex: 1,
    },
    empty: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      fontWeight: 600,
    },
    userNameText: {
      display: 'block',
      width: '230px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: '#262626',
    },
  }));

export default DirectMessageComponentStyle;
