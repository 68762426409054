import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { API } from '../properties/apiProperties';

export const login = async (
  username: string = '',
  password: string = '',
  mobile: string = '',
  source: CancelTokenSource,
  bbDomain: string,
  domainId: string,
  districturl: string,
  orgId:string,
  passcode: string,
  personId: string,
  resend: string,
  reset: string,
) => {
  var result = null;
  let options:AxiosRequestConfig = {}
  if (passcode !== '' || resend || reset){
    options = {
      url: API.authenticate,
      method: 'POST',
      data: {
        passcode: passcode,
        username: username,
        personId: personId,
        mobile: mobile,
        orgId: orgId,
        resend: resend,
        reset: reset
      },
      headers: {
        bbdomain: bbDomain,
        domainId: domainId,
        districturl: districturl,
        ispasscode: 'true',
        //personid: personId,
        // mobile: mobile
      },
      withCredentials: true,
    };
  } else {
    options = {
      url: API.authenticate,
      method: 'POST',
      data: {
        username: username,
        password: password,
        mobile: mobile,
        orgId: orgId,
      },
      headers: {
        bbdomain: bbDomain,
        domainId: domainId,
        districturl: districturl,
      },
      withCredentials: true,
    };
  }
  result = await axios(options);
  return result;
};

export const validateAuthToken = async (
  authToken: string,
  bbDomain: string,
  personId: string,
  mobile: string,
  source: CancelTokenSource
) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.authenticate,
    method: 'POST',
    data: {
      mobile: mobile,
    },
    headers: {
      authorization: authToken,
      bbdomain: bbDomain,
      personid: personId,
    },
    withCredentials: true,
    cancelToken: source.token,
  };

  result = await axios(options);
  return result;
};

export const validateOtpPasscode = async (
  passcode: string,
  bbDomain: string,
  domainId: string,
  districturl: string,
  mobile: string,
  personId: string,
  orgId: string
) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.authenticate,
    method: 'POST',
    data: {
      passcode: passcode,
      personId: personId,
      mobile: mobile,
      orgId: orgId
    },
    headers: {
      bbdomain: bbDomain,
      domainId: domainId,
      districturl: districturl,
      ispasscode: 'true',
      //personid: personId,
      // mobile: mobile
    },
    withCredentials: true,
  };

  result = await axios(options);
  return result;
};

export const getDomainList = async () => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.district,
    method: 'GET',
  };

  result = await axios(options);
  return result;
};

export const getAuthToken = async (bbdomain: string) => {
  var result = null;
  const options: AxiosRequestConfig = {
    url: API.authenticate,
    method: 'POST',
    withCredentials: true,
    headers: {
      bbdomain: bbdomain,
      domainid: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
    },
    data: {},
  };

  result = await axios(options);
  return result;
};