import React from 'react'

function VideoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1 5H18V9L23 5.04167V19L18 15V19H1V5Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18 8V16" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default VideoIcon