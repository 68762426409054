import { makeStyles, createStyles } from '@material-ui/core/styles';

const SettingsStyle = makeStyles((theme: any) => {
  return createStyles({
  root: {
    flexGrow: 1,
    marginBottom: 0,
  },
  wrapperWithSidebar: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  wrapperWithoutSidebar: {
    marginLeft: '-80px',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexGrow: 1
  },
  tabButton: {
    textTransform: 'none',
  },
  wrapper: {
    '& [class^="MuiTabsscroller"]': {
      display: 'flex',
      '& [class^="PrivateTabIndicatorroot"]': {
        backgroundColor: '#FF5991',
      },
    },
  },
  tabBar: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    ['@media (max-height: 500px)']: {
      height: '45px',
    },
  },
  groupNameDiv: {
    display: 'flex',
    flex: '1',
    alignContent: 'space-between',
  },
  groupName: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '18px',
    paddingLeft: '20px',
  },
  toggleButton: {
    width: '35px',
    height: '60px',
    backgroundColor: '#FFF',
    alignContent: 'center',
    padding: '2px 2px 2px 2px',
    borderWidth: '1px',
    borderColor: '#e0e0e0',
    borderStyle: 'solid',
    borderBottom: '1px',
    outline: 'none',
    ['@media (max-height: 500px)']: {
      height: '45px',
    },
  },
});
});

export default SettingsStyle;
