import React from 'react'
import { SvgIcon } from '@material-ui/core';

export const DeleteIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" 
        stroke={props.iconColor?props.iconColor:"#262626"} 
        strokeWidth={props.iconBold?props.iconBold:"2"}
        viewBox="0 0 24 24" fill="none" 
        xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6L9 1.5H15L18 6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 7.5L4.5 22.5H19.5L21 7.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.5 6H22.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 10.5V18" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.5 10.5L15.75 18" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 10.5L8.25 18" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
};

export default DeleteIcon;