import React, { useContext, useEffect } from 'react';
import ProfileEmailListStyle from './ProfileEmailListStyle';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Grid,
} from '@material-ui/core';
import TrashIcon from '../../Icons/TrashIcon';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import AddProfileEmail from './Forms/AddProfileEmail/AddProfileEmail';
import ProfileEmailListContext from './ProfileEmailListContext';
import DeleteProfileEmail from './Forms/DeleteProfileEmail/DeleteProfileEmail';
import { UserAuthCtx } from '../../components/AuthComponent';
import { resendEmail } from '../../services/UserProfileService';
import AttentionIcon from '../../Icons/AttentionIcon';

const ProfileEmailList = (props: any) => {
  const defaultFormState = {
    writing: false,
    index: -1,
  };

  const [showAdd, setShowAdd] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState<{
    writing: boolean;
    index: number;
  }>({
    ...defaultFormState,
  });

  const classes = ProfileEmailListStyle();
  const [isResendlinkClicked, setIsResendlinkClicked] = React.useState(false);

  const {
    selectionHandler,
    handleNotificationDisplay,
    handleNotification,
    handleUserProfileDetails,
    handleSetEmail,
    emailList,
    handleExpireNotification,
    handleShowTokenExpireNotification,
  } = props;

  const {
    emails: { display: displayEmails },
  }: any = React.useContext(ProfileEmailListContext);

  const isMaxLimit = displayEmails.length >= 5;
  const authContext = useContext(UserAuthCtx);
  const userId = authContext.userCtx.userId ? authContext.userCtx.userId : '';
  const domain = authContext.userCtx.userLoggedInDomain
    ? authContext.userCtx.userLoggedInDomain
    : '';
  const domainId = authContext.userCtx.domainId
    ? authContext.userCtx.domainId
    : '';

  const deleteEmailHandler = (index: number) => {
    setShowDelete({
      writing: true,
      index,
    });
  };

  const resendConfirmationHandler = (email: string) => {
    handleNotificationDisplay(false);
    setIsResendlinkClicked(false);
    const payload = {
      email: email,
      resend: true,
    };
    const resendResponse = resendEmail(domain, userId, payload).catch(
      appendIsError
    );
    resendResponse.then((response: any) => {
      setIsResendlinkClicked(true);
      if (response.status === 201) {
        handleSetEmailItem(email);
        handleNotificationDisplay(true);
        handleNotification({
          message: 'Email confirmation for daily digest sent.',
          severity: 'success',
          isShowClose: 1,
        });
      }
    });
  };

  const handleSetEmailItem = (email: string) => {
    var index = displayEmails.findIndex((item: any) => item.email == email);
    displayEmails[index].emailStatus = 2;
    handleSetEmail({
      ...emailList,
      display: [...displayEmails] as any,
    });
    showEmailExpireNotification(displayEmails);
  };

  const showEmailExpireNotification = (displayEmailList: any) => {
    handleShowTokenExpireNotification(false);
    for (let index in displayEmailList) {
      if (displayEmailList[index].emailStatus === 0) {
        handleShowTokenExpireNotification(true);
        handleExpireNotification({
          severity: 'error',
          message:
            'Email subscription unconfirmed. Resend the confirmation.' ||
            'Network error',
          isShowClose: 0,
        });
        break;
      }
    }
  };

  const appendIsError = (error: any = {}) => ({
    isError: true,
    error,
  });

  return (
    <div className={classes.root} data-testid='email-list'>
      <List>
        {displayEmails.map((emailObj: any, index: number) => (
          <ListItem key={index} classes={{ root: classes.item }}>
            <div
              className={`${
                emailObj.emailStatus === 0
                  ? classes.conditionalContainer
                  : classes.container
              }`}
            >
              <div className={classes.leftDiv}>
                <ListItemIcon>
                  <CustomCheckbox
                    data-testid='headerchb'
                    checked={emailObj.userPreference}
                    className={classes.noPadding}
                    style={{ padding: 0 }}
                    onChange={(event: any) => {
                      selectionHandler(index, event.target.checked);
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    emailObj.emailStatus === 0 || emailObj.emailStatus === 2
                      ? `${emailObj.email}, `
                      : emailObj.email
                  }
                  className={classes.itemText}
                  secondaryTypographyProps={{
                    style: { fontStyle: 'italic', fontSize: '12px' },
                    id: `confirmation-${index}`,
                    variant: 'inherit',
                  }}
                  secondary={
                    emailObj.emailStatus === 0 || emailObj.emailStatus === 2
                      ? 'Pending Confirmation'
                      : ''
                  }
                />
              </div>
              <div
                className={`${classes.rightDiv} ${
                  emailObj.emailStatus === 0 ? classes.listLineAlignment : ''
                } ${emailObj.emailStatus !== 0 ? classes.trashIcon : ''}`}
              >
                {emailObj.emailStatus === 0 ? (
                  <span
                    className={classes.linkExpired}
                    data-testid={`expired-email-${emailObj.email}`}
                  >
                    <AttentionIcon /> Confirmation link expired.&nbsp;
                  </span>
                ) : null}
                {emailObj.emailStatus === 0 || emailObj.emailStatus === 2 ? (
                  <Box
                    component='span'
                    className={classes.resendEmailLink}
                    data-testid={`resend-email-${index}`}
                    onClick={resendConfirmationHandler.bind(
                      null,
                      emailObj.email
                    )}
                  >
                    Resend confirmation
                  </Box>
                ) : (
                  <div
                    className={classes.actionBtns}
                    data-testid={`action-buttons-${emailObj.email}`}
                  >
                    <TrashIcon
                      className={classes.pointer}
                      onClick={deleteEmailHandler.bind(null, index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </ListItem>
        ))}
      </List>
      <Box display='flex' justifyContent='flex-end'>
        <Box
          component='span'
          onClick={isMaxLimit ? () => {} : () => setShowAdd(true)}
          className={classes.addEmailLink}
          title={isMaxLimit ? 'Only five Emails can be added' : ''}
          data-testid='add-email'
          style={{ color: isMaxLimit ? 'gray' : '#1D6897' }}
        >
          Add another email
        </Box>
      </Box>
      <AddProfileEmail show={showAdd} onClose={() => setShowAdd(false)} />
      <DeleteProfileEmail
        show={showDelete.writing}
        index={showDelete.index}
        onClose={() => setShowDelete({ ...defaultFormState })}
      />
    </div>
  );
};

export default ProfileEmailList;
