import React, { useState, useCallback, useContext, useEffect } from 'react';
import { makeStyles, createStyles, fade } from '@material-ui/core/styles';
import { Button, TextField, Grid } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateFnsUtils from '@date-io/date-fns';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { DatePickerIcon } from '../Icons/DatePickerIcon';
import AutoComplete from './AutoComplete/AutoComplete';
import { orgList } from '../dataLoaders/DataHandler';
import { Box } from '@material-ui/core';
import { JsdateToEpoch } from '../libs/date.utils';
import { UserAuthCtx } from './AuthComponent';
import ChevronUpDown from '../Icons/CheronUpDown';

function formatChannelList(option: any) {
  return (
    <>
      <Box component='div' display='flex' width='100%'>
        <Box
          component='span'
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {option.name}
        </Box>
        <Box component='span'>
          ,<Box component='span' ml={1} />
          {option.id}
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    root: {
      flexGrow: 1,
    },
    groupNameDiv: {
      display: 'flex',
      flex: '1',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    groupName: {
      display: 'flex',
      alignItems: 'left',
      fontWeight: 600,
      fontSize: '18px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    formControl: {
      display: 'flex',
      marginTop: '12px',
    },
    button: {
      backgroundColor: '#FFFFFF',
      border: '2px solid #666666',
      color: '#262626',
      fontWeight: 600,
      fontSize: '14px',
      width: 'fit-content',
      alignSelf: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    buttonHover: {
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#262626',
      },
    },
    focused: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${fade('#262626', 0.25)} 0 0 0 2px`,
        borderColor: '#262626',
      },
    },
    dateRangeContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    filterHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    filterHeaderMobile: {
      minWidth: '80px',
    },
    clearAllLink: {
      textDecorationLine: 'underline',
      fontSize: '14px',
      color: '#2073A1',
      cursor: 'pointer',
    },
    chevronUpDownIcon: {
      width: '17px',
      height: '17px',
      cursor: 'pointer',
      paddingTop: '2px',
    },
    datePickerIcon: {
      alignSelf: 'center',
      position: 'absolute',
      paddingTop: '20px',
      paddingLeft: '35%',
      zIndex: 2,
    },
    customDatePicker: {
      marginTop: 0,
      width: '100%',
    },
    asterisk: {
      float: 'none',
    },
    dateIcon: {
      position: 'relative',
      top: '4px'
    },
    datePickerButton: {
      minWidth: '24px'
    },
    calendarInputField: {
      paddingLeft: '5px'
    },
    gridRightSpacing:{
      paddingRight: '10px',
      ['@media (max-width: 640px)']: {
        paddingRight: '0px',
      },
    },
    gridLeftSpacing: {
      paddingLeft: '10px',
      ['@media (max-width: 640px)']: {
        paddingLeft: '0px',
      },
    }
  });
});

export interface SearchComponentStateTypes {
  startDate: string | null;
  endDate: string | null;
  school: string;
  startDateEpoch: number | null;
  endDateEpoch: number | null;
  channel: string;
  sender: string;
}

const FilterSearchComponent = (props: any) => {
  const classes = useStyles();
  const authContext = useContext(UserAuthCtx);
  var domainId = authContext.userCtx.domainId;
  const {
    showElements,
    handleSearchMessages,
    clearSearch,
    searchKeyword = null,
    showFilterPanel,
    toggleFilterPanel,
    schoolId,
  } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const inputTextFieldProps = {
    disableUnderline: true,
    autoFocus: false,
    classes: { focused: classes.focused, input: classes.calendarInputField },
    style: { paddingRight: 0 }
  };
  const defaultMinDate = '01/01/2020';

  const initialState = {
    startDate: null,
    endDate: null,
    school: '',
    startDateEpoch: null,
    endDateEpoch: null,
    channel: '',
    sender: '',
  };

  const [filterValues, setFilterValues] = useState<SearchComponentStateTypes>(
    initialState
  );

  const [isValidStartDate, setStartDateError] = useState(true);
  const [isValidEndDate, setEndDateError] = useState(true);
  const [startDateErrorMsg, setStartDateErrorMsg] = useState('');
  const [endDateErrorMsg, setEndDateErrorMsg] = useState('');

  const [schoolName, setSchoolName] = useState(null);
  const [inputStartValue, setInputStartValue] = useState('');
  const [inputEndValue, setInputEndValue] = useState('');

  // Update filter values if passed as a prop
  useEffect(() => {
    if (schoolId) {
      setFilterValues({
        ...filterValues,
        school: props.schoolId,
      });
    }
  }, [schoolId]);

  const clearAllFields = () => {
    setFilterValues(initialState);
    setSchoolName(null);
    clearSearch();
    setInputStartValue('');
    setInputEndValue('');
  };

  const handleStartDateChange = useCallback(
    (date: any, value: any) => {
      setInputStartValue(value);
      if (value) {
        value = `${value} 00:00:00`;
      }
      setFilterValues({
        ...filterValues,
        startDate: value,
        startDateEpoch: JsdateToEpoch(value),
      });
    },
    [filterValues]
  );

  const handleEndDateChange = useCallback(
    (date: any, value: any) => {
      setInputEndValue(value);
      if (value) {
        value = `${value} 23:59:59`;
      }

      setFilterValues({
        ...filterValues,
        endDate: value,
        endDateEpoch: JsdateToEpoch(value),
      });
    },
    [filterValues]
  );

  const handleStartDatePickerError = useCallback(
    (error: any, value: any) => {
      if (!error) {
        setStartDateError(true);
        setStartDateErrorMsg('');
      } else {
        setStartDateError(false);
        setStartDateErrorMsg(error);
      }
    },
    [filterValues]
  );

  const handleEndDatePickerError = useCallback(
    (error: any, value: any) => {
      if (!error) {
        setEndDateError(true);
        setEndDateErrorMsg('');
      } else {
        setEndDateError(false);
        setEndDateErrorMsg(error);
      }
    },
    [filterValues]
  );

  const isValidDateRange = () => {
    if (
      isValidStartDate &&
      isValidEndDate &&
      ((!filterValues.startDate && !filterValues.endDate) ||
        (filterValues.startDate && filterValues.endDate))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUserOnchange = useCallback(
    (e: any) => {
      setFilterValues({
        ...filterValues,
        sender: trimStartWhiteSpace(e.target.value),
      });
    },
    [filterValues]
  );

  const handleChannelOnchange = useCallback(
    (e: any) => {
      setFilterValues({
        ...filterValues,
        channel: trimStartWhiteSpace(e.target.value),
      });
    },
    [filterValues]
  );
  const [expanded, setExpanded] = useState(false);

  const dateFormatter = (str: string) => {
    if(str === '//' || str === '/'){
      return '';
    }
    return str.replace(/[^\d\/]+/gi, '');
  };

  const trimStartWhiteSpace = (input: string) =>
    input === ' ' ? input.trim() : input;

  return (
    <div className={classes.root}>
      {showElements.tabIndex === 0 ? (
        <div className={classes.groupNameDiv}>
          <div className={classes.filterHeader}>
            <span className={classes.groupName}>Filters</span>
          </div>
          <div
            className={clsx(classes.filterHeader, {
              [classes.filterHeaderMobile]: isMobile,
            })}
          >
            <span className={classes.clearAllLink} onClick={clearAllFields}>
              Clear all
            </span>
            {isMobile ? (
              <span
                className={classes.chevronUpDownIcon}
                onClick={() => toggleFilterPanel(!showFilterPanel)}
              >
                <ChevronUpDown type={showFilterPanel ? 'up' : 'down'} />
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : null}

      <div className={classes.formGroup}>
        {showElements.schoolSelector ? (
          <FormControl className={classes.formControl}>
            <InputLabel
              id='schoollbl'
              required
              classes={{ asterisk: classes.asterisk }}
            >
              School
            </InputLabel>
            <AutoComplete
              mode='data'
              id='schoollbl'
              isFreeForm={false}
              value={schoolName}
              dataFormatter={(data: any) => {
                return data.map((school: any) => {
                  const { id, name } = school;
                  return {
                    id,
                    name,
                  };
                });
              }}
              disabled={false}
              placeholder='Select school'
              noOptionsText='No schools'
              optionsArray={orgList}
              renderOption={formatChannelList}
              getOptionSelected={(option, value) => option.name === value}
              onChange={(event, item) => {
                setFilterValues({
                  ...filterValues,
                  school: item.id.toString() + '|' + domainId.toString(),
                });
                setSchoolName(item.name);
              }}
              getOptionLabel={(item) => {
                if (item.name) {
                  return item.name;
                }
                return item;
              }}
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </FormControl>
        ) : null}

        {showElements.dateRange ? (
          <FormControl className={classes.formControl}>
            <InputLabel>Date Range</InputLabel>
            <div className={classes.dateRangeContainer}>
              <Grid container >
                <Grid item xs={12} sm={6} className={classes.gridRightSpacing}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      data-testid='startDate'
                      className={classes.customDatePicker}
                      disableToolbar
                      autoOk
                      variant='inline'
                      format='MM/dd/yyyy'
                      margin='normal'
                      placeholder='Start date'
                      minDate={defaultMinDate}
                      maxDate={new Date()}
                      value={filterValues.startDate}
                      keyboardIcon={<DatePickerIcon className={classes.dateIcon} data-testid="start-date"/>}
                      KeyboardButtonProps={{ className: classes.datePickerButton }}
                      InputAdornmentProps={{
                        style: { marginLeft: 0 }
                      }}
                      onChange={handleStartDateChange}
                      onError={handleStartDatePickerError}
                      InputProps={inputTextFieldProps}
                      rifmFormatter={dateFormatter}
                      inputValue={inputStartValue}
                      maxDateMessage='Date should not be greater than current date'
                      invalidDateMessage='Invalid Date Format (MM/DD/YYYY)'
                      helperText={isValidStartDate && 'MM/DD/YYYY' || startDateErrorMsg}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridLeftSpacing}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      data-testid='endDate'
                      className={classes.customDatePicker}
                      disableToolbar
                      autoOk
                      variant='inline'
                      format='MM/dd/yyyy'
                      margin='normal'
                      placeholder='End date'
                      minDate={isValidStartDate && filterValues.startDate || defaultMinDate}
                      maxDate={new Date()}
                      value={filterValues.endDate}
                      keyboardIcon={<DatePickerIcon className={classes.dateIcon} data-testid="end-date"/>}
                      KeyboardButtonProps={{ className: classes.datePickerButton }}
                      InputAdornmentProps={{
                        style: { marginLeft: 0 }
                      }}
                      onChange={handleEndDateChange}
                      onError={handleEndDatePickerError}
                      InputProps={inputTextFieldProps}
                      rifmFormatter={dateFormatter}
                      inputValue={inputEndValue}
                      minDateMessage={() => {
                        let errMsg = 'Date should not be before minimal date';
                        if(filterValues.startDate && isValidStartDate){
                          errMsg = 'Date should not be before start date'
                        }
                        return errMsg;
                      }}
                      maxDateMessage='Date should not be greater than current date'
                      invalidDateMessage='Invalid Date Format (MM/DD/YYYY)'
                      helperText={isValidEndDate && 'MM/DD/YYYY' || endDateErrorMsg}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </div>
          </FormControl>
        ) : null}
        {showElements.channelField ? (
          <FormControl className={classes.formControl}>
            <InputLabel>Channel</InputLabel>
            <TextField
              data-testid='channelId'
              placeholder='Search Channels'
              type='text'
              value={filterValues.channel}
              onChange={handleChannelOnchange}
              InputProps={inputTextFieldProps}
            />
          </FormControl>
        ) : null}
        {showElements.userField ? (
          <FormControl className={classes.formControl}>
            <InputLabel
              required={showElements.tabIndex === 1}
              classes={{ asterisk: classes.asterisk }}
            >
              User
            </InputLabel>
            <TextField
              data-testid='userId'
              placeholder='Search Users'
              type='text'
              value={filterValues.sender}
              onChange={handleUserOnchange}
              InputProps={inputTextFieldProps}
            />
          </FormControl>
        ) : null}

        <FormControl className={classes.formControl}>
          <Button
            className={classes.button}
            type='button'
            disabled={
              (!(
                searchKeyword &&
                (schoolName || props.schoolId) &&
                isValidDateRange()
              ) &&
                showElements.tabIndex === 0) ||
              (!(schoolName && filterValues.sender) &&
                showElements.tabIndex === 1)
            }
            classes={{ root: classes.buttonHover }}
            onClick={() => handleSearchMessages(filterValues, searchKeyword)}
          >
            Search
          </Button>
        </FormControl>
      </div>
    </div>
  );
};
export default FilterSearchComponent;
