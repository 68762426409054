import React, { useLayoutEffect } from 'react';
import { Box } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { DateFormat, epochToJsDate } from '../../libs/date.utils';
import BadgeAvatar from '../BadgeAvatar';
import ReactHtmlParser from 'react-html-parser';
import MessagePanelStyles from '../MessagesPanel/MessagePanelStyle';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MessageCardTypes from './MessageCardTypes';

const dateFormat = {
  hour: '2-digit',
  minute: '2-digit',
};

export const MessageCardStyles = makeStyles((theme: any) => {
  return createStyles({
    cardContentRoot: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      border: '1px solid #cdcdcd',
      borderRadius: 2,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '&:hover': {
        backgroundColor: '#f8f8f8',
      },
    },
    cardTitle: {
      display: 'flex',
      flexDirection: 'row',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
    },
    senderTitle: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
      marginLeft: 0,
      alignSelf: 'center',
    },
    highlighter: {
      '& .highlight': {
        background: '#FF5991',
        padding: '1px 0',
      },
    },
    breakWord: {
      overflowWrap: 'anywhere',
    },
    messageContent: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      '& p': { margin: 0 },
      '& ul': {
        'margin-block-start': 0,
        'margin-block-end': 0,
      },
      '& li:empty': {
        display: 'none',
      },
      '& pre': {
        whiteSpace: 'break-spaces',
      },
    },
  });
});

const MessageCard = ({
  message,
  attachmentCount,
  channelName,
  createdAt,
  senderName,
  senderRole,
  highlightTokens = [],
  highlighter = (message, highlightTokens = []) => message,
  jumperAction = () => {},
  trimEmptyTags = false,
}: MessageCardTypes) => {
  let classes = MessagePanelStyles()();
  let cardStyles = MessageCardStyles();

  const messageDateFormat = DateFormat(
    epochToJsDate(Number(createdAt)).toString()
  );
  const messageTimeFormat = epochToJsDate(Number(createdAt));

  message = highlightTokens ? highlighter(message, highlightTokens) : message;

  useLayoutEffect(() => {
    setTimeout(() => {
      trimEmptyTags &&
        document
          .querySelectorAll('.message-contents :empty')
          .forEach((node) => node.remove());
    });
  }, [trimEmptyTags]);

  return (
    <CardContent className={cardStyles.cardContentRoot}>
      <div className={classes.liMessage}>
        <div className={cardStyles.cardTitle}>
          <span>
            {channelName +
              ' - ' +
              messageDateFormat +
              ' at ' +
              messageTimeFormat.toLocaleTimeString('en-US', dateFormat)}
          </span>
          <img
            className={classes.jumpIconClass}
            src='/images/jump-to.png'
            onClick={jumperAction}
            alt='Go to message'
          />
        </div>
        <Box display='flex' flexDirection='row' paddingTop='12px'>
          <Box component='div' display='flex'>
            <BadgeAvatar size={classes.medium} name={senderName} />
          </Box>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' flexDirection='row'>
              <div className={classes.group} role='button'>
                <div className={classes.profileName}>{senderName}</div>
                <div className={classes.profileRole}>
                  {senderRole.toLowerCase()}
                </div>
              </div>
            </Box>
            <Box
              className={`${cardStyles.messageContent} ${cardStyles.breakWord} ${cardStyles.highlighter} message-contents`}
            >
              {ReactHtmlParser(message)}
              {attachmentCount ? attachmentCount > 0 ? (<span style={{ color: "#8C8C8C", fontSize: "14px" }}>{attachmentCount} {attachmentCount > 1 ? 'attachments' : 'attachment'}</span>) : null : null}
            </Box>
          </Box>
        </Box>
      </div>
    </CardContent>
  );
};

export default MessageCard;
