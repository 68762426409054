import { makeStyles } from '@material-ui/core/styles';

const InfoCardStyle = makeStyles(() => ({
    root: {
      height: '100%',
      color: '#262626',
      boxShadow: 'none',
    },
    count: {
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '36px',
    },
    caption: {
        fontSize: '14px',
        lineHeight: '21px',
        color: '#262626',
    }
}));

export default InfoCardStyle;