import React from 'react'

function PdfIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 1H16L21 6V23H3V1Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <rect width="6" height="3" transform="matrix(1 0 0 -1 11 13)" stroke="#262626" strokeWidth="2" strokeLinejoin="round"/>
        <path d="M7 10H8" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 13H8" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 16L17 16" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 19H17" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 1V7H21" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default PdfIcon