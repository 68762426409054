export class ReplyToMessage {
  public name: string = '';
  public role: string = '';
  public message: string = '';
  public messageId: string = '';
  public id: string = '';
  public createdAt: number = 0;
  public channelName: string = '';
  public channelId: string = '';
  public channelType: string = '';
  public attachment: boolean = false;
  public uploadedFiles: any = [];
}
