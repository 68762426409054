import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = '291px';
const AppStyle = () =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    mainDiv: {
      display: 'flex',
      flex: 1,
    },
    westbarDiv: {
      //width: '100%',
      overflow: 'unset',
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
    },
    westbarDivSidebarShrink: {
      //width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
      },
    },
    westbarHide: {
      //width: '0vw',
    },
    convDiv: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      //width: '100vw',
    },
    convDivShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    convDivShiftSideBarShrink: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      //width: "calc(100% - 350px + 75px)",
      width: '100vw',
      marginLeft: '-80px',
    },
    convDivDeskShrink: {
      width: 'calc(100vw - 373px)',
    },
    convDivDesk: {
      width: 'calc(100vw - 294px)',
      marginLeft: '-80px',
    },
    convDivShiftFullSideBar: {
      flexGrow: 1,
      marginLeft: '-373px',
    },
    shiftFullSideBarImpersonate: {
      flexGrow: 1,
      marginLeft: '-294px',
    },
    showFullSideBarImpersonate: {
      flexGrow: 1,
    },
    mobileViewImpersonate: {
      width: '100vw',
    },
    progress: {
      height: '5px',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& div': {
        backgroundColor: '#FF5991',
      },
    },
    drawerPaper: {
      width: '370px',
      borderRightColor: '#CDCDCD',
      top: (props: any = {}) => {
        return props.isOpenedInMobile == 'true' ? 0 : '44px';
      },
      zIndex: 3,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    drawerPaperSidebarShrink: {
      width: '291px',
      top: (props: any = {}) => {
        return props.isOpenedInMobile == 'true' ? 0 : '44px';
      },
      zIndex: 3,
      [theme.breakpoints.up('sm')]: {
        //width: '340px',
        top: '44px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
  }));

export default AppStyle;
