import React from 'react';
import { SvgIcon } from '@material-ui/core';

export function MemberIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15 15C15 11.5 12.1634 8 8 8C3.83662 8 1 11.5 1 15'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 8C9.933 8 11.5 6.433 11.5 4.5C11.5 2.567 9.933 1 8 1C6.067 1 4.5 2.567 4.5 4.5C4.5 6.433 6.067 8 8 8Z'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}

export function ChatIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M15 8C15 11.866 11.866 15 8 15C7.10858 15 6.25608 14.8334 5.47194 14.5296L1 15L1.5 10.6031C1.17745 9.79841 1 8.91993 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z'
          stroke='#262626'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
}
