import { makeStyles } from '@material-ui/core';

const MessagePanelStyles = () =>
  makeStyles((theme) => ({
    root: {
      overflow: 'auto',
      scrollbarColor: '#666666 transparent',
      // scrollbarWidth: 'thin',
      display: 'flex',
      flexDirection: 'column',
      '&::-webkit-scrollbar': {
        width: "8px",
        display: "none",
      },
      '-ms-overflow-style': "none",  /* IE and Edge */
      scrollbarWidth: "none",
      
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#666666',
        borderRadius: '10px',
        border: '1px solid #666',
      },

      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#666666',
      },
    },
    messagesEditorWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100vh - 104px)`,
      justifyContent: 'flex-end',
    },
    messagesWrapperWithoutEditor: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    messagesPane: {
      position: 'relative',
      flex: 1,
      overflow: 'auto',
    },
    heightWithEditor: {},
    heightWithEditorMobile: {},
    heightWithOutEditor: {
      height: (props: any) => {
        const textHeight = document.querySelector(
          '[class*="makeStylesnoMargin"]'
        )?.clientHeight;
        if (props.isOpenedInMobile == 'true') {
          return `calc(100vh - 61px - ${textHeight ?? 0}px)`;
        } else {
          return `calc(100vh - 44px - 61px - ${textHeight ?? 0}px)`;
        }
      },
    },
    impersonateDesk: {
      //desktop view while impersonating
      height: 'calc(100vh - 275px)',
      paddingBottom: '0px',
      ['@media (max-height: 500px)']: {
        height: 'calc(100vh - 112px)',
      },
    },
    impersonateMobile: {
      // mobile view while impersonating
      height: 'calc(100vh - 210px)',
    },
    ulMessage: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    ulMessageMobile: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    header: {
      textAlign: 'center',
      height: '10px',
      background: 'transparent',
      display: 'flex',
    },
    medium: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      position: 'unset',
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    headerDivider: {
      display: 'flex',
      flex: 1,
    },
    headerText: {
      fontSize: '12px',
      marginLeft: '15px',
      marginRight: '15px',
    },
    profileName: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '21px',
      marginLeft: '0px',
      alignSelf: 'center',
    },
    message: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      wordBreak: 'break-word',
      '& pre': { whiteSpace: 'pre-wrap' },
      '& p': { margin: '0px !important' },
      '& a:link': { color: '#2013A1' },
    },
    eachMessage: {
      '&:hover': {
        backgroundColor: '#FFF7FA',
      },
    },
    liMessage: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    group: {
      display: 'flex',
      flexDirection: 'row',
      flex: '1',
    },
    messageTime: {
      fontSize: '10px',
      alignSelf: 'center',
      color: '#666666',
    },
    profileRole: {
      fontSize: '12px',
      color: '#666666',
      marginLeft: '5px',
      alignSelf: 'center',
      textTransform: 'capitalize',
    },
    divider: {
      height: '10px',
      margin: '5px',
      backgroundColor: '#666666',
    },
    restrictParent: {
      display: 'flex',
      backgroundColor: '#D7F7FF',
      paddingLeft: '30px',
      alignItems: 'center',
      paddingTop: '13px',
      paddingBottom: '11px',
      '& > svg': {
        ['@media (max-width: 768px)']: {
          alignSelf: 'flex-start',
        },
      },
      '& > div': {
        alignSelf: 'flex-start',
      },
    },
    restrictParentText: {
      paddingLeft: '5px',
    },
    replyToCard: {
      borderLeft: '4px solid #FF5991',
      flex: 1,
      // marginTop: 30,
      // marginLeft: 30,
      // marginRight: 30,
      marginBottom: 10,
    },
    copiedMessage: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      maxHeight: 'calc(21px * 3)',
      display: '-webkit-box',
      lineClamp: 3,
      WebkitLineClamp: 3,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      '& p': { margin: '0px !important' },
    },
    replyToMessage: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    courseIconClass: {
      width: '16px',
      height: '16px',
      marginRight: '2px',
    },
    cardTitle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontWeight: 600,
    },
    jumpIconClass: {
      width: '16px',
      height: '16px',
      marginLeft: 'auto',
      cursor: 'pointer',
    },
    tempReplyCardClass: {
      margin: '10px',
    },
    progress: {
      height: '5px',
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& div': {
        backgroundColor: '#FF5991',
      },
    },
    loader: {
      position: 'sticky',
      top: '50%',
      left: '50%',
      textAlign: 'center',
    },
    editorStyle: {
      justifyContent: 'flex-end',
      flexShrink: 0,
      padding: '0px',
      marginRight: '30px',
      marginLeft: '30px',
      marginBottom: '30px',
      '& .text-editor': {
        '& .quill': {
          '& .ql-container': {
            borderTopLeftRadius: '2px',
            borderTopRightRadius: '2px',
            '& .ql-editor.ql-blank::before': {
              fontStyle: 'normal',
            },
            '& .ql-tooltip': {
              marginTop: `0!important`,
              position: 'absolute',
            },
          },
        },
        '& .ql-toolbar': {
          borderBottomLeftRadius: '2px',
          borderBottomRightRadius: '2px',
          padding: 0,
        },
      },
    },
    editorStyleMobile: {
      justifyContent: 'flex-end',
      flexShrink: 0,
      padding: '0px',
      margin: '0px',
      '& .text-editor': {
        '& .quill': {
          '& .ql-container': {
            borderRight: 'none',
            borderLeft: 'none',
            '& .ql-editor.ql-blank::before': {
              fontStyle: 'normal',
            },
          },
        },
        '& .ql-toolbar': {
          borderRight: 'none !important',
          borderLeft: 'none !important',
          borderBottom: 'none !important',
          padding: 0,
        },
      },
    },
    noMargin: {
      margin: 0,
    },
    errorMessage: {
      color: '#FF0000',
      fontStyle: 'italic',
      fontSize: '12px',
    },
    translateProgressBar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40%',
      fontWeight: 200,
    },
    progressBarParent: {
      marginLeft: '10px',
      display: 'inline',
    },
    emptyMessageBox: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%)',
    },
  }));

export default MessagePanelStyles;
