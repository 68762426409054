import axios, { AxiosRequestConfig } from 'axios';
import { API } from '../properties/apiProperties';
import { get_cluster } from '../components/clusterGlobal';

export const getChatArchiveMessages = async (
  filters: any,
  bbdomain: string
) => {
  let result = null;

  const options: AxiosRequestConfig = {
    url: API.chatArchiveMessagesApi,
    method: 'POST',
    data: filters,
    headers: {
      bbdomain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
    },
  };
  result = await axios(options);
  return result;
};

// Check channel name is enabled/disabled
export const getChannelStatusUsingName = async (
  schoolId: string | number,
  channelName: string,
  domain: string
) => {
  let result = null;

  let queryParams = new URLSearchParams();
  queryParams.append('schoolId', schoolId + '');
  queryParams.append('channelName', channelName);
  const options: AxiosRequestConfig = {
    url: API.channelStatus,
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    params: queryParams,
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

// Check channel id is enabled/disabled
export const getChannelStatusUsingId = async (
  channelId: string,
  channelType: string,
  channelUser: string,
  domain: string
) => {
  let result = null;

  let queryParams = new URLSearchParams();
  queryParams.append('channelId', channelId + '');
  queryParams.append('channelType', channelType+ '');
  queryParams.append('channelUser', channelUser);
  const options: AxiosRequestConfig = {
    url: API.channelStatus,
    method: 'GET',
    headers: {
      bbDomain: domain,
      domainId: localStorage.getItem('domainID'),
      personId: localStorage.getItem('userId'),
      cluster: get_cluster(),
    },
    params: queryParams,
    withCredentials: true,
  };
  try {
    result = await axios(options);
  } catch (e) {
    console.log(e.message);
  }
  return result;
};

axios.interceptors.request.use(function (config) {
  config.headers.ACCEPT = 'application/json';
  return config;
});
