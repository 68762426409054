import React, {useState, useContext} from 'react'
import ChevronUpDown from '../../Icons/CheronUpDown';
import VideoIcon from "../../Icons/VideoIcon"
import PdfIcon from "../../Icons/PdfIcon"
import {AiOutlineAudio} from 'react-icons/ai';
import { GrImage } from 'react-icons/gr';
import DeleteIcon from "../../Icons/DeleteIcon"
import DownloadIcon1 from "../../Icons/DownloadIcon1"
import {imageFilesExtensions,videoFilesExtensions,AudioFilesExtensions, unsupportedImgExtensions} from "./FileExtensions"
import { getPresignedUrl } from '../../services/FilesServices';
import { UserAuthCtx } from '../AuthComponent';
import MessagePanelStyles from '../MessagesPanel/MessagePanelStyle';

import {
  ImagePreview, 
  ImageContainer, 
    DownloadIconContainer, 
    DeleteIconContainer, 
    FilesPreviewContainer, 
    ChevronContainer, 
    FileContainer, 
    FileName, 
    SmallImagePreview,
    SpanElement,
    IconsContainer,
    StyledReactTooltip
  } from './AttachmentDisplayComponentStyle';

function AttachmentDisplayComponent(props:any) {
  const [showAttachement,  setShowAttachment] = useState(true)
  const files = props.files
  const showFilesErrorMsg = props.showFilesErrorMsg
  const setShowImagePreview = props.setShowImagePreview
  const authContext = useContext(UserAuthCtx);
  const userDetails = useContext(UserAuthCtx).userCtx;
  const handleDisplayAttachment = () => {
    setShowAttachment(!showAttachement)
  }
  let fileName,fileExtension,splitedFileName,splittedfileExtension,location_actual,location
  const getFileInfo = (file:string) => {


    location_actual = file.split("?")[0]
    splitedFileName = location_actual.split("/")
    fileName = splitedFileName[splitedFileName.length - 1]
    splittedfileExtension = fileName.split(".")
    fileExtension = splittedfileExtension[splittedfileExtension.length - 1].toLowerCase()
    const encoded_filename = fileName.replace(/^.*\/([^/]+?)\?.*$/, '$1');
    const plain_filename= decodeURIComponent(encoded_filename);
    location = location_actual.replace(fileName, plain_filename)
    return {"fileExtension":fileExtension, "fileName":plain_filename, "location":location}
  }
  const handleDownload = (fileLocation:string,fileName:string) => {
    var flag = "download";
    const bbDomain = userDetails.userLoggedInDomain
    var downloadFiles = [];
    var payload = {
      "name": fileName,
      "location" : fileLocation
    }
    downloadFiles.push(payload);
    getPresignedUrl(downloadFiles, flag, bbDomain).then((response: any) => {
      if ("generated_url" in response.data) {
        var url = response.data.generated_url[fileName];
        const obj = document.createElement('a')
        obj.href = url
        obj.download = fileName
        obj.click() 
      }
      }).catch(function(response) {
        if(response instanceof Error) {
          console.log(response);
          showFilesErrorMsg({
            severity: 'fileLimitError',
            message:
              'Network Error',
            isShowClose: 1,
          },true)
        } else {
          console.log(response.data);
        }
      });
    }
  const isOnlyImages = files.every((file:any)=>imageFilesExtensions.includes(getFileInfo(file)['fileExtension']))
  const getHoverIcons = (props:boolean, location:string, file:string) => {
    return (
      <IconsContainer isImage={props}>
        <DownloadIconContainer data-testid="downloadbtn" id='showpopup' onClick={() => handleDownload(location,file)} data-tip data-for="DownloadToolTip"><DownloadIcon1/></DownloadIconContainer>
        <StyledReactTooltip offset={{bottom: 8}} id="DownloadToolTip" place="top" effect="solid">
          Download
        </StyledReactTooltip>
      </IconsContainer>
    )
  }


  const OpenImagePreviewModal = (file: string, fileName: string) => {
    setShowImagePreview({'imageUrl': file, 'imageName': fileName})

  }
  return (
    <div>
      <ChevronContainer>
        <span>{files.length} {files.length > 1 ? 'attachments' : 'attachment'}</span>
        <SpanElement onClick={ handleDisplayAttachment}>
          <ChevronUpDown type={showAttachement ? "up" : "down"} isFile='true'  />
        </SpanElement>
      </ChevronContainer>
      <FilesPreviewContainer showAttachement={showAttachement}>
        {files.map((file:any,index:number)=>{
          const fileExtension = getFileInfo(file)['fileExtension']
          const fileName = getFileInfo(file)['fileName']

          const fileLocation = getFileInfo(file)['location']
          let icon
          if(!isOnlyImages)
            if (videoFilesExtensions.includes(fileExtension)) {
              icon = <VideoIcon/>
            } else if (AudioFilesExtensions.includes(fileExtension)) {
              icon = <AiOutlineAudio style={{fontSize:"25px"}}/>
            }else if (unsupportedImgExtensions.includes(fileExtension)) {
              icon = <GrImage style={{fontSize:"25px", paddingLeft:"3px"}}/>
            } else {
              icon = <PdfIcon/>
            }
          return(
            <div key={fileName+index}>
              {isOnlyImages ?
                <ImageContainer >
                  <ImagePreview  data-testid="imgCon" src={file} alt={fileName} onClick={() => OpenImagePreviewModal(file, fileName)}/>
                  {getHoverIcons(true,fileLocation,fileName)}
                </ImageContainer>
              :
              <>
                {imageFilesExtensions.includes(fileExtension) ? 
                  <FileContainer style={{paddingLeft:"4px"}}>
                    <SmallImagePreview src={file} onClick={() => OpenImagePreviewModal(file, fileName)}/>
                    {fileName.length>13 ? <FileName data-testid={fileName} data-tip data-for={fileName+index}>{fileName.slice(0,12)+"..."}</FileName> :<FileName>{fileName}</FileName>}
                    <StyledReactTooltip offset={{top: 10}} id ={fileName+index} place="top" effect="solid">
                      {fileName}
                    </StyledReactTooltip>
                    {getHoverIcons(false,fileLocation,fileName)}
                  </FileContainer>
                : <FileContainer style={{paddingLeft:"4px"}}>
                    {icon}
                    {fileName.length>17 ? <FileName data-testid={fileName} data-tip data-for={fileName+index}>{fileName.slice(0,16)+"..."}</FileName> :<FileName>{fileName}</FileName>}
                    <StyledReactTooltip offset={{top: 10}} id ={fileName+index} place="top" effect="solid">
                      {fileName}
                    </StyledReactTooltip>
                    {getHoverIcons(false,fileLocation,fileName)}
                  </FileContainer>
                }  
              </>
              }
            </div>
          )
        })}
      </FilesPreviewContainer>
    </div>
  )
}

export default AttachmentDisplayComponent



