import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CloudUploadIcon = (props: any) => {
  return (
    <SvgIcon viewBox="0 -4 24 24" {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10C1 13 3 14 5 14H12C14 14 15 12.6569 15 11C15 9 13 8 13 8C13 8 14 2 8 2C3 2 3 7 3 7C3 7 1 7.5 1 10Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 6V10" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10 8L6 8" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </SvgIcon>
  );
};

export default CloudUploadIcon;
