import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AttentionIcon = (props: any) => {
  return (
    <SvgIcon viewBox='-2 -2 24 24' {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='1'
          y='1'
          width='14'
          height='14'
          rx='7'
          stroke='#C23E37'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 4V9'
          stroke='#C23E37'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <circle cx='8' cy='12' r='0.5' stroke='#C23E37' />
      </svg>
    </SvgIcon>
  );
};

export default AttentionIcon;
