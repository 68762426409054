import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Box, Tabs, Tab, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ChatArchiveUsers from './ChatArchiveUsers';
import ChatArchiveMessages from './ChatArchiveMessages';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      component='div'
      role='tabpanel'
      flexDirection='column'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: any) => {
  return createStyles({
    tabHeader: {
      fontWeight: 600,
      fontSize: '18px',
    },
    tabButton: {
      border: '1px solid #CDCDCD',
      borderRadius: '0px 2px 2px 0px',
      height: '38px',
      backgroundColor: '#FFFFFF',
      color: '#666666',
      fontSize: '14px',
      boxShadow: 'none!important',
    },
    tabSelected: {
      backgroundColor: '#262626',
      color: '#FFFFFF!important',
      border: '1px solid #CDCDCD',
      borderRadius: '2px 0px 0px 2px',
      boxSizing: 'border-box',
      fontSize: '14px',
    },
    tab: {
      '& [class^="MuiTabsflexContainer"]': {
        width: '100%',
      },
    },
    tabroot: {
      width: '50%',
    },
    tabIcon: {
      verticalAlign: 'middle',
    },
    wrapper: {
      paddingBottom: '12px',
      '& [class^="MuiTabsscroller"]': {
        display: 'flex',
      },
    },
    tabBar: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px',
      ['@media (max-width: 640px)']: {
        paddingRight: '20px',
      },    
    },
    tabBarSecondary: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px',
      [theme.breakpoints.down('xs')]: {
        paddingRight: '20px',
      },
      height: 'calc(100vh - 221px)',
      minHeight: '100px',
      overflow: 'auto',
      ['@media screen and (max-height: 150px) and (orientation:landscape)']: {
        height: '100vh'
      }
    },
    groupNameDiv: {
      display: 'flex',
      flex: '1',
      alignContent: 'space-between',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '8px',
    },
    groupName: {
      display: 'flex',
      alignItems: 'left',
      fontWeight: 600,
      fontSize: '18px',
    },
  });
});

const ChatArchivePanel = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className={classes.tabBar}>
        <div className={classes.groupNameDiv}>
          <span className={classes.groupName}>Chat Archive</span>
        </div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={classes.wrapper}
              classes={{
                root: classes.tab,
              }}
              TabIndicatorProps={{
                style: {
                  display: 'none',
                },
              }}
            >
              <Tab
                className={classes.tabButton}
                classes={{
                  root: classes.tabroot,
                  selected: classes.tabSelected,
                }}
                label={<div>Messages</div>}
                {...a11yProps(0)}
              />
              <Tab
                className={classes.tabButton}
                classes={{
                  root: classes.tabroot,
                  selected: classes.tabSelected,
                }}
                label={<div>Users</div>}
                {...a11yProps(1)}
              />
            </Tabs>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={classes.tabBarSecondary}>
        <TabPanel value={value} index={0} height='inherit'>
          <ChatArchiveMessages />
        </TabPanel>
        <TabPanel value={value} index={1} height='inherit'>
          <ChatArchiveUsers />
        </TabPanel>
      </div>
    </div>
  );
};
export default ChatArchivePanel;
