import React, { useEffect, useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import MembersPanelStyle from './MembersPanelStyle';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, Button, Grid, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getGroupMembers, getChannelReadRate } from '../../services/userAccountServices';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableComponent from '../TableComponent/TableComponent';
import { IMembersPanel } from './IMembersPanel';
import { IMembersColumn } from '../../Types/IMembersColumn';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import NotificationBox from '../NotificationBox/NotificationBox'
import { UserAuthCtx } from '../AuthComponent';
import { AddWithCircleIcon } from '../../Icons/AddWithCircleIcon';
import DeleteIcon from '../../Icons/DeleteIcon';
import AddMembersComponent from '../AddMembersComponent/AddMembersComponent';
import { StateContext } from '../StateContextComponent';
import ActionableAlertBox from '../ActionableAlertBox/ActionableAlertBox';
import { removeMemberApi } from '../../services/ChannelService';
import { Data } from '../TableComponent/ITableComponent'

const MembersPanel = ({
    selectedGroup,
    bbdomain,
    isParenDrawerOpen,
    setGroup = () => { },
    setShowError = () => { },
    showError = {},
}: IMembersPanel) => {
    const classes = MembersPanelStyle();
    const membersCount = 50;
    const authContext = useContext(UserAuthCtx);
    const userId = authContext.userCtx.userId ? authContext.userCtx.userId : '';
    const { selectedOrganizationId } = useContext(StateContext);
    const [addMembersConfirmation, setAddMembersConfirmation] = useState(false);
    const [recipients, setRecipients]: any[] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [readRate, setReadRate]: any = useState({
        isLoading: false,
        data: {}
    });
    const [count, setCount] = useState({
        prev: 0,
        next: membersCount
    });
    const getRoleState = () => {
        const roleValue = "All Roles";
        return roleValue;
    };

    const [roleValue, setRoleValue] = useState(getRoleState);
    const [hasMore, setHasMore] = useState(true);
    const [current, setCurrent]: any = useState([]);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(new Subject());
    const [readRateLoaded, setReadRateLoaded] = useState(false);
    const [isParent, setisParent] = useState(
        authContext.userCtx.userRole.toLowerCase() == 'parent' || authContext.userCtx.userRole.toLowerCase() == 'student'
    );
    // state to enable/disable remove button
    const [RemoveDisabled, setRemoveDisabled] = useState(false);
    // state to show/hide add and remove channel button
    const [isLoggedInUserOwner, setIsLoggedInUserOwner] = useState(false);
    // mediaQuery variable for handling grid spacing
    const isSmallScrn = useMediaQuery({ query: '(max-width: 1100px)' });

    const isReachChannel = selectedGroup.type === "CHANNEL"
    const ChannelCreator = selectedGroup.owner
    const reachColumn: IMembersColumn[] = [
        {
            id: 'checkbox',
            label: '',
            minWidth: 50,
            width: "60px",
            cellMinWidth: 40,
            allowSorting: false,
        }
    ];
    const columns: IMembersColumn[] = [
        {
            id: 'avatar',
            label: '',
            minWidth: 40,
            cellMinWidth: 40,
            allowSorting: false,
            width: '1%',
        },
        {
            id: 'name',
            label: 'Full name',
            minWidth: 90,
            cellMinWidth: 40,
            allowSorting: true,
            width: '20%',
            sortType: 'string',
        },
        {
            id: 'readRate',
            label: 'Read rate',
            minWidth: 50,
            cellMinWidth: 50,
            allowSorting: true,
            width: '15%',
            sortType: 'number',
        },
        {
            id: 'role',
            label: 'Role',
            minWidth: 55,
            cellMinWidth: 55,
            allowSorting: true,
            width: '10%',
            sortType: 'string',
        },
        {
            id: 'relationship',
            label: 'Relationship',
            minWidth: 50,
            cellMinWidth: 50,
            allowSorting: false,
            width: '25%',
            sortType: 'string',
        },
        {
            id: 'action',
            label: 'Message',
            minWidth: 20,
            cellMinWidth: 50,
            allowSorting: false,
            width: '55px',
        },
        {
            id: 'owner',
            label: 'Owner',
            minWidth: 20,
            cellMinWidth: 50,
            allowSorting: false,
            width: '55px',
        },
    ];
    const columnConfig = {
        mobile: [
            { ...columns[0], minWidth: 40, cellMinWidth: 40, width: '1%' },
            { ...columns[1], minWidth: 45, cellMinWidth: 45, width: '83%' },
        ],
        tabletExpand: [
            { ...columns[0], minWidth: 40, cellMinWidth: 40, width: '1%' },
            { ...columns[1], minWidth: 65, cellMinWidth: 65, width: '20%' },
            { ...columns[2], minWidth: 100, cellMinWidth: 100, width: '16%' },
            { ...columns[3], minWidth: 40, cellMinWidth: 40, width: '10%' },
            { ...columns[4], minWidth: 40, cellMinWidth: 40, width: '18%' },
            { ...columns[5], minWidth: 40, cellMinWidth: 40, width: '40px' },
            { ...columns[6], minWidth: 40, cellMinWidth: 40, width: '40px' },
        ],
        tablet: [
            { ...columns[0], minWidth: 20, cellMinWidth: 40, width: '10%' },
            { ...columns[1], minWidth: 95, cellMinWidth: 95, width: '60%' },
            { ...columns[5], minWidth: 30, cellMinWidth: 30, width: '40px' },
            { ...columns[6], minWidth: 40, cellMinWidth: 40, width: '40px' },
        ],
        parenttablet: [
            { ...columns[0], minWidth: 20, cellMinWidth: 40, width: '10%' },
            { ...columns[1], minWidth: 20, cellMinWidth: 40, width: '80%' },
        ],
        desktop: columns,
    };


    const ReachColumnConfig = [
        { ...reachColumn[0], minWidth: 40, cellMinWidth: 40, width: '40px' },
        // { ...reachColumn[1], minWidth: 20, cellMinWidth: 20, width: '40px' },
    ]
    const parentColumnConfig = [
        { ...columns[0], minWidth: 40, cellMinWidth: 40, width: '1%' },
        { ...columns[1], minWidth: 30, cellMinWidth: 30, width: '15%' },
        { ...columns[3], minWidth: 40, cellMinWidth: 40, width: '10%' },
        { ...columns[4], minWidth: 40, cellMinWidth: 40, width: '25%' },
    ]
    if (isReachChannel) {
        // If selected group is ReachChannel adding extra columns checkbox
        columnConfig.tabletExpand = isLoggedInUserOwner ? [{ ...ReachColumnConfig[0], width: '1%' }, ...columnConfig.tabletExpand] : !isParent ? [...columnConfig.tabletExpand] : [...parentColumnConfig]
        columnConfig.tablet = isLoggedInUserOwner ? [ReachColumnConfig[0], ...columnConfig.tablet] : !isParent ? [...columnConfig.tablet] : [...columnConfig.parenttablet]
        columnConfig.mobile = isLoggedInUserOwner && !isParent ? [ReachColumnConfig[0], ...columnConfig.mobile] : [...columnConfig.mobile]
        columnConfig.desktop = isLoggedInUserOwner ? [{ ...ReachColumnConfig[0], width: '1%' }, ...columnConfig.desktop] : !isParent ? [...columnConfig.desktop] : [...parentColumnConfig]
    }
    function createData(recipient: any): Data {
        const name = `${recipient.firstName} ${recipient.lastName}`;
        const role = recipient.role;
        const isLoggedInUser = recipient.personID == userId;
        const userType = recipient.userType;
        var relationship = [];
        const personId = recipient.personID;
        const readRate = Math.floor(Math.random() * 100);
        const roleID = recipient.roleID;
        const checkbox = false;
        for (var student of recipient.relationships)
            relationship.push(
                student.firstName +
                ' ' +
                student.lastName +
                ', <em>' +
                student.relationType.substr(0, 1).toUpperCase() +
                student.relationType.slice(1).toLowerCase() +
                '</em><br>'
            );
        return { name, role, relationship, personId, readRate, isLoggedInUser, userType, checkbox, roleID };
    }

    const areNamesMatching = (
        firstName: string,
        lastName: string,
        queryString: string
    ) => {
        return firstName.includes(queryString) || lastName.includes(queryString);
    };

    function createFilteredData(recipient: any, filterStr: string): any {
        let { firstName, lastName } = recipient;

        if (
            areNamesMatching(
                firstName.toLowerCase(),
                lastName.toLowerCase(),
                filterStr.toLowerCase()
            )
        ) {
            return true;
        }

        for (const student of recipient.relationships) {
            const { firstName, lastName } = student;

            if (
                areNamesMatching(
                    firstName.toLowerCase(),
                    lastName.toLowerCase(),
                    filterStr.toLowerCase()
                )
            ) {
                return true;
            }
        }

        return false;
    }

    const [isTableLoading, setTableLoading] = useState(false);

    const compareFullName = (itemA: any, itemB: any) => {
        let nameA = `${itemA.firstName} ${itemA.lastName}`;
        let nameB = `${itemB.firstName} ${itemB.lastName}`;
        return nameA.localeCompare(nameB);
    };

    const sortOwnersList = (ownersList: Array<any>) => {
        if (ownersList.length <= 2) {
            return ownersList;
        }

        let firstMember = ownersList.shift();
        ownersList.sort(compareFullName);
        return [firstMember, ...ownersList];
    };

    const loadData = async () => {
        setIsLoggedInUserOwner(false);
        let clone: any = [];
        setTableLoading(true);
        getGroupMembers(selectedGroup.groupType, bbdomain, selectedGroup.id)
            .then((response: any) => {
                // Combine owners and recipients
                const { owners, recipients } = response?.data;
                // initial sorting for owners and recipients
                let sortedOwners = sortOwnersList([...owners]);
                let sortedRecipients = recipients.sort(compareFullName);

                clone = [...sortedOwners, ...sortedRecipients];

                // TO enable add member and remove member button
                if (selectedGroup.type === "CHANNEL") {
                    for (let eachOwner of sortedOwners) {
                        if (eachOwner.personID == userId && eachOwner.userType == 'owner') {
                            setIsLoggedInUserOwner(true);
                            break;
                        }
                    }
                }
                setRecipients(clone);
                setTableLoading(false);
            })
            .catch((error) => {
                setRecipients(clone);
                setTableLoading(false);
                if (error.response) {
                    if (error.response.status === 504) {
                        setErrorMessage(
                            'Too many members are there, we couldnt render the complete list'
                        );
                    } else {
                        setErrorMessage(
                            error.response?.errorMessage
                                ? error.response?.errorMessage
                                : 'No results found !'
                        );
                    }
                } else {
                    setErrorMessage('No results found !');
                }
            });

    };

    const sliceIntoChunks = (arr: Array<any>, chunkSize: number) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    const loadReadRateData = () => {
        let userids: any = [];
        let domainid = localStorage.getItem('domainID');

        setReadRate({
            ...readRate,
            isLoading: true
        });

        // get all user ids in an array
        for (let eachRec of recipients) {
            userids.push(`${eachRec['personID']}|${domainid}`);
        }

        // split userids array into equal parts
        let sliced_userids = sliceIntoChunks(userids, membersCount);
        const promises = [];

        if (!isParent) {
            for (let index in sliced_userids) {
                promises.push(
                    getChannelReadRate(bbdomain, selectedGroup.id, selectedGroup.groupType, sliced_userids[index])
                )
            }
        }

        Promise.allSettled(promises).then((responses: any) => {
            let respObj: any = {};
            let isError = false;
            for (let resp of responses) {
                if (resp.status === 'fulfilled') {
                    let readRateObj = resp?.value?.data;
                    respObj = { ...respObj, ...readRateObj };
                } else {
                    isError = true;
                }
            }

            if (isError) {
                setShowError({isError: false, severity: 'ownerPrivilegesErr', isMembersTabError: true,  message: 'Error loading read rate.'})
            }

            setReadRate({
                data: {
                    ...readRate.data,
                    ...respObj
                },
                isLoading: false
            });

            // For sorting setting the state (setRows)
            let tableRows = rows;
            tableRows.forEach((rowObj) => {
                rowObj['readRate'] = respObj[rowObj['personId']]
            });
            setRows(tableRows);
        })
            .catch(err => console.log(err));
    }

    const getMoreData = () => {
        if (current.length === recipients.length) {
            setHasMore(false);
            return;
        }
        setTimeout(() => {
            setCurrent((current: any[]) => current.concat([...rows].slice(count.prev + membersCount, count.next + membersCount)));
            setItemsLoading(false);
        }, 2000);
        setCount((prevState) => ({ prev: prevState.prev + membersCount, next: prevState.next + membersCount }));
    }
    const loadMembersData = () => {
        setAllChecked(false)
        setCurrent([]);
        setRecipients([]);
        setRows([]);
        setCount({
            prev: 0,
            next: membersCount
        });
        setReadRateLoaded(false);
        setReadRate({
            isLoading: false,
            data: {}
        });
        loadData();
        setSearch('');
        setRoleValue(getRoleState);
    }
    useEffect(() => {
        loadMembersData();
        setShowError({...showError, isError: false, isMembersTabError: false, message: ''})
    }, [selectedGroup]);
    const [rows, setRows] = useState<Array<Data>>(new Array<Data>());
    const [rowsAll, setRowsAll] = useState<Array<Data>>(new Array<Data>());

    const handleToUpdate = (sortedRows: any) => {
        let sortedMembers: any = [];
        sortedRows.forEach((recipient: any) => {
            if (roleValue !== "All Roles") {
                if (recipient.role === roleValue) {
                    sortedMembers.push(recipient)
                }
            } else {
                sortedMembers.push(recipient)
            }
        })
        setRows(sortedMembers);
        setCurrent([...sortedMembers].slice(0, count.next));
    }

    useEffect(() => {
        let rowsData = [...recipients].map((recipient: any, index: number) => {
            return createData(recipient);
        });
        setRows(rowsData);
        setRowsAll(rowsData);
        setCurrent([...rowsData].slice(count.prev, count.next));
    }, [recipients]);

    useEffect(() => {
        if (!readRateLoaded && current && current.length) {
            setReadRateLoaded(true);
            loadReadRateData();
        }
    }, [current]);

    const [search, setSearch] = React.useState('');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setCurrent([]);
        setRows([]);
        setCount({
            prev: 0,
            next: membersCount
        });
        searchTerm.next(event.target.value);
        setItemsLoading(true);
    };

    useEffect(() => {
        const observable = searchTerm.pipe(
            map((s: any) => s.trim()),
            distinctUntilChanged(),
            debounceTime(1000)
        ).subscribe(term => {
            RoleAndSearchFilteredMembers(term, roleValue)
        });
        return () => observable.unsubscribe();

    }, [searchTerm, recipients, roleValue]);

    useEffect(() => {
        setItemsLoading(true);
        RoleAndSearchFilteredMembers(search, roleValue)
    }, [roleValue])

    const RoleAndSearchFilteredMembers = (searchTerm: string, roleValue: string) => {
        if (searchTerm.trim().length > 0) {
            let filteredMembers: any = [];
            recipients.forEach((recipient: any, index: number) => {
                if (createFilteredData(recipient, searchTerm.trim())) {
                    if (roleValue !== "All Roles") {
                        if (recipient.role === roleValue) {
                            filteredMembers.push(createData(recipient));
                        }
                    } else {
                        filteredMembers.push(createData(recipient));
                    }
                }
            });
            setRows(filteredMembers);
            setCurrent([...filteredMembers].slice(count.prev, count.next));

        } else {
            let rowData: any = [];
            if (roleValue !== "All Roles") {
                recipients.forEach((recipient: any, index: number) => {
                    if (recipient.role === roleValue) {
                        rowData.push(createData(recipient))
                    }
                })
            } else {
                rowData = recipients.map((recipient: any) => createData(recipient));
            }
            setRows(rowData);
            setCurrent([...rowData].slice(count.prev, count.next));
        }
        setItemsLoading(false);
    }
    useEffect(() => {
        // if the search text field has something and we dont have anything to display set 'nothing found' message
        if (search !== '') setNoResultsFound(rows.length === 0);
        // clear 'nothing found' message if either table is loading or we got something to display.
        // never display table content and 'nothing found' message together
        if (rows.length !== 0 || isTableLoading || itemsLoading) setNoResultsFound(false);
        // if table is not loading and we got nothing to display set 'nothing found' message
        if (rows.length === 0 && !isTableLoading && !itemsLoading) {
            setNoResultsFound(true);
            setErrorMessage('No results found !');
        }
    }, [rows, isTableLoading, itemsLoading]);

    const [noResultsFound, setNoResultsFound] = useState(false);

    const closeNotification = () => {
        setErrorMessage('');
    };

    const loadMoreMembers = (event: any) => {
        let scrollTopPos = Math.round(
            event.target.scrollHeight - event.target.scrollTop
        );
        let scrollBottom = scrollTopPos === event.target.clientHeight;

        let isScrolledToBottom =
            scrollTopPos - event.target.clientHeight <= 2 ? true : scrollBottom;

        if (isScrolledToBottom && !itemsLoading && (current.length != recipients.length)) {
            setItemsLoading(true);
            getMoreData();
        }
    }

    const handleRoleChange = (event: any) => {
        setRoleValue(event.target.value);
        setCount({
            prev: 0,
            next: membersCount
        })
    };
    const handleAddMembers = () => {
        setAddMembersConfirmation(true)
    }
    const closeAddMemberLayout = () => {
        setAddMembersConfirmation(false);
    }
    const [removeMembersArray, setremoveMembersArray] = useState<any>([]);
    const removeMembersname: Array<String> = [];
    const [allChecked, setAllChecked] = useState(false);
    const [Loading, setLoader] = useState(false);
    const [RemoveMembersConfirmation, setRemoveMembersConfirmation] = useState({
        open: false,
        title: 'Remove Members',
        message: ''
    });
    const handleRemoveMembers = () => {
        var removeMembersList = new Array<number>();
        rows.map((data: { checkbox: Boolean; personId: number; name: String }) => {
            if (data.checkbox) {
                if (removeMembersname.length < 2) {
                    removeMembersname.push(data.name.trim())
                }
                removeMembersList.push(data.personId)
            }
        }
        )
        const arraylen = removeMembersList.length
        setremoveMembersArray(removeMembersList)
        arraylen == 1 ?
            (setRemoveMembersConfirmation({ ...RemoveMembersConfirmation, open: true, message: `Do you want to remove ${removeMembersname[0]} from this channel?` }))
            : arraylen == 2 ?
                (setRemoveMembersConfirmation({ ...RemoveMembersConfirmation, open: true, message: `Do you want to remove ${removeMembersname[0]} and ${removeMembersname[1]} from this channel?` }))
                :
                (setRemoveMembersConfirmation({ ...RemoveMembersConfirmation, open: true, message: `Do you want to remove ${removeMembersname[0]}, ${removeMembersname[1]} and ${arraylen - 2} others from this channel?` }))
    }
    const closeRemoveMemberLayout = () => {
        setRemoveMembersConfirmation({ ...RemoveMembersConfirmation, open: false });
    }
    const removeMemberConfirmaiton = (removeMembersArray: any[]) => {
        // check rows state to fetch the selected checkbox (member id's)
        // api call to remove members
        const channelId = selectedGroup.id
        if (removeMembersArray.length > 0) {
            setLoader(true)
            removeMemberApi(channelId, removeMembersArray, bbdomain).then((response: any) => {
                setTableLoading(true)
                loadMembersData()
                setAllChecked(false)
                closeRemoveMemberLayout()
                setLoader(false)
            }).catch((error: any) => {
                setLoader(false)
                setShowError({ 
                    isError: false,
                    isMembersTabError: true, 
                    severity: 'ownerPrivilegesErr',
                    message: error.response.status === 401 ? 
                    'Failed to remove members from Channel. Please contact administrator' :
                    'Failed to remove members from Channel' })
                closeRemoveMemberLayout();
                setAllChecked(false)
            });
        }
    }
    const RemoveMembersConfirmationDialogStyle = {
        paper: {
            width: '450px',
            height: '291px',
        },
        root: {
            paddingTop: '20px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: 600,
            fontSize: "16px",
        },
        colorChange: {
            backgroundColor: "#C23E37!important",
            borderColor: "white!important",
            '&:hover': {
                backgroundColor: "#C23E37",
            },
        },
    };
    return (
        <div className={classes.memberDiv}>
            {addMembersConfirmation &&
                <AddMembersComponent
                    closeModal={closeAddMemberLayout}
                    channelId={selectedGroup.id}
                    channelName={selectedGroup.name}
                    bbdomain={bbdomain}
                    selectedOrganizationId={selectedOrganizationId}
                    loadMembers={loadMembersData}
                    setIsMemberError={setShowError}
                />
            }
            <ActionableAlertBox
                data-testid='RemovememberConfirmation'
                title={RemoveMembersConfirmation.title}
                shouldOpen={RemoveMembersConfirmation.open}
                onAction={() => {
                    removeMemberConfirmaiton(removeMembersArray)
                }}
                isLoading={Loading}
                disableBackdropClick={true}
                secondaryButtonLabel='Cancel'
                primaryButtonLabel='Remove'
                styles={RemoveMembersConfirmationDialogStyle}
                onClose={() => {
                    closeRemoveMemberLayout()
                }}
            >
                <Box data-testid='removeConfirmationMessage' className={classes.RemoveMemberStyle}> {RemoveMembersConfirmation.message}</Box>
            </ActionableAlertBox>

            {(showError.isMembersTabError || showError.isError) && <NotificationBox
                message={showError.message}
                severity={showError.severity}
                isShowClose={true}
                handleNotification={() => {
                    setShowError({message: '', isMembersTabError: false, isError: false, severity: ''})
                }}
            />}
            <div
                data-testid='membersPanelHeading'
                className={classes.memberTitle}
            >
                Members
      </div>
            <Grid container spacing={isSmallScrn ? 1 : 3} >
                <Grid
                    item
                    xs={7}
                    sm={isParenDrawerOpen ? 7 : 5}
                    md={isParenDrawerOpen ? 5 : 6}
                >
                    {/* Search member text box */}
                    <TextField
                        className={classNames(classes.searchBoxWidth)}
                        id='outlined-basic'
                        placeholder='Search by first name, last name, or relatives'
                        variant='outlined'
                        onChange={handleChange}
                        data-testid='searchInputBox'
                        disabled={isTableLoading || RemoveDisabled ? true : false}
                        value={search}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start' className={classes.iconPadding}>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={5}
                    sm={isParenDrawerOpen ? 5 : 3}
                    md={isParenDrawerOpen ? 3 : 3}
                >
                    {/* role select box */}
                    <Select value={roleValue} onChange={handleRoleChange}
                        className={classNames(classes.selectBoxWidth)}
                        data-testid='roleSelectBox'
                        disabled={isTableLoading || RemoveDisabled ? true : false}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}>
                        <MenuItem value="All Roles">All Roles</MenuItem>
                        <MenuItem value="School Administrator">School Admin</MenuItem>
                        <MenuItem value="Principal">Principal</MenuItem>
                        <MenuItem value="Teacher">Teacher</MenuItem>
                        <MenuItem value="Parent">Parent</MenuItem>
                        <MenuItem value="Student">Student</MenuItem>
                    </Select>
                </Grid>
                {/* condition to check whether the selected channel is of TYPE CHANNEL  */}
                {/* and login user is owner of the channel */}
                {selectedGroup.type === "CHANNEL" && isLoggedInUserOwner ?
                    <Grid
                        item
                        xs={12}
                        sm={isParenDrawerOpen ? 12 : 4}
                        md={isParenDrawerOpen ? 4 : 3}
                        className={`${isParenDrawerOpen ?
                            classNames(classes.gridRightDivOpened)
                            : classNames(classes.gridRightDiv)}`}
                    >
                        {/* Add Member button */}
                        <Button className={classes.addButtonStyle} data-testid='addMemberButton' onClick={handleAddMembers} disabled={RemoveDisabled ? true : false}>
                            <AddWithCircleIcon className={classes.attach_icon_style}
                                iconWidth="20"
                                iconHeight="20">
                            </AddWithCircleIcon>
              Add members
            </Button>
                        {/* Remove Member button */}
                        <Button className={classes.removeButtonStyle}
                            data-testid='delMemberButton'
                            disabled={RemoveDisabled ? false : true}
                            onClick={handleRemoveMembers}
                        >
                            <DeleteIcon className={classes.delete_icon_style}
                                iconColor={RemoveDisabled ? "#262626" : "#A4A4A4"}
                                iconBold="3">
                            </DeleteIcon>
              Remove
            </Button>
                    </Grid>
                    : ""}
            </Grid>

            <Paper className={classes.root}>
                <TableComponent
                    columnsConfig={columnConfig}
                    rows={current}
                    rowsAll={rowsAll}
                    isParenDrawerOpen={isParenDrawerOpen}
                    noResultsFound={noResultsFound}
                    errorMessage={errorMessage}
                    setGroup={setGroup}
                    readRate={readRate}
                    loadMoreMembers={loadMoreMembers}
                    handleToUpdate={handleToUpdate}
                    searchData={search}
                    isReachChannel={isReachChannel}
                    channelId={selectedGroup.id}
                    setisOwnerError={setShowError}
                    loadMembersData={loadMembersData}
                    isLoggedInUserOwner={isLoggedInUserOwner}
                    isParent={isParent}
                    parentColumnConfig={parentColumnConfig}
                    ChannelCreator={ChannelCreator}
                    setRemoveDisabled={setRemoveDisabled}
                    allChecked={allChecked}
                    setAllChecked={setAllChecked}
                    roleValue={roleValue}
                />
                {(isTableLoading || itemsLoading) && <LinearProgress className={classes.progress} />}
            </Paper>
        </div>
    );
};

export default MembersPanel;