export class ChannelItem {
    channelId: number = 0;
    channelName: string = "";
    channelType: string = "";
    category: string ="";
}

export class selectedChannel{
    channelType: string="";
    channelId: number = 0;
}
