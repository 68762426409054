import React from 'react';
import ProfileRelativesComponentStyle from './ProfileRelativesComponentStyle';
import ProfileRelativesComponentType from './ProfileRelativesComponentType';

const ProfileRelativesComponent = (props: ProfileRelativesComponentType) => {
  const { name, school, Role } = props;
  const classes = ProfileRelativesComponentStyle();
  return (
    <>
      <div data-testid={`relative_${name}`} className={classes.container}>
        <a className={classes.relativeName}>{name}</a>
          <div className={classes.relativeinfo}>
            School: <span>{school}</span>
          </div>
        <div className={classes.relativeinfo}>
          Role: <span>{Role}</span>
        </div>
      </div>
    </>
  );
};
export default ProfileRelativesComponent;
