
import { makeStyles, createStyles } from '@material-ui/core/styles';

const NotificationBoxStyle = makeStyles((theme: any) => {
    return createStyles({
      cardRoot: {
        flex: 1,
        alignSelf: 'flex-start',
      },
      fileError:{
        color:"#262626",
        fontSize:"15px",
        fontWeight:400,
        backgroundColor:"#FFDAD6",
        minHeight:"59px"
      },
      fileWarning:{
        color:"#262626",
        fontSize:"15px",
        fontWeight:400,
        backgroundColor:"#FFCC00",
        minHeight:"59px"
      },
      ownerPrivilegErr:{
        fontSize: '15px',
        color: 'rgb(77, 24, 22)',
        backgroundColor:'rgb(248, 235, 235)',
      },
      toaster: {
        // [theme.breakpoints.down('xs')]: {
        //   marginBottom: theme.spacing(2)
        // },
        // display: 'flex',
        // alignItems: 'center',
        // color: '#262626',
        // fontWeight: 'normal',
        // fontSize: '14px',
        // lineHeight: '21px',
        // background: '#FFB6AE'
      }
    });
});

export default NotificationBoxStyle;
