import React from 'react';
import { List, ListItem, Box } from '@material-ui/core';
import groupComponentSytle from '../styles/groupComponentstyle';
import { setChannelDelete } from '../services/ChannelService';
import { useEffect, useState, useContext } from 'react';
import { GroupEssential } from '../Types/GroupEssential';
import { GroupUnread } from '../Types/GroupUnread';
import { GroupType } from '../Types/GroupType';
import { ChannelNameType } from '../Types/ChannelNameType';
import { useMediaQuery } from 'react-responsive';
import {
    loadChannelData,
    channelList,
    setGroupUnreadCount,
} from '../dataLoaders/DataHandler';
import { UserAuthCtx } from './AuthComponent';
import { orgList } from '../dataLoaders/DataHandler';
import { StateContext } from './StateContextComponent';
import { SchoolInfo } from '../Types/SchoolInfo';
import MessageRouteContext, { MessageRouteContextType } from '../context/MessageRouteContext'
import { useMessageRoute } from '../libs/hookLib'
import ActionableAlertBox from './ActionableAlertBox/ActionableAlertBox';
import DeleteIcon from '../Icons/DeleteIcon';
import { AddWithCircleIcon } from '../Icons/AddWithCircleIcon';
import { channelCreationApi } from '../services/ChannelService';
import CustomSwitch from './CustomTable/GreenSwitch';
import CustomCircularProgress from './ReusableComponents/CircularProgress';

type groupType = {
    className: string;
    groupUnread: GroupUnread;
    isChannelEdited?: boolean;
    setGroup: React.Dispatch<React.SetStateAction<GroupEssential>>;
    setOrgListCountGroup: React.Dispatch<
        React.SetStateAction<{ [key: string]: number }>
    >;
    setParentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    selectedGroup: GroupEssential;
    selectMyGroup: string;
    setSelectMyGroup: React.Dispatch<React.SetStateAction<string>>;
    impersonating: boolean;
    setMessagePanelLoading?: Function;
    setConversationTitle?: Function;
    activePanel?: number;
    setActivePanel?: Function;
    setIsChannelEdited?: Function;
    OnDeleted?: boolean;
    setOnDeleted?: Function;
};
const GroupComponent = ({
    setGroup,
    setOrgListCountGroup,
    isChannelEdited,
    setParentDrawerOpen,
    groupUnread,
    selectedGroup,
    selectMyGroup,
    setSelectMyGroup,
    impersonating,
    setMessagePanelLoading = () => { },
    setConversationTitle = () => { },
    activePanel = 0,
    setActivePanel = () => { },
    OnDeleted,
    setOnDeleted = () => { },
    setIsChannelEdited = () => { },
}: groupType) => {
    const userDetails = useContext(UserAuthCtx).userCtx;
    let { userId, userLoggedInDomain, domainId, impersonatedUserId } = userDetails;
    var [isPageLoading, setIsPageLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [debouncedTime, setDebouncedTime] = useState(0);
    const [ongoingRequest, setOngoingRequest] = useState(false)
    const [toggleStatus, setToggleStatus] = useState(false);
    const [charLeft, setCharLeft] = useState(50);
    const [updateSetGroup, setUpdateSetGroup] = useState(false)
    var [listToDisplay, setListToDisplay] = useState<Array<GroupEssential>>(
        new Array<GroupEssential>()
    );


    const [isLoading, setLoader] = useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const {
        impersonatingUserDetails,
        selectedOrganizationId,
        setEnableSchoolsToSelect,
    } = useContext(StateContext);
    const {
        isMessageRoute,
        channelType: channelTypeFromRoute = ''
    } = useContext(MessageRouteContext) as MessageRouteContextType
    let { handleInvalidRoute } = useMessageRoute()

    let schoolInfo = new SchoolInfo();
    //ALLOW_ROLES_TO_ADD_CHANNEL (roleId - Role) : {3 - District Administrator, 4 - School Administrator, 5 - Superintendent, 6 - Principal, 8 - Teacher}
    const ALLOW_ROLES_TO_ADD_CHANNEL = ['3', '4', '5', '6', '8'];
    const CHANNEL_NAME_MAX_LENGTH = 50;

    //check if user is impersonated, then use impersonating user school id and name
    if (impersonating) {
        const impersonatedSchool = orgList.find(
            (org) => org.id === parseInt(impersonatingUserDetails.schoolId)
        );
        if (impersonatedSchool) {
            schoolInfo = {
                schoolId: impersonatedSchool.id,
                schoolName: impersonatedSchool.name,
            };
        }
    } else {
        schoolInfo = selectedOrganizationId;
    }

    type groupSelect = {
        key: number;
    };

    function groupListOnClick(groupEss: GroupEssential) {
        setGroup(groupEss);
        if (groupEss.unreadCount > 0) {
            //check if user is impersonated
            if (impersonating) {
                userId = impersonatingUserDetails.userId;
                userLoggedInDomain = impersonatingUserDetails.userLoggedInDomain;
                impersonatedUserId = impersonatingUserDetails.impersonatedUserId;
            }
        }
    }

    useEffect(() => {
        if (isChannelEdited) {
            setListToDisplay([]);
            setIsPageLoading(true);
            let userInfo = { userId, userLoggedInDomain, domainId };
            loadChannelData(userInfo).then((resp) => {
                setIsChannelEdited(false)
                setUpdateSetGroup(true)
                filterLoadedList()
            });
        }
    }, [isChannelEdited])
    // custom group selector for navigating to group onClick of 'jump icon'
    useEffect(() => {
        if (!impersonating || selectMyGroup.startsWith('old')) groupSelector();
        isMessageRoute && groupSelector()
    }, [selectMyGroup]);

    useEffect(() => {
        if (listToDisplay.length === 0) {
            const grpEss1 = new GroupEssential();
            groupListOnClick(grpEss1);
            setIsEmpty(true);
            setOnDeleted(false);
        }
        else {
            setSelectedIndex(0);
            groupListOnClick(listToDisplay[0]);
            setOnDeleted(false);
        }
    }, [OnDeleted]);

    function groupSelector() {
        let matchCounter = 0;
        var localSelector = selectMyGroup;
        if (localSelector.startsWith('old'))
            localSelector = selectMyGroup.substring(3, selectMyGroup.length);
        if (selectMyGroup !== '') {
            for (var index = 0; index < listToDisplay.length; index++) {
                var groupToDisplay = listToDisplay[index];
                if (groupToDisplay.id === localSelector) {
                    // simulate a channel click
                    groupListOnClick(groupToDisplay);
                    setSelectedIndex(index);
                    setSelectMyGroup('');
                    isMessageRoute && isMobile && setParentDrawerOpen(false);
                    return;
                } else {
                    matchCounter++
                }
            }

            if (listToDisplay.length
                && isMessageRoute
                && matchCounter == listToDisplay.length
                && channelTypeFromRoute?.toUpperCase() == GroupType.Groups) {
                // redirect to home page as group not found
                handleInvalidRoute()
            }
        }
    }
    useEffect(() => {
        if (impersonating || isMessageRoute) groupSelector();
        getOrgUnreadCount();
        if (updateSetGroup && listToDisplay.length > 0) {
            groupListOnClick(listToDisplay[selectedIndex])
            setUpdateSetGroup(false)
        }
    }, [listToDisplay]);

    // custom unread count setter to be moved to context
    useEffect(() => {
        if (groupUnread.id !== null) {
            setGroupUnreadCount(groupUnread.id, groupUnread.unreadCount);
            getOrgUnreadCount();
        }
    }, [groupUnread]);

    useEffect(() => {
        filterLoadedList();
        getOrgUnreadCount();
    }, [channelList]);

    function getOrgUnreadCount() {
        const orgListCountLocal: { [key: string]: number } = {};
        channelList.map((grpEss: GroupEssential) => {
            if (orgListCountLocal[grpEss.orgName] === undefined)
                orgListCountLocal[grpEss.orgName] = 0;
            orgListCountLocal[grpEss.orgName] =
                orgListCountLocal[grpEss.orgName] + grpEss.unreadCount;
        });
        setOrgListCountGroup(orgListCountLocal);
    }

    useEffect(() => {
        //Reseting the selectedChannelIndex
        if (isMobile) {
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(0);
            !isMessageRoute && setGroup(new GroupEssential());
        }

        if (channelList.length === 0 && selectedOrganizationId.schoolId !== 0) {
            setIsPageLoading(true);
            setIsEmpty(false);
            let userInfo = { userId, userLoggedInDomain, domainId, impersonatedUserId };
            if (impersonating) {
                const { userId, userLoggedInDomain, impersonatedUserId } = impersonatingUserDetails;
                userInfo = { userId, userLoggedInDomain, domainId: domainId, impersonatedUserId };
            }

            setOngoingRequest(true)
            loadChannelData(userInfo).then(() => {
                setOngoingRequest(false)
                filterLoadedList(true);
                setIsPageLoading(false);
            });
        } else {
            filterLoadedList(false);
        }
    }, [selectedOrganizationId]);

    function filterLoadedList(intialLoad: boolean = false) {
        let filteredChannels = channelList.filter(
            (grpEss: GroupEssential) => grpEss.orgId === schoolInfo.schoolId
        );
        setListToDisplay(filteredChannels);
        if (filteredChannels.length > 0) {
            setIsPageLoading(false);
            if (isMobile) {
                setEnableSchoolsToSelect(true);
            }
        }
        if (filteredChannels.length === 0) {
            const grpEss1 = new GroupEssential();
            groupListOnClick(grpEss1);
        }

        if (intialLoad && filteredChannels.length === 0) {
            setIsEmpty(true);
            setEnableSchoolsToSelect(true);
        } else {
            setIsEmpty(false);
        }

        if (
            !intialLoad &&
            filteredChannels.length == 0 &&
            selectedOrganizationId.schoolId !== 0 &&
            !impersonating
        ) {
            !ongoingRequest && setIsEmpty(true)
            setEnableSchoolsToSelect(true);
        }
    }

    useEffect(() => {
        if (selectedGroup.groupType === GroupType.DirectMessage) {
            setSelectedIndex(-1);
        }
    }, [selectedGroup]);

    const classes = groupComponentSytle()({
        charLeft
    });

    useEffect(() => {
        activePanel == 1 && setSelectedIndex(-1);
    }, [activePanel]);


    const [addChannelConfirmation, setAddChannelConfirmation] = useState({
        open: false,
        title: 'Create a channel'
    });

    const [ChannelName, setChannelName] = useState<ChannelNameType>({
        name: '',
        exist: false,
        creationFailed: false
    });


    const AddChannelConfirmationDialogStyle = {
        paper: {
            width: '27rem',
            height: '18rem',
        },
        root: {
            padding: '0 16px',
            marginTop: '16px'
        }
    };

    function newChannel(filteredChannels: string | any[], ChName: any) {
        for (var index = 0; index < filteredChannels.length; index++) {
            var groupToDisplay = filteredChannels[index];
            if (groupToDisplay.name === ChName) {
                // simulate a channel click
                setSelectedIndex(index);
                groupListOnClick(groupToDisplay)
                setIsPageLoading(false)
                break;
            }
        }
    }

    const channelCreationHandler = (ChName: any, togStatus: any) => {
        setLoader(true);
        let userInfo = { userId, userLoggedInDomain, domainId };
        channelCreationApi(ChName, togStatus, userLoggedInDomain).then((response: any) => {
            setLoader(false);
            setChannelName({ ...ChannelName, name: "", exist: false })
            setIsPageLoading(true)
            setIsEmpty(false)
            loadChannelData(userInfo).then(() => {
                let filteredChannels = channelList.filter(
                    (grpEss: GroupEssential) => grpEss.orgId === schoolInfo.schoolId
                );
                setListToDisplay(filteredChannels)
                newChannel(filteredChannels, ChName)
            });
            setAddChannelConfirmation({ ...addChannelConfirmation, open: false });
            setToggleStatus(false)
            setCharLeft(50);
        }).catch((err) => {
            setLoader(false);

            if (err.response.status == 409) {
                setChannelName({ ...ChannelName, creationFailed: false, exist: true })
            }
            else {
                setChannelName({ ...ChannelName, exist: false, creationFailed: true })
            }
            setAddChannelConfirmation({ ...addChannelConfirmation, open: true });
        });
    };


    const addChannelOnClickHandler = () => {
        setAddChannelConfirmation({ ...addChannelConfirmation, open: true });
        return true
    };

    const changeCharLeft = (event: any) => {
        const input = event.target;
        const start = input.selectionStart;
        const end = input.selectionEnd;
        setChannelName({ ...ChannelName, name: input.value, exist: false, creationFailed: false, input: input, start: start, end: end});
        
        if (event.target.value.trim('').length == 0) event.target.value = ''
        setCharLeft(CHANNEL_NAME_MAX_LENGTH - event.target.value.length)
    };


    const [unloadConfirmation, setUnloadConfirmation] = useState({
        open: false,
        title: 'Discard changes?',
        message: 'You have unsaved attachments. Are you sure you want to leave?',
        index: 0,
        groups: { name: "", id: "", type: "", unreadCount: 0, groupType: GroupType.Groups, orgName: "" },
    });

    const unloadConfirmationDialogStyle = {
        paper: {
            width: '20rem',
            height: '15rem',
        },
        root: {
            padding: '0 16px',
        },
    };

    const debouncedHandler = (index: any, groupEss: any) => {

        if (localStorage.getItem('uploadstatus') === "inprogress" && groupEss.id != selectedGroup.id) {
            setUnloadConfirmation({ ...unloadConfirmation, open: true, index: index, groups: groupEss });
            return false
        }
        setSelectedIndex(index);
        if (isMobile) {
            setParentDrawerOpen(false);
            groupListOnClick(groupEss);
        } else {
            if (groupEss.id != selectedGroup.id) {
                setConversationTitle(groupEss.name);
                setMessagePanelLoading(true);

                clearInterval(debouncedTime);
                let timeOut = window.setTimeout(function() {
                    groupListOnClick(groupEss);
                }, 500);
                setDebouncedTime(timeOut);
            } else {
                window.setTimeout(function() {
                    groupListOnClick(groupEss);
                }, 500);
            }
        }
    };

    useEffect(() => {
        if (selectedIndex != -1) {
            // this sets group component as active to unselects any item present in direct message component
            setActivePanel(0);
        } else {
            // this sets direct component as active to unselects any item present in group component
            setActivePanel(1);
        }
    }, [selectedIndex]);

    useEffect(() => {
        if (ChannelName.input && ChannelName.start && ChannelName.end){
            ChannelName.input.setSelectionRange(ChannelName.start, ChannelName.end)
        }
    }, [ChannelName]);

    return (
        <div className={classes.root}>


            <ActionableAlertBox
                data-testid='unloadConfirmationBox'
                title={unloadConfirmation.title}
                shouldOpen={unloadConfirmation.open}
                onAction={() => {
                    localStorage.setItem('uploadstatus', 'verified')
                    setConversationTitle(unloadConfirmation.groups);
                    debouncedHandler(unloadConfirmation.index, unloadConfirmation.groups)
                    setUnloadConfirmation({ ...unloadConfirmation, open: false, index: 0 });
                }}
                secondaryButtonLabel='Cancel'
                primaryButtonLabel='Discard'
                styles={unloadConfirmationDialogStyle}
                primaryButtonProps={{}}
                onClose={() => {
                    setUnloadConfirmation({
                        ...unloadConfirmation,
                        open: false,
                    });
                }}
            >
                <Box paddingBottom='74px'>{unloadConfirmation.message}</Box>
            </ActionableAlertBox>


            <ActionableAlertBox
                data-testid='addChannelConfirmation'
                title={addChannelConfirmation.title}
                shouldOpen={addChannelConfirmation.open}

                isLoading={isLoading}
                disableBackdropClick={true}

                onAction={() => {
                    // setCharLeft(50)
                    // setToggleStatus(false)
                    setChannelName({ ...ChannelName, exist: false, creationFailed: false })
                    if (addChannelConfirmation.open === true) {
                        channelCreationHandler(ChannelName.name.trim(), toggleStatus);
                    }
                }}
                secondaryButtonLabel='Cancel'
                primaryButtonLabel='Create'
                styles={AddChannelConfirmationDialogStyle}
                primaryButtonProps={{ disabled: charLeft === CHANNEL_NAME_MAX_LENGTH }}
                onClose={() => {
                    setCharLeft(50)
                    setToggleStatus(false)
                    setChannelName({ ...ChannelName, name: "", exist: false, creationFailed: false })
                    setAddChannelConfirmation({
                        ...addChannelConfirmation,
                        open: false,
                    });
                }}
            >
                <span className={classes.channelNameSpanEl}>Channel name</span>
                <br />
                <input className={classes.channelNameInputEl} 
                    data-testid='addChannelInputEl' 
                    placeholder='Type the name here' 
                    maxLength={CHANNEL_NAME_MAX_LENGTH} 
                    onChange={(event) => { changeCharLeft(event) }} 
                    value={ChannelName.name}></input>
                <br />
                <span className={classes.charsLeftSpanEl}>{charLeft} characters left</span>
                <div className={classes.toggleSwitchCon}>
                    <div className={classes.toggleSwitchEl}>
                        <CustomSwitch status={toggleStatus ? 1 : 0} handler={() => { setToggleStatus(!toggleStatus) }} />
                    </div>
                    <span style={{ marginBottom: (ChannelName.exist || ChannelName.creationFailed) ? "10px" : "22px" }}>Everyone can post</span>
                </div>
                {ChannelName.exist === true && (
                    <div>
                        <span className={classes.channelNameDuplicateSpanEl}>This name is already taken. Try a different name.</span>
                    </div>
                )}
                {ChannelName.creationFailed === true && (
                    <div>
                        <span className={classes.channelNameDuplicateSpanEl}>Channel Creation Failed.Please try again.</span>
                    </div>
                )}
            </ActionableAlertBox>

            <div className={classes.channelHeaderClass}>
                <span className={classes.iconStyle}>
                    <img className={classes.courseIconClass} src='/images/courses.png' />
                </span>
                <span data-testid='channelSpan' className={classes.spanChannelClass}>
                    {' '}
          channels
        </span>

                {ALLOW_ROLES_TO_ADD_CHANNEL.includes(localStorage.getItem('roleID') || '') && !impersonating ?
                    <AddWithCircleIcon
                        data-testid='addChannelBtn'
                        className={classes.addCircleItemClass}
                        onClick={addChannelOnClickHandler}
                    ></AddWithCircleIcon>
                    : ''
                }

            </div>
            <div>
                <List data-testid='channel-list'>
                    {isEmpty || isPageLoading ? (
                        <Box className={`${classes.centered}`}>
                            {isEmpty ? (
                                'No channels found / Channels disabled'
                            ) : (
                                    <CustomCircularProgress
                                        css={classes.centered}
                                        aria-label='Loading'
                                        size='medium'
                                    />
                                )}
                        </Box>
                    ) : (
                            <></>
                        )}
                    {listToDisplay.map((groupEss, index) => {
                        // setting the first element selected by default
                        if (
                            index === 0 &&
                            activePanel === 0 &&
                            (selectedIndex === 0 || selectedIndex === -1) &&
                            selectedGroup.groupType !== GroupType.DirectMessage && !isMessageRoute
                        ) {
                            groupListOnClick(groupEss);
                        }
                        var dataTestId = 'channel-list-item-';
                        var personid = localStorage.getItem('userId');
                        return (
                            <ListItem
                                className={classes.channelListItemClass}
                                classes={{ selected: classes.selected }}
                                button
                                key={index}
                                onClick={() =>
                                    (isMobile || index != selectedIndex) &&
                                    debouncedHandler(index, groupEss)
                                }
                                data-testid={dataTestId.concat(index.toString())}
                                selected={selectedIndex === index}
                            >
                                <div className={classes.groupName}>
                                    <span className={classes.groupNameText}>{groupEss.name}</span>
                                </div>
                                {groupEss.unreadCount !== 0 && (
                                    <div className={classes.notifyNumber}>
                                        {groupEss.unreadCount}
                                    </div>
                                )}
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        </div>
    );
};

export default GroupComponent;