export const EditorIconList = (color: string) => {
  const stroke_color = color ? color : '#262626';

  const icons: any = {
    bold: `<svg id="bold" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class=ql-stroke d="M1.5 14.5H3.71429H10.5C13 14.5 14.5 13.5 14.5 11C14.5 8.5 13 7.5 10.5 7.5C10.5 7.5 7.41559 7.5 5 7.5" stroke="${stroke_color}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M4.5 2C4.5 2 4.5 4.65685 4.5 7C4.5 9.34315 4.5 14 4.5 14" stroke="${stroke_color}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M5 7.50003C7.59375 7.50003 10.5 7.50003 10.5 7.50003C12.8846 7.50003 13.5 6.00003 13.5 4.5C13.5 2.99997 12.5 1.49997 10 1.49997L4 1.50003H1.5" stroke="${stroke_color}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    italic: `<svg id="italic" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class=ql-stroke d="M6 1H12" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M4 15H10" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M9 1L7 15" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    underline: `<svg id="underline" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class=ql-stroke d="M3 15H13" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M3 1C3 1 3 4.65685 3 7C3 10 5.23858 12 8 12C10.7614 12 13 10 13 7C13 4.65685 13 1 13 1" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    strike: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class=ql-stroke d="M11 4C11 2 10 1 7.99999 1C5.99998 1 5 2 5 4C5 6 8 7 8 7" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M11.5 10C12.5 12 11.5 15 7.99999 15C5.5 15 4 13 4 11" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M13 7L3 7" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    list_bullet: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class=ql-stroke x="3" y="2" width="1" height="2" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect class=ql-stroke x="3" y="7" width="1" height="2" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect class=ql-stroke x="3" y="12" width="1" height="2" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M8 3H13" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M8 8H13" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M8 13H13" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect class=ql-stroke x="3" y="7" width="1" height="2" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect class=ql-stroke x="3" y="12" width="1" height="2" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    list_ordered: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class=ql-stroke d="M1 1H3V6" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M1 6H5" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M8 3H15" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M8 11H15" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M1 9H5V11L1 14H5" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
    code_block: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path class=ql-stroke d="M4 5.00195L1 8.00195L4 11.002" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M12 11.002L15 8.00195L12 5.00195" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M10 1.00004L6.00001 15" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        `,
    link: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class=ql-stroke width="4.6811" height="7.02165" rx="2" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 15.7763 3.53372)" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <rect class=ql-stroke width="4.6811" height="7.02165" rx="2" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 8.5 10.81)" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path class=ql-stroke d="M11 5L5 11" stroke="${stroke_color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
  };
  return icons;
};

export const getSVGIcon = (type: string) => {
  const stroke_color = '#262626';
  const icon = EditorIconList(stroke_color)[type];
  return icon;
};
