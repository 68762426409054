import { makeStyles } from '@material-ui/core/styles';
import { RoundedCorner } from '@material-ui/icons';
import ReactTooltip from "react-tooltip";

const TableComponentStyle = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'None!important',
        border: 'None!important',
    },
    container: {
        maxHeight: 'calc(100vh - 208px)',
    },
    iconPadding: {
        marginRight: '5px!important',
    },
    ownerPopupStyle: {
        paddingLeft: '18px',
        paddingRight: '12px',
        fontSize: '15px',
        marginTop: '2.125rem',
    },
    tableHeadBorder: {
        borderTop: 'None!important',
        borderBottom: '2px solid #CDCDCD!important',
        background: 'none',
        position: 'relative',
        padding: '12px 6px 12px 6px',
    },
    tableCell: {
        padding: '0',
    },
    cellStyle: {
        verticalAlign: 'middle',
        padding: '12px 6px 12px 6px',
        overflowWrap: 'anywhere',
    },
    alertSpacing: {
        paddingLeft: '73px',
    },
    searchDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        display: 'flex',
        alignItems: 'center',
    },
    userNameContainer: {
        width: '100%'
    },
    tableHeadRow: {
        backgroundColor: '#E5E5E5'
    },
    nameCellStyle: {
        ['@media (max-width: 480px)']: {
            borderRadius: '0px 10px 10px 0px',
            backgroundColor: "#F8F8F8"
        },
    },
    checkboxCellStyle: {
        ['@media (max-width: 480px)']: {
            verticalAlign: 'top',
            backgroundColor: 'white'
        },
    },
    checkboxIcon: {
        backgroundColor: 'white',
        border: '2px #767676 solid',
        borderRadius: '2px',
        width: 16,
        height: 16,
    },
    checkedIcon: {
        backgroundColor: 'green',
        borderRadius: '2px',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
    },
    userNameStyle: {
        ['@media (max-width: 480px)']: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        fontWeight: 600,
    },
    checkboxStyle: {
        padding: 0,
        margin: 0,
    },
    avatarCellStyle: {
        ['@media (max-width: 480px)']: {
            verticalAlign: 'top!important',
            borderRadius: '10px 0px 0px 10px',
            backgroundColor: "#F8F8F8"
        },
    },
    relativeHeadingStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
        fontWeight: 600,
    },
    roleStyle: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
    },
    relativeStyle: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
    },
    small: {
        position: 'inherit',
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    infoIcon: {
        backgroundColor: '#fff',
        paddingTop: '3px',
        paddingLeft: '10px',
        width: '20px',
        minWidth: '20px',
        minHeight: 'inherit',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    tooltipHeader: {
        color: 'inherit',
        fontSize: '18px',
    },
    Ownertooltip: {
        display: 'flex',
        color: 'inherit',
        fontSize: '12px',
    },
    Messagetooltip: {
        display: 'flex',
        color: 'inherit',
        fontSize: '12px',
        justifyContent: 'center',
    },
    messageBtn: {
        color: '#000',
        width: '38px',
        height: '38px',
        border: 'none',
        backgroundColor: 'transparent',
        justifyContent: 'center',
    },
    hoverOnIcon: {
        '&:hover': {
            backgroundColor: '#CDCDCD',
            cursor: 'pointer'
        },
    },
    messageTabletBtn: {
        color: '#000',
        width: '110px',
        maxWidth: '350px',
        border: '1px solid #000',
        backgroundColor: '#000',
        padding: '5px',
        '&:hover': {
            color: '#fff',
        },
    },
    arrow: {
        '&::after': {
            background: '#000',
        },
    },
    tooltip: {
        backgroundColor: '#000',
        color: '#fff',
        boxShadow: 'none',
    },
    closebutton: {
        position: 'absolute',
        top: '12px',
        right: '14px',
        fontSize: '16px',
        cursor: 'pointer',
        zIndex: 9999,
    },
    actionColumn: {
        justifyContent: 'flex-start',
        marginLeft: '13px'
    },
    ownerColumn: {
        justifyContent: 'flex-start',
        marginLeft: '6px'
    },
    avatarColumn: {
        maxWidth: '30px',
    },
    customScroll: {
        overflow: 'auto',
        scrollbarColor: '#666666 transparent',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '8px',
        },

        /* Track */
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#666666',
            borderRadius: '10px',
            border: '1px solid #666',
        },

        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#666666',
        },
    },
}));

export default TableComponentStyle;
