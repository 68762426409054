import React from 'react';
import InforCardType from './InforCardType';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import InfoCardStyle from './InfoCardStyle';

const InfoCard = (props: InforCardType) => {
  const classes = InfoCardStyle();
  const { title, count } = props;
  const caption = props.caption || '';

  return (
    <Card
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              gutterBottom
              variant="h4"
            >
              { title }
            </Typography>
            <Typography
              color="textPrimary"
              className={classes.count}
            >
              { count }
            </Typography>
          </Grid>
        </Grid>
        <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <Typography
            className={classes.caption}
          >
            { caption }
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default InfoCard;

