import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DownloadIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4 4H1V15H15V4H12'
          stroke={props.disable ? '#666666' : '#262626'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 1V12'
          stroke={props.disable ? '#666666' : '#262626'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 10L8 12L10 10'
          stroke={props.disable ? '#666666' : '#262626'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadIcon;
