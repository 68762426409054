import { GroupType } from "./GroupType";

export class GroupEssential {
  public name: string = "";
  public id: string = "";
  public type: string = "";
  public unreadCount: number = 0;
  public groupType: GroupType = GroupType.Groups;
  public orgName: string = "";
  public owner: string = "";
  public orgId: number = 0;
}

//Sample Data

// "name": "Per. 2 History",
// "id": 2386697
