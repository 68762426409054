import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AnalysisOptions } from 'aws-sdk/clients/cloudsearch';

const enabledIcon = (
  <svg
    width='36'
    height='20'
    viewBox='0 0 36 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect opacity='0.3' width='36' height='20' rx='10' fill='#148F43' />
    <circle cx='26' cy='10' r='10' fill='#007D2C' />
    <path
      d='M23 10L25 13L29 7'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const disabledIcon = (
  <svg
    width='36'
    height='20'
    viewBox='0 0 36 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='36' height='20' rx='10' fill='#CDCDCD' />
    <circle cx='10' cy='10' r='10' fill='#666666' />
    <path
      d='M13.0039 7L7.00014 12.9962'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.00293 7L13.0029 13'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const StatusIcon = (props: any) => {
  const useStyles = makeStyles((theme) => {
    return createStyles({
      root: {
        width: '3rem',
      },
    });
  });
  const classes = useStyles();

  return (
    <SvgIcon
      classes={{
        root: classes.root,
      }}
      {...props}
    >
      {props.isenabled ? enabledIcon : disabledIcon}
    </SvgIcon>
  );
};

export default StatusIcon;
